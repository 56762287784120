import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.min.css";

const Welcome = () => {
	const navigate = useNavigate();
	useEffect(() => {
		// Retrieve token from localStorage
		//const token = localStorage.getItem("authToken");
		const authToken = sessionStorage.getItem("authToken");
		if (authToken) {
			console.log("token found.");
			navigate("/dashboard");
		} else {
			navigate("/login");
			console.log("No token found in sessionStorage.");
		}
	}, [navigate]);
};

export default Welcome;
