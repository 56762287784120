import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
const Bmc_WeeklyGrid = () => {
  const { role } = useAuth();
  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [branchlist, setBranchlist] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMonth, setSelectedMonth] = useState();
  const [cmDashboardCount, setBmDashboardCount] = useState([]);
  const selectBranch = (e) => {
    const branch = e.target.value;
    setSelectedBranch(branch);
    sessionStorage.setItem('selectedBranchId', branch); // Store in sessionStorage
    window.location.reload();
  };
  const selectMonth = (e) => {
    const sMonth = e.target.value;
    setSelectedMonth(sMonth);
    sessionStorage.setItem('selectedMonth', sMonth); // Store in sessionStorage
    window.location.reload();
  };
  const wtoday = new Date();
  const thisMonth = wtoday.getMonth() + 1;
  const thisYear = wtoday.getFullYear();
  const thisMonthWithYear = `${thisYear}-${String(thisMonth).padStart(2, '0')}`;

  useEffect(() => {
    const selectMonth = sessionStorage.getItem('selectedMonth') || thisMonthWithYear;
    setSelectedMonth(selectMonth);
  }, [thisMonthWithYear]);


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [excelData, setExcelData] = useState([]);

  const [items, setItems] = useState([]);
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const userEmp_code = userProfile.emp_code;
  console.log('userProfile:', userEmp_code);
  useEffect(() => {
    const fetchBranchList = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMUserBranchListDetails`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          if (result.status) {
            console.log('Branch List Data:', result.data);
            setBranchlist(result.data); // Update branchlist state
          } else {
            console.error('Error:', result.message);
          }
        } else {
          console.error('API Error:', response.statusText);
        }
      } catch (error) {
        console.error('Fetch Error:', error);
      }
    };

    fetchBranchList();
  }, [token]);


  useEffect(() => {
     
    const selectBranch = sessionStorage.getItem('selectedBranchId');
    const selectMonth = sessionStorage.getItem('selectedMonth') || thisMonthWithYear;
    // Set the current date if selectedDate is empty

    setSelectedMonth(selectMonth);

    // Check if selectedBranch exists, otherwise set default from branchlist once it's available
    if (selectBranch) {
      setSelectedBranch(selectBranch);
    } else if (branchlist.length > 0) {
      setSelectedBranch('0');
      sessionStorage.setItem('selectedBranchId', '0');
    }
  }, [branchlist, thisMonthWithYear]);

  useEffect(() => {
    if (branchlist.length > 0) {
      const defaultBranch = sessionStorage.getItem('selectedBranchId') || '0';
      setSelectedBranch(defaultBranch);
      sessionStorage.setItem('selectedBranchId', defaultBranch);
    }
  }, [branchlist]);

  const today = new Date().toISOString().split('T')[0];
  const newFormData = {
    branch_id: sessionStorage.getItem('selectedBranchId') || '',
    w_0100: 'Is BMW collection is done as on frequent basis without any deviation?',
    w_0101: '',
    w_0102: '',
    w_0200: 'Is the Front Main Board Clearly Visible & Untampered',
    w_0201: '',
    w_0202: '',
    w_0300: 'Is the CCTV positioned correctly, & all CCTVs are functioning & untampered?',
    w_0301: '',
    w_0302: '',
    w_0400: 'Is Pest Control visit done in last 15 days ?',
    w_0401: '',
    w_0402: '',
    w_0500: 'is Air Condition got serviced in the quarter as per schedule?',
    w_0501: '',
    w_0502: '',
    w_0600: 'is AC Service report available?',
    w_0601: '',
    w_0602: '',
    w_0700: 'is Generator got serviced in the quarter as per schedule',
    w_0701: '',
    w_0702: '',
    w_0800: 'is UPS got serviced in the quarter as per schedule',
    w_0801: '',
    w_0802: '',
    w_0900: 'is EPBX got serviced in the quarter as per schedule',
    w_0901: '',
    w_0902: '',
    w_1000: 'is Printer AMC/CMC Done',
    w_1001: '',
    w_1002: '',
    w_1100: 'is Scrap return done as in the month',
    w_1101: '',
    w_1102: '',
    w_1200: 'is Lenin Checking done?',
    w_1201: '',
    w_1202: '',
    w_1300: 'Are the new employees are well on boarded. Any challenges?',
    w_1301: '',
    w_1302: '',
    w_1400: 'NABL & NABH compliance across all depts (All Quality parameters)',
    w_1401: '',
    w_1402: '',
    createdBy: userProfile.emp_code,
  };




  useEffect(() => {
    const fetchMorningTasks = async () => {
      setLoading(true); // Show loading spinner
      const requestData = {
        selectedMonth: selectedMonth,
        selectedBranch: selectedBranch,
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBmcWeeklyTaskList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const result = await response.json();
          console.log('Fetched Data:', result); // Log the entire response

          // Access the nested data structure
          if (result && result.data && Array.isArray(result.data.data)) {
            setItems(result.data.data); // Populate items state
            setExcelData(result.data.data || []);
            setFilteredItems(result.data.data); // Populate filteredItems state
            console.log('Items:', result.data.data); // Log the valid data
          } else {
            console.error('Invalid data format. Expected array in result.data.data:', result.data);
            setItems([]); // Clear items if data format is unexpected
            setFilteredItems([]); // Clear filteredItems
            setExcelData([]);
          }
        } else {
          console.error('Fetch error:', await response.text());
          setExcelData([]);
        }
      } catch (error) {
        console.error('Error:', error);
        //navigate('/login');
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchMorningTasks();
  }, [token, navigate, selectedBranch, selectedMonth]);


  useEffect(() => {
    console.log('Items:', items);
    console.log('Filtered Items:', filteredItems);
  }, [items, filteredItems]);



  useEffect(() => {
    if (items.length > 0) {
      // Filter items based on search query
      const filtered = items.filter((item) => {
        const branchName = item.branch_name?.toLowerCase() || '';
        const formattedDate = new Date(item.createdDTM).toLocaleDateString('en-GB').replace(/\//g, '-');
        const createdDTM = item.createdDTM ? formattedDate.toLowerCase() : '';
        const fname = item.fname?.toLowerCase() || '';
        const clusterName = item.cluster_name?.toLowerCase() || '';
        const zoneName = item.zone_name?.toLowerCase() || '';

        return (
          branchName.includes(searchQuery.toLowerCase()) ||
          createdDTM.includes(searchQuery.toLowerCase()) ||
          fname.includes(searchQuery.toLowerCase()) ||
          clusterName.includes(searchQuery.toLowerCase()) ||
          zoneName.includes(searchQuery.toLowerCase())
        );
      });


      // Sort filtered items by role in descending order
      const sortedFilteredItems = filtered.sort((a, b) => {
        const createdDTMA = a.createdDTM ? a.createdDTM.toLowerCase() : '';
        const createdDTMB = b.createdDTM ? b.createdDTM.toLowerCase() : '';

        return createdDTMB.localeCompare(createdDTMA); // Descending order
      });

      // Set filtered items only if they differ
      if (
        JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
      ) {
        setFilteredItems(sortedFilteredItems);

      }
    }
  }, [searchQuery, items]);



  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];

  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);





  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const exportToExcel = () => {
    const formattedExcelData = excelData.map((item) => ({
      CreatedDTM: `${item.createdDTM || '--'} `,
      CreatedBy: `${item.fname + ' ' + item.lname || '--'} `,
      Branch: item.branch_name || '--',
      isBMWCollectionDoneOnFrequentBasisWithoutAnyDeviatione: `${item.w_0101 || '--'}`,
      isBMWCollectionDoneOnFrequentBasisWithoutAnyDeviatione_Description: ` ${item.w_0102 || '--'}`,

      isFrontMainBoardClearlyVisibleAndUntampered: `${item.w_0201 || '--'} `,
      isFrontMainBoardClearlyVisibleAndUntampered_Description: `${item.w_0202 || '--'
        }`,

      isCCTVPositionedCorrectlyAndAllCCTVsFunctioningAndUntampered: `${item.w_0301 || '--'}`,
      isCCTVPositionedCorrectlyAndAllCCTVsFunctioningAndUntampered_Description: `${item.w_0302 || '--'}`,

      isAirConditionServicedInQuarterAsPerSchedule: `${item.w_0401 || '--'} `,
      isAirConditionServicedInQuarterAsPerSchedule_Description: ` ${item.w_0402 || ''}`,

      isUPSServicedInQuarterAsPerSchedule: `${item.w_0501 || '--'} `,
      isUPSServicedInQuarterAsPerSchedule_Desription: ` ${item.w_0502 || '--'}`,

      isACServiceReportAvailable: `${item.w_0601 || '--'} `,
      isACServiceReportAvailable_Description: ` ${item.w_0602 || '-- '}`,

      isGeneratorServicedInQuarterAsPerSchedule: `${item.w_0701 || '--'} `,
      isGeneratorServicedInQuarterAsPerSchedule_Decription: ` ${item.w_0702 || '--'}`,

      isPrinterAMCOrCMCDone: `${item.w_0801 || '--'} `,
      isPrinterAMCOrCMCDone_Description: `${item.w_0802 || '--'}`,

      isPestControlVisitDoneInLast15Days: `${item.w_0901 || '--'}`,
      isPestControlVisitDoneInLast15Days_Description: `${item.w_0902 || '--'}`,

      isEPBXServicedInQuarterAsPerSchedule: `${item.w_1001 || '--'}`,
      isEPBXServicedInQuarterAsPerSchedule_Descritpion: `${item.w_1002 || '-- '}`,

      ModifiedOn: `${item.modifiedDTM || '-- '}`,

    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
    XLSX.writeFile(workbook, 'BM_Weekly_Tasks.xlsx');
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'></div>
        </div>

        <section style={{ minHeight: '630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"> <FormSkeleton /></div>
                  ) : (
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-sm-5'>
                          <table>
                            <tr>
                              <td>
                                <div className='mb-2'>
                                  <select
                                    style={{ width: '5em' }}
                                    className='form-control form-control-sm'
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                  >
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select></div>
                              </td>
                              <td style={{ width: '100%' }}>
                                <div
                                  id='example1_filter'
                                  className='dataTables_filter mb-2'
                                >

                                  <input
                                    type='search'
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className='form-control form-control-sm'
                                    placeholder='Search'
                                    aria-controls='example1'

                                  />

                                </div>
                              </td>
                              <td>
                                <div className='mb-2 '>
                                  <input type='month' name='selectedMonth' id='selectedMonth'
                                    onChange={selectMonth}
                                    value={selectedMonth || thisMonthWithYear}
                                    className='form-control form-control-sm ' />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>



                        <div className='col-sm-4'>
                          <table style={{ width: '100%' }}>
                            <tr>

                              <td>
                                <select
                                  className="form-control form-control-sm"
                                  name='selectedBranch'
                                  id='selectedBranch'
                                  value={selectedBranch}
                                  onChange={selectBranch}
                                >
                                  <option value="0">All Branch</option>
                                  {branchlist.length > 0 ? (
                                    branchlist.map((branch) => (
                                      <option key={branch.branch_id} value={branch.branch_id}>
                                        {branch.branch}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="" disabled>
                                      No branches available
                                    </option>
                                  )}
                                </select>


                              </td>
                              <td>
                                <button className='btn btn-sm btn-info '
                                  onClick={() => navigate(`/bmccombogrid`)}>
                                  BM Tasks
                                </button>

                              </td>
                            </tr>
                          </table>

                        </div>

                        <div className='col-sm-2'>

                          <button
                            type='button'
                            className='btn btn-sm btn-secondary ml-2'
                            onClick={exportToExcel}
                          > Weekly Task Excel
                          </button>
                        </div>
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Dated</th>
                              <th>Branch</th>
                              <th>CreatedBy</th>
                            </tr>
                          </thead>
                          <tbody>

                            {currentItems.length > 0 ? (
                              currentItems.map((item, index) => {
                                const formattedDate = new Date(item.createdDTM).toLocaleDateString('en-GB').replace(/\//g, '-');
                                return (
                                  <tr key={index}>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.bmw_id) {                                            
                                            navigate(`/EditBmWeeklyTask/${item.bmw_id}`);
                                          } else {
                                            console.error('mid is undefined or empty');
                                            //toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }}
                                        className="btn btn-sm btn-warning fas fa-edit"
                                      ></i>
                                    </td><td>{formattedDate || 'N/A'}</td>
                                    <td>{item.branch_name || 'N/A'}</td>
                                    <td>{`${item.fname || ''} ${item.lname || ''}`}</td>
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>

                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);
                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );
                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }
                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${pageNumber === currentPage ? 'active' : ''
                                    }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>
                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>

      </div>
      <Footer />
    </>
  );
};

export default Bmc_WeeklyGrid;
