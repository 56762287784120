import React, {   useState } from 'react';
import Logo from '../../assets/images/favicon.ico';
import SidebarImage from '../../assets/images/OperationsChecklists.gif';
import Image from 'react-bootstrap/Image';
import { useAuth } from '../../context/authprovider';
import { Link } from 'react-router-dom';

function Sidebar() {
	const { role } = useAuth();
	const [isSettingsOpen, setSettingsOpen] = useState(false);
	const [isSidebarOpen, setSidebarOpen] = useState(false);

	// Toggle Settings accordion
	const toggleSettings = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const settingsNav = e.target.closest('.nav-item');
		const submenu = settingsNav.querySelector('.nav-treeview');

		if (submenu) {
			if (isSettingsOpen) {
				submenu.style.display = 'none';
				settingsNav.classList.remove('menu-open');
			} else {
				submenu.style.display = 'block';
				settingsNav.classList.add('menu-open');
			}
			setSettingsOpen(!isSettingsOpen);
		}
	};
	function logout() {
		//console.log('Clearing session storage');
		sessionStorage.clear(); // Clear all data in sessionStorage
		//console.log('Session storage cleared:', sessionStorage); // Check if it's cleared
		window.location.href = '/login'; // Redirect to the login page
	}
	// Close sidebar
	const closeSidebar = () => {
		const body = document.querySelector('body');
		body.classList.remove('sidebar-open');
		setSidebarOpen(false);
	};

	 
	const hasAccess = role === 'SUPER_ADMIN';

	return (
		<aside
			className={`main-sidebar sidebar-dark-primary ${
				isSidebarOpen ? 'open' : ''
			}`}
		>
			<div className='sidebar'>
				<a href='/' className='brand-link bg-warning'>
				<Image
						style={{
							opacity: '.8',
							backgroundColor: '#ffffff',
							borderRadius: 10,
						}}
						className='brand-image elevation-3 '
						src={Logo}
						alt='VDC'
					/>
					<div>
						<span className='brand-text font-weight-bold'> VDC Check List</span>
					</div>
					{isSidebarOpen && (
						<button
							onClick={closeSidebar}
							className='btn btn-link position-absolute'
							style={{ right: '1px', top: '10px', color: '#fff', zIndex: 1000 }}
						>
							<i className='fas fa-times'></i>
						</button>
					)}
				</a>
				<div className='sidebar'>
					<nav className='mt-2'>
						<ul
							className='nav nav-pills nav-sidebar flex-column'
							data-widget='treeview'
							role='menu'
							data-accordion='false'
						>
							<li className='nav-item'>
								<a
									href='/dashboard'
									className='nav-link'
									onClick={closeSidebar}
								>
									<i className='nav-icon fas fa-tachometer-alt' />
									<p>Dashboard</p>
								</a>
							</li>
							{hasAccess && (
								<>
									<li className='nav-item'>
										<a href='/' className='nav-link' onClick={toggleSettings}>
											<i className='nav-icon fas fa-user' />
											<p>
												Settings
												<i className='fas fa-angle-left right' />
											</p>
										</a>
										<ul
											className='nav nav-treeview'
											style={{ display: isSettingsOpen ? 'block' : 'none' }}
										>
											<li className='nav-item'>
												<Link
													to='/manage_zone'
													className='nav-link'
													onClick={closeSidebar}
												>
													<i className='far fa-circle nav-icon icon-y' />
						 							<p>Manage Zone</p>
												</Link>
											</li>
											<li className='nav-item'>
												<Link
													to='/manage_branch'
													className='nav-link'
													onClick={closeSidebar}
												>
													<i className='far fa-circle nav-icon icon-y' />
													<p>Branch List</p>
												</Link>
											</li>
											<li className='nav-item'>
												<Link
													to='/manage_cluster'
													className='nav-link'
													onClick={closeSidebar}
												>
													<i className='far fa-circle nav-icon icon-y' />
													<p>Cluster List</p>
												</Link>
											</li>
											{/* <li className='nav-item'>
												<Link
													to='/cluster_map'
													className='nav-link'
													onClick={closeSidebar}
												>
													<i className='far fa-circle nav-icon icon-y' />
													<p>Cluster Map</p>
												</Link>
											</li> */}
											<li className='nav-item'>
												<Link
													to='/zone_map'
													className='nav-link'
													onClick={closeSidebar}
												>
													<i className='far fa-circle nav-icon icon-y' />
													<p>Zone Map</p>
												</Link>
											</li>
											<li className='nav-item'>
												<Link
													to='/manage_users'
													className='nav-link'
													onClick={closeSidebar}
												>
													<i className='far fa-circle nav-icon icon-y' />
													<p>User Details</p>
												</Link>
											</li>
										</ul>
									</li>
								</>
							)}
							{/* Conditionally render the "Employee List" based on role */}
							{/* {role === 'CM' && (
								<li className='nav-item'>
									<a href='/Bmc_morningGrid' className='nav-link'>
										<i className='nav-icon fas fa-th' />
										<p>BM Morning Task list</p>
									</a>
								</li>
							)}
							{role === 'CM' && (
								<li className='nav-item'>
									<a href='/Bmc_NightGrid' className='nav-link'>
										<i className='nav-icon fas fa-th' />
										<p>BM Night Task list</p>
									</a>
								</li>
							)}
								{role === 'CM' && (
								<li className='nav-item'>
									<a href='/Bmc_WeeklyGrid' className='nav-link'>
										<i className='nav-icon fas fa-th' />
										<p>BM Weekly list</p>
									</a>
								</li>
							)} */}
							{role === 'CM' && (
								<li className='nav-item'>
									<a href='/bmccombogrid' className='nav-link'>
										<i className='nav-icon fas fa-th' />
										<p>BM Task </p>
									</a>
								</li>
							)}


							{/* {role === 'CM' && (
								<li className='nav-item'>
									<a href='/cmGrid' className='nav-link'>
										<i className='nav-icon fas fa-th' />
										<p>CM Morning Task</p>
									</a>
								</li>
							)}
							{role === 'CM' && (
								<li className='nav-item'>
									<a href='/cmnightgrid' className='nav-link'>
										<i className='nav-icon fas fa-th' />
										<p>CM Night Task</p>
									</a>
								</li>
							)} */}
							{role === 'AVP' && (
								<> 
								<li className='nav-item'>
										<a href='/Bm_z_CombinedGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Task</p>
										</a>
									</li>

									{/* <li className='nav-item'>
										<a href='/Bm_z_morningGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Morning Task</p>
										</a>
									</li>
									<li className='nav-item'>
										<a href='/Bm_z_NightGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Night Task</p>
										</a>
									</li> */}
									{/* <li className='nav-item'>
										<a href='/Cm_z_morningGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>CM Morning Task</p>
										</a>
									</li>
									<li className='nav-item'>
										<a href='/Cm_z_nightGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>CM Night Task</p>
										</a>
									</li> */}
								</>
							)}

							{role === 'ZONAL_MANAGER' && (
								<>
								
								<li className='nav-item'>
										<a href='/Bm_z_CombinedGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Task</p>
										</a>
									</li>

									{/* <li className='nav-item'>
										<a href='/Bm_z_morningGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Morning Task</p>
										</a>
									</li>
									<li className='nav-item'>
										<a href='/Bm_z_NightGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Night Task</p>
										</a>
									</li> */}
									{/* <li className='nav-item'>
										<a href='/Cm_z_morningGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>CM Morning Task</p>
										</a>
									</li>
									<li className='nav-item'>
										<a href='/Cm_z_nightGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>CM Night Task</p>
										</a>
									</li> */}
								</>
							)}
							{role === 'BM' && (
								<>
								<li className='nav-item'>
										<a href='/bmcombogrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Tasks</p>
										</a>
									</li>
									{/* <li className='nav-item'>
										<a href='/bmgrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Morning Task</p>
										</a>
									</li>
									<li className='nav-item'>
										<a href='/bmnightgrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Night Closing Task</p>
										</a>
									</li>
									<li className='nav-item'>
										<a href='/BmWeeklyGrid' className='nav-link'>
											<i className='nav-icon fas fa-th' />
											<p>BM Weekly Task</p>
										</a>
									</li> */}
								</>
							)}
							<li className='nav-item'>							 
								<a href='/changePassword' className='nav-link'>
								<i className='nav-icon fas fa-unlock-alt' />
											<p>Change Password</p>
										</a>
							</li>
							<li className='nav-item'>
								<Link href='#' onClick={logout} className='nav-link'>
									<i
										className='nav-icon fas fa-sign-out-alt'
										style={{ marginRight: '5px' }}
									></i>{' '}
									{/* Adjust margin as needed */}
									Logout
								</Link>
							</li>

							 

 
						</ul>
						
					</nav>

					<div className="sidebar-bottom">
                        <Image
                            style={{ maxWidth: '100%' }}
                            src={SidebarImage}
                            alt='VDC'
                        />
                    </div>
				 		</div>
			</div>
		</aside>
	);
}

export default Sidebar;
