import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';


import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FormSkeleton from '../../components/other/formSkeleton';
const BmGrid = () => {
  const { role } = useAuth();

  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [branchlist, setBranchlist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const userEmp_code = userProfile.emp_code;
  // Get today's date in YYYY-MM-DD format
  const today = new Date();
  const thisMonth = today.getMonth() + 1;
  const thisYear = today.getFullYear();
  const thisMonthWithYear = `${thisYear}-${String(thisMonth).padStart(2, '0')}`;

  const todayDate = today.toISOString().split('T')[0];
  const [selectedBranch, setSelectedBranch] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const yesterdayDate = today.toISOString().split('T')[0];
  // const handlePageChange = (pageNumber) => {
  // 	setCurrentPage(pageNumber);
  // };
  const [items, setItems] = useState([]);
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const selectDate = (e) => {
    const sDate = e.target.value;
    setSelectedDate(sDate);
    sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
  };
  const selectMonth = (e) => {
    const sMonth = e.target.value;
    setSelectedMonth(sMonth);
    sessionStorage.setItem('selectedMonth', sMonth); // Store in sessionStorage
    window.location.reload();
  };
  const closeModal = () => {
    setShowModal(false);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchBranchList = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('API Response:', response); // Log API response
        if (response.ok) {
          const result = await response.json();
          console.log('Parsed API Response:', result);
          if (result.STATUS) {
            // Check the correct key
            setBranchlist(result.data);
          } else {
            //toast.error( result.message || 	'An error occurred while fetching the Branch list.',);
          }
        } else {
          const errorBody = await response.text();
          console.error('Error response:', errorBody);
          //toast.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        //toast.error('Failed to fetch branch list');
      } finally {
        setLoading(false); // Ensure loading state is set to false after the fetch
      }
    };

    fetchBranchList();
  }, [token]);

  useEffect(() => {
    const selectDate = sessionStorage.getItem('selectedDate');
    const selectBranch = sessionStorage.getItem('selectedBranchId');
    const selectMonth = sessionStorage.getItem('selectedMonth');
    // Set the current date if selectedDate is empty
    if (selectDate) {
      setSelectedDate(selectDate);
    } else {
      const today = new Date().toISOString().split('T')[0];
      setSelectedDate(today);
    }

    if (selectMonth) {
      setSelectedMonth(selectMonth);
    } else {
      setSelectedMonth(thisMonthWithYear);
    }

    // Check if selectedBranch exists, otherwise set default from branchlist once it's available
    if (selectBranch) {
      setSelectedBranch(selectBranch);
    } else if (branchlist.length > 0) {
      setSelectedBranch(branchlist[0].branch_id);
      sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
    }
  }, [branchlist, thisMonthWithYear]);

  const initialFormData = {
    mid: '',
    cluster: '',
    cluster_id: '',
    mt0100: 'is All Manpower Available?',
    mt0101: '',
    mt0102: '',
    mt0200: 'is All Manpower in proper dress code?',
    mt0201: '',
    mt0202: '',
    mt0300: 'Generator Check',
    mt0301: '',
    mt0302: '',
    mt0400: 'is Pooja Done',
    mt0401: '',
    mt0402: '',
    mt0500: 'Are all Biomedical equiment working',
    mt0501: '',
    mt0502: '',
    mt0600: 'is Doc Avaialble or Oncall Doc arranged?',
    mt0601: '',
    mt0602: '',
    mt0700: 'Previous day pending reports',
    mt0701: '',
    mt0702: '',
    mt0800: 'Are there Maintainance Observation',
    mt0801: '',
    mt0802: '',
    mt0900: 'is front door glass cleaned well?',
    mt0901: '',
    mt0902: '',
    mt1000: 'is IT Portal updated with upto date information?',
    mt1001: '',
    mt1002: '',
    branch: '',
    emp_code: userEmp_code,
    createdDTM: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the formData state with the selected date
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      ...formData, // Get the current form data, including the date and other values
      createdDTM: formData.createdDTM,  // Ensure createdDTM is included
      branch_id: sessionStorage.getItem('selectedBranchId'),  // Add branch_id from session storage or wherever it is stored
    };

    console.log('Request Data:', requestData); // Log the request data for debugging

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/addMorningTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === true) {
          navigate(`/EditBmMorningTask/${result.data}`);
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally add error handling like toast notifications
    }
  };


  useEffect(() => {
    const fetchMorningTasks = async () => {
      const requestData = {
        selectedMonth: selectedMonth,
      };
      try {
        setLoading(true); // Set loading to true while fetching
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchMorningTaskList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const result = await response.json();
          console.log('API Response:', result); // Log the full API response
          console.log('Data in result:', result.data); // Log result.data to verify

          // Access result.data.data to get the actual array
          if (Array.isArray(result.data.data)) {
            setExcelData(result.data.data);
            setFilteredItems(result.data.data); // Set the fetched data
            setItems(result.data.data);
          } else {
            ////toast.error('Fetched data is not an array');
            setExcelData([]);
          }


        } else {
          navigate(`/login`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        navigate(`/login`);

      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchMorningTasks();
  }, [token, navigate, selectedDate]); // Trigger the fetch when token, navigate, or selectedDate changes


  useEffect(() => {
    if (items.length > 0) {
      // Filter items based on search query
      const filtered = items.filter((item) => {
        const fullName = `${item.fname || ''} ${item.lname || ''
          }`.toLowerCase();

        const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
        const formattedDate = new Date(item.createdDTM).toLocaleDateString();
        const createdDTM = item.createdDTM ? formattedDate.toLowerCase() : '';
        const fname = item.fname ? item.fname.toLowerCase() : '';
        const cluster_name = item.cluster_name ? item.cluster_name.toLowerCase() : '';
        const zone_name = item.zone_name ? item.zone_name.toLowerCase() : '';

        return (
          branch_name.includes(searchQuery.toLowerCase()) ||
          createdDTM.includes(searchQuery.toLowerCase()) ||
          fname.includes(searchQuery.toLowerCase()) ||
          cluster_name.includes(searchQuery.toLowerCase()) ||
          zone_name.includes(searchQuery.toLowerCase())
        );
      });

      // Sort filtered items by role in descending order
      const sortedFilteredItems = filtered.sort((a, b) => {
        const createdDTMA = a.createdDTM ? a.createdDTM.toLowerCase() : '';
        const createdDTMB = b.createdDTM ? b.createdDTM.toLowerCase() : '';

        return createdDTMB.localeCompare(createdDTMA); // Descending order
      });

      // Set filtered items only if they differ
      if (
        JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
      ) {
        setFilteredItems(sortedFilteredItems);

      }
    }
  }, [searchQuery, items]);

  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];
  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const exportToExcel = () => {
    const formattedExcelData = excelData.map((item) => ({
      CreatedDTM: `${item.createdDTM || '--'} `,
      CreatedBy: `${item.fname + ' ' + item.lname || '--'} `,
      Branch: item.branch_name || '--',

      is_All_Manpower_Available: `${item.mt0101 || '--'} `,
      is_All_Manpower_Available_Description: ` ${item.mt0102 || '--'}`,

      is_All_Manpower_In_ProperDressCode: `${item.mt0201 || '--'} `,
      is_All_Manpower_In_ProperDressCode_Desription: `${item.mt0202 || '--'}`,

      Generator_Check: `${item.mt0301 || '--'}`,
      Generator_Check_Desription: `${item.mt0302 || ''}`,

      is_Pooja_Done: `${item.mt0401 || '--'} `,

      Are_All_Biomedical_Equiment_Working: `${item.mt0501 || '--'}`,
      Are_All_Biomedical_Equiment_Working_Description: ` ${item.mt0502 || '--'}`,

      is_Doc_Avaialble_or_Oncall_Doc_Arranged: `${item.mt0601 || '--'}`,
      is_Doc_Avaialble_or_Oncall_Doc_Arranged_Description: `${item.mt0602 || ''}`,

      Previous_Day_Pending_Reports: `${item.mt0701 || '--'} `,
      Previous_Day_Pending_Reports_Description: ` ${item.mt0702 || '--'}`,

      Are_There_Maintainance_Observation: `${item.mt0801 || '--'} `,
      Are_There_Maintainance_Observation_Description: ` ${item.mt0802 || '-- '
        }`,
      is_Front_Door_Glass_Cleaned_Well: `${item.mt0901 || '--'}`,
      is_Front_Door_Glass_Cleaned_Well_Description: ` ${item.mt0902 || '-- '
        }`,

      is_IT_Portalup_Dated_With_Upto_Date_Information: `${item.mt1001 || '--'}  `,
      is_IT_Portalup_Dated_With_Upto_Date_Information_Description: ` ${item.mt1002 || ' '
        }`,
      LastModifiedDTM: `${item.modifiedDTM || '--'} `,

    }));
    const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
    XLSX.writeFile(workbook, 'BM_Morning_Tasks.xlsx');
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'></div>
        </div>

        <section style={{ minHeight: '630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"><FormSkeleton /></div>
                  ) : (
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-sm-6'>
                          <table>
                            <tr>
                              <td>
                                <div className='mb-2'>
                                  <select
                                    style={{ width: '5em' }}
                                    className='form-control form-control-sm'
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                  >
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select></div>
                              </td>
                              <td style={{ width: '100%' }}>
                                <div
                                  id='example1_filter'
                                  className='dataTables_filter mb-2'
                                >

                                  <input
                                    type='search'
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className='form-control form-control-sm'
                                    placeholder='Search'
                                    aria-controls='example1'

                                  />

                                </div>
                              </td>
                              <td>
                                <div className='mb-2 '>
                                  <input type='month' name='selectedMonth' id='selectedMonth'
                                    onChange={selectMonth}
                                    value={selectedMonth || thisMonthWithYear}
                                    className='form-control form-control-sm ' />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>



                        <div className='col-sm-2'>
                          <button
                            type='button'
                            className='btn btn-sm btn-success'
                            onClick={() => setShowModal(true)}
                          >
                            Add Morning Task
                          </button>
                        </div>
                        <div className='col-sm-2'>

                          <button
                            type='button'
                            className='btn btn-sm btn-secondary'
                            onClick={exportToExcel}
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Branch</th>
                              <th>Dated</th>
                              <th>Created by</th>
                              <th>Cluster</th>
                              <th>Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.length > 0 ? (
                              currentItems.map((item, index) => {
                                const formattedDate = new Date(item.createdDTM).toLocaleDateString();
                                return (
                                  <tr key={index}>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.mid) {
                                            console.log('Navigating to EditBmMorningTask with mid:', item.mid); // Log the mid value
                                            navigate(`/EditBmMorningTask/${item.mid}`);
                                          } else {
                                            console.error('mid is undefined or empty');
                                            //toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }}
                                        className="btn btn-sm btn-warning fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>{item.branch_name}</td>
                                    <td>{formattedDate}</td>
                                    <td>{item.fname} {item.lname}</td>
                                    <td>{item.cluster_name}</td>
                                    <td>{item.zone_name}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);

                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );

                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }

                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${pageNumber === currentPage ? 'active' : ''
                                    }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>

                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>

      </div>

      {/* Custom Modal */}
      {showModal && (
        <div className='customModal'>
          <div className='customModalContent'>
            <span className='close' onClick={handleCloseModal}>
              &times;
            </span>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Add Task</label>
                <input
                  type="date"
                  name="createdDTM"
                  onChange={handleInputChange}
                  min={yesterdayDate}
                  max={todayDate}
                  required
                  className="form-control form-control-sm col-5"
                />
              </div>
              <button type="submit" className="btn btn-success btn-sm" disabled={loading}>
                Submit
              </button>
            </form>


          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default BmGrid;
