import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';

const EditBmWeeklyTask = () => {
	const { role } = useAuth();
	const [showSnackbar, setShowSnackbar] = useState(false);
 
	const [loading, setLoading] = useState([]);
	 
	 
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	// const [selectedBranch, setSelectedBranch] = useState();
	 
	const [mtDetails, setMtDetails] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const { bmw_id } = useParams(); // Extract the bmw_id from the URL parameters
	const [bmw_idSelected, setBmw_idSelected] = useState(bmw_id); // Set the bmw_idSelected state with the extracted bmw_id
	//const [items, setItems] = useState([]);
	useEffect(() => {
		if (bmw_id) {
			setBmw_idSelected(bmw_id); // Set the bmw_idSelected state
		} else {
			console.error('bmw_id is undefined or invalid');
		}
	}, [bmw_id]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};



	const [formData, setFormData] = useState({
		bmw_id: '',
		branch_id: sessionStorage.getItem('selectedBranchId') || '',
		w_0100: 'Is BMW collection is done as on frequent basis without any deviation?',
		w_0101: '',
		w_0102: '',
		w_0200: 'Is the Front Main Board Clearly Visible & Untampered',
		w_0201: '',
		w_0202: '',
		w_0300: 'Is the CCTV positioned correctly, & all CCTVs are functioning & untampered?',
		w_0301: '',
		w_0302: '',
		w_0400: 'Is Pest Control visit done in last 15 days ?',
		w_0401: '',
		w_0402: '',
		w_0500: 'is Air Condition got serviced in the quarter as per schedule?',
		w_0501: '',
		w_0502: '',
		w_0600: 'is AC Service report available?',
		w_0601: '',
		w_0602: '',
		w_0700: 'is Generator got serviced in the quarter as per schedule',
		w_0701: '',
		w_0702: '',
		w_0800: 'is UPS got serviced in the quarter as per schedule',
		w_0801: '',
		w_0802: '',
		w_0900: 'is EPBX got serviced in the quarter as per schedule',
		w_0901: '',
		w_0902: '',
		w_1000: 'is Printer AMC/CMC Done',
		w_1001: '',
		w_1002: '',
		w_1100: 'is Scrap return done as in the month',
		w_1101: '',
		w_1102: '',
		w_1200: 'is Lenin Checking done?',
		w_1201: '',
		w_1202: '',
		w_1300: 'Are the new employees are well on boarded. Any challenges?',
		w_1301: '',
		w_1302: '',
		w_1400: 'NABL & NABH compliance across all depts (All Quality parameters)',
		w_1401: '',
		w_1402: '',
		createdBy: userEmp_code,
	});

	const today = new Date() ;
	const nowDay = new Date();
	nowDay.setDate(nowDay.getDate() - 1);
	// const previousDay = nowDay.toISOString().split('T')[0];
	// const todayDate = today.toISOString().split('T')[0];
	const yesterday = new Date(today);
	let yesterdayDate = null;
	if (today.getDay() === 1) { // Check if today is Monday   
	  yesterday.setDate(today.getDate() - 2); // Set to Saturday
	} else {    
	  yesterday.setDate(today.getDate() - 1);
	}
	yesterdayDate = yesterday.toISOString().split('T')[0];

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					bmw_id: bmw_idSelected,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getBmWeeklyTask`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});
				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}
				const mtData = await mtResponse.json();
				if (mtData && mtData.data) {
					//console.log('BMweeklydata :', mtData.data);
					setMtDetails([mtData.data]); // Set the received data to state as an array
					setFormData(mtData.data); // Set the form data with the received data
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}
		fetchMtData();
	}, [token, bmw_idSelected]); // Dependencies to trigger the effect

	// const selectBranch = (e) => {
	// 	const branchId = e.target.value;
	// 	setSelectedBranch(branchId);
	// 	sessionStorage.setItem('selectedBranchId', branchId); // Store in sessionStorage
	// };

	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => { setShowModal(false); };
	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const fileSize = file.size / 1024 / 1024; // Convert size to MB

			if (!['image/jpeg', 'application/pdf'].includes(fileType)) {
				alert('Only .jpg or .pdf files are allowed.');
				e.target.value = ''; // Clear the input
				return;
			}

			if (fileSize > 5) {
				alert('File size must be less than 5MB.');
				e.target.value = ''; // Clear the input
				return;
			}

			// If file is valid, set it to state or perform further actions
			// setFile(file); // Example state update
		}
	};

	const handleFileSubmit = async (e) => {
		e.preventDefault();
		const fileInput = e.target.elements.file;
		const file = fileInput.files[0];

		if (!file) {
			alert('Please select a file to upload.');
			return;
		}

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('bmw_id', bmw_idSelected);

			const response = await fetch(`${process.env.REACT_APP_API_URL}api/uploadFile`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status}`);
			}

			const result = await response.json();
			if (result.status) {
				setShowModal(false); // Close the modal
				sessionStorage.setItem('showSnackbar', 'true');
				window.location.reload();
			} else {
				alert('Failed to upload file.');
			}
		} catch (error) {
			console.error('Error uploading file:', error);
			alert('An error occurred while uploading the file.');
		}
	};

	const [fileslist, setFileslist] = useState([]);
	useEffect(() => {
		if (token && bmw_idSelected) {
			const fetchFiles = async () => {
				setLoading(true); // Set loading state
				const formData = new FormData();
				formData.append('bmw_id', bmw_idSelected);

				try {
					const response = await fetch(`${process.env.REACT_APP_API_URL}api/getFiles`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: formData,
					});

					if (response.ok) {
						const result = await response.json();
						console.log('Files List:', result);

						// Check for the new `status` key
						if (result?.status === 'success') {
							setFileslist(result.data || []); // Use an empty array as fallback for `data`
						} else {
							console.error(
								'Failed to fetch files:',
								result?.message || 'Unexpected response structure'
							);
						}
					} else {
						const errorBody = await response.text();
						console.error('API Error Response:', errorBody);
					}
				} catch (error) {
					console.error('Fetch error:', error.message || error);
				} finally {
					setLoading(false); // Ensure loading state is reset
				}
			};

			fetchFiles();
		}
	}, [token, bmw_idSelected]);



	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			// Prepare the request payload in JSON format with updated mtDetails

			const requestData = { ...formData, bmw_id: bmw_idSelected };
			const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/updateBmWeeklyTask`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`, // Ensure correct format
				},
				body: JSON.stringify(requestData), // Stringify the updated data to send
			});

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {
				console.log('Data submitted successfully!');
				sessionStorage.setItem('showSnackbar', 'true');
				window.location.reload()
			} else {
				console.error('Failed to submit data');

			}
		} catch (error) {
			console.error('Error submitting data:', error);
 
		}
	};
	useEffect(() => {
		if (sessionStorage.getItem('showSnackbar') === 'true') {
			setShowSnackbar(true);
			sessionStorage.removeItem('showSnackbar');
			// Hide snackbar after 3 seconds
			setTimeout(() => setShowSnackbar(false), 2000);
		}
	}, []);
	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}

				{/* /.content-header */}
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'></div>
										{showSnackbar && <div className="snackbar">Form Submitted Successfully!</div>}

										{/* Display all fields from mtDetails */}
										<div className='col-sm-12'>
											{mtDetails && mtDetails.length > 0 ? (
												<>
													{mtDetails.map((task, index) => (
														<React.Fragment key={`${task.id}-${index}`}>
															<div className='row'>
																<div className='col-3 col-md-3'>
																	<p className='mt-2 pp1'>
																		<b>Weekly Task : </b>{new Date(task.createdDTM).toLocaleDateString('en-US', {
																			day: 'numeric',
																			month: 'short',
																			year: 'numeric',
																			hour: 'numeric',
																			minute: 'numeric',
																		})}

																	</p>

																</div>
																<div className='col-3 col-md-3'>
																	<p className='mt-2 pp1'>
																		<b>By:</b> {task.fname + ' ' + task.lname} &nbsp;
																	</p>

																</div>
																<div className='col-3 col-md-3'>
																	<p className='mt-2 pp1'>
																		<b>	Branch :</b> {task.branch_name}
																	</p>

																</div>

																{role === 'BM' && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>

																		<table>
																			<tr>
																				<td>
																					<button style={{ minWidth: '10em' }}
																						className='btn btn-sm btn-info '
																						onClick={() => navigate(`/BmWeeklyGrid`)}>
																						Weekly Tasks
																					</button></td>
																				<td>

																					{(formData.createdDTM >= yesterdayDate && role === 'BM') && (
																						<button style={{ minWidth: '10em' }}
																							className='btn btn-sm btn-primary'
																							onClick={() => setShowModal(true)}>
																							Upload Files
																						</button>
																					)}


																				</td>
																			</tr>
																		</table>
																	</div>)}

																{role === 'CM' && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>
																		<button style={{ minWidth: '10em' }}
																			className='btn btn-sm btn-info '
																			onClick={() => navigate(`/Bmc_WeeklyGrid`)}>
																			Weekly Tasks
																		</button>
																	</div>)}

																	 

																		{(role === 'ZONAL_MANAGER' || role === 'AVP') && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>
																		<button style={{minWidth : '10em' }}
																		className='btn btn-sm btn-info '
																			onClick={() => navigate(`/Bm_z_weeklyGrid`)}>
																			Weekly Task
																		</button>
																	</div> )}
															</div>
															<form onSubmit={(e) => handleEditSubmit(e)} className='row '>
																<div className='row'>

																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} classname='w_-2  pp1'>
																			<i className="fas fa-users bg-danger  mr-2"></i>Weekly Task
																		</p>
																	</div>
																	<div className='col-12 col-md-6 '>
																		<p><i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0100} : {task.w_0101} </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className="input-group">
																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0101 && !task.w_0101 ? 'empty-field' : ''
																					}`}
																				name="w_0101"
																				id="w_0101"
																				value={formData.w_0101 || task.w_0101}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option> {/* Default empty option */}
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>

																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0102'
																				id='w_0102'
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				value={formData.w_0102 || task.w_0102 || ''} // Set the default value here
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>
																		</div>

																	</div>

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0200} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>

																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0201 && !task.w_0201 ? 'empty-field' : ''
																					}`}
																				name='w_0201'
																				id='w_0201'
																				value={
																					formData.w_0201 || task.w_0201 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>
																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0202'
																				id='w_0202'
																				value={
																					formData.w_0202 || task.w_0202 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>


																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0300} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>
																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0301 && !task.w_0301 ? 'empty-field' : ''
																					}`}
																				name='w_0301'
																				id='w_0301'
																				value={
																					formData.w_0301 || task.w_0301 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>
																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0302'
																				id='w_0302'
																				value={
																					formData.w_0302 || task.w_0302 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>
																		</div>
																	</div>

																	{/* //Are all Biomedical equiment working */}
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0500} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>
																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0501 && !task.w_0501 ? 'empty-field' : ''
																					}`}
																				name='w_0501'
																				id='w_0501'
																				value={
																					formData.w_0501 || task.w_0501 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>
																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0502'
																				id='w_0502'
																				value={
																					formData.w_0502 || task.w_0502 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>
																		</div>
																	</div>

																	{/* Are there Maintainance Observation */}
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0800} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>
																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0801 && !task.w_0801 ? 'empty-field' : ''
																					}`}
																				name='w_0801'
																				id='w_0801'
																				value={
																					formData.w_0801 || task.w_0801 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0802'
																				id='w_0802'
																				value={
																					formData.w_0802 || task.w_0802 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>





																	{/* is Doc Avaialble or Oncall Doc arranged? */}
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0600} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>

																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0601 && !task.w_0601 ? 'empty-field' : ''
																					}`}
																				name='w_0601'
																				id='w_0601'
																				value={
																					formData.w_0601 || task.w_0601 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0602'
																				id='w_0602'
																				value={
																					formData.w_0602 || task.w_0602 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* Previous day pending reports */}

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0700} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>

																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																			 
																				className={`form-control form-control-sm ${!formData.w_0701 && !task.w_0701 ? 'empty-field' : ''
																					}`}
																				name='w_0701'
																				id='w_0701'
																				value={
																					formData.w_0701 || task.w_0701 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0702'
																				id='w_0702'
																				value={
																					formData.w_0702 || task.w_0702 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* is IT Portal updated with upto date information? */}

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_1000} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>

																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_1001 && !task.w_1001 ? 'empty-field' : ''
																					}`}
																				name='w_1001'
																				id='w_1001'
																				value={
																					formData.w_1001 || task.w_1001 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_1002'
																				id='w_1002'
																				value={
																					formData.w_1002 || task.w_1002 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>





																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0400} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>

																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0401 && !task.w_0401 ? 'empty-field' : ''
																					}`}
																				name='w_0401'
																				id='w_0401'
																				value={
																					formData.w_0401 || task.w_0401 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.w_0900} : </p>
																	</div>
																	<div className='col-4 col-md-1 mb-2'>
																		<div className='input-group'>

																			<select  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				className={`form-control form-control-sm ${!formData.w_0901 && !task.w_0901 ? 'empty-field' : ''
																					}`}
																				name='w_0901'
																				id='w_0901'
																				value={
																					formData.w_0901 || task.w_0901 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-5 mb-2'>
																		<div className='input-group'>

																			<textarea  disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																				name='w_0902'
																				id='w_0902'
																				value={
																					formData.w_0902 || task.w_0902 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>
																	<div className='col-12 col-md-12 mb-2'>
																		<div className='input-group'>
																			<input
																				name='bmw_id'
																				id='bmw_id'
																				value={
																					formData.bmw_id || task.bmw_id || ''
																				}
																				type='hidden'

																			/>
																			{(task.createdDTM >= yesterdayDate && role === 'BM') && (
																				<button
																					type='submit'
																					className='btn btn-sm btn-primary'
																				>
																					Submit
																				</button>
																			)}

																		</div>
																	</div>

																</div>
															</form>
														</React.Fragment>

													))}
												</>
											) : (
												<div style={{ minHeight: '600px' }}>
													<FormSkeleton />
												</div>
											)}

											<div className='col-sm-12 row mt-5'>

												<div className='col-12 col-md-12' >
													{fileslist.length > 0 && <p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2  pp1'>
														<i class="fas fa-file-upload bg-danger mr-2"></i>

														File Attathed with this Task
													</p>}

													{fileslist.length > 0 ? (
														fileslist.map((file, index) => (
															<p key={index} >
																<a style={{ color: '#000000' }} href={`${process.env.REACT_APP_API_URL}public/uploads/${file.file_name}`} target="_blank" rel="noreferrer">
																	<i
																		className="fas fa-file-alt fa-1x "
																		style={{ color: '#3498db' }} // Customize icon color
																	></i> {file.file_name}
																</a>
															</p>
														))
													) : (
														<p></p>
													)}

												</div>

											</div>


										</div>
										{/* Custom Modal */}
										{showModal && (
											<div className='customModal'>
												<div className='customModalContent'>
													<span className='close' onClick={handleCloseModal}>
														&times;
													</span>
													<form className="form" onSubmit={handleFileSubmit}>
														<div className="form-group">
															<label>Upload .jpg or .pdf file</label>
															<input
																type="file"
																name="file"
																onChange={handleFileInputChange}
																required
																className="form-control  "
															/>
														</div>
														<button type="submit" className="btn btn-success btn-sm" disabled={loading}>
															Upload
														</button>
													</form>


												</div>
											</div>
										)}



									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	);
};

export default EditBmWeeklyTask;
