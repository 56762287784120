import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';

import * as XLSX from 'xlsx';
import '../customStyle.css';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FormSkeleton from '../../components/other/formSkeleton';
const Manage_branch = () => {
	const navigate = useNavigate();

	const [items, setItems] = useState([]);
	const [managerslist, setManagerslist] = useState([]);
	const [clusterslist, setClusterslist] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [currentBranch, setCurrentBranch] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [filteredClusters, setFilteredClusters] = useState([]); // Clusters filtered by area
	const [modalOpen, setModalOpen] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	// Modal state
	const [showModal, setShowModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [selectedBranchInfo, setSelectedBranchInfo] = useState(null);
	const [formData, setFormData] = useState({
		branch: '',
		branch_id: '',
		branch_code: '', // Ensure this is included
		cluster_id: '', // Ensure this is included
		mobile: '',
		id: '',
		area: '',
	});
	const [arealist, setArealist] = useState('');
	const token = sessionStorage.getItem('authToken');
	console.log('token is ', token);
	const validateEditFormData = () => {
		if (!formData.branch.trim()) {
			//toast.error('Branch name is required.'); // Updated from dept_name
			return false;
		}

		return true;
	};
 
	useEffect(() => {
		if (selectedBranchInfo) {
			setFormData({
				branch: selectedBranchInfo.branch || '',
				branch_code: selectedBranchInfo.branch_code || '', // Ensure branch_code is included
				cluster_id: selectedBranchInfo.cluster_id || '', // Ensure cluster_id is included
				mobile: selectedBranchInfo.mobile || '',
				id: selectedBranchInfo.id || '',
				area_id: selectedBranchInfo.area_id || '',
				branch_id: selectedBranchInfo.branch_id || '',
			});
		} else {
			setFormData({
				branch: '',
				branch_code: '', // Reset branch_code
				cluster_id: '', // Reset cluster_id
				mobile: '',
				id: '',
				area_id: '',
				branch_id: '',
			}); // Reset form data when closing modal
		}
	}, [selectedBranchInfo]);

	useEffect(() => {
		const fetchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchDetails`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
						// No need to set 'Content-Type' for FormData
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.status) {
						setItems(result.data);
						setFilteredItems(result.data);
						setLoading(false);
					} else {
						//toast.error( 	result.message || 	'An error occurred while fetching the branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchList();
	}, []); // Empty dependency array, so this runs only once on component mount

	useEffect(() => {
		console.log('Selected Branch Info:', selectedBranchInfo); // Log selected info
		if (selectedBranchInfo) {
			setFormData({
				branch: selectedBranchInfo.branch || '',
				branch_code: selectedBranchInfo.branch_code || '', // Ensure this is set correctly
				cluster_id: selectedBranchInfo.cl_id || '', // Correctly reference the cluster ID
				mobile: selectedBranchInfo.mobile || '',
				branch_id: selectedBranchInfo.branch_id || '',
			});
		} else {
			setFormData({
				branch: '',
				branch_code: '', // Reset correctly when closing modal
				cluster_id: '',
				mobile: '',
				branch_id: '',
			});
		}
	}, [selectedBranchInfo]);

	useEffect(() => {
		if (items.length > 0) {
		  // Filter items based on search query
		  const filtered = items.filter((item) => {
			const branch_name = item.branch ? item.branch.toLowerCase() : '';
		
			const cluster = item.cluster ? item.cluster.toString().toLowerCase() : '';
			 
			const zone = item.zone ? item.zone.toString().toLowerCase() : '';
	
			return (
				(item.branch?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
				
				(item.cluster?.toString().toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
			 
				(item.zone?.toString().toLowerCase() || '').includes(searchQuery.toLowerCase())
			);
		  });
	
		  // Sort filtered items by role in descending order
		  const sortedFilteredItems = filtered.sort((a, b) => {
			const branch_nameA = a.branch_name ? a.branch_name.toLowerCase() : '';
			const branch_nameB = b.branch_name ? b.branch_name.toLowerCase() : '';
	
			return branch_nameB.localeCompare(branch_nameA); // Descending order
		  });
	
		  // Set filtered items only if they differ
		  if (JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)) {
			setFilteredItems(sortedFilteredItems);
		  }
		}
	  }, [searchQuery, items]);

	  // Pagination logic
	  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	  const indexOfLastItem = currentPage * itemsPerPage;
	  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];
	
	  const startItem = indexOfFirstItem + 1;
	  const endItem = Math.min(indexOfLastItem, filteredItems.length);
	
	  const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	  };

	  const debounceTimeout = useRef(null); // Initialize the debounceTimeout

	  const handleSearchChange = (e) => {
		  const value = e.target.value;
		  if (debounceTimeout.current) {
			  clearTimeout(debounceTimeout.current);
		  }
		  debounceTimeout.current = setTimeout(() => {
			  setSearchQuery(value);
		  }, 300); // Adjust delay as needed
	  };
	

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(column);
			setSortOrder('asc');
		}
	};

	const exportToExcel = () => {
		const filteredClusters = filteredItems.map((item, index) => ({
			SrNo: index + 1, // Add a serial number starting from 1
			Branch: item.branch,
			Cluster: item.cluster,
			zone: item.zone,
		}));

		const worksheet = XLSX.utils.json_to_sheet(filteredClusters);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
		XLSX.writeFile(workbook, 'Branches_Details.xlsx');
	};

	// Open modal for adding or editing a branch
	const openModal = () => {
		console.log('Opening modal');
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setCurrentBranch(null);
		setShowModal(false);
		setShowDeleteModal(false);
	};
	// Handle modal close
	const handleCloseModal = () => {
		setShowModal(false);
		setShowDeleteModal(false);
		setSelectedBranchInfo(null); // Clear the selected bank info after closing modal
	};
	// Handle Edit button click
	const handleEditClick = (item) => {
		console.log('Clicked Item:', item); // Debug clicked item
		setSelectedBranchInfo(item); // Set the selected bank info
		setShowModal(true); // Show the modal
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Validate form data
		if (!validateEditFormData()) {
			console.log('Validation failed');
			return; // Stop execution if validation fails
		}

		// Create FormData object
		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== undefined && formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		try {
			console.log('Calling API...'); // Indicate API call is about to happen
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNewBranch`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			console.log('API Response:', response); // Log API response
			if (response.ok) {
				const result = await response.json();
				console.log('Parsed API Response:', result);
				if (result.status) {
					  window.location.reload() ;
					closeModal();
					navigate('/manage_branch');
				} else {
					//toast.error( 	result.message || 'An error occurred while adding the branch.',);
				}
			} else {
				const errorBody = await response.text();
				console.error('Error response:', errorBody);
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const handleSubmitEditForm = async (e) => {
		e.preventDefault();

		console.log('Form Data before submission:', formData); // Log formData

		const isValid = validateEditFormData(); // Ensure validation
		console.log('Validation result:', isValid);
		if (!isValid) return;

		// Log each individual field to see if they are set correctly
		console.log('branch_code:', formData.branch_code);
		console.log('cluster_id:', formData.cluster_id);

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			console.log(`${key}: ${value}`);
		}

		console.log('Form data to submit:', formDataToSubmit); // Check what is being sent

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/editBranchDetails`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			console.log('API Response:', response); // Log the raw response

			if (response.ok) {
				const result = await response.json();
				console.log('Parsed API Response:', result); // Check the parsed response

				if (result.status) {
					  window.location.reload() 
					handleCloseModal();
					navigate('/manage_branch');
				} else {
					//toast.error(result.message || 'An error occurred while updating.');
				}
			} else {
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
				console.error('Error submitting form:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const handleSubmitDeleteForm = async (e) => {
		e.preventDefault();

		//console.log('Form Data before submission:', formData); // Log formData

		const isValid = validateEditFormData(); // Ensure validation
		console.log('Validation result:', isValid);
		if (!isValid) return;

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			console.log(`${key}: ${value}`);
		}

		console.log('Form data to submit:', formDataToSubmit); // Check what is being sent

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/deleteBranch`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			console.log('API Response:', response); // Log the raw response

			if (response.ok) {
				const result = await response.json();
				console.log('Parsed API Response:', result); // Check the parsed response

				if (result.status) {
					 
					  window.location.reload();
					handleCloseModal();
					//navigate('/manage_department');
				} else {
					//toast.error(result.message || 'An error occurred while updating.');
				}
			} else {
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
				console.error('Error submitting form:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};
	const handleDeleteClick = (item) => {
		//console.log('Clicked Item:', item); // Debug clicked item
		setSelectedBranchInfo(item); // Set the selected bank info
		setShowDeleteModal(true); // Show the modal
	};
 

	return (
		<>
			<Header /> <Sidebar />
			
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h3 className='m-0'>View Branch Details</h3>
							</div>
						</div>
					</div>
				</div>
				<section className='content'>
					<div className='container-fluid' style={{minHeight:'600px'}}>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										{loading ? (
											<p> <FormSkeleton /> </p>
										) : error ? (
											<p>Error: {error}</p>
										) : (
											<>
												<div className='row'>
													<div className='col-sm-3'>
														<div className='form-group'>
															<select
																style={{ width: '5em' }}
																className='form-control custom-select-sm'
																value={itemsPerPage}
																onChange={handleItemsPerPageChange}
															>
																<option value={10}>10</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div
															id='example1_filter'
															className='dataTables_filter'
															style={{ float: 'right' }}
														>
															<label>
															<input
																type='search'
																value={searchQuery}
																onChange={handleSearchChange}
																className='form-control form-control-sm'
																placeholder='Search'
																aria-controls='example1'
															/>
															</label>
														</div>
													</div>
													<div className='col-sm-4'>
														<span style={{ float: 'right' }}>
															<button
																type='button'
																className='btn btn-sm btn-success'
																onClick={() => setModalOpen(true)}
															>
																Add New
															</button>
															{modalOpen && (
																<div className='customModal'>
																	<div className='customModalContent'>
																		<span
																			className='close'
																			onClick={handleCloseModal}
																		>
																			&times;
																		</span>
																		<h4>Add New Branch </h4>

																		<form onSubmit={handleSubmit}>
																			<div className='modal-body'>
																				<div className='form-group'>
																					<label htmlFor='branch'>
																						Branch Name
																					</label>
																					<input
																						type='text'
																						id='branch'
																						className='form-control form-control-sm'
																						name='branch'
																						value={formData.branch}
																						onChange={handleInputChange}
																						required
																					/>
																				</div>
																				<div className='form-group'>
																					<label htmlFor='branch'>
																						Branch Code
																					</label>
																					<input
																						type='text'
																						id='branch_code'
																						className='form-control form-control-sm'
																						name='branch_code'
																						value={formData.branch_code}
																						onChange={handleInputChange}
																						required
																					/>
																				</div>
																			</div>
																			<div className='modal-footer'>
																				<button
																					type='button'
																					className='btn btn-secondary'
																					onClick={closeModal}
																				>
																					Close
																				</button>
																				<button
																					type='submit'
																					className='btn btn-primary'
																				>
																					Add
																				</button>
																			</div>
																		</form>
																	</div>
																</div>
															)}
															<button
																type='button'
																className='btn btn-sm btn-secondary ml-2'
																onClick={exportToExcel}
															>
																Export to Excel
															</button>
														</span>
													</div>
												</div>

												<table className='table table-bordered table-striped'>
													<thead>
														<tr>
															<th>S.No</th>
															<th>
																<button
																	onClick={() => handleSort('branch')}
																	className='btn btn-link'
																	style={{
																		color: 'black',
																		backgroundColor: 'transparent',
																		border: 'none',
																		cursor: 'pointer',
																	}}
																>
																	Branch Name{' '}
																	{sortColumn === 'branch' &&
																		(sortOrder === 'asc' ? '🔼' : '🔽')}
																</button>
															</th>

															<th>
																<button
																	onClick={() => handleSort('cluster_name')}
																	className='btn btn-link'
																	style={{
																		color: 'black',
																		backgroundColor: 'transparent',
																		border: 'none',
																		cursor: 'pointer',
																	}}
																>
																	Cluster{' '}
																	{sortColumn === 'cluster_name' &&
																		(sortOrder === 'asc' ? '🔼' : '🔽')}
																</button>
															</th>
															<th>Zone</th>

															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{currentItems.length > 0 ? (
															currentItems.map((item, index) => (
																<tr key={item.id}>
																	<td>
																		{index +
																			1 +
																			(currentPage - 1) * itemsPerPage}
																	</td>
																	<td>{item.branch}</td>

																	<td>
																		{item.cluster || (
																			<small style={{ color: '#9c9a9a' }}>
																				No cluster mapped
																			</small>
																		)}
																	</td>
																	<td>
																		{item.zone || (
																			<small style={{ color: '#9c9a9a' }}>
																				No zone mapped
																			</small>
																		)}
																	</td>

																	<td>
																		<button
																			type='button'
																			className='btn btn-success btn-sm'
																			onClick={() => handleEditClick(item)}
																		>
																			Edit
																		</button>

																		<button
																			style={{ marginLeft: '1em' }}
																			type='button'
																			className='btn btn-danger btn-sm'
																			onClick={() => handleDeleteClick(item)}
																		>
																			Delete
																		</button>
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan='6' className='text-center'>
																	No records found.
																</td>
															</tr>
														)}
													</tbody>
												</table>

												<nav>
													<ul className='pagination' style={{ float: 'right' }}>
														<li className='page-item'>
															<button
																className='page-link'
																onClick={() =>
																	handlePageChange(currentPage - 1)
																}
																disabled={currentPage === 1}
																style={{
																	fontSize: '12px',
																	padding: '5px 10px',
																}} // smaller font and padding
															>
																Previous
															</button>
														</li>
														{[...Array(totalPages).keys()].map((pageNumber) => (
															<li
																key={pageNumber}
																className={`page-item ${
																	pageNumber + 1 === currentPage ? 'active' : ''
																}`}
															>
																<button
																	className='page-link'
																	onClick={() =>
																		handlePageChange(pageNumber + 1)
																	}
																	style={{
																		fontSize: '12px', // smaller font
																		padding: '5px 10px', // reduce padding for smaller buttons
																	}}
																>
																	{pageNumber + 1}
																</button>
															</li>
														))}
														<li className='page-item'>
															<button
																className='page-link'
																onClick={() =>
																	handlePageChange(currentPage + 1)
																}
																disabled={currentPage === totalPages}
																style={{
																	fontSize: '12px',
																	padding: '5px 10px',
																}} // smaller font and padding
															>
																Next
															</button>
														</li>
													</ul>
												</nav>

												<p>
													Showing {startItem} to {endItem} of{' '}
													{filteredItems.length} entries
												</p>
											</>
										)}
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{/* Custom Modal */}
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<h4>Edit Branch Details</h4>
						{selectedBranchInfo && (
							<>
								{console.log('Selected Branch Info:', selectedBranchInfo)}
								<form onSubmit={handleSubmitEditForm}>
									<div className='form-group'>
										<label htmlFor='branch'>Branch</label>
										<input
											type='text'
											id='branch'
											name='branch'
											value={formData.branch}
											onChange={handleInputChange}
											className='form-control'
											required
										/>
									</div>

									<input
										type='hidden'
										name='branch_id'
										value={formData.branch_id}
									/>
									<button type='submit' className='btn btn-sm btn-primary'>
										Submit
									</button>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			{showDeleteModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>

						{selectedBranchInfo && (
							<>
								{/* {console.log('Selected Branch Info:', selectedBranchInfo)} */}
								<form onSubmit={handleSubmitDeleteForm}>
									<div className='modal-body'>
										<div className='form-group'>
											<label htmlFor='department'>
												Are you sure? Want to delete branch - {formData.branch}
											</label>
										</div>
									</div>
									<div className='modal-footer'>
										<input
											type='hidden'
											name='branch_id'
											value={formData.branch_id || ''}
										/>
										<button
											type='button'
											className='btn btn-sm btn-secondary'
											onClick={closeModal}
										>
											Close
										</button>
										<button type='submit' className='btn btn-sm btn-primary'>
											Update
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Manage_branch;
