import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
const BmWeeklyGrid = () => {
  const { role } = useAuth();
  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [branchlist, setBranchlist] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();  
  const [selectedMonth, setSelectedMonth] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};
	const [excelData, setExcelData] = useState([]);

  const today = new Date();
  const thisMonth = today.getMonth() + 1;
  const thisYear = today.getFullYear();
  const thisMonthWithYear = `${thisYear}-${String(thisMonth).padStart(2, '0')}`;


  const [items, setItems] = useState([]);
	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	}; 
  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
  console.log('userProfile:', userEmp_code);
  useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
 
				if (response.ok) {
					const result = await response.json();
					 
					if (result.STATUS) {					 
						setBranchlist(result.data);
					} else {}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					
				}
			} catch (error) {
				console.error('Fetch error:', error);
				
			} finally {
				setLoading(false); 
			}
		};

		fetchBranchList();
	}, [token]);

  useEffect(() => {
     
    const selectBranch = sessionStorage.getItem('selectedBranchId');
    const selectMonth = sessionStorage.getItem('selectedMonth') || thisMonthWithYear;    

    setSelectedMonth(selectMonth);

    // Check if selectedBranch exists, otherwise set default from branchlist once it's available
    if (selectBranch) {
      setSelectedBranch(selectBranch);
    } else if (branchlist.length > 0) {
      setSelectedBranch(branchlist[0].branch_id);
      sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
    }
  }, [branchlist, thisMonthWithYear]);


  const selectMonth = (e) => {
    const sMonth = e.target.value;
    setSelectedMonth(sMonth);
    sessionStorage.setItem('selectedMonth', sMonth); // Store in sessionStorage
    window.location.reload();
  };
  

  const newFormData = { 
    branch_id: sessionStorage.getItem('selectedBranchId') || '' ,  
		w_0100: 'Is BMW collection is done as on frequent basis without any deviation?' , 
		w_0101: '' , 
		w_0102: '' , 
		w_0200: 'Is the Front Main Board Clearly Visible & Untampered' , 
		w_0201: '' , 
		w_0202: '' , 
		w_0300: 'Is the CCTV positioned correctly, & all CCTVs are functioning & untampered?' , 
		w_0301: '' , 
		w_0302: '' , 
		w_0400: 'Is Pest Control visit done in last 15 days ?' , 
		w_0401: '' , 
		w_0402: '' , 
		w_0500: 'is Air Condition got serviced in the quarter as per schedule?' , 
		w_0501: '' , 
		w_0502: '' , 
		w_0600: 'is AC Service report available?' , 
		w_0601: '' , 
		w_0602: '' , 
		w_0700: 'is Generator got serviced in the quarter as per schedule' , 
		w_0701: '' , 
		w_0702: '' , 
		w_0800: 'is UPS got serviced in the quarter as per schedule' , 
		w_0801: '' , 
		w_0802: '' , 
		w_0900: 'is EPBX got serviced in the quarter as per schedule' , 
		w_0901: '' , 
		w_0902: '' , 
		w_1000: 'is Printer AMC/CMC Done' , 
		w_1001: '' , 
		w_1002: '' , 
		w_1100: 'is Scrap return done as in the month' , 
		w_1101: '' , 
		w_1102: '' , 
		w_1200: 'is Lenin Checking done?' , 
		w_1201: '' , 
		w_1202: '' , 
		w_1300: 'Are the new employees are well on boarded. Any challenges?' , 
		w_1301: '' , 
		w_1302: '' , 
		w_1400: 'NABL & NABH compliance across all depts (All Quality parameters)' , 
		w_1401: '' , 
		w_1402: '' , 
    createdBy: userProfile.emp_code ,
  };
   
  const createNewBMweeklyTask = async () => {       
    const requestData = {
      createdDTM:today,				 
      ...newFormData,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/createBmWeeklyTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('BmWeeklyTask :', result.data); // Log API response
        if (result.status === true) {	          			 
          navigate(`/EditBmWeeklyTask/${result.data}`);
        }					 
      }
    } catch (error) {
       
    }
  };

 
  useEffect(() => {
    const fetchMorningTasks = async () => {
      const requestData = {
        selectedMonth: selectedMonth,
      };
      try {
      setLoading(true); // Set loading to true while fetching
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBmWeeklyTaskList`, {
        method: 'POST',
        headers: {
        Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });
    
      if (response.ok) {
        const result = await response.json();
        
         
        // Access result.data to get the actual array
        if (Array.isArray(result.data)) {
          setExcelData(result.data);
          setFilteredItems(result.data); // Set the fetched data
          setItems(result.data);
        } else {
         // // toast.error('Fetched data is not an array');
          setExcelData([]);
        }
   
  
      } else {
        const errorBody = await response.text();
        console.error('Error:', errorBody); // Log the error from the server
        //toast.error('Failed to fetch data');
        setExcelData([]);
      }
      } catch (error) {
      console.error('Fetch error:', error);
       
      } finally {
      setLoading(false); // Set loading to false after fetching
      }
    };
    
    fetchMorningTasks();
    }, [token, selectedMonth]); // Trigger the fetch when token or navigate changes
    
  useEffect(() => {
		if (items.length > 0) {
			// Filter items based on search query
			const filtered = items.filter((item) => {
				const fullName = `${item.fname || ''} ${
					item.lname || ''
				}`.toLowerCase();
 
        const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
        const formattedDate = new Date(item.createdDTM).toLocaleDateString('en-GB').replace(/\//g, '-');
        const taskDate = item.createdDTM ? formattedDate.toLowerCase() : '';
        const fname = item.fname ? item.fname.toLowerCase() : '';
        const cluster_name = item.cluster_name ? item.cluster_name.toLowerCase() : '';
        const zone_name = item.zone_name ? item.zone_name.toLowerCase() : '';

        return (
          branch_name.includes(searchQuery.toLowerCase()) ||
          taskDate.includes(searchQuery.toLowerCase()) ||
          fname.includes(searchQuery.toLowerCase()) ||
          cluster_name.includes(searchQuery.toLowerCase()) ||
          zone_name.includes(searchQuery.toLowerCase())
        );
			});

			// Sort filtered items by role in descending order
			const sortedFilteredItems = filtered.sort((a, b) => {
				const createdDTMA = a.createdDTM ? a.createdDTM.toLowerCase() : '';
				const createdDTMB = b.createdDTM ? b.createdDTM.toLowerCase() : '';

				return createdDTMB.localeCompare(createdDTMA); // Descending order
			});

			// Set filtered items only if they differ
			if (
				JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
			) {
				setFilteredItems(sortedFilteredItems);
			 
			}
		}
	}, [searchQuery, items]);

  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];

  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
 

  const exportToExcel = () => {
		const formattedExcelData = excelData.map((item) => ({
      CreatedDTM: `${item.createdDTM || '--'} `,
			CreatedBy: `${item.fname + ' ' + item.lname || '--'} `,
			Branch: item.branch_name || '--',
			isBMWCollectionDoneOnFrequentBasisWithoutAnyDeviation: `${item.w_0101 || '--'}`,
      isBMWCollectionDoneOnFrequentBasisWithoutAnyDeviation_Description: ` ${item.w_0102 || '--'}`,

			isFrontMainBoardClearlyVisibleAndUntampered: `${item.w_0201 || '--'} `,
      isFrontMainBoardClearlyVisibleAndUntampered_Description: `${
				item.w_0202 || '--'
			}`,

			isCCTVPositionedCorrectlyAndAllCCTVsFunctioningAndUntampered: `${item.w_0301 || '--'}`,
      isCCTVPositionedCorrectlyAndAllCCTVsFunctioningAndUntampered_Description: `${item.w_0302 || '--'}`,

			isAirConditionServicedInQuarterAsPerSchedule: `${item.w_0401 || '--'} `,
      isAirConditionServicedInQuarterAsPerSchedule_Description: ` ${item.w_0402 || ''}`,

			isUPSServicedInQuarterAsPerSchedule: `${item.w_0501 || '--'} `,
      isUPSServicedInQuarterAsPerSchedule_Desription: ` ${item.w_0502 || '--'	}`,

			isACServiceReportAvailable: `${item.w_0601 || '--'} `,
      isACServiceReportAvailable_Description: ` ${item.w_0602 || '-- '	}`,

			isGeneratorServicedInQuarterAsPerSchedule: `${item.w_0701 || '--'} `,
      isGeneratorServicedInQuarterAsPerSchedule_Description: ` ${item.w_0702 || '--'}`,

			isPrinterAMCOrCMCDone: `${item.w_0801 || '--'} `,
      isPrinterAMCOrCMCDone_Description: `${item.w_0802 || '--'	}`,

			isPestControlVisitDoneInLast15Days: `${item.w_0901 || '--'}`,
      isPestControlVisitDoneInLast15Days_Description:  `${item.w_0902 || '--'}`,

			isEPBXServicedInQuarterAsPerSchedule: `${item.w_1001 || '--'}`,
      isEPBXServicedInQuarterAsPerSchedule_Descritpion: `${item.w_1002 || '-- '}`,
			ModifiedOn: `${item.modifiedDTM || '-- '}`,

		}));

		const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
		XLSX.writeFile(workbook, 'BM_Weekly_Tasks.xlsx');
	};

  return (
    <>
      <Header />
      <Sidebar />
      
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'></div>
        </div>

        <section style={{ minHeight:'630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"> <FormSkeleton /></div>
                  ) : (
                    <div className="card-body">
                      <div className='row'>
                      <div className='col-sm-5'>
                          <table>
                            <tr>
                              <td>
                                <div className='mb-2'>
                                  <select
                                    style={{ width: '5em' }}
                                    className='form-control form-control-sm'
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                  >
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select></div>
                              </td>
                              <td style={{ width: '100%' }}>
                                <div
                                  id='example1_filter'
                                  className='dataTables_filter mb-2'
                                >

                                  <input
                                    type='search'
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className='form-control form-control-sm'
                                    placeholder='Search'
                                    aria-controls='example1'

                                  />

                                </div>
                              </td>
                              <td>
                                <div className='mb-2 '>
                                
                                  <input type='month' name='selectedMonth' id='selectedMonth'
                                    onChange={selectMonth}
                                    value={selectedMonth || thisMonthWithYear}
                                    className='form-control form-control-sm ' />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                        
                        
                        <div className='col-sm-4'>
  
                          <button
                            type='button'
                            className='btn btn-sm btn-success'
                            onClick={() => createNewBMweeklyTask()}
                          > Add Weekly Task  
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-secondary ml-2'
                            onClick={exportToExcel}
                          >  Export to Excel
                          </button>
                          <button className='btn btn-sm btn-info ml-2'
                                onClick={() => navigate(`/bmcombogrid`)}>
                                BM Tasks
                                </button>
                        </div>
                        
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Dated</th>
                              <th>Branch</th>
                              <th>CreatedBy</th>                                                          
                            </tr>
                          </thead>
                          <tbody>
                          {currentItems.length > 0 ? (
                              currentItems.map((item, index) => {
                                 
                                const formattedDate = new Date(item.createdDTM).toLocaleDateString('en-GB').replace(/\//g, '-');
                                return (
                                  <tr key={index}>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.bmw_id) {                                           
                                            navigate(`/EditBmWeeklyTask/${item.bmw_id}`);
                                          } else {
                                            console.error('mid is undefined or empty');                                             
                                          }
                                        }}
                                        className="btn btn-sm btn-primary fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>{formattedDate}</td>
                                    <td>{item.branch_name}</td>
                                    <td>{item.fname}{item.lname}</td>                                    
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);
                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );
                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }
                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    pageNumber === currentPage ? 'active' : ''
                                  }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav> 
                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      
      </div>
      <Footer />
    </>
  );
};

export default BmWeeklyGrid;
