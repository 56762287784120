import React from 'react';
import Loader from '../../assets/images/loader.gif'

const FormSkeleton = () => {
	return (
		// <div className='text-center mt-4'>
			 
		// 	<div className='spinner-border text-primary mt-3' role='status'>
		// 		<span className='visually-hidden'>Loading...</span>
		// 	</div>
		// </div>

		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
								<img style={{maxWidth:'250px'}} src={Loader} alt='loading' />
        {/* <div className="loader"></div> */}
    </div>
	);
};

export default FormSkeleton;
