import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
const Bmc_morningGrid = () => {
  const { role } = useAuth();
  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [clusterlist, setClusterlist] = useState([]);
  const [formData, setFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};
	const [excelData, setExcelData] = useState([]);
	// const handlePageChange = (pageNumber) => {
	// 	setCurrentPage(pageNumber);
	// };
  const [items, setItems] = useState([]);
	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

   

 

  useEffect(() => {
		if (items.length > 0) {
			// Filter items based on search query
			const filtered = items.filter((item) => {
				const fullName = `${item.fname || ''} ${
					item.lname || ''
				}`.toLowerCase();

       

				const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
				const formattedDate = new Date(item.createdDTM).toLocaleDateString();
        const createdDTM = item.createdDTM ?  formattedDate.toLowerCase() : '';
				const fname = item.fname ? item.fname.toLowerCase() : '';
				const cluster_name = item.cluster_name ? item.cluster_name.toLowerCase() : '';
				const zone_name = item.zone_name ? item.zone_name.toLowerCase() : '';
				 
				return (				 
					branch_name.includes(searchQuery.toLowerCase()) ||
					createdDTM.includes(searchQuery.toLowerCase()) ||
					fname.includes(searchQuery.toLowerCase()) ||
					cluster_name.includes(searchQuery.toLowerCase()) ||
					zone_name.includes(searchQuery.toLowerCase())
				);
			});

			// Sort filtered items by role in descending order
			const sortedFilteredItems = filtered.sort((a, b) => {
				const createdDTMA = a.createdDTM ? a.createdDTM.toLowerCase() : '';
				const createdDTMB = b.createdDTM ? b.createdDTM.toLowerCase() : '';

				return createdDTMB.localeCompare(createdDTMA); // Descending order
			});

			// Set filtered items only if they differ
			if (
				JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
			) {
				setFilteredItems(sortedFilteredItems);
			 
			}
		}
	}, [searchQuery, items]);

  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];

  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
						sessionStorage.setItem(
							'selectedClusterId',
							result.data[0].cluster_id,
						);
					} else {
            navigate(`/login`);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
          navigate(`/login`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
        navigate(`/login`);
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

  useEffect(() => {
    const fetchMorningTasks = async () => {
      try {
      setLoading(true); // Set loading to true while fetching
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBmcMorningTaskList`, {
        method: 'POST',
        headers: {
        Authorization: `Bearer ${token}`,
        }, 
      });
    
      if (response.ok) {
        const result = await response.json();
        console.log('API Response:', result); // Log the full API response
        console.log('Data in result:', result.data); // Log result.data to verify
    
        // Access result.data.data to get the actual array
        if (Array.isArray(result.data.data)) {
          setExcelData(result.data.data);
          setFilteredItems(result.data.data); // Set the fetched data
          setItems(result.data.data);
        } else {
          ////toast.error('Fetched data is not an array');
          setExcelData([]);
        }
   
  
      } else {
        //toast.error('Failed to fetch morning tasks');
      }
      } catch (error) {
      console.error('Fetch error:', error);
      navigate(`/login`);
      //toast.error('Failed to fetch morning tasks');
      } finally {
      setLoading(false); // Set loading to false after fetching
      }
    };
    
    fetchMorningTasks();
    }, [token]); // Trigger the fetch when token changes
    
   

  const exportToExcel = () => {
		const formattedExcelData = excelData.map((item) => ({
			Branch: item.branch_name || '--',
			is_All_Manpower_Available: `${item.mt0101 || '--'} ${item.mt0102 || ''}`,
			is_All_Manpower_In_ProperDressCode: `${item.mt0201 || '--'} ${
				item.mt0202 || '--'
			}`,
			Generator_Check: `${item.mt0301 || '--'} ${item.mt0302 || ''}`,
			is_Pooja_Done: `${item.mt0401 || '--'} ${item.mt0402 || ''}`,
			Are_All_Biomedical_Equiment_Working: `${item.mt0501 || '--'} ${
				item.mt0502 || ' '
			}`,
			is_Doc_Avaialble_or_Oncall_Doc_Arranged: `${item.mt0601 || '--'} ${
				item.mt0602 || ' '
			}`,
			Previous_Day_Pending_Reports: `${item.mt0701 || '--'} ${item.mt0702 || '--'}`,
			Are_There_Maintainance_Observation: `${item.mt0801 || '--'} ${
				item.mt0802 || ' '
			}`,
			is_Front_Door_Glass_Cleaned_Well: `${item.mt0901 || '--'} ${
				item.mt0902 || ' '
			}`,
			is_IT_Portalup_Dated_With_Upto_Date_Information: `${item.mt1001 || '--'} ${
				item.mt1002 || ' '
			}`,
			Dated: `${item.createdDTM || '--'} `,
			CreatedBy: `${item.fname + ' ' + item.lname || '--'} `,
		}));
		const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
		XLSX.writeFile(workbook, 'BM_Morning_Tasks_Details.xlsx');
	};

  return (
    <>
      <Header />
      <Sidebar />
      
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'></div>
        </div>

        <section style={{ minHeight:'630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"><div style={{minHeight:'630px'}}>
                    <FormSkeleton />
                    </div></div>
                  ) : (
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <select
                              style={{ width: '5em' }}
                              className='form-control form-control-sm'
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div
                            id='example1_filter'
                            className='dataTables_filter'
                          >
                            <label>
                              <input
                                type='search'
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className='form-control form-control-sm'
                                placeholder='Search'
                                aria-controls='example1'
                              />
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-5'>                           
                          <button
                            type='button'
                            className='btn btn-sm btn-secondary ml-2'
                            onClick={exportToExcel}
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Branch</th>
                              <th>Dated</th>
                              <th>Created by</th>
                              <th>Cluster</th>
                              <th>Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                          {currentItems.length > 0 ? (
                              currentItems.map((item, index) => {
                                const formattedDate = new Date(item.createdDTM).toLocaleDateString();
                                return (
                                  <tr key={index}>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.mid) {
                                            console.log('Navigating to EditBmMorningTask with mid:', item.mid); // Log the mid value
                                            navigate(`/EditBmMorningTask/${item.mid}`);
                                          } else {
                                            console.error('mid is undefined or empty');
                                            //toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }}
                                        className="btn btn-sm btn-primary fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>{item.branch_name}</td>
                                    <td>{formattedDate}</td>
                                    <td>{item.fname} {item.lname}</td>
                                    <td>{item.cluster_name}</td>
                                    <td>{item.zone_name}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);

                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );

                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }

                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    pageNumber === currentPage ? 'active' : ''
                                  }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav> 

                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      
      </div>
      <Footer />
    </>
  );
};

export default Bmc_morningGrid;
