import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FormSkeleton from '../../components/other/formSkeleton';
const BmNightGrid = () => {
  const { role } = useAuth();
  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};
 
	// const handlePageChange = (pageNumber) => {
	// 	setCurrentPage(pageNumber);
	// };
  const [items, setItems] = useState([]);
	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

  const [branchlist, setBranchlist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const userEmp_code = userProfile.emp_code;
  // Get today's date in YYYY-MM-DD format
  const today = new Date();
  const todayDate = today.toISOString().split('T')[0];
  const [selectedBranch, setSelectedBranch] = useState();
  // Get yesterday's date in YYYY-MM-DD format
  today.setDate(today.getDate() - 1);
  const yesterdayDate = today.toISOString().split('T')[0];

  const initialFormData = {
		nid:'',
		cluster: '',
		cluster_id: '',
		nt0100: 'Branch Closing time as per last update form branch',
		nt0101: '',
		nt0102: '',
		nt0200: 'Day-End Report Tally - Total Revenue',
		nt0201: '',
		nt0202: '',
		nt0300: 'Day-End Report Tally - Cash',
		nt0301: '',
		nt0302: '',
		nt0400: 'Day-End Report Tally - Paytm',
		nt0401: '',
		nt0402: '',
		nt0500: 'Day-End Report Tally - Card',
		nt0501: '',
		nt0502: '',
		nt0600: 'Total USG Done',
		nt0601: '',
		nt0602: '',
		nt0700: 'Total CT Done',
		nt0701: '',
		nt0702: '',
		nt0800: 'Total MRI Done',
		nt0801: '',
		nt0802: '',
		nt0900: 'Total X-Ray Done',
		nt0901: '',
		nt0902: '',
		nt1000: 'PDR Cases',
		nt1001: '',
		nt1002: '',
		nt1100: 'PDR Amount',
		nt1101: '',
		nt1200: 'Total Refunds (Count)',
		nt1201: '',
		nt1300: 'Total Refunds (Amount)',
		nt1301: '',
		nt1400: 'is Petty cash handling clear?',
		nt1401: '',
		nt1500: 'Logists/Sample Pickup: is sample collection done a per schedule?',
		nt1501: '',
		nt1600: 'is all the stock avaiability is in line with branch business',
		nt1601: '',
		nt1700: 'is All V-Mitr Notifications cleared?',
		nt1701: '',
		nt1800: 'Google ratings collected in the day',
		nt1801: '',
		nt1900: 'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
		nt1901: '',
		nt2000: 'Doctor Productivity',
		nt2001: '',
		nt2100: 'Doctor 1 BMID',
		nt2101: '',
		nt2200: 'Doctor 1 Name',
		nt2201: '',
		nt2300: 'USG Done -Doc 1',
		nt2301: '',
		nt2400: 'CT Done -Doc 1',
		nt2401: '',
		nt2500: 'MRI Done -Doc 1',
		nt2501: '',
		nt2600: 'X-Ray Done -Doc 1',
		nt2601: '',
		nt2700: 'Doctor 2 BMID',
		nt2701: '',
		nt2800: 'Doctor 2 Name',
		nt2801: '',
		nt2900: 'USG Done -Doc 2',
		nt2901: '',
		nt3000: 'CT Done -Doc 2',
		nt3001: '',
		nt3100: 'MRI Done -Doc 2 ',
		nt3101: '',
		nt3200: 'X-Ray Done -Doc 2',
		nt3201: '',
		nt3300: 'Doctor 3 BMID',
		nt3301: '',
		nt3400: 'Doctor 3 Name',
		nt3401: '',
		nt3500: 'USG Done -Doc 3',
		nt3501: '',
		nt3600: 'CT Done -Doc 3',
		nt3601: '',
		nt3700: 'MRI Done -Doc 3',
		nt3701: '',
		nt3800: 'X-Ray Done -Doc 3',
		nt3801: '',
		nt3900: 'Cardiologist Name',
		nt3901: '',
		nt4000: 'TMT Done',
		nt4001: '',
		nt4100: 'ECG Done',
		nt4101: '',
		branch: '',
		emp_code: userEmp_code,
	};

	const [formData, setFormData] = useState(initialFormData);
  const closeModal = () => {
    setShowModal(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value, // Update the formData state with the selected date
    }));
  };


  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const requestData = {
      ...formData, // Get the current form data, including the date and other values
      createdDTM: formData.createdDTM,  // Ensure createdDTM is included
      branch_id: sessionStorage.getItem('selectedBranchId'),  // Add branch_id from session storage or wherever it is stored
    };
  
    console.log('Request Data:', requestData); // Log the request data for debugging
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNightTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        const result = await response.json();
        if (result.status === true) {
          navigate(`/EditBmNightTask/${result.data}`);
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally add error handling like toast notifications
    }
  };
  

  useEffect(() => {
	const fetchMorningTasks = async () => {
	  try {
		setLoading(true); // Set loading to true while fetching
		const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchNightTaskList`, {
		  method: 'POST',
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		});
  
		if (response.ok) {
		  const result = await response.json();
		  console.log('API Response:', result); // Log the full API response
		  console.log('Data in result:', result.data); // Log result.data to verify
  
		  // Access result.data.data to get the actual array
		  if (Array.isArray(result.data.data)) {
        setExcelData(result.data.data);
			  setFilteredItems(result.data.data); // Set the fetched data
        setItems(result.data.data);
		  } else {
			 // // toast.error('Fetched data is not an array');
        setExcelData([]);
		  }
 

		} else {
      console.log('Server Error:', response.status, response.statusText); // Log server error
		 // navigate(`/login`);
		}
	  } catch (error) {
		console.error('Fetch error:', error);
    navigate(`/login`);
	  } finally {
		setLoading(false); // Set loading to false after fetching
	  }
	};
  
	fetchMorningTasks();
  }, [token]); // Trigger the fetch when token changes
  

  useEffect(() => {
		if (items.length > 0) {
			// Filter items based on search query
			const filtered = items.filter((item) => {
				const fullName = `${item.fname || ''} ${
					item.lname || ''
				}`.toLowerCase();
        const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
				const formattedDate = new Date(item.createdDTM).toLocaleDateString();
        const createdDTM = item.createdDTM ?  formattedDate.toLowerCase() : '';
				const fname = item.fname ? item.fname.toLowerCase() : '';
				const cluster_name = item.cluster_name ? item.cluster_name.toLowerCase() : '';
				const zone_name = item.zone_name ? item.zone_name.toLowerCase() : '';
				 
        return (				 
					branch_name.includes(searchQuery.toLowerCase()) ||
					createdDTM.includes(searchQuery.toLowerCase()) ||
					fname.includes(searchQuery.toLowerCase()) ||
					cluster_name.includes(searchQuery.toLowerCase()) ||
					zone_name.includes(searchQuery.toLowerCase())
				);
			});

			// Sort filtered items by role in descending order
			const sortedFilteredItems = filtered.sort((a, b) => {
				const createdDTMA = a.createdDTM ? a.createdDTM.toLowerCase() : '';
				const createdDTMB = b.createdDTM ? b.createdDTM.toLowerCase() : '';

				return createdDTMB.localeCompare(createdDTMA); // Descending order
			});

			// Set filtered items only if they differ
			if (
				JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
			) {
				setFilteredItems(sortedFilteredItems);
			 
			}
		}
	}, [searchQuery, items]);

  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];

  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


   
  const exportToExcel = () => {
		const formattedExcelData = excelData.map((item) => ({
      CreatedDTM: `${item.createdDTM || '--'} `,
			CreatedBy: `${item.fname + ' ' + item.lname || '--'} `,
			Branch: item.branch_name || '--',
			DayEndReportTallyTotalRevenue: `${item.nt0201 || '--'} ${item.nt0202 || ''}`,
			DayEndReportTallyCash: `${item.nt0301 || '--'} `,
      DayEndReportTallyPaytm: `${item.nt0401 || '--'} `,
      DayEndReportTallyCard: `${item.nt0501 || '--'} `,
      PDR_Amount: `${item.nt1101 || '--'} `,
      TotalRefundsCount: `${item.nt1201 || '--'} `,
      TotalRefundsAmount: `${item.nt1301 || '--'} `,
      isPettyCashHandlingClear: `${item.nt1401 || '--'} `,
      isPettyCashHandlingClear_Description: `${item.nt1402 || '--'} `,
      CrossBillingsDone_USG_CT_MRI_XRay_PET_CT_only: `${item.nt1901 || '--'} `,
      
      TotalUSG_Done: `${item.nt0601 || '--'} `,
      TotalCT_Done: `${item.nt0701 || '--'} `,      
      TotalMRI_Done: `${item.nt0801 || '--'} `,
      TotalXRay_Done: `${item.nt0901 || '--'} `,
      TotalPDRcases: `${item.nt1001 || '--'} `,

      GoogleRatingsCollectedInTheDay: `${item.nt1801 || '--'} `,
      isAllVMitrNotificationsCleared: `${item.nt1701 || '--'} `,
      BranchClosingTimeAsPerLastUpdateFormBranch: `${item.nt0101 || '--'} `,

     
		}));
		const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
		XLSX.writeFile(workbook, 'BM_Night_Tasks.xlsx');
	};

  return (
    <>
      <Header />
      <Sidebar />
      
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'></div>
        </div>

        <section style={{ minHeight:'630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"> <FormSkeleton/> </div>
                  ) : (
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <select
                              style={{ width: '5em' }}
                              className='form-control form-control-sm'
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div
                            id='example1_filter'
                            className='dataTables_filter'
                          >
                            <label>
                              <input
                                type='search'
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className='form-control form-control-sm'
                                placeholder='Search'
                                aria-controls='example1'
                              />
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-5'>
                        <button
                            type='button'
                            className='btn btn-sm btn-success'
                            onClick={() => setShowModal(true)}
                          >
                            Add Night Task
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-secondary ml-2'
                            onClick={exportToExcel}
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Branch</th>
                              <th>Dated</th>
                              <th>Created by</th>
                              <th>Cluster</th>
                              <th>Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                          {currentItems.length > 0 ? (
                              currentItems.map((item, index) => {
                                const formattedDate = new Date(item.createdDTM).toLocaleDateString();
                                return (
                                  <tr key={index}>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.nid) {
                                            console.log('Navigating to EditBmMorningTask with mid:', item.mid); // Log the mid value
                                            navigate(`/EditBmNightTask/${item.nid}`);
                                          } else {
                                            console.error('mid is undefined or empty');
                                           // // toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }}
                                        className="btn btn-sm btn-primary fas fa-edit"
                                      ></i> 
                                    </td>
                                    <td>{item.branch_name}</td>
                                    <td>{formattedDate}</td>
                                    <td>{item.fname} {item.lname}</td>
                                    <td>{item.cluster_name}</td>
                                    <td>{item.zone_name}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);

                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );

                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }

                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    pageNumber === currentPage ? 'active' : ''
                                  }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>

                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
        {/* Custom Modal */}
        {showModal && (
        <div className='customModal'>
          <div className='customModalContent'>
            <span className='close' onClick={handleCloseModal}>
              &times;
            </span>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Add Night Task</label>
                <input
                  type="date"
                  name="createdDTM"
                  onChange={handleInputChange}
                  min={yesterdayDate}
                  max={todayDate}
                  required
                  className="form-control form-control-sm col-5"
                />
              </div>
              <button type="submit" className="btn btn-success btn-sm" disabled={loading}>
                Submit
              </button>
            </form>


          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default BmNightGrid;
