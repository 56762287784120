import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { Link, useNavigate } from 'react-router-dom';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import FormSkeleton from '../../components/other/formSkeleton';

const BmNightTask = () => {
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [submitStatus, setSubmitStatus] = useState(null);
	const [formData, setFormData] = useState([]);
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();
	const [uploadedMTlist, setUploadedMTlist] = useState([]);
	const [mtDetails, setMtDetails] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	// const [formData, setFormData] = useState({
	// 	cluster: '',
	// 	cluster_id: '',
	// 	nid: '',
	// 	nt0100: 'Branch Closing time as per last update form branch',
	// 	nt0101: '',
	// 	nt0102: '',
	// 	nt0200: 'Day-End Report Tally - Total Revenue',
	// 	nt0201: '',
	// 	nt0202: '',
	// 	nt0300: 'Day-End Report Tally - Cash',
	// 	nt0301: '',
	// 	nt0302: '',
	// 	nt0400: 'Day-End Report Tally - Paytm',
	// 	nt0401: '',
	// 	nt0402: '',
	// 	nt0500: 'Day-End Report Tally - Card',
	// 	nt0501: '',
	// 	nt0502: '',
	// 	nt0600: 'Total USG Done',
	// 	nt0601: '',
	// 	nt0602: '',
	// 	nt0700: 'Total CT Done',
	// 	nt0701: '',
	// 	nt0702: '',
	// 	nt0800: 'Total MRI Done',
	// 	nt0801: '',
	// 	nt0802: '',
	// 	nt0900: 'Total X-Ray Done',
	// 	nt0901: '',
	// 	nt0902: '',
	// 	nt1000: 'PDR Cases',
	// 	nt1001: '',
	// 	nt1002: '',
	// 	nt1100: 'PDR Amount',
	// 	nt1101: '',
	// 	nt1200: 'Total Refunds (Count)',
	// 	nt1201: '',
	// 	nt1300: 'Total Refunds (Amount)',
	// 	nt1301: '',
	// 	nt1400: 'is Petty cash handling clear?',
	// 	nt1401: '',
	// 	nt1500: 'Logists/Sample Pickup: is sample collection done a per schedule?',
	// 	nt1501: '',
	// 	nt1600: 'is all the stock avaiability is in line with branch business',
	// 	nt1601: '',
	// 	nt1700: 'is All V-Mitr Notifications cleared?',
	// 	nt1701: '',
	// 	nt1800: 'Google ratings collected in the day',
	// 	nt1801: '',
	// 	nt1900: 'Corss Billings done (USG/CT/MRI/X-ray/PET CT only)',
	// 	nt1901: '',
	// 	nt2000: 'Doctor Productivity',
	// 	nt2001: '',
	// 	nt2100: 'Doctor 1 BMID',
	// 	nt2101: '',
	// 	nt2200: 'Doctor 1 Name',
	// 	nt2201: '',
	// 	nt2300: 'USG Done -Doc 1',
	// 	nt2301: '',
	// 	nt2400: 'CT Done -Doc 1',
	// 	nt2401: '',
	// 	nt2500: 'MRI Done -Doc 1',
	// 	nt2501: '',
	// 	nt2600: 'X-Ray Done -Doc 1',
	// 	nt2601: '',
	// 	nt2700: 'Doctor 2 BMID',
	// 	nt2701: '',
	// 	nt2800: 'Doctor 2 Name',
	// 	nt2801: '',
	// 	nt2900: 'USG Done -Doc 2',
	// 	nt2901: '',
	// 	nt3000: 'CT Done -Doc 2',
	// 	nt3001: '',
	// 	nt3100: 'MRI Done -Doc 2 ',
	// 	nt3101: '',
	// 	nt3200: 'X-Ray Done -Doc 2',
	// 	nt3201: '',
	// 	nt3300: 'Doctor 3 BMID',
	// 	nt3301: '',
	// 	nt3400: 'Doctor 3 Name',
	// 	nt3401: '',
	// 	nt3500: 'USG Done -Doc 3',
	// 	nt3501: '',
	// 	nt3600: 'CT Done -Doc 3',
	// 	nt3601: '',
	// 	nt3700: 'MRI Done -Doc 3',
	// 	nt3701: '',
	// 	nt3800: 'X-Ray Done -Doc 3',
	// 	nt3801: '',
	// 	nt3900: 'Cardiologist Name',
	// 	nt3901: '',
	// 	nt4000: 'TMT Done',
	// 	nt4001: '',
	// 	nt4100: 'ECG Done',
	// 	nt4101: '',
	// 	branch: '',
	// 	emp_code: userEmp_code,
	// });

	const initialFormData = {
		cluster: '',
		cluster_id: '',
		nid: '',
		nt0100: 'Branch Closing time as per last update from branch',
		nt0101: '',
		nt0102: '',
		nt0200: 'Day-End Report Tally - Total Revenue',
		nt0201: '',
		nt0202: '',
		nt0300: 'Day-End Report Tally - Cash',
		nt0301: '',
		nt0302: '',
		nt0400: 'Day-End Report Tally - Paytm',
		nt0401: '',
		nt0402: '',
		nt0500: 'Day-End Report Tally - Card',
		nt0501: '',
		nt0502: '',
		nt0600: 'Total USG Done',
		nt0601: '',
		nt0602: '',
		nt0700: 'Total CT Done',
		nt0701: '',
		nt0702: '',
		nt0800: 'Total MRI Done',
		nt0801: '',
		nt0802: '',
		nt0900: 'Total X-Ray Done',
		nt0901: '',
		nt0902: '',
		nt1000: 'PDR Cases',
		nt1001: '',
		nt1002: '',
		nt1100: 'PDR Amount',
		nt1101: '',
		nt1200: 'Total Refunds (Count)',
		nt1201: '',
		nt1300: 'Total Refunds (Amount)',
		nt1301: '',
		nt1400: 'Is Petty cash handling clear?',
		nt1401: '',
		nt1500:
			'Logistics/Sample Pickup: Is sample collection done as per schedule?',
		nt1501: '',
		nt1502: '',
		nt1600: 'Is all the stock availability in line with branch business?',
		nt1601: '',
		nt1602: '',
		nt1700: 'Is All V-Mitr Notifications cleared?',
		nt1701: '',
		nt1800: 'Google ratings collected in the day',
		nt1801: '',
		nt1900: 'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
		nt1901: '',
		nt2000: 'Doctor Productivity',
		nt2001: '',
		nt2100: 'Doctor 1 BMID',
		nt2101: '',
		nt2200: 'Doctor 1 Name',
		nt2201: '',
		nt2300: 'USG Done -Doc 1',
		nt2301: '',
		nt2400: 'CT Done -Doc 1',
		nt2401: '',
		nt2500: 'MRI Done -Doc 1',
		nt2501: '',
		nt2600: 'X-Ray Done -Doc 1',
		nt2601: '',
		nt2700: 'Doctor 2 BMID',
		nt2701: '',
		nt2800: 'Doctor 2 Name',
		nt2801: '',
		nt2900: 'USG Done -Doc 2',
		nt2901: '',
		nt3000: 'CT Done -Doc 2',
		nt3001: '',
		nt3100: 'MRI Done -Doc 2',
		nt3101: '',
		nt3200: 'X-Ray Done -Doc 2',
		nt3201: '',
		nt3300: 'Doctor 3 BMID',
		nt3301: '',
		nt3400: 'Doctor 3 Name',
		nt3401: '',
		nt3500: 'USG Done -Doc 3',
		nt3501: '',
		nt3600: 'CT Done -Doc 3',
		nt3601: '',
		nt3700: 'MRI Done -Doc 3',
		nt3701: '',
		nt3800: 'X-Ray Done -Doc 3',
		nt3801: '',
		nt3900: 'Cardiologist Name',
		nt3901: '',
		nt4000: 'TMT Done',
		nt4001: '',
		nt4100: 'ECG Done',
		nt4101: '',
		branch: '',
		emp_code: userEmp_code,
	};

	useEffect(() => {
		if (mtDetails && Array.isArray(mtDetails) && mtDetails.length > 0) {
			const mtDetail = mtDetails[0]; // Assuming mtDetails[0] contains the desired record

			// Dynamically set formData using the mtDetail data
			setFormData((prevState) => ({
				...prevState,
				nid: mtDetail.nid || '',
				nt0100:
					mtDetail.nt0100 ||
					'Branch Closing time as per last update from branch',
				nt0101: mtDetail.nt0101 || '',
				nt0200: mtDetail.nt0200 || 'Day-End Report Tally - Total Revenue',
				nt0201: mtDetail.nt0201 || '',
				nt0202: mtDetail.nt0202 || '',
				nt0300: mtDetail.nt0300 || 'Day-End Report Tally - Cash',
				nt0301: mtDetail.nt0301 || '',
				nt0302: mtDetail.nt0302 || '',
				nt0400: mtDetail.nt0400 || 'Day-End Report Tally - Paytm',
				nt0401: mtDetail.nt0401 || '',
				nt0402: mtDetail.nt0402 || '',
				nt0500: mtDetail.nt0500 || 'Day-End Report Tally - Card',
				nt0501: mtDetail.nt0501 || '',
				nt0502: mtDetail.nt0502 || '',
				nt0600: mtDetail.nt0600 || 'Total USG Done',
				nt0601: mtDetail.nt0601 || '',
				nt0602: mtDetail.nt0602 || '',
				nt0700: mtDetail.nt0700 || 'Total CT Done',
				nt0701: mtDetail.nt0701 || '',
				nt0702: mtDetail.nt0702 || '',
				nt0800: mtDetail.nt0800 || 'Total MRI Done',
				nt0801: mtDetail.nt0801 || '',
				nt0802: mtDetail.nt0802 || '',
				nt0900: mtDetail.nt0900 || 'Total X-Ray Done',
				nt0901: mtDetail.nt0901 || '',
				nt0902: mtDetail.nt0902 || '',
				nt1000: mtDetail.nt1000 || 'PDR Cases',
				nt1001: mtDetail.nt1001 || '',
				nt1002: mtDetail.nt1002 || '',
				nt1100: mtDetail.nt1100 || 'PDR Amount',
				nt1101: mtDetail.nt1101 || '',
				nt1200: mtDetail.nt1200 || 'Total Refunds (Count)',
				nt1201: mtDetail.nt1201 || '',
				nt1300: mtDetail.nt1300 || 'Total Refunds (Amount)',
				nt1301: mtDetail.nt1301 || '',
				nt1400: mtDetail.nt1400 || 'Is Petty cash handling clear?',
				nt1401: mtDetail.nt1401 || '',
				nt1500:
					mtDetail.nt1500 ||
					'Logistics/Sample Pickup: Is sample collection done as per schedule?',
				nt1501: mtDetail.nt1501 || '',
				nt1502: mtDetail.nt1502 || '',
				nt1600:
					mtDetail.nt1600 ||
					'Is all the stock availability in line with branch business?',
				nt1601: mtDetail.nt1601 || '',
				nt1602: mtDetail.nt1602 || '',
				nt1700: mtDetail.nt1700 || 'Is All V-Mitr Notifications cleared?',
				nt1701: mtDetail.nt1701 || '',
				nt1800: mtDetail.nt1800 || 'Google ratings collected in the day',
				nt1801: mtDetail.nt1801 || '',
				nt1900:
					mtDetail.nt1900 ||
					'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
				nt1901: mtDetail.nt1901 || '',
				nt2000: mtDetail.nt2000 || 'Doctor Productivity',
				nt2001: mtDetail.nt2001 || '',
				nt2100: mtDetail.nt2100 || 'Doctor 1 BMID',
				nt2101: mtDetail.nt2101 || '',
				nt2200: mtDetail.nt2200 || 'Doctor 1 Name',
				nt2201: mtDetail.nt2201 || '',
				nt2300: mtDetail.nt2300 || 'USG Done -Doc 1',
				nt2301: mtDetail.nt2301 || '',
				nt2400: mtDetail.nt2400 || 'CT Done -Doc 1',
				nt2401: mtDetail.nt2401 || '',
				nt2500: mtDetail.nt2500 || 'MRI Done -Doc 1',
				nt2501: mtDetail.nt2501 || '',
				nt2600: mtDetail.nt2600 || 'X-Ray Done -Doc 1',
				nt2601: mtDetail.nt2601 || '',
				nt2700: mtDetail.nt2700 || 'Doctor 2 BMID',
				nt2701: mtDetail.nt2701 || '',
				nt2800: mtDetail.nt2800 || 'Doctor 2 Name',
				nt2801: mtDetail.nt2801 || '',
				nt2900: mtDetail.nt2900 || 'USG Done -Doc 2',
				nt2901: mtDetail.nt2901 || '',
				nt3000: mtDetail.nt3000 || 'CT Done -Doc 2',
				nt3001: mtDetail.nt3001 || '',
				nt3100: mtDetail.nt3100 || 'MRI Done -Doc 2',
				nt3101: mtDetail.nt3101 || '',
				nt3200: mtDetail.nt3200 || 'X-Ray Done -Doc 2',
				nt3201: mtDetail.nt3201 || '',
				nt3300: mtDetail.nt3300 || 'Doctor 3 BMID',
				nt3301: mtDetail.nt3301 || '',
				nt3400: mtDetail.nt3400 || 'Doctor 3 Name',
				nt3401: mtDetail.nt3401 || '',
				nt3500: mtDetail.nt3500 || 'USG Done -Doc 3',
				nt3501: mtDetail.nt3501 || '',
				nt3600: mtDetail.nt3600 || 'CT Done -Doc 3',
				nt3601: mtDetail.nt3601 || '',
				nt3700: mtDetail.nt3700 || 'MRI Done -Doc 3',
				nt3701: mtDetail.nt3701 || '',
				nt3800: mtDetail.nt3800 || 'X-Ray Done -Doc 3',
				nt3801: mtDetail.nt3801 || '',
				nt3900: mtDetail.nt3900 || 'Cardiologist Name',
				nt3901: mtDetail.nt3901 || '',
				nt4000: mtDetail.nt4000 || 'TMT Done',
				nt4001: mtDetail.nt4001 || '',
				nt4100: mtDetail.nt4100 || 'ECG Done',
				nt4101: mtDetail.nt4101 || '',
				branch: mtDetail.branch || '',
			}));
		} else {
			// If no details to edit, set form to initial state for a new task
			setFormData(initialFormData);
		}
	}, [mtDetails]); // Ensure mtDetails is properly updated

	const today = new Date().toISOString().split('T')[0];

	useEffect(() => {
		const createDefaultTask = async () => {
			const requestData = {
				createdDTM: selectedDate,
				branch_id: selectedBranch,
				...initialFormData,
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNightTask`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestData),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status === true) {
						////// toast.success(result.message);
					}
					// 	else {
					// 		////toast.error(
					// 			result.message || 'An error occurred while adding the task.',
					// 		);
					// 	}
					// } else {
					// 	////toast.error(
					// 		'Error: ' + response.status + ' - ' + response.statusText,
					// 	);
				}
			} catch (error) {
				////toast.error('Failed to create default task');
			}
		};

		if (
			selectedDate === today &&
			selectedBranch &&
			(!mtDetails || mtDetails.length === 0)
		) {
			createDefaultTask();
		}
	}, [selectedBranch, selectedDate, mtDetails, token, initialFormData, today]);

	const closeModal = () => {
		setModalOpen(false);
		setShowModal(false);
	};
	const handleOpenModal = (item) => {
		setShowModal(true); // Show the modal
	};
	useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setBranchlist(result.data);
					} else {
						////toast.error( 	result.message || 'An error occurred while fetching the Branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				////toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchBranchList();
	}, [token]);

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');

		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}
	}, [branchlist]);
	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedBranch: selectedBranch,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getNightTaskDetails`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedBranch: selectedBranch,
				};

				// Make the API request with JSON payload
				const mtListResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/uploadedNightTlist`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtListResponse.ok) {
					throw new Error(`Error: ${mtListResponse.status}`);
				}

				const nightTaskListData = await mtListResponse.json();

				if (nightTaskListData && Array.isArray(nightTaskListData.data)) {
					console.log('MtData:', nightTaskListData.data);
					setUploadedMTlist(nightTaskListData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setUploadedMTlist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setUploadedMTlist([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
					} else {
						////toast.error( 	result.message || 	'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				////toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const selectBranch = (e) => {
		const branchId = e.target.value;
		setSelectedBranch(branchId);
		sessionStorage.setItem('selectedBranchId', branchId); // Store in sessionStorage
	};

	const selectDate = (e) => {
		const sDate = e.target.value;
		setSelectedDate(sDate);
		sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
	};
	const getBranchName = (branchId) => {
		const branch = branchlist.find((b) => b.branch_id === branchId);
		return branch ? branch.branch : 'Branch not found';
	};
	const validateEditFormData = () => {
		console.log('Validating form data');

		// if (!formData.mt0101 || formData.mt0101 === '') {
		// 	console.log('Missing value for "is All Manpower in proper dress code?"');
		// 	return false;
		// }
		// More validation checks...

		return true; // If everything is valid
	};
	// const handleSubmit = async (e) => {
	// 	e.preventDefault();

	// 	if (!validateEditFormData()) {
	// 		console.log('Validation failed');
	// 		return;
	// 	}

	// 	// Prepare requestData by filtering non-empty values from formData
	// 	const requestData = {
	// 		createdDTM: selectedDate,
	// 		branch: selectedBranch,
	// 		// Append non-empty fields from formData dynamically
	// 		...Object.fromEntries(
	// 			Object.entries(formData)
	// 				.filter(([key, value]) => value !== '') // Filter out empty values
	// 				.map(([key, value]) => [key, value]), // Keep the key-value pair
	// 		),
	// 	};

	// 	console.log('Form data to submit:', requestData);

	// 	try {
	// 		const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNightTask`, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json', // Send JSON data
	// 				Authorization: `Bearer ${token}`,
	// 			},
	// 			body: JSON.stringify(requestData), // Send as JSON
	// 		});

	// 		if (response.ok) {
	// 			const result = await response.json();
	// 			if (result.status === true) {
	// 				////// toast.success(result.message, {
	// 					onClose: () => window.location.reload(),
	// 				});
	// 				closeModal();
	// 				navigate('/bmnighttask');
	// 			} else {
	// 				////toast.error(
	// 					'Message : ' + result.message ||
	// 						'An error occurred while adding the User.',
	// 				);
	// 			}
	// 		} else {
	// 			////toast.error('Error: ' + response.status + ' - ' + response.statusText);
	// 		}
	// 	} catch (error) {
	// 		////toast.error('Failed to submit form');
	// 	}
	// };
	// const handleSubmit = async (e) => {
	// 	e.preventDefault();

	// 	if (!validateEditFormData()) {
	// 		console.log('Validation failed');
	// 		return;
	// 	}

	// 	// Prepare requestData
	// 	const requestData = {
	// 		createdDTM: selectedDate,
	// 		branch: selectedBranch,
	// 		...Object.fromEntries(
	// 			Object.entries(formData)
	// 				.filter(([key, value]) => value !== '')
	// 				.map(([key, value]) => [key, value]),
	// 		),
	// 	};

	// 	try {
	// 		const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNightTask`, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				Authorization: `Bearer ${token}`,
	// 			},
	// 			body: JSON.stringify(requestData),
	// 		});

	// 		if (response.ok) {
	// 			const result = await response.json();
	// 			if (result.status === true) {
	// 				// toast.success(result.message, {
	// 					onClose: () => window.location.reload(),
	// 				});
	// 				closeModal();
	// 				navigate('/bmnighttask');
	// 				// Reset form after successful submission
	// 				setFormData(initialFormData);
	// 			} else {
	// 				////toast.error(
	// 					'Message : ' + result.message ||
	// 						'An error occurred while adding the task.',
	// 				);
	// 			}
	// 		} else {
	// 			////toast.error('Error: ' + response.status + ' - ' + response.statusText);
	// 		}
	// 	} catch (error) {
	// 		////toast.error('Failed to submit form');
	// 	}
	// };

	const handleSelectCluster = (e) => {
		const selectedClusterId = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			cluster: selectedClusterId,
			cluster_id: selectedClusterId,
		}));

		sessionStorage.setItem('selectedClusterId', selectedClusterId);
		console.log('Cluster ID stored in session:', selectedClusterId);
	};

	const [submittedPayload, setSubmittedPayload] = useState(null); // State to store the submitted payload

	const handleEditSubmit = async (e) => {
		e.preventDefault();

		console.log('FormData before submit:', formData); // Log before submitting

		try {
			const requestData = { ...formData };
			setSubmittedPayload(requestData);

			const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/saveNightTaskDetails`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(requestData),
			});

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {
				  window.location.reload()
				 
			} else {
				////toast.error('Failed to submit data');
			}
		} catch (error) {
			console.error('Error submitting data:', error);
			setSubmitStatus({ message: `Error: ${error.message}`, type: 'error' });
		}
	};

	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';

	return (
		<>
			<Header /> <Sidebar /> 
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-3 form-group'>
								<select
									name='selectBranch'
									id='selectBranch'
									onChange={selectBranch}
									className='form-control form-control-sm'
									value={selectedBranch} // Set the default selected value
								>
									<option value=''>Select Branch</option>
									{branchlist.map((b) => (
										<option key={b.id} value={b.branch_id}>
											{b.branch}
										</option>
									))}
								</select>
							</div>

							<div className='col-sm-3 form-group'>
								<input
									type='date'
									name='selectDate'
									onChange={selectDate}
									className='form-control form-control-sm'
									placeholder='Select Date'
									max={today}
									value={selectedDate} // Set the default value here
								/>
							</div>
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</div>
				{/* /.content-header */}
				{/* Main content */}

				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row mt-4'>
											<div className='col-sm-12 row'>
												<div className='col-sm-8'>
													<h6>
														Night Closing Check list of Branch:{' '}
														<span className='red'>
															{' '}
															{getBranchName(selectedBranch)}{' '}
														</span>{' '}
														Dated: <span className='red'> {selectedDate}</span>
													</h6>

													{mtDetails && mtDetails.length > 0 ? (
														<>
															{' '}
															<form
																className='col-sm-12'
																onSubmit={handleEditSubmit}
															>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-sm-6'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0100'
																			id='nt0100'
																			value={formData.nt0100 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='time'
																					name='nt0101'
																					id='nt0101'
																					value={formData.nt0101 || ''}
																					onChange={handleInputChange} // <-- Add onChange handler
																				/>
																			</div>
																		</div>
																	</div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0200'
																			id='nt0200'
																			value={formData.nt0200 || ''}
																			onChange={handleInputChange}
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0201'
																					id='nt0201'
																					value={formData.nt0201 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																</div>

																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-md-6 mb-2'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0300'
																			id='nt0300'
																			value={formData.nt0300 || ''}
																			onChange={handleInputChange}
																		/>
																	</div>
																	<div className='col-12 col-md-4 mb-2'>
																		<input
																			className='form-control form-control-sm'
																			type='number'
																			name='nt0301'
																			id='nt0301'
																			value={formData.nt0301 || ''}
																			onChange={handleInputChange}
																		/>
																	</div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0400'
																			id='nt0400'
																			value={formData.nt0400 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0401'
																					id='nt0401'
																					value={formData.nt0401 || ''}
																					onChange={handleInputChange} // <-- Add onChange handler
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0500'
																			id='nt0500'
																			value={formData.nt0500 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0501'
																					id='nt0501'
																					value={formData.nt0501 || ''}
																					onChange={handleInputChange} // <-- Add onChange handler
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0600'
																			id='nt0600'
																			value={formData.nt0600 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0601'
																					id='nt0601'
																					value={formData.nt0601 || ''}
																					onChange={handleInputChange} // <-- Add onChange handler
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0700'
																			id='nt0700'
																			value={formData.nt0700 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0701'
																					id='nt0701'
																					value={formData.nt0701 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0800'
																			id='nt0800'
																			value={formData.nt0800 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0801'
																					id='nt0801'
																					value={formData.nt0801 || ''}
																					onChange={handleInputChange} //
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt0900'
																			id='nt0900'
																			value={formData.nt0900 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt0901'
																					id='nt0901'
																					value={formData.nt0901 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1000'
																			id='nt1000'
																			value={formData.nt1000 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					name='nt1001'
																					id='nt1001'
																					value={formData.nt1001 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1100'
																			id='nt1100'
																			value={formData.nt1100 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt1101'
																					id='nt1101'
																					value={formData.nt1101 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1200'
																			id='nt1200'
																			value={formData.nt1200 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt1201'
																					id='nt1201'
																					value={formData.nt1201 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1300'
																			id='nt1300'
																			value={formData.nt1300 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt1301'
																					id='nt1301'
																					value={formData.nt1301 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1400'
																			id='nt1400'
																			value={formData.nt1400 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-sm-4'>
																		<select
																			className='form-control form-control-sm'
																			name='nt1401'
																			id='nt1401'
																			value={formData.nt1401 || ''}
																			onChange={handleInputChange}
																		>
																			<option value={''}>Select</option>
																			<option value={'Yes'}>Yes</option>
																			<option value={'No'}>No</option>
																		</select>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1500'
																			id='nt1500'
																			value={formData.nt1500 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-sm-4'>
																		<select
																			className='form-control form-control-sm'
																			name='nt1501'
																			id='nt1501'
																			value={formData.nt1501 || ''}
																			onChange={handleInputChange}
																		>
																			<option value={''}>Select</option>
																			<option value={'Yes'}>Yes</option>
																			<option value={'No'}>No</option>
																		</select>
																	</div>
																	<div className='col-sm-4'>
																		{' '}
																		<textarea
																			class='form-control'
																			name='nt1502'
																			id='nt15002'
																			value={formData.nt1502 || ''}
																			onChange={handleInputChange}
																			rows='1'
																		></textarea>
																	</div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1600'
																			id='nt1600'
																			value={formData.nt1600 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-sm-4'>
																		<select
																			className='form-control form-control-sm'
																			name='nt1601'
																			id='nt1601'
																			value={formData.nt1601 || ''}
																			onChange={handleInputChange}
																		>
																			<option value={''}>Select</option>
																			<option value={'Yes'}>Yes</option>
																			<option value={'No'}>No</option>
																		</select>

																		{/* <div className='form-inline'>
																<div className='form-check'>
																	<input
																		className='form-check-input'
																		type='radio'
																		name='nt1601'
																		id='nt1601'
																		value={formData.nt1601 || ''}
																		onChange={handleInputChange}
																	/>
																	<label
																		className='form-check-label'
																		htmlFor='flexRadioDefault1'
																	>
																		Yes
																	</label>
																</div>
																<div className='form-check ml-3'>
																	<input
																		className='form-check-input'
																		type='radio'
																		name='nt1601'
																		id='nt16001'
																		value={formData.nt1601 || ''}
																		onChange={handleInputChange}
																	/>
																	<label
																		className='form-check-label'
																		htmlFor='flexRadioDefault2'
																	>
																		No
																	</label>
																</div>
															</div> */}
																	</div>
																	<div className='col-sm-4'>
																		<textarea
																			class='form-control'
																			name='nt1602'
																			id='nt1602'
																			value={formData.nt1602 || ''}
																			onChange={handleInputChange}
																			rows='1'
																		></textarea>
																	</div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1700'
																			id='nt1700'
																			value={formData.nt1700 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt1701'
																					id='nt1701'
																					value={formData.nt1701 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1800'
																			id='nt1800'
																			value={formData.nt1800 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt1801'
																					id='nt1801'
																					value={formData.nt1801 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt1900'
																			id='nt1900'
																			value={formData.nt1900 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt1901'
																					id='nt1901'
																					value={formData.nt1901 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2000'
																			id='nt2000'
																			value={formData.nt2000 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2001'
																					id='nt2001'
																					value={formData.nt2001 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2100'
																			id='nt2100'
																			value={formData.nt2100 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2101'
																					id='nt2101'
																					value={formData.nt2101 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2200'
																			id='nt2200'
																			value={formData.nt2200 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2201'
																					id='nt2201'
																					value={formData.nt2201 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2300'
																			id='nt2300'
																			value={formData.nt2300 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2301'
																					id='nt2301'
																					value={formData.nt2301 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2400'
																			id='nt2400'
																			value={formData.nt2400 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2401'
																					id='nt2401'
																					value={formData.nt2401 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		{' '}
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2500'
																			id='nt2500'
																			value={formData.nt2500 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2501'
																					id='nt2501'
																					value={formData.nt2501 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2600'
																			id='nt2600'
																			value={formData.nt2600 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2601'
																					id='nt2601'
																					value={formData.nt2601 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2700'
																			id='nt2700'
																			value={formData.nt2700 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2701'
																					id='nt2701'
																					value={formData.nt2701 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2800'
																			id='nt2800'
																			value={formData.nt2800 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2801'
																					id='nt2801'
																					value={formData.nt2801 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>

																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt2900'
																			id='nt2900'
																			value={formData.nt2900 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt2901'
																					id='nt2901'
																					value={formData.nt2901 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>

																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3000'
																			id='nt3000'
																			value={formData.nt3000 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3001'
																					id='nt3001'
																					value={formData.nt3001 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3100'
																			id='nt3100'
																			value={formData.nt3100 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3101'
																					id='nt3101'
																					value={formData.nt3101 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3200'
																			id='nt3200'
																			value={formData.nt3200 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3201'
																					id='nt3201'
																					value={formData.nt3201 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3300'
																			id='nt3300'
																			value={formData.nt3300 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3301'
																					id='nt3301'
																					value={formData.nt3301 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3400'
																			id='nt3400'
																			value={formData.nt3400 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3401'
																					id='nt3401'
																					value={formData.nt3401 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3500'
																			id='nt3500'
																			value={formData.nt3500 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3501'
																					id='nt3501'
																					value={formData.nt3501 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3600'
																			id='nt3600'
																			value={formData.nt3600 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3601'
																					id='nt3601'
																					value={formData.nt3601 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3700'
																			id='nt3700'
																			value={formData.nt3700 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3701'
																					id='nt3701'
																					value={formData.nt3701 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3800'
																			id='nt3800'
																			value={formData.nt3800 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3801'
																					id='nt3801'
																					value={formData.nt3801 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt3900'
																			id='nt3900'
																			value={formData.nt3900 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt3901'
																					id='nt3901'
																					value={formData.nt3901 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt4000'
																			id='nt4000'
																			value={formData.nt4000 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt4001'
																					id='nt4001'
																					value={formData.nt4001 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>
																<div
																	className='form-group row p-2 mb-2'
																	style={{
																		background: '#eeeeee',
																	}}
																>
																	<div className='col-12 col-sm-6 mb-2 mb-sm-0'>
																		<input
																			className='form-control form-control-sm'
																			type='text'
																			name='nt4100'
																			id='nt4100'
																			value={formData.nt4100 || ''}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>{' '}
																	</div>
																	<div className='col-12 col-sm-4'>
																		<div className='form-inline'>
																			<div className='form-check w-100'>
																				<input
																					className='form-control form-control-sm'
																					type='number'
																					name='nt4101'
																					id='nt4101'
																					value={formData.nt4101 || ''}
																					onChange={handleInputChange}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='col-sm-4'></div>
																</div>

																<div className='col-sm-4 mt-2'>
																	<input
																		type='hidden'
																		name='nid'
																		value={formData.nid || ''}
																		onChange={handleInputChange} // <-- Add onChange handler
																	/>
																	<button
																		type='submit'
																		className='btn btn-sm btn-info'
																	>
																		Submit
																	</button>
																</div>
															</form>
														</>
													) : (
														<></>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default BmNightTask;
