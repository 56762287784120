import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';

import * as XLSX from 'xlsx';
import '../customStyle.css';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import FormSkeleton from '../../components/other/formSkeleton';

const Manage_user = () => {
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');

	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState('emp_code');
	const [sortOrder, setSortOrder] = useState('asc');
	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showResetModal, setShowResetModal] = useState(false);
	const [showAddRoleModal, setShowAddRoleModal] = useState(false);
	const [selectedUserInfo, setSelectedUserInfo] = useState(null);
	const [brancheslist, setBrancheslist] = useState([]);
	const [clusterslist, setClusterslist] = useState([]);
	const [roleslist, setRoleslist] = useState([]);
	const [mailId, setMailId] = useState(''); // Add this line

	const [zoneslist, setZoneslist] = useState('');
	const [showSnackbar, setShowSnackbar] = useState(false);
	useEffect(() => {
		if (sessionStorage.getItem('showSnackbar') === 'true') {
			setShowSnackbar(true);
			sessionStorage.removeItem('showSnackbar');
			// Hide snackbar after 3 seconds
			setTimeout(() => setShowSnackbar(false), 2000);
		}
	}, []);

	useEffect(() => {
		async function fetchBranchData() {
			try {
				const branchResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchDetails`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
				});
				if (!branchResponse.ok) {
					throw new Error(`Error: ${branchResponse.status}`);
				}
				const branchData = await branchResponse.json();

				if (branchData && Array.isArray(branchData.data)) {
					setBrancheslist(branchData.data); // Make sure data is an array
				} else {
					console.error('No valid data received');
					setBrancheslist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching branches:', error);
				setBrancheslist([]); // Empty array on error
			}
		}
		fetchBranchData();
	}, [token]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUsersList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json', // Ensure you include content type if needed
					},
				});

				if (response.ok) {
					const result = await response.json();

					if (result.STATUS && Array.isArray(result.data)) {
						setItems(result.data);
						setFilteredItems(result.data);
						setLoading(false);
						if (result.data.length > 0) {
							setMailId(result.data[0].email); // Set mail_id as email from response data
						}
					} else {
						console.error('API Error:', result);

					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);

				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch user list: ' + error.message);
			}
		};

		fetchData();
	}, [token]);
	useEffect(() => {
		async function fetchRoleData() {
			try {
				const rolesResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getRoles`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
				});
				if (!rolesResponse.ok) {
					throw new Error(`Error: ${rolesResponse.status}`);
				}
				const roleData = await rolesResponse.json();

				if (roleData && Array.isArray(roleData.data)) {
					setRoleslist(roleData.data); // Make sure data is an array
				} else {
					console.error('No valid data received');
					setRoleslist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching roles:', error);
				setRoleslist([]); // Empty array on error
			}
		}

		fetchRoleData();
	}, [token]);

	useEffect(() => {
		async function fetchRoleData() {
			try {
				const rolesResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getZones`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
				});
				if (!rolesResponse.ok) {
					throw new Error(`Error: ${rolesResponse.status}`);
				}
				const roleData = await rolesResponse.json();

				if (roleData && Array.isArray(roleData.data)) {
					setZoneslist(roleData.data); // Make sure data is an array
				} else {
					console.error('No valid data received');
					setZoneslist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching roles:', error);
				setZoneslist([]); // Empty array on error
			}
		}

		fetchRoleData();
	}, [token]);

	useEffect(() => {
		const filtered = items
			.filter((item) => {
				const fullName = `${item.fname || ''} ${item.lname || ''
					}`.toLowerCase();
				const email = item.mail_id ? item.mail_id.toLowerCase() : '';
				const empCode = item.emp_code ? item.emp_code.toLowerCase() : '';
				const role = item.role ? item.role.toLowerCase() : '';
				const branch_id = item.branch_id ? item.branch_id.toLowerCase() : '';

				return (
					fullName.includes(searchQuery.toLowerCase()) ||
					email.includes(searchQuery.toLowerCase()) ||
					empCode.includes(searchQuery.toLowerCase()) ||
					role.includes(searchQuery.toLowerCase()) ||
					branch_id.includes(searchQuery.toLowerCase())
				);
			})
			.sort((a, b) => {
				const aValue =
					(a[sortColumn] && a[sortColumn].toString().toLowerCase()) || '';
				const bValue =
					(b[sortColumn] && b[sortColumn].toString().toLowerCase()) || '';

				return sortOrder === 'desc'
					? aValue.localeCompare(bValue)
					: bValue.localeCompare(aValue);
			});

		// Set filtered items only if they differ
		if (JSON.stringify(filtered) !== JSON.stringify(filteredItems)) {
			setFilteredItems(filtered);
		}
	}, [searchQuery, items, sortColumn, sortOrder]);

	useEffect(() => {
		if (items.length > 0) {
			// Filter items based on search query
			const filtered = items.filter((item) => {
				const fullName = `${item.fname || ''} ${item.lname || ''
					}`.toLowerCase();
				const email = item.mail_id ? item.mail_id.toLowerCase() : '';
				const empCode = item.emp_code ? item.emp_code.toLowerCase() : '';
				const role = item.role ? item.role.toLowerCase() : '';

				const branch_id = item.branch_id ? item.branch_id.toLowerCase() : '';
				const area = item.branch_id ? item.area.toLowerCase() : '';
				return (
					fullName.includes(searchQuery.toLowerCase()) ||
					email.includes(searchQuery.toLowerCase()) ||
					empCode.includes(searchQuery.toLowerCase()) ||
					role.includes(searchQuery.toLowerCase()) ||
					area.includes(searchQuery.toLowerCase()) ||

					branch_id.includes(searchQuery.toLowerCase())
				);
			});

			// Sort filtered items by role in descending order
			const sortedFilteredItems = filtered.sort((a, b) => {
				const roleA = a.role ? a.role.toLowerCase() : '';
				const roleB = b.role ? b.role.toLowerCase() : '';

				return roleB.localeCompare(roleA); // Descending order
			});

			// Set filtered items only if they differ
			if (
				JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
			) {
				setFilteredItems(sortedFilteredItems);

				setSortOrder('desc'); // Ensure the sort order is set
			}
		}
	}, [searchQuery, items]);
	const [formData, setFormData] = useState({
		fname: '',
		lname: '',
		emp_code: '',
		email: '',
		role: '',
		mobile: '',
		branch_id: [],
		cluster_id: [],
		zone_id: [],

	});

	useEffect(() => {
		if (selectedUserInfo) {
			const branchInfo =
				selectedUserInfo.userBranchList.length > 0
					? selectedUserInfo.userBranchList[0] // Modify this if needed
					: {};
			setFormData({
				...formData, // Spread existing data
				fname: selectedUserInfo.fname || '',
				lname: selectedUserInfo.lname || '',
				emp_code: selectedUserInfo.emp_code || '',
				email: selectedUserInfo.mail_id || '',
				role: selectedUserInfo.role || '',
				mobile: selectedUserInfo.mobile || '',
				branch_id: branchInfo.branch_id || '',
				cluster_id: branchInfo.cluster_id || '',
				zone_id: branchInfo.zone_id || '',

			});
		} else {
			// Reset logic
			setFormData({
				fname: '',
				lname: '',
				emp_code: '',
				email: '',
				role: '',
				mobile: '',
				branch_id: '',
				cluster_id: '',
				zone: '',
				zone_id: '',

			});
		}
	}, [selectedUserInfo]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};
	const validateEditFormData = () => {
		const requiredFields = [
			{ key: 'fname', message: 'User name is required.' },
			{ key: 'emp_code', message: 'User id is required.' },
			{ key: 'email', message: 'User email is required...' },
		];

		for (const { key } of requiredFields) {
			if (!formData[key]) {

				return false;
			}
		}

		if (formData.id && formData.id <= 0) {

			return false;
		}

		return true;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!validateEditFormData()) {
			return;
		}

		// Prepare JSON data
		const dataToSubmit = {
			fname: formData.fname,
			lname: formData.lname,
			emp_code: formData.emp_code,
			email: formData.email,
			role: formData.role,
			mobile: formData.mobile,
			zone_id: formData.zone_id || [],

			branch_id: formData.branch_id || [], // Ensure this is an array
			cluster_id: formData.cluster_id || [], // Ensure this is an array
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addUser`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json', // Set JSON header
				},
				body: JSON.stringify(dataToSubmit),
			});

			if (response.ok) {
				const result = await response.json();
				if (result.STATUS) {
					sessionStorage.setItem('showSnackbar', 'true');
					window.location.reload()

					closeModal();
					navigate('/manage_users');
				} else {
					console.error('Fetch error:', response.error);
					;
				}
			} else {
				console.error('Fetch error:', response.error);
				navigate(`/login`);
			}
		} catch (error) {
			console.error('Fetch error:');
			navigate(`/login`);
		}
	};

	const handleSubmitEditForm = async (e) => {
		e.preventDefault();

		const isValid = validateEditFormData(); // Ensure validation
		//console.log('Validation result:', isValid);
		if (!isValid) return;

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		//console.log('Form data to submit:', formDataToSubmit); // Check what is being sent

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/editUser`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			//console.log('API Response:', response); // Log the raw response

			if (response.ok) {
				const result = await response.json();
				//console.log('Parsed API Response:', result); // Check the parsed response

				if (result.STATUS) {
					handleCloseModal();
					sessionStorage.setItem('showSnackbar', 'true');
					window.location.reload()


					//	navigate('/manage_department');
				} else {
					//toast.error(result.message || 'An error occurred while updating.');
				}
			} else {
				console.error('Fetch error:', response.error);
				navigate(`/login`);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const handleSubmitDeleteForm = async (e) => {
		e.preventDefault();

		// Log formData before submission

		// No additional validation needed for deletion
		if (!formData.emp_code) {
			//toast.error('User ID is required for deletion.');
			return;
		}

		const formDataToSubmit = new FormData();
		formDataToSubmit.append('emp_code', formData.emp_code);
		formDataToSubmit.append('STATUS', 'I'); // Setting STATUS to 'I' directly

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/editUser`, {
				// Use appropriate delete endpoint
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			if (response.ok) {
				const result = await response.json();
				if (result.STATUS) {
					handleCloseModal();
					sessionStorage.setItem('showSnackbar', 'true');
					window.location.reload()

				} else {
					//toast.error(result.message || 'An error occurred while deleting.');
				}
			} else {
				console.error('Fetch error:', response.error);
				navigate(`/login`);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};
	const handleSubmitResetForm = async (e) => {
		e.preventDefault();

		// Log formData before submission
		//console.log('Form Data before submission:', formData);

		// No additional validation needed for deletion
		if (!formData.emp_code) {
			//toast.error('User ID is required for deletion.');
			return;
		}

		const formDataToSubmit = new FormData();
		formDataToSubmit.append('emp_code', formData.emp_code);

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		//console.log('Form data to submit:', formDataToSubmit);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/resetPass`, {
				// Use appropriate delete endpoint
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			if (response.ok) {
				const result = await response.json();
				if (result.STATUS) {

					handleCloseModal();
					sessionStorage.setItem('showSnackbar', 'true');
					window.location.reload()
				} else {
					console.error('Fetch error:', response.error);
					navigate(`/login`);
				}
			} else {
				console.error('Error submitting form:', response);
				navigate(`/login`);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const handleAddBranchesForm = async (e) => {
		e.preventDefault();

		// Log formData before submission
		//console.log('Form Data before submission:', formData);

		// Validate emp_code
		if (!formData.emp_code) {
			//toast.error('User ID is required for adding a Branch or Cluster.');
			return;
		}

		const formDataToSubmit = new FormData();
		formDataToSubmit.append('emp_code', formData.emp_code);
		formDataToSubmit.append('cluster', formData.cluster); // Add this line to include role
		formDataToSubmit.append('branch_id', formData.branch_id); // Add this line to include role
		formDataToSubmit.append('zone_id', formData.zone_id);
		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		//console.log('Form data to submit:', formDataToSubmit);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addBranchOrClusterToEmp`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			if (response.ok) {
				const result = await response.json();
				if (result.STATUS) {
					window.location.reload();
					handleCloseModal();
				} else {

				}
			} else {
				//toast.error('Error: ' + response.STATUS + ' - ' + response.statusText);
				console.error('Error submitting form:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	// Sorting function for other columns
	const handleSort = (column) => {
		const newSortOrder =
			sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
		const sortedItems = [...filteredItems].sort((a, b) => {
			if (a[column] < b[column]) return newSortOrder === 'desc' ? -1 : 1;
			if (a[column] > b[column]) return newSortOrder === 'desc' ? 1 : -1;
			return 0;
		});
		setFilteredItems(sortedItems);
		setSortColumn(column);
		setSortOrder(newSortOrder);
	};

	const exportToExcel = () => {
		const filteredTechnicians = filteredItems.map((item) => {
			const userBranchList = item.userBranchList || [];
			const zones = userBranchList.map(branch => branch.zone).join(', ');
			const branches = userBranchList.map(branch => branch.branch).join(', ');
			const clusters = userBranchList.map(branch => branch.cluster).join(', ');

			return {
				BMID: item.emp_code,
				FirstName: item.fname,
				LastName: item.lname,
				email: item.mail_id,
				Mobile: item.mobile,
				Role: item.role,
				Zone: zones,
				Branches: branches,
				Clusters: clusters,
				Status: item.active,
				Validity: item.validity,
				Location: item.location_name,
				ReportingTo: item.report_mngr,
				FunctionalManager: item.function_mngr,
				Designation: item.designation_name,
				Grade: item.grade,
				DOJ: item.doj,
			};
		});
		const worksheet = XLSX.utils.json_to_sheet(filteredTechnicians);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
		XLSX.writeFile(workbook, 'Users_Details.xlsx');
	};

	const handleInputChange = (e) => {
		const { name, value, type, options } = e.target;

		// Handling checkboxes/select with multiple options
		if (type === 'select-multiple') {
			const selectedValues = Array.from(options)
				.filter((option) => option.selected)
				.map((option) => option.value);

			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: selectedValues,
			}));
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};



	const handleCheckboxChange = (e, field) => {
		const { value, checked } = e.target;

		setFormData((prevData) => {
			const updatedField = Array.isArray(prevData[field])
				? [...prevData[field]]
				: [];

			if (checked) {
				updatedField.push(value); // Add value
			} else {
				const index = updatedField.indexOf(value);
				if (index > -1) updatedField.splice(index, 1); // Remove value
			}

			return {
				...prevData,
				[field]: updatedField, // Ensure the field is an array when updated
			};
		});
	};



	// Helper function for array updates
	function handleArrayUpdate(array, action) {
		switch (action.operation) {
			case 'add':
				return [...array, action.value];
			case 'remove':
				return array.filter((item) => item !== action.value);
			default:
				return array;
		}
	}



	const closeModal = () => {
		setModalOpen(false);
		setShowModal(false);
		setShowDeleteModal(false);
		setShowAddRoleModal(false);
		setFormData({ name: '', number: '', id: '' }); // Reset form
	};

	// Handle modal close
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedUserInfo(null); // Clear the selected bank info after closing modal
		setShowDeleteModal(false);
		setShowResetModal(false);
		setShowAddRoleModal(false);
	};

	// Handle Edit button click
	const handleEditClick = (item) => {
		////console.log('Clicked Item:', item); // Debug clicked item
		setSelectedUserInfo(item); // Set the selected bank info
		setShowModal(true); // Show the modal
	};
	const handleDeleteClick = (item) => {
		////console.log('Clicked Item:', item); // Debug clicked item
		setSelectedUserInfo(item);
		setShowDeleteModal(true); // Show the modal
	};
	const handleResetPass = (item) => {
		setSelectedUserInfo(item);
		setShowResetModal(true); // Show the modal
	};

	const handleAddRole = (item) => {
		setSelectedUserInfo(item);
		setShowAddRoleModal(true); // Show the modal
	};

	useEffect(() => {
		async function fetchClusterData() {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getClusters`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!response.ok) {
					throw new Error(`Error: ${response.status}`);
				}
				const data = await response.json();
				if (data && Array.isArray(data.data)) {
					setClusterslist(data.data); // Set the complete cluster list
				} else {
					console.error('No valid cluster data received');
				}
			} catch (error) {
				console.error('Error fetching clusters:', error);
			}
		}

		fetchClusterData();
	}, [token]);


	return (
		<>
			<Header /> <Sidebar />
			{showSnackbar && <div className="snackbar">Form Submitted Successfully!</div>}
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h3 className='m-0'>View User's Details</h3>
							</div>
						</div>
					</div>
				</div>
				<section className='content'>
					<div className='container-fluid'>
						<div className='row' style={{ minHeight: '630px' }}>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										{loading ? (
											<p><FormSkeleton /> </p>
										) : error ? (
											<p>Error: {error}</p>
										) : (
											<>
												<div className='row'>
													<div className='col-sm-3'>
														<div className='form-group'>
															<select
																style={{ width: '5em' }}
																className='form-control form-control-sm'
																value={itemsPerPage}
																onChange={handleItemsPerPageChange}
															>
																<option value={10}>10</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
														</div>
													</div>
													<div className='col-sm-4'>
														<div
															id='example1_filter'
															style={{ float: 'right' }}
															className='col-sm-9 dataTables_filter'
														>
															<label>
																<input
																	type='search'
																	value={searchQuery}
																	onChange={handleSearchChange}
																	className='form-control form-control-sm'
																	placeholder='Search'
																	aria-controls='example1'
																/>
															</label>
														</div>
													</div>
													<div className='col-sm-5'>
														<span style={{ float: 'right' }}>
															<button
																type='button'
																className='btn btn-sm btn-success'
																onClick={() => setModalOpen(true)}
															>
																Add New User
															</button>
															{modalOpen && (
																<div className='customModal'>
																	<div className='customModalContent'>
																		<span
																			className='close'
																			onClick={handleCloseModal}
																		>
																			&times;
																		</span>
																		<h4>Add New User </h4>
																		<form onSubmit={handleSubmit}>
																			<div className='modal-body'>
																				<div className='form-group'>
																					<table>
																						<tr>
																							<td>
																								<label htmlFor='emp_code'>
																									BMID
																								</label>
																								<input
																									type='number'
																									min='100'
																									id='emp_code'
																									placeholder='Employee Code'
																									className='form-control form-control-sm'
																									name='emp_code'
																									value={
																										formData.emp_code || ''
																									}
																									onChange={handleInputChange}
																								/>
																							</td>
																						</tr>
																					</table>
																				</div>
																				<div className='form-group'>
																					<label htmlFor='fname'>Name</label>
																					<table>
																						<tr>
																							<td>
																								<input
																									type='text'
																									id='fname'
																									className='form-control form-control-sm'
																									name='fname'
																									placeholder='First Name'
																									value={formData.fname || ''}
																									onChange={handleInputChange}
																								/>
																							</td>
																							<td>
																								<input
																									type='text'
																									id='lname'
																									placeholder='Last Name'
																									className='form-control form-control-sm'
																									name='lname'
																									value={formData.lname || ''}
																									onChange={handleInputChange}
																								/>
																							</td>
																						</tr>
																					</table>
																				</div>
																				<div className='form-group'>
																					<label htmlFor='email'>
																						Contact {mailId}
																					</label>
																					<table>
																						<tr>
																							<td>
																								<input
																									type='email'
																									id='email'
																									placeholder='Email'
																									className='form-control form-control-sm'
																									name='email'
																									value={formData.email || ''}
																									onChange={handleInputChange}
																								/>
																							</td>
																							<td>
																								<input
																									type='number'
																									id='mobile'
																									placeholder='Mobile'
																									className='form-control form-control-sm'
																									name='mobile'
																									value={formData.mobile || ''}
																									onChange={handleInputChange}
																								/>
																							</td>
																						</tr>
																					</table>
																				</div>

																				<div className='form-group'>
																					<label htmlFor='role'>Role</label>
																					<select
																						name='role'
																						className='form-control form-control-sm'
																						value={formData.role || ''}
																						onChange={handleInputChange}
																						required
																					>
																						<option value=''>
																							Select Role
																						</option>
																						{roleslist.map((role) => (
																							<option
																								key={role.id}
																								value={role.role}
																							>
																								{role.role}
																							</option>
																						))}
																					</select>
																				</div>

																				{formData.role === 'BM' && (
																					<div className='form-group'>
																						<label>Select Branches</label>
																						<div
																							style={{
																								maxHeight: '150px',
																								overflowY: 'auto',
																								border: '1px solid #ccc',
																								padding: '8px',
																							}}
																						>
																							{brancheslist.map((branch) => (
																								<div
																									key={branch.branch_id}
																									className='form-check'
																								>
																									<input
																										type='checkbox'
																										className='form-check-input'
																										id={`branch_id-${branch.branch_id}`}
																										value={branch.branch_id}
																										checked={
																											Array.isArray(
																												formData.branch_id,
																											) &&
																											formData.branch_id.includes(
																												branch.branch_id,
																											)
																										}
																										onChange={(e) =>
																											handleCheckboxChange(
																												e,
																												'branch_id',
																											)
																										}
																									/>
																									<label
																										className='form-check-label'
																										htmlFor={`branch_id-${branch.branch_id}`}
																									>
																										{branch.branch}
																									</label>
																								</div>
																							))}
																						</div>
																					</div>
																				)}

																				{formData.role === 'CM' && (
																					<div className='form-group'>
																						<label>Select Clusters</label>
																						<div
																							style={{
																								maxHeight: '150px',
																								overflowY: 'auto',
																								border: '1px solid #ccc',
																								padding: '8px',
																							}}
																						>
																							{clusterslist.map((cl) => (
																								<div
																									key={cl.cluster_id}
																									className='form-check'
																								>
																									<input
																										type='checkbox'
																										className='form-check-input'
																										id={`cluster-${cl.cluster_id}`} // Corrected template string syntax
																										value={cl.cluster_id}
																										checked={
																											Array.isArray(
																												formData.cluster_id,
																											) &&
																											formData.cluster_id.includes(
																												cl.cluster_id,
																											)
																										}
																										onChange={(e) =>
																											handleCheckboxChange(
																												e,
																												'cluster_id',
																											)
																										}
																									/>
																									<label
																										className='form-check-label'
																										htmlFor={`cluster-${cl.cluster_id}`} // Corrected template string syntax
																									>
																										{cl.cluster}
																									</label>
																								</div>
																							))}
																						</div>
																					</div>
																				)}
																				{formData.role === 'ZONAL_MANAGER' && (
																					<div className='form-group'>
																						<label htmlFor='zone_id'>
																							Zone
																						</label>
																						<div>
																							<select
																								id='zone_id'
																								name='zone_id' // Allow multiple selections
																								value={formData.zone_id || ''} // Ensure `formData.area_id` is an array for multiple selection
																								onChange={handleInputChange}
																								className='form-control'
																							>
																								{zoneslist.map((a) => (
																									<option
																										key={a.id}
																										value={a.z_id}
																									>
																										{a.zone}
																									</option>
																								))}
																							</select>
																						</div>
																					</div>
																				)}

																				{formData.role === 'AVP' && (
																					<div className='form-group'>
																						<label htmlFor='zone_id'>
																							Zone list
																						</label>
																						<div>
																							{zoneslist.map((a) => (
																								<div
																									key={a.z_id}
																									className='form-check'
																								>
																									<input
																										type='checkbox'
																										id={`zone-${a.z_id}`}
																										name='zone_id'
																										value={a.z_id}
																										checked={formData.zone_id.includes(
																											a.z_id,
																										)} // Check if the zone_id is in the array
																										onChange={(e) =>
																											handleCheckboxChange(
																												e,
																												'zone_id',
																											)
																										} // Pass 'zone_id' to the handler
																										className='form-check-input'
																									/>

																									<label
																										htmlFor={`zone-${a.z_id}`}
																										className='form-check-label'
																									>
																										{a.zone}
																									</label>
																								</div>
																							))}
																						</div>
																					</div>
																				)}
																			</div>

																			<div className='modal-footer'>
																				<button
																					type='button'
																					className='btn btn-sm btn-secondary'
																					onClick={closeModal}
																				>
																					Close
																				</button>
																				<button
																					type='submit'
																					className='btn btn-sm btn-primary'
																				>
																					Add
																				</button>
																			</div>
																		</form>
																	</div>
																</div>
															)}
															<button
																type='button'
																className='btn btn-sm btn-secondary ml-2'
																onClick={exportToExcel}
															>
																Export to Excel
															</button>
														</span>
													</div>
												</div>

												<table className='table table-bordered table-striped'>
													<thead>
														<tr>
															<th>S.No</th>
															<th>BMID</th>
															<th>Users</th>
															<th>Mobile</th>
															<th>Zone</th>
															<th>Branches Mapped</th>
															<th>Clusters Mapped</th>
															<th>Role</th>
															<th style={{ minWidth: '180px' }}>Action</th>
														</tr>
													</thead>
													<tbody>
														{currentItems.length > 0 ? (
															currentItems.map((item, index) => (
																<tr key={index}>
																	<td>
																		{index +
																			1 +
																			(currentPage - 1) * itemsPerPage}
																	</td>
																	<td>{item.emp_code}</td>
																	<td>
																		{item.fname} {item.lname}
																	</td>
																	<td>{item.mobile}</td>
																	<td>
																		{item.userBranchList &&
																			item.userBranchList.length > 0 ? (
																			<>
																				{item.userBranchList.map(
																					(s, bIndex) => (
																						<div key={bIndex}>
																							<ul
																								style={{
																									margin: 0,
																									padding: 0,
																									listStyleType: 'none',
																								}}
																							>
																								<li>{s.zone}</li>
																							</ul>
																						</div>
																					),
																				)}
																			</>
																		) : (
																			' -- '
																		)}
																	</td>

																	<td>
																		{item.userBranchList &&
																			item.userBranchList.length > 0 ? (
																			<>
																				{item.userBranchList.map(
																					(branch_id, bIndex) => (
																						<div key={bIndex}>
																							<ul
																								style={{
																									margin: 0,
																									padding: 0,
																									listStyleType: 'none',
																								}}
																							>
																								<li>{branch_id.branch}</li>
																							</ul>
																						</div>
																					),
																				)}
																			</>
																		) : (
																			' -- '
																		)}
																	</td>
																	<td>
																		{item.userBranchList &&
																			item.userBranchList.length > 0 ? (
																			<>
																				{item.userBranchList.map(
																					(branch_id, bIndex) => (
																						<div key={bIndex}>
																							<ul
																								style={{
																									margin: 0,
																									padding: 0,
																									listStyleType: 'none',
																								}}
																							>
																								<li>
																									<span
																										style={{ color: '#ec4f4f' }}
																									></span>
																									{branch_id.cluster}
																								</li>
																							</ul>
																						</div>
																					),
																				)}
																			</>
																		) : (
																			' -- '
																		)}
																	</td>
																	<td>
																		{item.role ? item.role : '--'}
																		{item.isAdmin === 'Y' ? 'Admin' : ''}
																	</td>

																	<td>
																		<button
																			type='button'
																			className='btn btn-success btn-sm'
																			onClick={() => handleEditClick(item)}
																			aria-label='Edit'
																			title='Edit'
																		>
																			<i className='fas fa-edit'></i>
																		</button>

																		<Link
																			style={{ marginLeft: '2px' }}
																			to={`#`}
																		>
																			<button
																				type='button'
																				className='btn btn-danger btn-sm'
																				onClick={() => handleDeleteClick(item)}
																				aria-label='Delete'
																				title='Delete'
																			>
																				<i className='fas fa-trash'></i>
																			</button>
																		</Link>

																		<Link
																			style={{ marginLeft: '2px' }}
																			to={`#`}
																		>
																			<button
																				type='button'
																				className='btn btn-info btn-sm'
																				onClick={() => handleResetPass(item)}
																				aria-label='Reset Password'
																				title='Reset Password'
																			>
																				<i className='fas fa-redo'></i>
																			</button>
																		</Link>
																		<Link
																			style={{ marginLeft: '2px' }}
																			to={`#`}
																		>
																			<button
																				type='button'
																				className='btn btn-primary btn-sm'
																				onClick={() => handleAddRole(item)}
																				aria-label='Add Branch'
																				title='Add Branch'
																			>
																				<i className='fas fa-plus'></i>
																			</button>
																		</Link>
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan='3'>No data available</td>
															</tr>
														)}
													</tbody>
												</table>
											</>
										)}
									</div>
								</div>
								<nav>
									<ul className='pagination' style={{ float: 'right' }}>
										<li className='page-item'>
											<button
												className='page-link'
												onClick={() => handlePageChange(currentPage - 1)}
												disabled={currentPage === 1}
											>
												Previous
											</button>
										</li>
										{(() => {
											const pages = [];
											const totalPagesToShow = 10; // Number of pages to show
											const halfToShow = Math.floor(totalPagesToShow / 2);

											let startPage = Math.max(1, currentPage - halfToShow);
											let endPage = Math.min(
												totalPages,
												startPage + totalPagesToShow - 1,
											);

											if (endPage - startPage < totalPagesToShow - 1) {
												startPage = Math.max(1, endPage - totalPagesToShow + 1);
											}

											for (
												let pageNumber = startPage;
												pageNumber <= endPage;
												pageNumber++
											) {
												pages.push(
													<li
														key={pageNumber}
														className={`page-item ${pageNumber === currentPage ? 'active' : ''
															}`}
													>
														<button
															className='page-link'
															onClick={() => handlePageChange(pageNumber)}
														>
															{pageNumber}
														</button>
													</li>,
												);
											}
											return pages;
										})()}
										<li className='page-item'>
											<button
												className='page-link'
												onClick={() => handlePageChange(currentPage + 1)}
												disabled={currentPage === totalPages}
											>
												Next
											</button>
										</li>
									</ul>
								</nav>

								<div className='mb-3'>
									Showing {startItem} to {endItem} of {filteredItems.length}{' '}
									entries
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{/* Custom Modal */}
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<h4>Edit User Details</h4>
						{selectedUserInfo && (
							// <form onSubmit={handleSubmitEditForm}>
							// 	<div className='modal-body'>
							// 		<div className='form-group'>
							// 			<label htmlFor='emp_code'>
							// 				BMID {formData.emp_code || ''}
							// 			</label>
							// 		</div>

							// 		<div className='form-group'>
							// 			<label htmlFor='fname'>Name</label>
							// 			<table>
							// 				<tr>
							// 					<td>
							// 						<input
							// 							type='text'
							// 							id='fname'
							// 							className='form-control form-control-sm'
							// 							name='fname'
							// 							placeholder='First Name'
							// 							value={formData.fname || ''}
							// 							onChange={handleInputChange}
							// 						/>
							// 					</td>
							// 					<td>
							// 						<input
							// 							type='text'
							// 							id='lname'
							// 							placeholder='Last Name'
							// 							className='form-control form-control-sm'
							// 							name='lname'
							// 							value={formData.lname || ''}
							// 							onChange={handleInputChange}
							// 						/>
							// 					</td>
							// 				</tr>
							// 			</table>
							// 		</div>
							// 		<div className='form-group'>
							// 			<label htmlFor='email'>Contact</label>
							// 			<table>
							// 				<tr>
							// 					<td>
							// 						<input
							// 							type='email'
							// 							id='email'
							// 							placeholder='Email'
							// 							className='form-control form-control-sm'
							// 							name='email'
							// 							value={formData.email || ''}
							// 							onChange={handleInputChange}
							// 						/>
							// 					</td>
							// 					<td>
							// 						<input
							// 							type='tel'
							// 							id='mobile'
							// 							placeholder='Mobile'
							// 							className='form-control form-control-sm'
							// 							name='mobile'
							// 							value={formData.mobile || ''}
							// 							onChange={handleInputChange}
							// 						/>
							// 					</td>
							// 				</tr>
							// 			</table>
							// 		</div>

							// 		<div className='form-group'>
							// 			<label htmlFor='ac_type'>Role</label>
							// 			<table>
							// 				<tr>
							// 					<td>
							// 						<select
							// 							name='role'
							// 							className='form-control form-control-sm'
							// 							value={formData.role || ''}
							// 							onChange={handleInputChange}
							// 							required
							// 						>
							// 							<option value={''}>Select Role</option>
							// 							{roleslist.map((b) => (
							// 								<option key={b.id} value={b.role}>
							// 									{b.role}
							// 								</option>
							// 							))}
							// 						</select>
							// 					</td>
							// 				</tr>
							// 			</table>
							// 		</div>

							// 		{/* <div className='form-group'>
							// 			<label htmlFor='BRANCH'>Branch Name</label>
							// 			<select
							// 				id='BRANCH'
							// 				name='BRANCH'
							// 				value={formData.BRANCH || ''}
							// 				onChange={handleInputChange}
							// 				className='form-control form-control-sm'
							// 				required
							// 			>
							// 				<option value=''>Select a Branch</option>
							// 				{brancheslist.map((b) => (
							// 					<option key={b.id} value={b.id}>
							// 						{b.branch_id}
							// 					</option>
							// 				))}
							// 			</select>
							// 		</div> */}
							// 	</div>
							// 	<div className='modal-footer'>
							// 		<input type='hidden' name='id' value={formData.id || ''} />
							// 		<button
							// 			type='button'
							// 			className='btn btn-secondary'
							// 			onClick={handleCloseModal}
							// 		>
							// 			Close
							// 		</button>
							// 		<button type='submit' className='btn btn-primary'>
							// 			Update
							// 		</button>
							// 	</div>
							<form onSubmit={handleSubmitEditForm}>
								<div className='modal-body'>
									<div className='form-group'>
										<table>
											<tr>
												<td>
													<label htmlFor='emp_code'>BMID</label>
													<input
														type='text'
														id='emp_code'
														placeholder='Employee Code'
														className='form-control form-control-sm'
														name='emp_code'
														value={formData.emp_code || ''}
														onChange={handleInputChange}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div className='form-group'>
										<label htmlFor='fname'>Name</label>
										<table>
											<tr>
												<td>
													<input
														type='text'
														id='fname'
														className='form-control form-control-sm'
														name='fname'
														placeholder='First Name'
														value={formData.fname || ''}
														onChange={handleInputChange}
													/>
												</td>
												<td>
													<input
														type='text'
														id='lname'
														placeholder='Last Name'
														className='form-control form-control-sm'
														name='lname'
														value={formData.lname || ''}
														onChange={handleInputChange}
													/>
												</td>
											</tr>
										</table>
									</div>
									<div className='form-group'>
										<label htmlFor='email'>Contact {mailId}</label>
										<table>
											<tr>
												<td>
													<input
														type='email'
														id='email'
														placeholder='Email'
														className='form-control form-control-sm'
														name='email'
														value={formData.email || ''}
														onChange={handleInputChange}
													/>
												</td>
												<td>
													<input
														type='tel'
														id='mobile'
														placeholder='Mobile'
														className='form-control form-control-sm'
														name='mobile'
														value={formData.mobile || ''}
														onChange={handleInputChange}
													/>
												</td>
											</tr>
										</table>
									</div>

									<div className='form-group'>
										<table>
											<tr>


												<td>
													<label htmlFor='ac_type'>Role</label>
													<select
														name='role'
														className='form-control form-control-sm'
														value={formData.role || ''}
														onChange={handleInputChange}
														required
													>
														<option value={''}>Select Role</option>
														{roleslist.map((b) => (
															<option key={b.id} value={b.role}>
																{b.role}
															</option>
														))}
													</select>
												</td>
											</tr>
										</table>
									</div>
								</div>

								<div className='modal-footer'>
									<div className='modal-footer'>
										<input type='hidden' name='id' value={formData.id || ''} />
										<button
											type='button'
											className='btn btn-secondary'
											onClick={handleCloseModal}
										>
											Close
										</button>
										<button type='submit' className='btn btn-primary'>
											Update
										</button>
									</div>
								</div>
							</form>
						)}
					</div>
				</div>
			)}
			{showDeleteModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						{selectedUserInfo && (
							<form onSubmit={handleSubmitDeleteForm}>
								<div className='modal-body'>
									<div className='form-group'>
										<label htmlFor='department'>
											Are you sure you want to delete the User: {formData.fname}
											?
										</label>
									</div>
								</div>
								<div className='modal-footer'>
									<input
										type='hidden'
										name='emp_code'
										value={formData.emp_code || ''}
									/>
									<button
										type='button'
										className='btn btn-secondary'
										onClick={handleCloseModal}
									>
										Close
									</button>
									<button type='submit' className='btn btn-danger'>
										Delete
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			)}
			{showResetModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						{selectedUserInfo && (
							<form onSubmit={handleSubmitResetForm}>
								<div className='modal-body'>
									<div className='form-group'>
										<label htmlFor='department'>
											Are you sure you want to reset password for User:{' '}
											{formData.fname}? <br />
											Please Note New Password will be Pass@1981#
										</label>
									</div>
								</div>
								<div className='modal-footer'>
									<input
										type='hidden'
										name='emp_code'
										value={formData.emp_code || ''}
									/>
									<button
										type='button'
										className='btn btn-secondary'
										onClick={handleCloseModal}
									>
										Close
									</button>
									<button type='submit' className='btn btn-danger'>
										Reset
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			)}
			{showAddRoleModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						{selectedUserInfo && (
							<form onSubmit={handleAddBranchesForm}>
								<div className='modal-body'>
									{/* Conditional Rendering based on selectedUserInfo.role */}
									{selectedUserInfo.role === 'BM' ? (
										<>
											<div className='form-group'>
												<label htmlFor='department'>
													Assign Branch to: {selectedUserInfo.fname}{' '}
													{selectedUserInfo.lname}
												</label>
											</div>
											<div className='form-group'>
												<select
													className='form-control'
													name='branch_id'
													value={formData.branch_id || ''}
													onChange={handleInputChange}
												>
													<option value=''>Select a Branch</option>
													{brancheslist.map((r) => (
														<option key={r.id} value={r.branch_id}>
															{r.branch}
														</option>
													))}
												</select>
											</div>
										</>
									) : selectedUserInfo.role === 'CM' ? (
										<>
											<div className='form-group'>
												<label htmlFor='department'>
													Assign Cluster to: {selectedUserInfo.fname}{' '}
													{selectedUserInfo.lname}
												</label>
											</div>
											<div className='form-group'>
												<select
													className='form-control'
													name='cluster'
													value={formData.cluster || ''}
													onChange={handleInputChange}
												>
													<option value=''>Select a Cluster</option>
													{clusterslist.map((r) => (
														<option key={r.id} value={r.cluster_id}>
															{r.cluster}
														</option>
													))}
												</select>
											</div>
										</>
									) : selectedUserInfo.role === 'ZONAL_MANAGER' ? (
										<>
											<div className='form-group'>
												<label htmlFor='department'>
													Assign Zone to: {selectedUserInfo.fname}{' '}
													{selectedUserInfo.lname}
												</label>
											</div>
											<div className='form-group'>
												<select
													className='form-control'
													name='zone_id'
													value={formData.zone_id || ''}
													onChange={handleInputChange}
												>
													<option value=''>Select a Zone</option>
													{zoneslist.map((r) => (
														<option key={r.id} value={r.z_id}>
															{r.zone}
														</option>
													))}
												</select>
											</div>
										</>
									) : selectedUserInfo.role === 'AVP' ? (
										<>
											<div className='form-group'>
												<label htmlFor='department'>
													Assign Zone to: {selectedUserInfo.fname}{' '}
													{selectedUserInfo.lname}
												</label>
											</div>
											<div className='form-group'>
												<select
													className='form-control'
													name='zone_id'
													value={formData.zone_id || ''}
													onChange={handleInputChange}
												>
													<option value=''>Select a Zone</option>
													{zoneslist.map((r) => (
														<option key={r.id} value={r.z_id}>
															{r.zone}
															{r.z_id}
														</option>
													))}
												</select>
											</div>
										</>
									) : (
										<>
											<div className='form-group'>
												<label htmlFor='department'>
													{selectedUserInfo.fname} {selectedUserInfo.lname} has
													no Role assigned. Please add role using edit.
												</label>
											</div>{' '}
										</>
									)}
								</div>
								<div className='modal-footer'>
									<input
										type='hidden'
										name='emp_code'
										value={formData.emp_code || ''}
									/>
									<button
										type='button'
										className='btn btn-sm btn-secondary'
										onClick={handleCloseModal}
									>
										Close
									</button>
									{selectedUserInfo.role === 'BM' ||
										selectedUserInfo.role === 'ZONAL_MANAGER' ||
										selectedUserInfo.role === 'AVP' ||
										selectedUserInfo.role === 'CM' ? (
										<button type='submit' className='btn btn-sm btn-danger'>
											Add
										</button>
									) : (
										<></>
									)}
								</div>
							</form>
						)}
					</div>
				</div>
			)}
			);
			<Footer />
		</>
	);
};

export default Manage_user;
