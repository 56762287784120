import React, { useState, useEffect } from 'react';
 
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
 
import '../customStyle.css';

const BM_Dashboard = () => {
	 
	
const[bmDashboardCount, setBmDashboardCount]= useState([]);
const token = sessionStorage.getItem('authToken');
const [loading, setLoading] = useState([]);
const [selectedMonth, setSelectedMonth] = useState();
const selectMonth = (e) => {
	const sMonth = e.target.value;
	setSelectedMonth(sMonth);
	sessionStorage.setItem('selectedMonth', sMonth); // Store in sessionStorage
	window.location.reload();
  };
  const today = new Date();
  const thisMonth = today.getMonth() + 1;
  const thisYear = today.getFullYear();
  const thisMonthWithYear = `${thisYear}-${String(thisMonth).padStart(2, '0')}`;
  const [branchlist, setBranchlist] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  useEffect(() => {
    const fetchBranchList = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        //console.log('API Response:', response); // Log API response
        if (response.ok) {
          const result = await response.json();
          //console.log('Parsed API Response:', result);
          if (result.STATUS) {
            // Check the correct key
            setBranchlist(result.data);
          } else {
            //toast.error( result.message || 	'An error occurred while fetching the Branch list.',);
          }
        } else {
          const errorBody = await response.text();
          console.error('Error response:', errorBody);
          //toast.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        //toast.error('Failed to fetch branch list');
      } finally {
        setLoading(false); // Ensure loading state is set to false after the fetch
      }
    };

    fetchBranchList();
  }, [token, selectedBranch]);

  useEffect(() => {
     
    const selectBranch = sessionStorage.getItem('selectedBranchId');
    const selectMonth = sessionStorage.getItem('selectedMonth') || thisMonthWithYear;
    // Set the current date if selectedDate is empty
    
    setSelectedMonth(selectMonth);
    // Check if selectedBranch exists, otherwise set default from branchlist once it's available
    if (selectBranch) {
      setSelectedBranch(selectBranch);
    } else if (branchlist.length > 0) {
      setSelectedBranch(branchlist[0].branch_id);
      sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
    }
  }, [branchlist, thisMonthWithYear, selectedBranch]);

useEffect(() => {
	const fetchDashboard = async () => {
		const requestData = {
			selectedMonth: selectedMonth,	
			selectedBranch: selectedBranch,		 
		  };
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/BM_DashboardCount`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(requestData),
			});

			//console.log('Dashboard Count :', response); // Log API response
			if (response.ok) {
				const result = await response.json();
				//console.log('Parsed API Response:', result);
				if (result.STATUS) {
					// Check the correct key
					setBmDashboardCount(result.data);
				} else {
					 //console.log(result.message);
				}
			} else {
				const errorBody = await response.text();
				console.error('Error response:', errorBody);
				 
			}
		} catch (error) {
			console.error('Fetch error:', error);
			 
		} finally {
			setLoading(false); // Ensure loading state is set to false after the fetch
		}
	};

	fetchDashboard();
}, [token, selectedMonth, selectedBranch]);

	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				<div className='content-header'>
				<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-12 col-sm-10'>
								<h3 className='m-0'> Welcome to Vijaya Diagnostic Centre </h3>
							</div>
							<div className='col-12 col-sm-2'>
							 <div className='mb-2 '>
								  <input type='month' name='selectedMonth' id='selectedMonth'
									onChange={selectMonth}
									value={selectedMonth || thisMonthWithYear}
									className='form-control form-control-sm ' />
								 
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className='content'>
					<div className='container-fluid'>
						<div className='row' style={{ minHeight:'630px' }}> 
							<div className='col-12 col-sm-6 col-md-3'>
								<div className='small-box bg-info' >
									<div className='inner'>
										<h3>{bmDashboardCount.morningTasksCount}</h3>
										<p>Total Morning Tasks</p>
									</div>
									<div className='icon d-block '>
										<i className='fas fa-sun'></i> 
									</div>
									 
								</div>
							</div>
							<div className='col-12 col-sm-6 col-md-3'>
								<div className='small-box bg-success'>
									<div className='inner'>
										<h3>
										{bmDashboardCount.nightTasksCount}
										</h3>
										<p>Total Night Closing Tasks</p>
									</div>
									<div className='icon d-block '><i className="fas fa-solid fa-moon"></i>
									</div>	
																	 
								</div>
							</div>							 
							<div className='col-12 col-sm-6 col-md-3'> </div>		
							<div className='col-12 col-sm-6 col-md-3'> 	</div>								 
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default BM_Dashboard;
