import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = sessionStorage.getItem('authToken');
        const storedRole = sessionStorage.getItem('role');
        if (token && storedRole) {
            setIsAuthenticated(true);
            setRole(storedRole);
        }
        setLoading(false);
    }, []);

    const login = (token, role) => {
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('role', role);
        setIsAuthenticated(true);
        setRole(role);
    };

    const logout = () => {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('role');
        setIsAuthenticated(false);
        setRole(null);
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                login,
                logout,
                loading,
                role
            }}
        >
            {!loading && children}
        </AuthContext.Provider>
    );
};


// Custom hook to use the AuthContext
export const useAuth = () => {
	return useContext(AuthContext);
};

export default AuthContext;
