import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    function logout() {
        //console.log("Clearing session storage");
        sessionStorage.clear(); // Clear all data in sessionStorage
        //console.log("Session storage cleared:", sessionStorage); // Check if it's cleared
        window.location.reload(); // Reload the current page
    }

    return (
        <>
            <footer className='main-footer'>
                <strong>
                    Copyright © 2024-2025{" "}
                    <a href='https://vijayadiagnostic.com'>Vijaya Diagnostic Centre</a>.
                </strong>
                All rights reserved.
                <div className='float-right d-none d-sm-inline-block'>
                    <b>Version</b> 1.0.0
                </div>
            </footer>
            {/* Control Sidebar */}
            <aside className='control-sidebar control-sidebar-dark'>
                <Link
                    href='#'
                    onClick={logout}
                    className='nav-link'>
                    Logout
                </Link>
            </aside>
        </>
    );
}

export default Footer;