import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
const Zcm_list = () => {
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [zoneslist, setZoneslist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [selectedCluster, setSelectedCluster] = useState();
	const [selectedBranch, setSelectedBranch] = useState();
	const [mtDetails, setMtDetails] = useState();
	const [nightTaskDetails, setNightTaskDetails] = useState();
	const [selectedDate, setSelectedDate] = useState();
	const [selectedZoneId, setSelectedZoneId] = useState();
	const token = sessionStorage.getItem('authToken');
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	};

	const selectDate = (e) => {
		const sDate = e.target.value;
		setSelectedDate(sDate);
		sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
	};

	const handleSelectZone = (e) => {
		const zoneId = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			zone_id: selectedZoneId,
		}));
		setSelectedZoneId(zoneId);
		sessionStorage.setItem('selectedZoneId', zoneId); // Store in sessionStorage
	};

	const handleSelectCluster = (e) => {
		const selectedClusterId = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			cluster: selectedClusterId,
			cluster_id: selectedClusterId,
		}));

		sessionStorage.setItem('selectedClusterId', selectedClusterId);
		//console.log('Cluster ID stored in session:', selectedClusterId);
	};

	// const handleInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

	// 	setFormData((prevFormData) => ({
	// 		...prevFormData,
	// 		[name]: value, // Update the specific field
	// 	}));
	// };
	const [formData, setFormData] = useState({
		cluster: '',
		cluster_id: '',
		zone_id: '',
		zone: '',
		branch: '',
		branch_id: '',
	});

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');
		const selectCluster = sessionStorage.getItem('selectedClusterId');
		const selectedZoneId = sessionStorage.getItem('selectedZoneId');
		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}

		if (selectCluster) {
			setSelectedCluster(selectCluster);
		} else if (clusterlist.length > 0) {
			setSelectedCluster(clusterlist[0].cluster_id);
			sessionStorage.setItem('selectedClusterId', clusterlist[0].cluster_id);
		}

		if (selectedZoneId) {
			setSelectedZoneId(selectedZoneId);
			// Fetch clusterId based on selectedZoneId
			const fetchClusterId = async () => {
				try {
					const formData = new FormData();
					formData.append('zone_id', selectedZoneId);
					const response = await fetch(`${process.env.REACT_APP_API_URL}api/getZoneClusterList`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: formData,
					});
					if (response.ok) {
						const result = await response.json();
						if (result.STATUS && result.data.length > 0) {
							const clusterId = result.data[0].cluster_id;
							setSelectedCluster(clusterId);
							sessionStorage.setItem('selectedClusterId', clusterId);
						}
					} else {
						console.error('Failed to fetch clusterId');
					}
				} catch (error) {
					console.error('Error fetching clusterId:', error);
				}
			};
			fetchClusterId();
		} else if (zoneslist.length > 0) {
			const defaultZoneId = zoneslist[0].zone_id;
			setSelectedZoneId(defaultZoneId);
			sessionStorage.setItem('selectedZoneId', defaultZoneId);
			// Fetch clusterId based on defaultZoneId
			const fetchClusterId = async () => {
				try {
					const formData = new FormData();
					formData.append('zone_id', defaultZoneId);
					const response = await fetch(`${process.env.REACT_APP_API_URL}api/getZoneClusterList`, {
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: formData,
					});
					if (response.ok) {
						const result = await response.json();
						if (result.STATUS && result.data.length > 0) {
							const clusterId = result.data[0].cluster_id;
							setSelectedCluster(clusterId);
							sessionStorage.setItem('selectedClusterId', clusterId);
						}
					} else {
						console.error('Failed to fetch clusterId');
					}
				} catch (error) {
					console.error('Error fetching clusterId:', error);
				}
			};
			fetchClusterId();
		}
	}, [branchlist, clusterlist, zoneslist]);

	// Fetch the list of Clusters
	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
					} else {
						 
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	// Fetch the list of zones
	useEffect(() => {
		async function fetchRoleData() {
			try {
				const rolesResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getUserZones`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
				});
				if (!rolesResponse.ok) {
					throw new Error(`Error: ${rolesResponse.status}`);
				}
				const roleData = await rolesResponse.json();

				if (roleData && Array.isArray(roleData.data)) {
					//console.log(`Zone data: ${roleData.data}`);
					setZoneslist(roleData.data); // Make sure data is an array
				} else {
					console.error('No valid data received');
					setZoneslist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching roles:', error);
				setZoneslist([]); // Empty array on error
			}
		}

		fetchRoleData();
	}, [token]);

	// Fetch the list of clusters
	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const selectedZoneId = sessionStorage.getItem('selectedZoneId'); // Retrieve cluster ID from session storage
				// Create FormData and append the selectedClusterId
				const formData = new FormData();
				formData.append('zone_id', selectedZoneId);

				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getZoneClusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});

				console.log('API Response:', response); // Log the response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						setClusterlist(result.data);
					} else {
						 
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		if (token && sessionStorage.getItem('selectedZoneId')) {
			fetchClusterList();
		}
	}, [token, sessionStorage.getItem('selectedZoneId')]); // Dependency array

	// Fetch the morning task details
	// useEffect(() => {
	// 	async function fetchMtData() {
	// 		try {
	// 			// Prepare the request payload in JSON format
	// 			const requestData = {
	// 				selectedDate: selectedDate,
	// 				selectedCluster: selectedCluster,
	// 			};

	// 			// Make the API request with JSON payload
	// 			const mtResponse = await fetch(
	// 				`${process.env.REACT_APP_API_URL}api/getCmMorningTaskDetails`,
	// 				{
	// 					method: 'POST',
	// 					headers: {
	// 						'Content-Type': 'application/json', // Set content-type as JSON
	// 						Authorization: `Bearer ${token}`, // Ensure correct format
	// 					},
	// 					body: JSON.stringify(requestData), // Stringify the JSON payload
	// 				},
	// 			);

	// 			if (!mtResponse.ok) {
	// 				throw new Error(`Error: ${mtResponse.status}`);
	// 			}

	// 			const mtData = await mtResponse.json();

	// 			if (mtData && Array.isArray(mtData.data)) {
	// 				console.log('MtData:', mtData.data);
	// 				setMtDetails(mtData.data); // Set the received data to state
	// 			} else {
	// 				console.error('No valid data received');
	// 				setMtDetails([]); // Empty array if no valid data
	// 			}
	// 		} catch (error) {
	// 			console.error('Error fetching morning task data:', error);
	// 			setMtDetails([]); // Empty array on error
	// 		}
	// 	}

	// 	fetchMtData();
	// }, [token, selectedDate, selectedBranch]);
	useEffect(() => {
		const fetchMtData = async () => {
			try {
				// Wait for selectedCluster to be available
				const selectedCluster = await new Promise((resolve) => {
					const interval = setInterval(() => {
						const cluster = sessionStorage.getItem('selectedClusterId');
						if (cluster) {
							clearInterval(interval);
							resolve(cluster);
						}
					}, 100);
				});

				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedCluster: selectedCluster,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/getCmMorningTaskDetails`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		};

		fetchMtData();
	}, [token, selectedDate, selectedCluster]);
	//
	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

	// Fetch the night task details
	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedCluster: selectedCluster,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/getCm_nightTaskDetails`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setNightTaskDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setNightTaskDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setNightTaskDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedCluster]); // Dependencies to trigger the effect

	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];
	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-3 form-group'>
								<select
									className='form-control form-control-sm'
									name='zone_id'
									value={formData.zone_id || selectedZoneId || ''}
									onChange={handleSelectZone}
								>
									<option value=''>Select Zone</option>
									{zoneslist.map((r) => (
										<option key={r.id} value={r.zone_id}>
											{r.zone}
										</option>
									))}
								</select>
							</div>
							<div className='col-sm-3 form-group'>
								<select
									className='form-control form-control-sm'
									name='cluster'
									value={formData.cluster || selectedCluster || ''}
									onChange={handleSelectCluster}
								>
									<option value=''>Select Cluster</option>
									{clusterlist.map((r) => (
										<option key={r.id} value={r.cluster_id}>
											{r.cluster}
										</option>
									))}
								</select>
							</div>

							<div className='col-sm-3 form-group'>
								<div className='form-group col-sm-8'>
									<input
										type='date'
										name='selectDate'
										onChange={selectDate}
										className='form-control form-control-sm'
										placeholder='Select Date'
										max={today}
										value={selectedDate} // Set the default value here
									/>
								</div>
							</div>
						</div>
						{/* /.row */}
						<section className='col-lg-12'>
							<div className='card'>
								<div className='card-body row'>
									<div className='col-sm-6 mt-2'>
										<h5>Morning Check List</h5>
										{mtDetails && mtDetails.length > 0 ? (
											<div
												className='task-container'
												style={{
													border: '1px solid #cccccc',
													paddingLeft: '10px',
													paddingTop: '10px',
												}}
											>
												{mtDetails.map((task, index) => (
													<div key={index} className='task-card'>
														<p>
															Created By :
															<strong>
																{task.fname} {task.lname}{' '}
																<small>({task.emp_code} )</small>
																<small className='red'>
																	({formatDate(task.createdDTM)} )
																</small>
															</strong>
														</p>
														<p className='pp1'>
															<strong> {task.mt0100} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0101} {task.mt0102}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt0200} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0201} {task.mt0202}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.mt0300} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0301} {task.mt0302}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt0400} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0401} {task.mt0402}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt0500} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0501} {task.mt0502}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt0600} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0601} {task.mt0602}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt0700} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0701} {task.mt0702}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt0800} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0801} {task.mt0802}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.mt0900} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt0901} {task.mt0902}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.mt1000} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.mt1001} {task.mt1002}
															</span>
														</p>
													</div>
												))}
											</div>
										) : (
											<p>No data available</p>
										)}
									</div>

									<div className='col-sm-6 mt-2'>
										<h5>Night Closing Check List</h5>
										{nightTaskDetails && nightTaskDetails.length > 0 ? (
											<div
												className='task-cocm_ntainer'
												style={{
													border: '1px solid #cccccc',
													paddingLeft: '10px',
													paddingTop: '10px',
												}}
											>
												{nightTaskDetails.map((task, index) => (
													<div key={index} className='task-card'>
														<p>
															Created By :
															<strong>
																{task.fname} {task.lname}{' '}
																<small>({task.emp_code} )</small>
																<small className='red'>
																	({formatDate(task.createdDTM)} )
																</small>
															</strong>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0100} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0101} {task.cm_nt0102}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0200} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0201} {task.cm_nt0202}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0200} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0201} {task.cm_nt0202}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt0300} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0301} {task.cm_nt0302}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0400} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0401} {task.cm_nt0402}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0500} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0501} {task.cm_nt0502}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0600} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0601} {task.cm_nt0602}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0700} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0701} {task.cm_nt0702}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt0800} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0801} {task.cm_nt0802}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt0900} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt0901} {task.cm_nt0902}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1000} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1001} {task.cm_nt1002}
															</span>
														</p>

														{/* <p className='pp1'>
															<strong> {task.cm_nt1100} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1101} {task.cm_nt1102}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1200} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1201} {task.cm_nt1202}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt1300} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1301} {task.cm_nt1302}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1400} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1401} {task.cm_nt1402}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1500} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1501} {task.cm_nt1502}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1600} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1601} {task.cm_nt1602}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1700} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1701} {task.cm_nt1702}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt1800} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1801} {task.cm_nt1802}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt1900} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt1901} {task.cm_nt1902}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2000} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2001} {task.cm_nt2002}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt2100} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2101} {task.cm_nt2102}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2200} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2201} {task.cm_nt2202}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt2300} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2301} {task.cm_nt2302}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2400} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2401} {task.cm_nt2402}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2500} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2501} {task.cm_nt2502}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2600} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2601} {task.cm_nt2602}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2700} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2701} {task.cm_nt2702}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt2800} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2801} {task.cm_nt2802}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt2900} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt2901} {task.cm_nt2902}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3000} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3001} {task.cm_nt3002}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt3100} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3101} {task.cm_nt3102}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3200} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3201} {task.cm_nt3202}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt3300} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3301} {task.cm_nt3302}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3400} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3401} {task.cm_nt3402}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3500} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3501} {task.cm_nt3502}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3600} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3601} {task.cm_nt3602}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3700} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3701} {task.cm_nt3702}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt3800} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3801} {task.cm_nt3802}
															</span>
														</p>

														<p className='pp1'>
															<strong> {task.cm_nt3900} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt3901} {task.cm_nt3902}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt4000} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt4001} {task.cm_nt4002}
															</span>
														</p>
														<p className='pp1'>
															<strong> {task.cm_nt4100} : </strong>{' '}
															<span className='red'>
																{' '}
																{task.cm_nt4101} {task.cm_nt4102}
															</span>
														</p> */}
													</div>
												))}
											</div>
										) : (
											<p>No data available</p>
										)}
									</div>
								</div>
							</div>
						</section>
					</div>
					{/* /.container-fluid */}
				</div>
				{/* /.content-header */}
			</div>
			<Footer />
		</>
	);
};

export default Zcm_list;
