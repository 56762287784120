import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { useParams, useNavigate } from 'react-router-dom';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css';

import '../customStyle.css';


const EditCmNightTask = () => {
	const { cm_nid } = useParams();
	const [cm_nidSelected, setCm_nidSelected] = useState(cm_nid);
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);	 
	const [loading, setLoading] = useState([]);	 
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');	 
	const [mtDetails, setMtDetails] = useState(null);
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const [formData, setFormData] = useState([]);
	const [showSnackbar, setShowSnackbar] = useState(false);
	useEffect(() => {
        if (sessionStorage.getItem('showSnackbar') === 'true') {
            setShowSnackbar(true);
            sessionStorage.removeItem('showSnackbar');
            // Hide snackbar after 3 seconds
            setTimeout(() => setShowSnackbar(false), 2000);
        }
    }, []);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

 

	useEffect(() => {
		if (cm_nid) {
			setCm_nidSelected(cm_nid); // Set the midSelected state
		} else { console.error('mid is undefined or invalid'); }
	}, [cm_nid]);



	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);



 


	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
						sessionStorage.setItem(
							'selectedClusterId',
							result.data[0].cluster_id,
						);
					} else {
						navigate('/login');
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					navigate('/login');
				}
			} catch (error) {
				console.error('Fetch error:', error);
				navigate('/login');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

	 
 
	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			if (!mtDetails || mtDetails.length === 0) {
				throw new Error('No mtDetails available to submit');
			}

			// Prepare the request payload in JSON format with updated mtDetails
			const requestData = {
				...mtDetails[0], // Assuming you're sending only the first item for simplicity.
				...formData, // Include formData in the request payload
			};


			const mtResponse = await fetch(
				`${process.env.REACT_APP_API_URL}api/saveCmNightTaskDetails`,
				{
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the updated data to send
				},
			);
			
			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}
			
			const responseData = await mtResponse.json();
			
			if (responseData.status === true) {
				sessionStorage.setItem('showSnackbar', 'true');	
				 window.location.reload();
			} else {
				console.error('Failed to submit data');
				////toast.error('Failed to submit form');
			}

		} catch (error) {
			console.error('Error submitting data:', error);
			 
		}
	};

	const initialFormData =
	{
		cluster: '',
		cluster_id: sessionStorage.getItem('selectedClusterId') || '',
		cm_nt0100: 'Question Number 1 ?',
		cm_nt0101: '',
		cm_nt0102: '',
		cm_nt0200: 'Question Number 2 ?',
		cm_nt0201: '',
		cm_nt0202: '',
		cm_nt0300: 'Question Number 3 ?',
		cm_nt0301: '',
		cm_nt0302: '',
		cm_nt0400: 'Question Number 4 ?',
		cm_nt0401: '',
		cm_nt0402: '',
		cm_nt0500: 'Question Number 5 ?',
		cm_nt0501: '',
		cm_nt0502: '',
		cm_nt0600: 'Question Number 6 ?',
		cm_nt0601: '',
		cm_nt0602: '',
		cm_nt0700: 'Question Number 7 ?',
		cm_nt0701: '',
		cm_nt0702: '',
		cm_nt0800: 'Question Number 8 ?',
		cm_nt0801: '',
		cm_nt0802: '',
		cm_nt0900: 'Question Number 9 ?',
		cm_nt0901: '',
		cm_nt0902: '',
		cm_nt1000: 'Question Number 10 ?',
		cm_nt1001: '',
		cm_nt1002: '',
		branch: '',
		emp_code: userEmp_code,
	};
	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];
	useEffect(() => {
		if (mtDetails && Array.isArray(mtDetails) && mtDetails.length > 0) {
			const mtDetail = mtDetails[0]; // Assuming mtDetails[0] comtains the desired record

			// Dynamically set formData using the mtDetail data
			setFormData((prevState) => ({
				...prevState,
				cm_nid: mtDetail.cm_nid || '',
				cluster_id: mtDetail.cluster_id || '',
				cm_nt0100: mtDetail.cm_nt0100 || 'Question Number 1 ? ',
				cm_nt0101: mtDetail.cm_nt0101 || '',
				cm_nt0102: mtDetail.cm_nt0102 || '',
				cm_nt0200: mtDetail.cm_nt0200 || 'Question Number 2 ? ',
				cm_nt0201: mtDetail.cm_nt0201 || '',
				cm_nt0202: mtDetail.cm_nt0202 || '',
				cm_nt0300: mtDetail.cm_nt0300 || 'Question Number 3 ? ',
				cm_nt0301: mtDetail.cm_nt0301 || '',
				cm_nt0302: mtDetail.cm_nt0302 || '',
				cm_nt0400: mtDetail.cm_nt0400 || 'Question Number 4 ? ',
				cm_nt0401: mtDetail.cm_nt0401 || '',
				cm_nt0402: mtDetail.cm_nt0402 || '',
				cm_nt0500: mtDetail.cm_nt0500 || 'Question Number 5 ? ',
				cm_nt0501: mtDetail.cm_nt0501 || '',
				cm_nt0502: mtDetail.cm_nt0502 || '',
				cm_nt0600: mtDetail.cm_nt0600 || 'Question Number 6 ? ',
				cm_nt0601: mtDetail.cm_nt0601 || '',
				cm_nt0602: mtDetail.cm_nt0602 || '',
				cm_nt0700: mtDetail.cm_nt0700 || 'Question Number 7 ? ',
				cm_nt0701: mtDetail.cm_nt0701 || '',
				cm_nt0702: mtDetail.cm_nt0702 || '',
				cm_nt0800: mtDetail.cm_nt0800 || 'Question Number 8 ? ',
				cm_nt0801: mtDetail.cm_nt0801 || '',
				cm_nt0802: mtDetail.cm_nt0802 || '',
				cm_nt0900: mtDetail.cm_nt0900 || 'Question Number 9 ? ',
				cm_nt0901: mtDetail.cm_nt0901 || '',
				cm_nt0902: mtDetail.cm_nt0902 || '',
				cm_nt1000: mtDetail.cm_nt1000 || 'Question Number 10? ',
				cm_nt1001: mtDetail.cm_nt1001 || '',
				cm_nt1002: mtDetail.cm_nt1002 || '',
			}));
		} else {
			// If no details to edit, set form to initial state for a new task
			setFormData(initialFormData);
		}
	}, [mtDetails]);

	useEffect(() => {
		const fetchMtData = async () => {
			try {
				// Wait for selectedCluster to be available
				const selectedCluster = await new Promise((resolve) => {
					const interval = setInterval(() => {
						const cluster = sessionStorage.getItem('selectedClusterId');
						if (cluster) {
							clearInterval(interval);
							resolve(cluster);
						}
					}, 100);
				});

				// Prepare the request payload in JSON format
				const requestData = {
					cm_nid: cm_nidSelected,

				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/getCmNightTaskDetailsNew`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		};

		fetchMtData();
	}, [token, cm_nid]);


	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row' style={{ minHeight: '630px' }}>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'></div>
										{showSnackbar && <div className="snackbar">Form Submitted Successfully!</div>}
										<form onSubmit={(e) => handleEditSubmit(e)} className='row '>

											{/* Display all fields from mtDetails */}
											<div className='col-sm-8'>
												{mtDetails && mtDetails.length > 0 ? (
													<>
														{mtDetails.map((task, index) => (
															<React.Fragment key={`${task.id}-${index}`}>
																<div className='row'>
																	<div className='col-12 col-md-12'>
																		<p className='mt-2 pp1'>
																			Dated: {new Date(task.createdDTM).toLocaleDateString('en-US', {
																				day: 'numeric',
																				month: 'short',
																				year: 'numeric'
																			})}
																			&nbsp; By: {task.fname} {task.lname}
																			&nbsp; Cluster: {task.cluster_name}
																		</p>

																	</div>
																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='cm_nt-2  pp1'>
																			<i className="fas fa-users bg-danger  mr-2"></i> Subject Group
																		</p>
																	</div>
																	<div className='col-12 col-md-6 '>
																		<p><i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0100} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className="input-group">
																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0101 && !task.cm_nt0101 ? 'empty-field' : ''
																					}`}
																				name="cm_nt0101"
																				id="cm_nt0101"
																				value={formData.cm_nt0101 || task.cm_nt0101 || ''}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option> {/* Default empty option */}
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>

																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0102'
																				id='cm_nt0102'
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				value={formData.cm_nt0102 || task.cm_nt0102 || ''} // Set the default value here
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 100 characters
																			/>
																		</div>

																	</div>

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0200} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0201 && !task.cm_nt0201 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0201'
																				id='cm_nt0201'
																				value={
																					formData.cm_nt0201 || task.cm_nt0201 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<textarea
																				name='cm_nt0202'
																				id='cm_nt0202'
																				value={
																					formData.cm_nt0202 || task.cm_nt0202 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>
																</div>
																<div key={index} className='row'>
																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='cm_nt-2 pp1'>
																			<i className="fas fa-tools bg-danger  mr-2 "></i>	Subject Group 2
																		</p>
																	</div>
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0300} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0301 && !task.cm_nt0301 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0301'
																				id='cm_nt0301'
																				value={
																					formData.cm_nt0301 || task.cm_nt0301 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0302'
																				id='cm_nt0302'
																				value={
																					formData.cm_nt0302 || task.cm_nt0302 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* //Are all Biomedical equiment working */}
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0500} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0501 && !task.cm_nt0501 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0501'
																				id='cm_nt0501'
																				value={
																					formData.cm_nt0501 || task.cm_nt0501 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0502'
																				id='cm_nt0502'
																				value={
																					formData.cm_nt0502 || task.cm_nt0502 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* Are there Maintainance Observation */}
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0800} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0801 && !task.cm_nt0801 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0801'
																				id='cm_nt0801'
																				value={
																					formData.cm_nt0801 || task.cm_nt0801 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0802'
																				id='cm_nt0802'
																				value={
																					formData.cm_nt0802 || task.cm_nt0802 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																</div>

																<div key={index} className='row'>
																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='cm_nt-2 pp1'>
																			<i className="fas fa-tasks  bg-danger mr-2 "></i>	Subject Group 3
																		</p>
																	</div>

																	{/* is Doc Avaialble or Oncall Doc arranged? */}
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0600} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0601 && !task.cm_nt0601 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0601'
																				id='cm_nt0601'
																				value={
																					formData.cm_nt0601 || task.cm_nt0601 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0602'
																				id='cm_nt0602'
																				value={
																					formData.cm_nt0602 || task.cm_nt0602 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* Previous day pending reports */}

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0700} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0701 && !task.cm_nt0701 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0701'
																				id='cm_nt0701'
																				value={
																					formData.cm_nt0701 || task.cm_nt0701 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0702'
																				id='cm_nt0702'
																				value={
																					formData.cm_nt0702 || task.cm_nt0702 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																	{/* is IT Portal updated with upto date information? */}

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt1000} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt1001 && !task.cm_nt1001 ? 'empty-field' : ''
																					}`}
																				name='cm_nt1001'
																				id='cm_nt1001'
																				value={
																					formData.cm_nt1001 || task.cm_nt1001 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt1002'
																				id='cm_nt1002'
																				value={
																					formData.cm_nt1002 || task.cm_nt1002 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>

																</div>

																<div key={index} className='row'>
																	<div className='col-12 col-md-12'  >
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='cm_nt-2 pp1'>
																			<i className="fas fa-info-circle bg-danger mr-2 ml-2 "></i>	Subject Group 4
																		</p>
																	</div>
																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0400} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0401 && !task.cm_nt0401 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0401'
																				id='cm_nt0401'
																				value={
																					formData.cm_nt0401 || task.cm_nt0401 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>

																	<div className='col-12 col-md-6 '>
																		<p> <i className="fas fa-circle fa-1x mr-2" style={{ color: '#ebd729' }} ></i> {task.cm_nt0900} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<select
																				className={`form-control form-control-sm ${!formData.cm_nt0901 && !task.cm_nt0901 ? 'empty-field' : ''
																					}`}
																				name='cm_nt0901'
																				id='cm_nt0901'
																				value={
																					formData.cm_nt0901 || task.cm_nt0901 || ''
																				}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option>
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>

																		</div>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='cm_nt0902'
																				id='cm_nt0902'
																				value={
																					formData.cm_nt0902 || task.cm_nt0902 || ''
																				}
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																			/>

																		</div>
																	</div>
																	<div className='col-12 col-md-12 mb-2'>
																		<div className='input-group'>
																			<input
																				name='mid'
																				id='mid'
																				value={
																					formData.cm_nid || task.cm_nid || ''
																				}
																				type='hidden'

																			/>
																			{(task.createdDTM === today && role === 'CM') && (
																				<button
																					type='submit'
																					className='btn btn-sm btn-primary'
																				>
																					Submit
																				</button>
																			)}
																		</div>
																	</div>

																</div>
															</React.Fragment>

														))}
													</>
												) : (
													<div style={{ minHeight: '630px' }}>
														<FormSkeleton />
													</div>
												)}
											</div>

										</form>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			 
			<Footer />
		</>
	);
};

export default EditCmNightTask;
