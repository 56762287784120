import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { Link, useNavigate } from 'react-router-dom';
 import FormSkeleton from '../../components/other/formSkeleton';
 
import '../customStyle.css';
 
const AddBmMorningTask = () => {
	const { role } = useAuth(); 
	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	 
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();
	 
	const [mtDetails, setMtDetails] = useState();
	const [midSelected, setMidSelected] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
 

	const initialFormData = {
		mid: '',
		cluster: '',
		cluster_id: '',
		mt0100: 'is All Manpower Available?',
		mt0101: '',
		mt0102: '',
		mt0200: 'is All Manpower in proper dress code?',
		mt0201: '',
		mt0202: '',
		mt0300: 'Generator Check',
		mt0301: '',
		mt0302: '',
		mt0400: 'is Pooja Done',
		mt0401: '',
		mt0402: '',
		mt0500: 'Are all Biomedical equiment working',
		mt0501: '',
		mt0502: '',
		mt0600: 'is Doc Avaialble or Oncall Doc arranged?',
		mt0601: '',
		mt0602: '',
		mt0700: 'Previous day pending reports',
		mt0701: '',
		mt0702: '',
		mt0800: 'Are there Maintainance Observation',
		mt0801: '',
		mt0802: '',
		mt0900: 'is front door glass cleaned well?',
		mt0901: '',
		mt0902: '',
		mt1000: 'is IT Portal updated with upto date information?',
		mt1001: '',
		mt1002: '',
		branch: '',
		emp_code: userEmp_code,
	};
	const [formData, setFormData] = useState(initialFormData);

	useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setBranchlist(result.data);
					} else {
						//toast.error( result.message || 	'An error occurred while fetching the Branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchBranchList();
	}, [token]);

	const today = new Date().toISOString().split('T')[0];

	useEffect(() => {
		const createDefaultTask = async () => {
			const requestData = {
				createdDTM: selectedDate,
				branch_id: selectedBranch,
				...initialFormData,
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/addMorningTask`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestData),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status === true) {					 
					 navigate(`/EditBmMorningTask/${result.data}`);
					}
					 
				}
			} catch (error) {
				 
			}
		};

		if (
			selectedDate === today &&
			selectedBranch &&
			(!mtDetails || mtDetails.length === 0)
		) {
			createDefaultTask();
		}
	}, [selectedBranch, selectedDate, mtDetails, token, initialFormData, today]);

	 
	

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');

		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}
	}, [branchlist]);

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedBranch: selectedBranch,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getMorningTaskDetails`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data); 
					setMtDetails(mtData.data); // Set the received data to state
					setMidSelected(mtData.data[0]?.mid);
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

	 

	 
 
	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				<section className='content'> 
					<div className='container-fluid'> 
						<div className='row'>
							<section className='col-lg-12 connectedSortable'> 
								<div className='card' style={{ minHeight:'630px' }}> 
									<div className='card-body'>
										{!selectedBranch ? (
											<div>No branches are assigned to user</div>
										) : (
											// Your existing content here
											<div>
												{/* Your existing content */}
											</div>
										)}
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default AddBmMorningTask;
