import React, { useState, useEffect } from 'react';
import Header from '../components/common/Header';
import Footer   from '../components/common/Footer';
import Sidebar  from '../components/common/Sidebar';

import './customStyle.css'; 
import 'react-toastify/dist/ReactToastify.css';  

import { useNavigate } from 'react-router-dom';

const Changepassword = () => {
	const [profile, setProfile] = useState(null);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordStrength, setPasswordStrength] = useState('');
	const [error, setError] = useState('');
	const token = sessionStorage.getItem('authToken');
	const navigate = useNavigate();
	useEffect(() => {
		const storedProfile = sessionStorage.getItem('userProfile');
		if (storedProfile) {
			setProfile(JSON.parse(storedProfile));
		}
	}, []);
	const [showSnackbar, setShowSnackbar] = useState(false);
	useEffect(() => {
        if (sessionStorage.getItem('showSnackbar') === 'true') {
            setShowSnackbar(true);
            sessionStorage.removeItem('showSnackbar');
            // Hide snackbar after 3 seconds
            setTimeout(() => setShowSnackbar(false), 2000);
        }
    }, []);
	 
	const checkPasswordStrength = (passwordInput) => {
		let strength = '';
		if (passwordInput.match(/^(?!.*\b(vdc|vijaya)\b)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
			strength = 'Strong';
		} else if (passwordInput.length >= 6) {
			strength = 'Medium';
		} else {
			strength = 'Weak';
		}
		setPasswordStrength(strength);
	};

	const handlePasswordChange = (e) => {
		const passwordInput = e.target.value;
		setPassword(passwordInput);
		checkPasswordStrength(passwordInput);  
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setError('Passwords do not match');
			return;
		}
		try {
			const formData = new FormData();
			formData.append('bmid', profile.emp_code);
			formData.append('pass', password);
			formData.append('conf_pass', confirmPassword);
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/changeMyPass`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`, // Ensure the token is included in the header
				},
				body: formData,
			});
			const result = await response.json();
			
			if (response.ok) {			 
				setPassword(null);
				setConfirmPassword(null);
				sessionStorage.setItem('showSnackbar', 'true');			
				window.location.reload() 
			} else {
				setError(result.message || 'An error occurred while updating password');
				console.log('Error:', result.message);
				//
			}
		} catch (error) {
			//navigate('/login');
			console.log('Error:', error);
		}
	};

	return (
		<>
			<Header /> <Sidebar />
			{showSnackbar && <div className="snackbar">Change Passord Submitted Successfully!</div>}	 
			<div className='content-wrapper' >
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h3 className='m-0'>Change Password Details</h3>
							</div>
						</div>
					</div>
				</div>

				<section className='content'>
					<div className='container-fluid'style={{minHeight:'600px'}} >
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'>
											<div className='col-sm-3'>
												<form onSubmit={handleSubmit}>
													<div className='form-group'>
														<label>New Password</label>
														<input
															type='password'
															id='psw'
															name='pass'
															value={password}
															pattern='^(?!.*\b(vdc|vijaya)\b)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$'
															title='Must contain at least one number and one uppercase and lowercase letter, at least 8 or more characters, and should not contain the words "vdc" or "vijaya"'
															required
															className='form-control form-control-sm'
															onChange={handlePasswordChange} // Now using onChange
														/>
														<div id='password-strength-status'>
															{passwordStrength && (
																<p>Password Strength: {passwordStrength}</p>
															)}
														</div>
													</div>
													<div className='form-group'>
														<label>Confirm Password</label>
														<input
															type='password'
															className='form-control form-control-sm'
															required
															name='conf_pass'
															id='cpass'
															value={confirmPassword}
															onChange={(e) =>
																setConfirmPassword(e.target.value)
															}
														/>
														{profile && (
															<input
																type='hidden'
																name='bmid'
																value={profile.user_id}
															/>
														)}
													</div>
													{error && <p style={{ color: 'red' }}>{error}</p>}
													<div className='form-group'>
														<input
															type='submit'
															className='btn btn-sm btn-primary'
															value='Update'
														/>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Changepassword;
