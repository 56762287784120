import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
const BmNightTask = () => {
	const { role } = useAuth();
	const { nid } = useParams(); // Extract the mid parameter from the URL
	const [nidSelected, setNidSelected] = useState(nid); // Set the nidSelected state with the extracted mid

	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [showSnackbar, setShowSnackbar] = useState(false);
	useEffect(() => {
		if (sessionStorage.getItem('showSnackbar') === 'true') {
			setShowSnackbar(true);
			sessionStorage.removeItem('showSnackbar');
			// Hide snackbar after 3 seconds
			setTimeout(() => setShowSnackbar(false), 2000);
		}
	}, []);

	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();

	const [mtDetails, setMtDetails] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const [rows, setRows] = useState([
		{ doctor_name: '', bmid: '', doctor_count: '' }, // Example row structure
	]);

	const [rows1, setRows1] = useState([
		{ doctor_name: '', bmid: '', doctor_count: '' }, // Example row structure
	]);

	const [rows2, setRows2] = useState([
		{ doctor_name: '', bmid: '', doctor_count: '' }, // Example row structure
	]);

	const [rows3, setRows3] = useState([
		{ doctor_name: '', bmid: '', doctor_count: '' }, // Example row structure
	]);

	const [rows4, setRows4] = useState([
		{ doctor_name: '', bmid: '', doctor_count: '' }, // Example row structure
	]);

	const [rows5, setRows5] = useState([
		{ doctor_name: '', bmid: '', doctor_count: '' }, // Example row structure
	]);

	const handleAddRow = (e) => {
		e.preventDefault();
		setRows((rows) => [...rows, { doctor_name: '', bmid: '', doctor_count: '' }]);
	};

	const handleRemoveRow = (index) => {
		setRows((rows) => rows.filter((_, i) => i !== index));
	};

	// Step 3: Add a new row
	const handleAddRowXray = (e) => {
		e.preventDefault();
		setRows1([...rows1, { doctor_name: '', bmid: '', doctor_count: '' }]);
	};

	// Step 4: Remove a row
	const handleRemoveRowXray = (index) => {
		const updatedRows = rows1.filter((_, i) => i !== index); // Remove the row at the specific index
		setRows1(updatedRows);
	};

	// Step 3: Add a new row
	const handleAddRowCt = (e) => {
		e.preventDefault();
		setRows2([...rows2, { doctor_name: '', bmid: '', doctor_count: '' }]);
	};

	// Step 4: Remove a row
	const handleRemoveRowCt = (index) => {
		const updatedRows = rows2.filter((_, i) => i !== index); // Remove the row at the specific index
		setRows2(updatedRows);
	};

	// Step 3: Add a new row
	const handleAddRowUSG = (e) => {
		e.preventDefault();
		setRows3([...rows3, { doctor_name: '', bmid: '', doctor_count: '' }]);
	};

	// Step 4: Remove a row
	const handleRemoveRowUSG = (index) => {
		const updatedRows = rows3.filter((_, i) => i !== index); // Remove the row at the specific index
		setRows3(updatedRows);
	};


	// Step 3: Add a new row
	const handleAddRowCardioTMT = (e) => {
		e.preventDefault();
		setRows4([...rows4, { doctor_name: '', bmid: '', doctor_count: '' }]);
	};

	// Step 4: Remove a row
	const handleRemoveRowCardioTMT = (index) => {
		const updatedRows = rows4.filter((_, i) => i !== index); // Remove the row at the specific index
		setRows4(updatedRows);
	};


	// Step 3: Add a new row
	const handleAddRowCardioECG = (e) => {
		e.preventDefault();
		setRows5([...rows5, { doctor_name: '', bmid: '', doctor_count: '' }]);
	};

	// Step 4: Remove a row
	const handleRemoveRowCardioECG = (index) => {
		const updatedRows = rows5.filter((_, i) => i !== index); // Remove the row at the specific index
		setRows5(updatedRows);
	};




	// const handleInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

	// 	setFormData((prevFormData) => ({
	// 		...prevFormData,
	// 		[name]: value, // Update the specific field
	// 	}));

	// };

	const handleInputChange = (e) => {
		if (!e || !e.target) {
			console.error('Event or target is undefined'); // Debugging
			return;
		}

		const { name, value } = e.target; // Safely destructure
		console.log(`Input changed - Name: ${name}, Value: ${value}`);

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};






	const handleInputChange1 = (rowType, index, event) => {
		if (!event || !event.target) {
			console.error('Event or target is undefined'); // Debugging
			return;
		}

		const { name, value } = event.target; // Safely destructure
		// const updatedRows = [...rows];
		// updatedRows[index][name] = value; // Update the specific row's field
		// setRows(updatedRows);

		if (rowType === 'rows1') {
			const updatedRows1 = [...rows1];
			updatedRows1[index][name] = value;
			setRows1(updatedRows1);
		} else if (rowType === 'rows2') {
			const updatedRows2 = [...rows2];
			updatedRows2[index][name] = value;
			setRows2(updatedRows2);
		} else if (rowType === 'rows3') {
			const updatedRows3 = [...rows3];
			updatedRows3[index][name] = value;
			setRows3(updatedRows3);
		} else if (rowType === 'rows4') {
			const updatedRows4 = [...rows4];
			updatedRows4[index][name] = value;
			setRows4(updatedRows4);
		} else if (rowType === 'rows5') {
			const updatedRows5 = [...rows5];
			updatedRows5[index][name] = value;
			setRows5(updatedRows5);
		} else {
			const updatedRows = [...rows];
			updatedRows[index][name] = value; // Update the specific row's field
			setRows(updatedRows);
		}

	};




	const [formData, setFormData] = useState({
		nid: '',
		cluster: '',
		cluster_id: '',
		nt0100: 'Branch Closing time as per last update form branch',
		nt0101: '',
		nt0102: '',
		nt0200: 'Day-End Report Tally - Total Revenue',
		nt0201: '',
		nt0202: '',
		nt0300: 'Day-End Report Tally - Cash',
		nt0301: '',
		nt0302: '',
		nt0400: 'Day-End Report Tally - Paytm',
		nt0401: '',
		nt0402: '',
		nt0500: 'Day-End Report Tally - Card',
		nt0501: '',
		nt0502: '',
		nt0600: 'Total USG Done',
		nt0601: '',
		nt0602: '',
		nt0700: 'Total CT Done',
		nt0701: '',
		nt0702: '',
		nt0800: 'Total MRI Done',
		nt0801: '',
		nt0802: '',
		nt0900: 'Total X-Ray Done',
		nt0901: '',
		nt0902: '',
		nt1000: 'PDR Cases',
		nt1001: '',
		nt1002: '',
		nt1100: 'PDR Amount',
		nt1101: '',
		nt1200: 'Total Refunds (Count)',
		nt1201: '',
		nt1300: 'Total Refunds (Amount)',
		nt1301: '',
		nt1400: 'is Petty cash handling clear?',
		nt1401: '',
		nt1500: 'Logists/Sample Pickup: is sample collection done a per schedule?',
		nt1501: '',
		nt1600: 'is all the stock avaiability is in line with branch business',
		nt1601: '',
		nt1700: 'is All V-Mitr Notifications cleared?',
		nt1701: '',
		nt1800: 'Google ratings collected in the day',
		nt1801: '',
		nt1900: 'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
		nt1901: '',
		nt2000: 'Doctor Productivity',
		nt2001: '',
		nt2100: 'Doctor 1 BMID',
		nt2101: '',
		nt2200: 'Doctor 1 Name',
		nt2201: '',
		nt2300: 'USG Done -Doc 1',
		nt2301: '',
		nt2400: 'CT Done -Doc 1',
		nt2401: '',
		nt2500: 'MRI Done -Doc 1',
		nt2501: '',
		nt2600: 'X-Ray Done -Doc 1',
		nt2601: '',
		nt2700: 'Doctor 2 BMID',
		nt2701: '',
		nt2800: 'Doctor 2 Name',
		nt2801: '',
		nt2900: 'USG Done -Doc 2',
		nt2901: '',
		nt3000: 'CT Done -Doc 2',
		nt3001: '',
		nt3100: 'MRI Done -Doc 2 ',
		nt3101: '',
		nt3200: 'X-Ray Done -Doc 2',
		nt3201: '',
		nt3300: 'Doctor 3 BMID',
		nt3301: '',
		nt3400: 'Doctor 3 Name',
		nt3401: '',
		nt3500: 'USG Done -Doc 3',
		nt3501: '',
		nt3600: 'CT Done -Doc 3',
		nt3601: '',
		nt3700: 'MRI Done -Doc 3',
		nt3701: '',
		nt3800: 'X-Ray Done -Doc 3',
		nt3801: '',
		nt3900: 'Cardiologist Name',
		nt3901: '',
		nt4000: 'TMT Done',
		nt4001: '',
		nt4100: 'ECG Done',
		nt4101: '',
		branch: '',
		doctor_name: '',
		emp_code: userEmp_code,
		rows: [''],
		rows2: [''],
		rows3: [''],
		rows4: [''],
		rows5: [''],
	});


	useEffect(() => {
		if (nid) {
			console.log('nid:', nid); // Log the extracted nid
			setNidSelected(nid); // Set the nidSelected state
		} else {
			console.error('nid is undefined or invalid');
			//////toast.error('Invalid task ID. Please check the URL.');
			navigate('/error'); // Navigate to an error page or handle the error appropriately
		}
	}, [nid, navigate]);

	useEffect(() => {
		const fetchMri = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getMriData`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ nid: nidSelected }),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						// Transform API data to match rows structure
						const transformedData = result.data.map((item) => ({
							doctor_name: item.doctor_name || '',
							bmid: item.bmid || '',
							doctor_count: item.doctor_count || '',
							id: item.id || '', // Add unique ID for each row
						}));
						setRows(transformedData);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
				}
			} catch (error) {
				console.error('Fetch error:', error);
			} finally {
				setLoading(false);
			}
		};


		fetchMri();
	}, [token, nidSelected]);

	useEffect(() => {
		const fetchXray = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getXrayData`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ nid: nidSelected }),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						// Transform API data to match rows structure
						const transformedData = result.data.map((item) => ({
							doctor_name: item.doctor_name || '',
							bmid: item.bmid || '',
							doctor_count: item.doctor_count || '',
							id: item.id || '', // Add unique ID for each row
						}));
						setRows1(transformedData);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
				}
			} catch (error) {
				console.error('Fetch error:', error);
			} finally {
				setLoading(false);
			}
		};


		fetchXray();
	}, [token, nidSelected]);

	useEffect(() => {
		const fetchCt = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCtData`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ nid: nidSelected }),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						// Transform API data to match rows structure
						const transformedData = result.data.map((item) => ({
							doctor_name: item.doctor_name || '',
							bmid: item.bmid || '',
							doctor_count: item.doctor_count || '',
							id: item.id || '', // Add unique ID for each row
						}));
						setRows2(transformedData);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
				}
			} catch (error) {
				console.error('Fetch error:', error);
			} finally {
				setLoading(false);
			}
		};


		fetchCt();
	}, [token, nidSelected]);

	useEffect(() => {
		const fetchUsg = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUsgData`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ nid: nidSelected }),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						// Transform API data to match rows structure
						const transformedData = result.data.map((item) => ({
							doctor_name: item.doctor_name || '',
							bmid: item.bmid || '',
							doctor_count: item.doctor_count || '',
							id: item.id || '', // Add unique ID for each row
						}));
						setRows3(transformedData);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
				}
			} catch (error) {
				console.error('Fetch error:', error);
			} finally {
				setLoading(false);
			}
		};


		fetchUsg();
	}, [token, nidSelected]);

	useEffect(() => {
		const fetchCardioTmt = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCardioTmtData`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ nid: nidSelected }),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						// Transform API data to match rows structure
						const transformedData = result.data.map((item) => ({
							doctor_name: item.doctor_name || '',
							bmid: item.bmid || '',
							doctor_count: item.doctor_count || '',
							id: item.id || '', // Add unique ID for each row
						}));
						setRows4(transformedData);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
				}
			} catch (error) {
				console.error('Fetch error:', error);
			} finally {
				setLoading(false);
			}
		};


		fetchCardioTmt();
	}, [token, nidSelected]);

	useEffect(() => {
		const fetchCardioEcg = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCardioEcgData`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ nid: nidSelected }),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						// Transform API data to match rows structure
						const transformedData = result.data.map((item) => ({
							doctor_name: item.doctor_name || '',
							bmid: item.bmid || '',
							doctor_count: item.doctor_count || '',
							id: item.id || '', // Add unique ID for each row
						}));
						setRows5(transformedData);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
				}
			} catch (error) {
				console.error('Fetch error:', error);
			} finally {
				setLoading(false);
			}
		};


		fetchCardioEcg();
	}, [token, nidSelected]);


	useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setBranchlist(result.data);
					} else {
						//////toast.error( result.message || 	'An error occurred while fetching the Branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//////toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchBranchList();
	}, [token]);

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');

		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const todayDate = new Date().toISOString().split('T')[0];
			setSelectedDate(todayDate);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}
	}, [branchlist]);

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format


				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getNightTaskDetailsNew`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify({ nid: nidSelected }), // Stringify the JSON payload
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
					const initialFormData = mtData.data.reduce((acc, task) => {
						return { ...acc, ...task };
					}, {});
					setFormData(initialFormData);
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, nidSelected]); // Dependencies to trigger the effect



	const [showModal, setShowModal] = useState(false);
	const handleCloseModal = () => { setShowModal(false); };
	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const fileType = file.type;
			const fileSize = file.size / 1024 / 1024; // Convert size to MB

			if (!['image/jpeg', 'application/pdf'].includes(fileType)) {
				alert('Only .jpg or .pdf files are allowed.');
				e.target.value = ''; // Clear the input
				return;
			}

			if (fileSize > 5) {
				alert('File size must be less than 5MB.');
				e.target.value = ''; // Clear the input
				return;
			}

			// If file is valid, set it to state or perform further actions
			// setFile(file); // Example state update
		}
	};

	const handleFileSubmit = async (e) => {
		e.preventDefault();
		const fileInput = e.target.elements.file;
		const file = fileInput.files[0];

		if (!file) {
			alert('Please select a file to upload.');
			return;
		}

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('nid', nidSelected);

			const response = await fetch(`${process.env.REACT_APP_API_URL}api/uploadFile`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status}`);
			}

			const result = await response.json();
			if (result.status) {				 
				setShowModal(false); // Close the modal
				sessionStorage.setItem('showSnackbar', 'true');
				window.location.reload();
			} else {
				alert('Failed to upload file.');
			}
		} catch (error) {
			console.error('Error uploading file:', error);
			alert('An error occurred while uploading the file.');
		}
	};

	const [fileslist, setFileslist] = useState([]);
	useEffect(() => {
		if (token && nidSelected) {
		  const fetchFiles = async () => {
			setLoading(true); // Set loading state
			const formData = new FormData();
			formData.append('nid', nidSelected);
	  
			try {
			  const response = await fetch(`${process.env.REACT_APP_API_URL}api/getFiles`, {
				method: 'POST',
				headers: {
				  Authorization: `Bearer ${token}`,
				},
				body: formData,
			  });
	  
			  if (response.ok) {
				const result = await response.json();
				console.log('Files List:', result);
	  
				// Check for the new `status` key
				if (result?.status === 'success') {
				  setFileslist(result.data || []); // Use an empty array as fallback for `data`
				} else {
				  console.error(
					'Failed to fetch files:',
					result?.message || 'Unexpected response structure'
				  );
				}
			  } else {
				const errorBody = await response.text();
				console.error('API Error Response:', errorBody);
			  }
			} catch (error) {
			  console.error('Fetch error:', error.message || error);
			} finally {
			  setLoading(false); // Ensure loading state is reset
			}
		  };
	  
		  fetchFiles();
		}
	  }, [token, nidSelected]);
	  

	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			// Prepare the request payload in JSON format with updated mtDetails
			const requestData = { ...formData, selectedDate, selectedBranch, rows: rows, rows1: rows1, rows2: rows2, rows3: rows3, rows4: rows4, rows5: rows5, nid: nidSelected };

			const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/saveNightTaskDetails`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`, // Ensure correct format
				},
				body: JSON.stringify(requestData), // Stringify the updated data to send
			});

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {
				console.log('Data submitted successfully!');
				sessionStorage.setItem('showSnackbar', 'true');
				window.location.reload()
			} else {
				console.error('Failed to submit data');

			}
		} catch (error) {
			console.error('Error submitting data:', error);

		}
	};

	const today = new Date() ;
	const nowDay = new Date();
	nowDay.setDate(nowDay.getDate() - 1);
	// const previousDay = nowDay.toISOString().split('T')[0];
	// const todayDate = today.toISOString().split('T')[0];
	const yesterday = new Date(today);
	let yesterdayDate = null;
	if (today.getDay() === 1) { // Check if today is Monday   
	  yesterday.setDate(today.getDate() - 2); // Set to Saturday
	} else {    
	  yesterday.setDate(today.getDate() - 1);
	}
	yesterdayDate = yesterday.toISOString().split('T')[0];

	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>

				{/* /.content-header */}
				{/* Main content */}


				<section className='content'>
					<div className='container-fluid'>
						<div className='row' style={{ minHeight: '630px' }}>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										{showSnackbar && <div className="snackbar">Form Submitted Successfully!</div>}

										
											{/* Display all fields from mtDetails */}
											<div className='col-sm-10'>
												{mtDetails && mtDetails.length > 0 ? (
													<>
														{mtDetails.map((task, index) => (
															<React.Fragment key={`${task.id}-${index}`}>
																<div className='row'>
																	<div className='col-4 col-md-4'>
																		<p className='mt-2 pp1'>
																			<b>Night Task : </b>{new Date(task.createdDTM).toLocaleDateString('en-US', {
																				day: 'numeric',
																				month: 'short',
																				year: 'numeric',
																				hour: 'numeric',
																				minute: 'numeric',
																			})}

																		</p>

																	</div>
																	<div className='col-3 col-md-3'>
																		<p className='mt-2 pp1'>
																			<b>By:</b> {task.fname + ' ' + task.lname} &nbsp;
																		</p>
																	</div>
																	<div className='col-4 col-md-3'>
																		<p className='mt-2 pp1'>
																			<b>	Branch :</b> {task.branch_name}
																		</p>
																	</div>

																	{role === 'BM' && (
																		<div className='col-2 col-md-2 mt-2 mb-2'>
																			<table>
																				<tr>
																					<td>	
																						<button style={{ minWidth: '10em' }}
																						className='btn btn-sm btn-info '
																						onClick={() => navigate(`/bmcombogrid`)}>
																						BM Tasks
																					</button></td>
																					<td>
																						{(formData.createdDTM >= yesterdayDate && role === 'BM') && (
																						<button style={{ minWidth: '10em' }}
																						className='btn btn-sm btn-primary'
																						onClick={() => setShowModal(true)}>
																						 Upload Files
																						</button>
																						)}
																					</td>
																				</tr>
																			</table>
																		</div>)}

																	{role === 'CM' && (
																		<div className='col-2 col-md-2 mt-2 mb-2'>
																			<button style={{ minWidth: '10em' }}
																				className='btn btn-sm btn-info '
																				onClick={() => navigate(`/bmccombogrid`)}>
																				BM Tasks
																			</button>
																		</div>)}

																		{(role === 'ZONAL_MANAGER' || role === 'AVP') && (
																	<div className='col-2 col-md-2 mt-2 mb-2'>
																		<button style={{minWidth : '10em' }}
																		className='btn btn-sm btn-info '
																			onClick={() => navigate(`/Bm_z_CombinedGrid`)}>
																			BM Tasks
																		</button>
																	</div> )}
																	
																</div>
																<form onSubmit={(e) => handleEditSubmit(e)} >
																<div className='row'>

																	<div className='col-12 col-md-12'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='nt-2  pp1'>
																			<i className="fas fa-chart-line bg-danger mr-2"></i>Tally Cash Revenue 	</p>
																	</div>
																	<div className='col-12 col-md-5 '>
																		<p>
																		{task.nt0201 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			  {task.nt0200} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className="input-group">
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				min='0'
																				className={`form-control form-control-sm ${!formData.nt0201 && !task.nt0201 ? 'empty-field' : ''
																					}`}
																				name="nt0201"
																				id="nt0201"
																				value={formData.nt0201 || task.nt0201 || '0'}
																				onChange={handleInputChange}
																				max='1000000'
																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt0301 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)} {task.nt0300} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0301 && !task.nt0301 ? 'empty-field' : ''
																					}`}
																				name='nt0301'
																				id='nt0301'
																				value={
																					formData.nt0301 || task.nt0301 || '0'
																				}
																				min='0'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt0401 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt0400} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0401 && !task.nt0401 ? 'empty-field' : ''
																					}`}
																				min='0'
																				max='1000000'
																				name='nt0401'
																				id='nt0401'
																				value={
																					formData.nt0401 || task.nt0401 || '0'
																				}

																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt0501 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt0500} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0501 && !task.nt0501 ? 'empty-field' : ''
																					}`}
																				name='nt0501'
																				id='nt0501'
																				value={
																					formData.nt0501 || task.nt0501 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt1101 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1100} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1101 && !task.nt1101 ? 'empty-field' : ''
																					}`}
																				name='nt1101'
																				id='nt1101'
																				value={
																					formData.nt1101 || task.nt1101 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt1201 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1200} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1201 && !task.nt1201 ? 'empty-field' : ''
																					}`}
																				name='nt1201'
																				id='nt1201'
																				value={
																					formData.nt1201 || task.nt1201 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>


																	<div className='col-12 col-md-5'>
																		<p> 
																		{task.nt1301 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1300} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1301 && !task.nt1301 ? 'empty-field' : ''
																					}`}
																				name='nt1301'
																				id='nt1301'
																				value={
																					formData.nt1301 || task.nt1301 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt1401 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1400} : </p>
																	</div>
																	<div className='col-12 col-md-2 mb-2'>
																		<div className="input-group">
																			<select disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1401 && !task.nt1401 ? 'empty-field' : ''
																					}`}
																				name="nt1401"
																				id="nt1401"
																				value={formData.nt1401 || task.nt1401 || ''}
																				onChange={handleInputChange}
																			>
																				<option value="">Select</option> {/* Default empty option */}
																				<option value="Yes">Yes</option>
																				<option value="No">No</option>
																			</select>
																		</div>

																	</div>
																	<div className='col-12 col-md-4 mb-2'>
																		<div className='input-group'>

																			<textarea
																				name='nt0102'
																				id='nt0102'
																				onChange={handleInputChange}
																				className="form-control form-control-sm"
																				placeholder="Enter Description"
																				value={formData.nt0102 || task.nt0102 || ''} // Set the default value here
																				rows="1" // Adjust the number of rows to fit the field
																				maxLength="200"
																			/>
																		</div>

																	</div>


																	<div className='col-12 col-md-5'>
																		<p> 
																		{task.nt1901 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1900} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1901 && !task.nt1901 ? 'empty-field' : ''
																					}`}
																				name='nt1901'
																				id='nt1901'
																				value={
																					formData.nt1901 || task.nt1901 || '0'
																				}
																				min='0'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-12 mt-2'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='nt-2  pp1'>
																			<i className="fas fa-chart-line bg-danger mr-2"></i>Operation Productivity 	</p>
																	</div>


																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt0601 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt0600} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0601 && !task.nt0601 ? 'empty-field' : ''
																					}`}
																				name='nt0601'
																				id='nt0601'
																				value={
																					formData.nt0601 || task.nt0601 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>


																	<div className='col-12 col-md-5'>
																		<p> 
																		{task.nt0701 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt0700} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0701 && !task.nt0701 ? 'empty-field' : ''
																					}`}
																				name='nt0701'
																				id='nt0701'
																				value={
																					formData.nt0701 || task.nt0701 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>


																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt0801 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt0800} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0801 && !task.nt0801 ? 'empty-field' : ''
																					}`}
																				name='nt0801'
																				id='nt0801'
																				value={
																					formData.nt0801 || task.nt0801 || '0'
																				}
																				min='0'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt0901 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt0900} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt0901 && !task.nt0901 ? 'empty-field' : ''
																					}`}
																				name='nt0901'
																				id='nt0901'
																				value={
																					formData.nt0901 || task.nt0901 || '0'
																				}
																				min='0'
																				max='1000000'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt1001 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1000} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1001 && !task.nt1001 ? 'empty-field' : ''
																					}`}
																				name='nt1001'
																				id='nt1001'
																				value={
																					formData.nt1001 || task.nt1001 || '0'
																				}
																				min='0'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-12 mt-2'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='nt-2  pp1'>
																			<i className="fas fa-chart-line bg-danger mr-2"></i>General	</p>
																	</div>



																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt1801 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1800} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1801 && !task.nt1801 ? 'empty-field' : ''
																					}`}
																				name='nt1801'
																				id='nt1801'
																				value={
																					formData.nt1801 || task.nt1801 || '0'
																				}
																				min='0'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>


																	<div className='col-12 col-md-5 '>
																		<p> 
																		{task.nt1701 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)}
																			 {task.nt1700} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} type="number" style={{ maxWidth: '8em' }}
																				className={`form-control form-control-sm ${!formData.nt1701 && !task.nt1701 ? 'empty-field' : ''
																					}`}
																				name='nt1701'
																				id='nt1701'
																				value={
																					formData.nt1701 || task.nt1701 || '0'
																				}
																				min='0'
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>


																	<div className='col-12 col-md-5 '>
																		<p> {task.nt0101 === '' ? (
																				<i
																					className="fas fa-circle fa-1x mr-2 fade-in-out"
																					style={{ color: '#8eaef1'  }}
																				></i>
																			) : (
																				<i
																					className="fas fa-check fa-1x mr-2"
																					style={{ color: '#0081fa' }}
																				></i>
																			)} {task.nt0100} : </p>
																	</div>
																	<div className='col-12 col-md-3 mb-2'>
																		<div className='input-group'>
																			<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'} style={{ maxWidth: '8em' }}
																				type="time"
																				className={`form-control form-control-sm ${!formData.nt0101 && !task.nt0101 ? 'empty-field' : ''
																					}`}
																				name='nt0101'
																				id='nt0101'
																				value={
																					formData.nt0101 || task.nt0101 || ''
																				}
																				onChange={handleInputChange}

																			/>
																		</div>
																	</div>

																	<div className='col-12 col-md-12 mt-2'>
																		<p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='nt-2  pp1'>
																			<i className="fas fa-chart-line bg-danger mr-2"></i>Doctors Productivity	</p>
																	</div>
																	<div className='col-12 col-md-6  mb-2'>
																		<i className="fas fa-circle fa-1x mr-2" style={{ color: '#8eaef1' }} ></i> <b>MRI</b>
																		{ role === 'BM' && (
																		<button className="btn btn-sm btn-warning  m-2 " onClick={handleAddRow}>
																			+
																		</button>
																		)}

																	</div>
																	{rows.map((row, index) => (
																		<div className="row mb-2" key={index}>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="text"
																						className="form-control form-control-sm"
																						name="doctor_name"
																						value={row.doctor_name}
																						onChange={(e) => handleInputChange1('rows', index, e)}
																						placeholder="Doctor Name"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="bmid"
																						value={row.bmid} min='0'
																						onChange={(e) => handleInputChange1('rows', index, e)}
																						placeholder="DOCTOR BMID"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-4 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="doctor_count"
																						value={row.doctor_count} min='0'
																						onChange={(e) => handleInputChange1('rows', index, e)}
																						placeholder="MRI COUNT"
																					/>
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="hidden"
																						className="form-control form-control-sm"
																						name="id"
																						value={row.id}
																						onChange={(e) => handleInputChange1('rows', index, e)}

																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-2 mb-2">
																			{ (role === 'BM' && rows.length > 1 ) && (
																				<button
																					className="btn btn-danger btn-sm"
																					onClick={() => handleRemoveRow(index)}
																					disabled={rows.length === 1}
																				>
																					<i className="fas fa-trash"></i>
																				</button>
																			)}
																			</div>
																		</div>
																	))}






																	<div className='col-12 col-md-6  mb-2'>
																		<i className="fas fa-circle fa-1x mr-2" style={{ color: '#8eaef1' }} ></i> <b>XRAY</b>
																		{ role === 'BM' && (
																		<button className="btn btn-sm btn-warning  m-2 " onClick={handleAddRowXray}>
																			+
																		</button>
																		)}

																	</div>
																	{rows1.map((row, index) => (
																		<div className="row mb-2" key={index}>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="text"
																						className="form-control form-control-sm"
																						name="doctor_name"
																						value={row.doctor_name}
																						onChange={(e) => handleInputChange1('rows1', index, e)}
																						placeholder="Doctor Name"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="bmid"
																						value={row.bmid} min='0'
																						onChange={(e) => handleInputChange1('rows1', index, e)}
																						placeholder="DOCTOR BMID"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-4 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="doctor_count"
																						value={row.doctor_count} min='0'
																						onChange={(e) => handleInputChange1('rows1', index, e)}
																						placeholder="XRAY COUNT"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-2 mb-2">
																			{ (role === 'BM' && rows1.length > 1) && (
																				<button
																					className="btn btn-danger btn-sm"
																					onClick={() => handleRemoveRowXray(index)}
																					disabled={rows1.length === 1} // Disable removing if only one row exists
																				>
																					<i className="fas fa-trash"></i>
																				</button>
																			)}
																			</div>
																		</div>
																	))}





																	<div className='col-12 col-md-6  mb-2'>
																		<i className="fas fa-circle fa-1x mr-2" style={{ color: '#8eaef1' }} ></i> <b>CT</b>
																		{ role === 'BM' && (
																		<button className="btn btn-sm btn-warning  m-2 " onClick={handleAddRowCt}>
																			+
																		</button> )}
																	</div>
																	{rows2.map((row, index) => (
																		<div className="row mb-2" key={index}>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="text"
																						className="form-control form-control-sm"
																						name="doctor_name"
																						value={row.doctor_name}
																						onChange={(e) => handleInputChange1('rows2', index, e)}
																						placeholder="Doctor Name"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="bmid"
																						value={row.bmid} min='0'
																						onChange={(e) => handleInputChange1('rows2', index, e)}
																						placeholder="DOCTOR BMID"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-4 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="doctor_count"
																						value={row.doctor_count} min='0'
																						onChange={(e) => handleInputChange1('rows2', index, e)}

																						placeholder="CT COUNT"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-2 mb-2">
																			{ (role === 'BM'  && rows2.length > 1) && (
																				<button
																					className="btn btn-danger btn-sm"
																					onClick={() => handleRemoveRowCt(index)}
																					disabled={rows2.length === 1} // Disable removing if only one row exists
																				>
																					<i className="fas fa-trash"></i>
																				</button>
																			)}
																			</div>
																		</div>
																	))}




																	<div className='col-12 col-md-6  mb-2'>
																		<i className="fas fa-circle fa-1x mr-2" style={{ color: '#8eaef1' }} ></i> <b>USG</b>
																		{ role === 'BM' && (
																		<button className="btn btn-sm btn-warning  m-2 " onClick={handleAddRowUSG}>
																			+
																		</button> )}
																	</div>
																	{rows3.map((row, index) => (
																		<div className="row mb-2" key={index}>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="text"
																						className="form-control form-control-sm"
																						name="doctor_name"
																						value={row.doctor_name}
																						onChange={(e) => handleInputChange1('rows3', index, e)}
																						placeholder="Doctor Name"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="bmid"
																						value={row.bmid} min='0'
																						onChange={(e) => handleInputChange1('rows3', index, e)}
																						placeholder="DOCTOR BMID"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-4 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="doctor_count"
																						value={row.doctor_count} min='0'
																						onChange={(e) => handleInputChange1('rows3', index, e)}
																						placeholder="USG COUNT"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-2 mb-2">
																			{( role === 'BM'&& rows3.length > 1 ) && (
																				<button
																					className="btn btn-danger btn-sm"
																					onClick={() => handleRemoveRowUSG(index)}
																					disabled={rows3.length === 1} // Disable removing if only one row exists
																				>
																					<i className="fas fa-trash"></i>
																				</button>
																			)}
																			</div>
																		</div>
																	))}



																	<div className='col-12 col-md-6  mb-2 '>

																		<i className="fas fa-circle fa-1x mr-2" style={{ color: '#8eaef1' }} ></i> <b>CardioTMT</b>
																		{ role === 'BM' && (
																		<button className="btn btn-sm btn-warning  m-2 " onClick={handleAddRowCardioTMT}>
																			+
																		</button> )}


																	</div>
																	{rows4.map((row, index) => (
																		<div className="row mb-2" key={index}>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="text"
																						className="form-control form-control-sm"
																						name="doctor_name"
																						value={row.doctor_name}
																						onChange={(e) => handleInputChange1('rows4', index, e)}
																						placeholder="Doctor Name"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="bmid"
																						value={row.bmid} min='0'
																						onChange={(e) => handleInputChange1('rows4', index, e)}
																						placeholder="DOCTOR BMID"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-4 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="doctor_count"
																						value={row.doctor_count} min='0'
																						onChange={(e) => handleInputChange1('rows4', index, e)}
																						placeholder="CardioTMT COUNT"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-2 mb-2">
																			{ (role === 'BM' && rows4.length > 1 )&& (
																				<button
																					className="btn btn-danger btn-sm"
																					onClick={() => handleRemoveRowCardioTMT(index)}
																					disabled={rows4.length === 1} // Disable removing if only one row exists
																				>
																					<i className="fas fa-trash"></i>
																				</button>
																			)}
																			</div>
																		</div>
																	))}

																	<div className='col-12 col-md-6 mb-2 '>
																		<i className="fas fa-circle fa-1x mb-2 mr-2" style={{ color: '#8eaef1' }} ></i> <b>CardioECG</b>
																		{ role === 'BM' && (
																		<button className="btn btn-sm btn-warning  m-2 " onClick={handleAddRowCardioECG}>
																			+
																		</button> )}
																	</div>

																	{rows5.map((row, index) => (
																		<div className="row mb-2" key={index}>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="text"
																						className="form-control form-control-sm"
																						name="doctor_name"
																						value={row.doctor_name}
																						onChange={(e) => handleInputChange1('rows5', index, e)}
																						placeholder="Doctor Name"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-3 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="bmid"
																						value={row.bmid} min='0'
																						onChange={(e) => handleInputChange1('rows5', index, e)}
																						placeholder="DOCTOR BMID"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-4 mb-2">
																				<div className="input-group">
																					<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																						type="number"
																						className="form-control form-control-sm"
																						name="doctor_count"
																						value={row.doctor_count} min='0'
																						onChange={(e) => handleInputChange1('rows5', index, e)}
																						placeholder="CardioECG COUNT"
																					/>
																				</div>
																			</div>
																			<div className="col-12 col-md-2 mb-2">
																			{ (role === 'BM' && rows5.length > 1) && (	
																				<button
																					className="btn btn-danger btn-sm"
																					onClick={() => handleRemoveRowCardioECG(index)}
																					disabled={rows5.length === 1} // Disable if only one row exists
																					aria-label="Remove row"
																				>
																					
																					<i className="fas fa-trash"></i>

																					
																				</button> )}

																			</div>
																		</div>
																	))}




																	<div className='col-sm-6 mt-2'>
																		<input   disabled={task.createdDTM <= yesterdayDate || role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
																			type='hidden'
																			name='nid'
																			value={formData.nid || task.nid}
																			onChange={handleInputChange} // <-- Add onChange handler
																		/>
																		{(task.createdDTM >= yesterdayDate && role === 'BM') && (
																			<button
																				type='submit'
																				className='btn btn-sm btn-primary'
																			>
																				Submit
																			</button>
																		)}
																	</div>

																</div>
																</form>
															</React.Fragment>

														))}
													</>
												) : (
													<div style={{ minHeight: '630px' }}>
														<FormSkeleton />
													</div>
												)}
											</div>
											<div className='col-sm-12 row mt-5'>
											 
											 <div className='col-12 col-md-12' >
												 {fileslist.length > 0 && <p style={{ color: 'blue', borderRadius: '5px', border: '1px solid #cccccc', width: '280px', background: '#eeeeee', padding: '5px' }} className='mt-2  pp1'>
													 <i class="fas fa-file-upload bg-danger mr-2"></i>

													 File Attathed with this Task
												 </p>}
											  
											 {fileslist.length > 0 ? (
												   fileslist.map((file, index) => (
												 <p key={index} >
												  <a style={{color:'#000000'}} href={`${process.env.REACT_APP_API_URL}public/uploads/${file.file_name}`} target="_blank" rel="noreferrer">
													 <i
													 className="fas fa-file-alt fa-1x "
													 style={{ color: '#3498db' }} // Customize icon color
													 ></i> {file.file_name}
												  </a>
												 </p>
											 ))
											 ) : (
											 <p></p>
											 )}											
												  
											 </div>
										  
									 </div>



									 



									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>

			{/* Custom Modal */}
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<form className="form" onSubmit={handleFileSubmit}>
							<div className="form-group">
								<label>Upload .jpg or .pdf file</label>
								<input   disabled={ role === 'ZONAL_MANAGER' || role === 'AVP' || role === 'CM'}
									type="file"
									name="file"
									onChange={handleFileInputChange}									 
									required
									className="form-control  "
								/>
							</div>
							<button type="submit" className="btn btn-success btn-sm" disabled={loading}>
								Upload
							</button>
						</form>


					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default BmNightTask;
