import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { Link, useNavigate } from 'react-router-dom';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import Manage_cluster from '../settings/Manage_cluster';
const CmNightTask = () => {
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [submitStatus, setSubmitStatus] = useState(null);
	const [selectedCluster, setSelectedCluster] = useState();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();
	const [uploadedMTlist, setUploadedMTlist] = useState([]);
	const [mtDetails, setMtDetails] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const [formData, setFormData] = useState([]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};

	const closeModal = () => {
		setModalOpen(false);
		setShowModal(false);
	};
	const handleOpenModal = (item) => {
		setShowModal(true); // Show the modal
	};
	useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setBranchlist(result.data);
					} else {
						//toast.error( 	result.message || 'An error occurred while fetching the Branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchBranchList();
	}, [token]);

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');
		const selectCluster = sessionStorage.getItem('selectedClusterId');
		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}

		if (selectCluster) {
			setSelectedCluster(selectCluster);
		} else if (clusterlist.length > 0) {
			setSelectedCluster(clusterlist[0].cluster_id);
			sessionStorage.setItem('selectedClusterId', clusterlist[0].cluster_id);
		}
	}, [branchlist, clusterlist]);

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedCluster: selectedCluster,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/getCm_nightTaskDetails`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData c :', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedCluster]); // Dependencies to trigger the effect

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedCluster: selectedCluster,
				};

				// Make the API request with JSON payload
				const mtListResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/uploadedCm_nightlist`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtListResponse.ok) {
					throw new Error(`Error: ${mtListResponse.status}`);
				}

				const mtListData = await mtListResponse.json();

				if (mtListData && Array.isArray(mtListData.data)) {
					console.log('MtData:', mtListData.data);
					setUploadedMTlist(mtListData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setUploadedMTlist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setUploadedMTlist([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedCluster]); // Dependencies to trigger the effect

	//
	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
						sessionStorage.setItem(
							'selectedClusterId',
							result.data[0].cluster_id,
						);
					} else {
						//toast.error( 	result.message || 'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const selectBranch = (e) => {
		const branchId = e.target.value;
		setSelectedBranch(branchId);
		sessionStorage.setItem('selectedBranchId', branchId); // Store in sessionStorage
	};

	const selectDate = (e) => {
		const sDate = e.target.value;
		setSelectedDate(sDate);
		sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
	};
	const getBranchName = (branchId) => {
		const branch = branchlist.find((b) => b.branch_id === branchId);
		return branch ? branch.branch : 'Branch not found';
	};
	const getClusterName = (clusterId) => {
		const cluster = clusterlist.find((b) => b.cluster_id === clusterId);
		return cluster ? cluster.cluster : 'Cluster not found';
	};
	const validateEditFormData = () => {
		console.log('Validating form data');

		return true; // If everything is valid
	};

	const handleSelectCluster = (e) => {
		const selectedClusterId = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			cluster: selectedClusterId,
			cluster_id: selectedClusterId,
		}));

		sessionStorage.setItem('selectedClusterId', selectedClusterId);
		console.log('Cluster ID stored in session:', selectedClusterId);
	};

	const [submittedPayload, setSubmittedPayload] = useState(null); // State to store the submitted payload

	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			// Ensure formData is not empty
			if (!formData || Object.keys(formData).length === 0) {
				throw new Error('No data available to submit');
			}

			// Prepare the request payload in JSON format with updated formData
			const requestData = formData;

			// Show the payload in the state before sending
			setSubmittedPayload(requestData);

			const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/saveCm_nightTaskDetails`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`, // Ensure correct format
				},
				body: JSON.stringify(requestData), // Stringify the updated data to send
			});

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {
				console.log('Data submitted successfully!');
				  window.location.reload() 
			} else {
				console.error('Failed to submit data');
				//toast.error('Failed to submit form');
			}
		} catch (error) {
			console.error('Error submitting data:', error);
			// Show error message
			setSubmitStatus({ message: `Error: ${error.message}`, type: 'error' });
		}
	};

	const initialFormData = {
		cluster: '',
		cluster_id: sessionStorage.getItem('selectedClusterId') || '',
		cm_nt0100: 'Question Number 1 ?',
		cm_nt0101: '',
		cm_nt0102: '',
		cm_nt0200: 'Question Number 2 ?',
		cm_nt0201: '',
		cm_nt0202: '',
		cm_nt0300: 'Question Number 3 ?',
		cm_nt0301: '',
		cm_nt0302: '',
		cm_nt0400: 'Question Number 4 ?',
		cm_nt0401: '',
		cm_nt0402: '',
		cm_nt0500: 'Question Number 5 ?',
		cm_nt0501: '',
		cm_nt0502: '',
		cm_nt0600: 'Question Number 6 ?',
		cm_nt0601: '',
		cm_nt0602: '',
		cm_nt0700: 'Question Number 7 ?',
		cm_nt0701: '',
		cm_nt0702: '',
		cm_nt0800: 'Question Number 8 ?',
		cm_nt0801: '',
		cm_nt0802: '',
		cm_nt0900: 'Question Number 9 ?',
		cm_nt0901: '',
		cm_nt0902: '',
		cm_nt1000: 'Question Number 10 ?',
		cm_nt1001: '',
		cm_nt1002: '',
		branch: '',
		emp_code: userEmp_code,
	};

	useEffect(() => {
		if (mtDetails && Array.isArray(mtDetails) && mtDetails.length > 0) {
			const mtDetail = mtDetails[0]; // Assuming mtDetails[0] cocm_ntains the desired record

			// Dynamically set formData using the mtDetail data
			setFormData((prevState) => ({
				...prevState,
				cm_nid: mtDetail.cm_nid || '',
				cluster_id: mtDetail.cluster_id || '',
				cm_nt0100:
					mtDetail.cm_nt0100 ||
					'Branch Closing time as per last update from branch',
				cm_nt0101: mtDetail.cm_nt0101 || '',
				cm_nt0200: mtDetail.cm_nt0200 || 'Day-End Report Tally - Total Revenue',
				cm_nt0201: mtDetail.cm_nt0201 || '',
				cm_nt0202: mtDetail.cm_nt0202 || '',
				cm_nt0300: mtDetail.cm_nt0300 || 'Day-End Report Tally - Cash',
				cm_nt0301: mtDetail.cm_nt0301 || '',
				cm_nt0302: mtDetail.cm_nt0302 || '',
				cm_nt0400: mtDetail.cm_nt0400 || 'Day-End Report Tally - Paytm',
				cm_nt0401: mtDetail.cm_nt0401 || '',
				cm_nt0402: mtDetail.cm_nt0402 || '',
				cm_nt0500: mtDetail.cm_nt0500 || 'Day-End Report Tally - Card',
				cm_nt0501: mtDetail.cm_nt0501 || '',
				cm_nt0502: mtDetail.cm_nt0502 || '',
				cm_nt0600: mtDetail.cm_nt0600 || 'Total USG Done',
				cm_nt0601: mtDetail.cm_nt0601 || '',
				cm_nt0602: mtDetail.cm_nt0602 || '',
				cm_nt0700: mtDetail.cm_nt0700 || 'Total CT Done',
				cm_nt0701: mtDetail.cm_nt0701 || '',
				cm_nt0702: mtDetail.cm_nt0702 || '',
				cm_nt0800: mtDetail.cm_nt0800 || 'Total MRI Done',
				cm_nt0801: mtDetail.cm_nt0801 || '',
				cm_nt0802: mtDetail.cm_nt0802 || '',
				cm_nt0900: mtDetail.cm_nt0900 || 'Total X-Ray Done',
				cm_nt0901: mtDetail.cm_nt0901 || '',
				cm_nt0902: mtDetail.cm_nt0902 || '',
				cm_nt1000: mtDetail.cm_nt1000 || 'PDR Cases',
				cm_nt1001: mtDetail.cm_nt1001 || '',
				cm_nt1002: mtDetail.cm_nt1002 || '',
				// cm_nt1100: mtDetail.cm_nt1100 || 'PDR Amoucm_nt',
				// cm_nt1101: mtDetail.cm_nt1101 || '',
				// cm_nt1200: mtDetail.cm_nt1200 || 'Total Refunds (Coucm_nt)',
				// cm_nt1201: mtDetail.cm_nt1201 || '',
				// cm_nt1300: mtDetail.cm_nt1300 || 'Total Refunds (Amoucm_nt)',
				// cm_nt1301: mtDetail.cm_nt1301 || '',
				// cm_nt1400: mtDetail.cm_nt1400 || 'Is Petty cash handling clear?',
				// cm_nt1401: mtDetail.cm_nt1401 || '',
				// cm_nt1500:
				// 	mtDetail.cm_nt1500 ||
				// 	'Logistics/Sample Pickup: Is sample collection done as per schedule?',
				// cm_nt1501: mtDetail.cm_nt1501 || '',
				// cm_nt1502: mtDetail.cm_nt1502 || '',
				// cm_nt1600:
				// 	mtDetail.cm_nt1600 ||
				// 	'Is all the stock availability in line with branch business?',
				// cm_nt1601: mtDetail.cm_nt1601 || '',
				// cm_nt1602: mtDetail.cm_nt1602 || '',
				// cm_nt1700: mtDetail.cm_nt1700 || 'Is All V-Mitr Notifications cleared?',
				// cm_nt1701: mtDetail.cm_nt1701 || '',
				// cm_nt1800: mtDetail.cm_nt1800 || 'Google ratings collected in the day',
				// cm_nt1801: mtDetail.cm_nt1801 || '',
				// cm_nt1900:
				// 	mtDetail.cm_nt1900 ||
				// 	'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
				// cm_nt1901: mtDetail.cm_nt1901 || '',
				// cm_nt2000: mtDetail.cm_nt2000 || 'Doctor Productivity',
				// cm_nt2001: mtDetail.cm_nt2001 || '',
				// cm_nt2100: mtDetail.cm_nt2100 || 'Doctor 1 BMID',
				// cm_nt2101: mtDetail.cm_nt2101 || '',
				// cm_nt2200: mtDetail.cm_nt2200 || 'Doctor 1 Name',
				// cm_nt2201: mtDetail.cm_nt2201 || '',
				// cm_nt2300: mtDetail.cm_nt2300 || 'USG Done -Doc 1',
				// cm_nt2301: mtDetail.cm_nt2301 || '',
				// cm_nt2400: mtDetail.cm_nt2400 || 'CT Done -Doc 1',
				// cm_nt2401: mtDetail.cm_nt2401 || '',
				// cm_nt2500: mtDetail.cm_nt2500 || 'MRI Done -Doc 1',
				// cm_nt2501: mtDetail.cm_nt2501 || '',
				// cm_nt2600: mtDetail.cm_nt2600 || 'X-Ray Done -Doc 1',
				// cm_nt2601: mtDetail.cm_nt2601 || '',
				// cm_nt2700: mtDetail.cm_nt2700 || 'Doctor 2 BMID',
				// cm_nt2701: mtDetail.cm_nt2701 || '',
				// cm_nt2800: mtDetail.cm_nt2800 || 'Doctor 2 Name',
				// cm_nt2801: mtDetail.cm_nt2801 || '',
				// cm_nt2900: mtDetail.cm_nt2900 || 'USG Done -Doc 2',
				// cm_nt2901: mtDetail.cm_nt2901 || '',
				// cm_nt3000: mtDetail.cm_nt3000 || 'CT Done -Doc 2',
				// cm_nt3001: mtDetail.cm_nt3001 || '',
				// cm_nt3100: mtDetail.cm_nt3100 || 'MRI Done -Doc 2',
				// cm_nt3101: mtDetail.cm_nt3101 || '',
				// cm_nt3200: mtDetail.cm_nt3200 || 'X-Ray Done -Doc 2',
				// cm_nt3201: mtDetail.cm_nt3201 || '',
				// cm_nt3300: mtDetail.cm_nt3300 || 'Doctor 3 BMID',
				// cm_nt3301: mtDetail.cm_nt3301 || '',
				// cm_nt3400: mtDetail.cm_nt3400 || 'Doctor 3 Name',
				// cm_nt3401: mtDetail.cm_nt3401 || '',
				// cm_nt3500: mtDetail.cm_nt3500 || 'USG Done -Doc 3',
				// cm_nt3501: mtDetail.cm_nt3501 || '',
				// cm_nt3600: mtDetail.cm_nt3600 || 'CT Done -Doc 3',
				// cm_nt3601: mtDetail.cm_nt3601 || '',
				// cm_nt3700: mtDetail.cm_nt3700 || 'MRI Done -Doc 3',
				// cm_nt3701: mtDetail.cm_nt3701 || '',
				// cm_nt3800: mtDetail.cm_nt3800 || 'X-Ray Done -Doc 3',
				// cm_nt3801: mtDetail.cm_nt3801 || '',
				// cm_nt3900: mtDetail.cm_nt3900 || 'Cardiologist Name',
				// cm_nt3901: mtDetail.cm_nt3901 || '',
				// cm_nt4000: mtDetail.cm_nt4000 || 'TMT Done',
				// cm_nt4001: mtDetail.cm_nt4001 || '',
				// cm_nt4100: mtDetail.cm_nt4100 || 'ECG Done',
				// cm_nt4101: mtDetail.cm_nt4101 || '',
			}));
		} else {
			// If no details to edit, set form to initial state for a new task
			setFormData(initialFormData);
		}
	}, [mtDetails]); // Ensure mtDetails is properly updated

	const today = new Date().toISOString().split('T')[0];

	useEffect(() => {
		const createDefaultTask = async () => {
			const requestData = {
				createdDTM: selectedDate,
				cluster_id: selectedCluster,
				...initialFormData,
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/addCmNightTask`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestData),
				});

				if (response.ok) {
					const result = await response.json();
					if (result.status === true) {
						//// toast.success(result.message);
					} else {
						//toast.error( 	result.message || 'An error occurred while adding the task.',);
					}
				} else {
					//toast.error( 	'Error: ' + response.status + ' - ' + response.statusText,);
				}
			} catch (error) {
				//toast.error('Failed to create default task');
			}
		};

		if (
			selectedDate === today &&
			selectedCluster &&
			(!mtDetails || mtDetails.length === 0)
		) {
			createDefaultTask();
		}
	}, [selectedCluster, selectedDate, mtDetails, token, initialFormData, today]);

	return (
		<>
			<Header /> <Sidebar /> 
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-3 form-group'>
								<select
									className='form-control form-control-sm'
									name='cluster'
									value={formData.cluster_id || selectedCluster || ''}
									onChange={handleSelectCluster}
								>
									<option value=''>Select Cluster</option>
									{clusterlist.map((r) => (
										<option key={r.id} value={r.cluster_id}>
											{r.cluster}
										</option>
									))}
								</select>
							</div>

							<div className='col-sm-3 form-group'>
								<input
									type='date'
									name='selectDate'
									onChange={selectDate}
									className='form-control form-control-sm'
									placeholder='Select Date'
									max={today}
									value={selectedDate} // Set the default value here
								/>
							</div>
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</div>
				{/* /.content-header */}
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'></div>

										<div className='row '>
											{selectedCluster && selectedDate && (
												<div className='col-sm-8'>
													<h6>
														Night Closing Check list of Cluster:{' '}
														<span className='blue'>
															{getClusterName(selectedCluster)}{' '}
														</span>{' '}
														Dated: <span className='blue'> {selectedDate}</span>
													</h6>
												</div>
											)}

											{/* Display all fields from mtDetails */}
											<div className='col-sm-8'>
												{/* Display all fields from mtDetails with serial number */}
												{mtDetails && mtDetails.length > 0 && (
													<div className='row'>
														<div className='col-sm-12'>
															<div className='table-responsive'>
																<form
																	className='col-sm-12'
																	onSubmit={handleEditSubmit}
																>
																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0100'
																				id='cm_nt0100'
																				value={formData.cm_nt0100 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0101'
																						id='cm_nt0101'
																						value={formData.cm_nt0101 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0200'
																				id='cm_nt0200'
																				value={formData.cm_nt0200 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0201'
																						id='cm_nt0201'
																						value={formData.cm_nt0201 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0300'
																				id='cm_nt0300'
																				value={formData.cm_nt0300 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0301'
																						id='cm_nt0301'
																						value={formData.cm_nt0301 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0400'
																				id='cm_nt0400'
																				value={formData.cm_nt0400 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0401'
																						id='cm_nt0401'
																						value={formData.cm_nt0401 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0500'
																				id='cm_nt0500'
																				value={formData.cm_nt0500 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0501'
																						id='cm_nt0501'
																						value={formData.cm_nt0501 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0600'
																				id='cm_nt0600'
																				value={formData.cm_nt0600 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0601'
																						id='cm_nt0601'
																						value={formData.cm_nt0601 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0700'
																				id='cm_nt0700'
																				value={formData.cm_nt0700 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0701'
																						id='cm_nt0701'
																						value={formData.cm_nt0701 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0800'
																				id='cm_nt0800'
																				value={formData.cm_nt0800 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0801'
																						id='cm_nt0801'
																						value={formData.cm_nt0801 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='cm_nt0900'
																				id='cm_nt0900'
																				value={formData.cm_nt0900 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='cm_nt0901'
																						id='cm_nt0901'
																						value={formData.cm_nt0901 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt1000'
																				id='mt1000'
																				value={formData.mt1000 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt1001'
																						id='mt1001'
																						value={formData.mt1001 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	{selectedDate == today && (
																		<button
																			type='submit'
																			className='btn btn-sm btn-primary'
																		>
																			Submit
																		</button>
																	)}
																</form>
															</div>
														</div>
													</div>
												)}
											</div>
											{/* <div className='col-sm-4'>
												<div style={{ paddingLeft: '1em', paddingTop: '1em' }}>
													<h5>Checklist Submitted</h5>
													{uploadedMTlist.map((b) => {
														const formattedDate = new Date(
															b.createdDTM,
														).toLocaleDateString('en-GB'); // Format date as needed
														return (
															<p
																key={b.id}
																onClick={() => setSelectedDate(b.createdDTM)} // Set selectedDate on click
																style={{ cursor: 'pointer' }} // Optional: cursor pointer for clickable indication
															>
																{formattedDate}
															</p>
														);
													})}
												</div>
											</div> */}
											{/* Repeat for other form sections */}
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>

						{/* <form onSubmit={handleSubmit}> */}
						<div className='modal-body'>
							<div className='form-group'>
								<table>
									<tr>
										<td>
											{/* <label htmlFor='emp_code'>
													Create New Checklist for Date :
												</label>

												<input
													type='text'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={selectedDate}
													onChange={handleInputChange}
												/>
												<input
													type='text'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={selectedBranch}
													onChange={handleInputChange}
												/> */}
											{/* <input
													type='date'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={formData.checkListDate || ''}
													onChange={handleInputChange}
													max={new Date().toISOString().split('T')[0]} // Sets max date to today
													min={
														new Date(
															new Date().setDate(new Date().getDate() - 4),
														)
															.toISOString()
															.split('T')[0]
													} // Sets min date to 4 days back
												/> */}
										</td>
									</tr>
								</table>
							</div>
						</div>

						<div className='modal-footer'>
							{/* <input type='hidden' name='id' value={formData.id || ''} /> */}
							<button
								type='button'
								className='btn btn-sm btn-secondary'
								onClick={handleCloseModal}
							>
								Close
							</button>
							<button type='submit' className='btn btn-sm btn-primary'>
								Add
							</button>
						</div>
						{/* </form> */}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default CmNightTask;
