import React, { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';

function Header() {
	const navigate = useNavigate();
	//const area = sessionStorage.getItem('area');
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const [userProfile, setUserProfile] = useState({});
	useEffect(() => {
		const profile = JSON.parse(sessionStorage.getItem('userProfile'));
		if (profile) {
			setUserProfile(profile);
			//console.log('User profile:', profile);
		}
	}, []);

	// useEffect(() => {
	// 	const authToken = sessionStorage.getItem('authToken');
	// 	if (!authToken) {
	// 		navigate('/login');
	// 		//console.log('No token found in sessionStorage.');
	// 	}else{
	// 		try{
	// 			const checkToken = async () => {
	// 				const response = await fetch(`${process.env.REACT_APP_API_URL}api/checkToken`, {
	// 					method: 'POST',
	// 					headers: {
	// 						Authorization: `Bearer ${authToken}`,
	// 					},
	// 				});
	// 				if (response.ok) {
	// 					const result = await response.json();
	// 					if (!result.STATUS) {
	// 						navigate('/login');
	// 					}
	// 				} else {
	// 					const errorBody = await response.text();
	// 					console.error('Error response:', errorBody);
	// 					navigate('/login');
	// 				}
	// 			};
	// 			checkToken();
	// 		} catch (error) {
	// 			console.error('Fetch error:', error);
	// 			navigate('/login');
	// 		}
			 
	// 	}
	// }, [navigate,]);

	const toggleSidebar = () => {
		const body = document.querySelector('body');
		if (window.innerWidth <= 768) {
			// Apply full-screen overlay for mobile
			body.classList.toggle('sidebar-open');
		} else {
			// Toggle sidebar for desktop
			if (body.classList.contains('sidebar-collapse')) {
				body.classList.remove('sidebar-collapse');
				setSidebarOpen(false);
			} else {
				body.classList.add('sidebar-collapse');
				setSidebarOpen(true);
			}
		}
	};

	return (
		<nav className='main-header navbar navbar-expand navbar-white navbar-light'>
			<ul className='navbar-nav' style={{ listStyleType: 'none' }}>
				<li className='nav-item'>
					<button className='nav-link btn' onClick={toggleSidebar}>
						<i className='fas fa-bars' />
					</button>
				</li>
				<li className='nav-item d-none d-sm-inline-block'>
					<span style={{ color: '#2600fc' }} className='nav-link'>
						{userProfile.role === 'admin' ? 'Admin' : userProfile.role === 'BM' ? 'Branch Manager'
						: userProfile.role === 'CM' ? 'Cluster Manager': userProfile.role === 'AVP' ? 'AVP'
						: userProfile.role === 'ZONAL_MANAGER' ? 'Zonal Manager'
						: userProfile.role === 'SUPER_ADMIN' ? 'SUPER ADMIN'  : 'User'}
					</span>
				</li>
			</ul>
			<ul className='navbar-nav ml-auto' style={{ listStyleType: 'none' }}>
				<li className='nav-item'>
					<i className='fas fa-user' /> &nbsp;
					{userProfile && (
						<>
							{userProfile.fname} {userProfile.lname} - {userProfile.emp_code}
						</>
					)}
				</li>
			</ul>
		</nav>
	);
}

export default Header;
