import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
//import { useAuth } from '../../context/authprovider';
import {  useNavigate } from 'react-router-dom';
 
import '../customStyle.css';
const AddBmNightTask = () => {
	 
	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);	 
	const [nidSelected, setNidSelected] = useState();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();
	 
	const [mtDetails, setMtDetails] = useState();
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	 
	const today = new Date().toISOString().split('T')[0];
	const initialFormData = {
		nid:'',
		cluster: '',
		cluster_id: '',
		nt0100: 'Branch Closing time as per last update form branch',
		nt0101: '',
		nt0102: '',
		nt0200: 'Day-End Report Tally - Total Revenue',
		nt0201: '',
		nt0202: '',
		nt0300: 'Day-End Report Tally - Cash',
		nt0301: '',
		nt0302: '',
		nt0400: 'Day-End Report Tally - Paytm',
		nt0401: '',
		nt0402: '',
		nt0500: 'Day-End Report Tally - Card',
		nt0501: '',
		nt0502: '',
		nt0600: 'Total USG Done',
		nt0601: '',
		nt0602: '',
		nt0700: 'Total CT Done',
		nt0701: '',
		nt0702: '',
		nt0800: 'Total MRI Done',
		nt0801: '',
		nt0802: '',
		nt0900: 'Total X-Ray Done',
		nt0901: '',
		nt0902: '',
		nt1000: 'PDR Cases',
		nt1001: '',
		nt1002: '',
		nt1100: 'PDR Amount',
		nt1101: '',
		nt1200: 'Total Refunds (Count)',
		nt1201: '',
		nt1300: 'Total Refunds (Amount)',
		nt1301: '',
		nt1400: 'is Petty cash handling clear?',
		nt1401: '',
		nt1500: 'Logists/Sample Pickup: is sample collection done a per schedule?',
		nt1501: '',
		nt1600: 'is all the stock avaiability is in line with branch business',
		nt1601: '',
		nt1700: 'is All V-Mitr Notifications cleared?',
		nt1701: '',
		nt1800: 'Google ratings collected in the day',
		nt1801: '',
		nt1900: 'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
		nt1901: '',
		nt2000: 'Doctor Productivity',
		nt2001: '',
		nt2100: 'Doctor 1 BMID',
		nt2101: '',
		nt2200: 'Doctor 1 Name',
		nt2201: '',
		nt2300: 'USG Done -Doc 1',
		nt2301: '',
		nt2400: 'CT Done -Doc 1',
		nt2401: '',
		nt2500: 'MRI Done -Doc 1',
		nt2501: '',
		nt2600: 'X-Ray Done -Doc 1',
		nt2601: '',
		nt2700: 'Doctor 2 BMID',
		nt2701: '',
		nt2800: 'Doctor 2 Name',
		nt2801: '',
		nt2900: 'USG Done -Doc 2',
		nt2901: '',
		nt3000: 'CT Done -Doc 2',
		nt3001: '',
		nt3100: 'MRI Done -Doc 2 ',
		nt3101: '',
		nt3200: 'X-Ray Done -Doc 2',
		nt3201: '',
		nt3300: 'Doctor 3 BMID',
		nt3301: '',
		nt3400: 'Doctor 3 Name',
		nt3401: '',
		nt3500: 'USG Done -Doc 3',
		nt3501: '',
		nt3600: 'CT Done -Doc 3',
		nt3601: '',
		nt3700: 'MRI Done -Doc 3',
		nt3701: '',
		nt3800: 'X-Ray Done -Doc 3',
		nt3801: '',
		nt3900: 'Cardiologist Name',
		nt3901: '',
		nt4000: 'TMT Done',
		nt4001: '',
		nt4100: 'ECG Done',
		nt4101: '',
		branch: '',
		emp_code: userEmp_code,
	};

	const [formData, setFormData] = useState(initialFormData);

 
	useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setBranchlist(result.data);
					} else {
						//toast.error( 	result.message || 'An error occurred while fetching the Branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchBranchList();
	}, [token]);

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');

		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}
	}, [branchlist]);
	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedBranch: selectedBranch,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getNightTaskDetails`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
					setNidSelected(mtData.data[0]?.nid);
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

  
 
	 

	useEffect(() => {
		const createDefaultTask = async () => {
			const requestData = {
				createdDTM: selectedDate,
				branch_id: selectedBranch,
				...initialFormData,
			};
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNightTask`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestData),
				});
				if (response.ok) {
					const result = await response.json();
					if (result.status === true) {					 
					 navigate(`/EditBmNightTask/${result.data}`);
					}					 
				}
			} catch (error) {
				 
			}
		};

		if (
			selectedDate === today &&
			selectedBranch &&
			(!mtDetails || mtDetails.length === 0)
		) {
			createDefaultTask();
		}
	}, [selectedBranch, selectedDate, mtDetails, token, initialFormData, today]);

 
	 
	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				<section className='content'> 
					<div className='container-fluid'> 
						<div className='row'>
							<section className='col-lg-12 connectedSortable'> 
								<div className='card' style={{ minHeight:'630px' }}> 
									<div className='card-body'>
										{!selectedBranch ? (
											<div>No branches are assigned to user</div>
										) : (
											// Your existing content here
											<div>
												{/* Your existing content */}
											</div>
										)}
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default AddBmNightTask;
