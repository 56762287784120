import React from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Sidebar from '../components/common/Sidebar';
import { useAuth } from '../context/authprovider';
import './customStyle.css';
const Nighttask = () => {
	const { role } = useAuth();
	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];
	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1 className='m-0'>
									{role === 'BM' && <> BM Night Closing Task </>}
									{role === 'CM' && <> CM Night Closing Task </>}
									{role === 'SUPER_ADMIN' && <> Morning Task All List</>}
								</h1>
							</div>
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</div>
				{/* /.content-header */}
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'>
											<div className='col-sm-3'>
												<input
													type='date'
													className='form-control form-control-sm'
													placeholder='Select Date'
													max={today}
												/>
											</div>
										</div>
										<div className='row mt-4'>
											<div className='col-sm-12 row'>
												<div className='col-sm-8'>
													{' '}
													<b> Night Closing Task of Dated : 28-10-2024 </b>{' '}
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4 ss'>
													Branch Closing time as per last update form branch
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='time'
																name='b_close_time'
																id='b_close_time'
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Day-End Report Tally - Total Revenue
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Day-End Report Tally - Cash
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Day-End Report Tally - Paytm
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Day-End Report Tally - Card
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Total USG Done</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Total CT Done</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Total MRI Done</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Total X-Ray Done</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>PDR Cases</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>PDR Amount</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Total Refunds (Count)</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Total Refunds (Amount)</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													is Petty cash handling clear?
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-inline'>
															<div className='form-check'>
																<input
																	className='form-check-input'
																	type='radio'
																	name='flexRadioDefault'
																	id='flexRadioDefault1'
																/>
																<label
																	className='form-check-label'
																	htmlFor='flexRadioDefault1'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	className='form-check-input'
																	type='radio'
																	name='flexRadioDefault'
																	id='flexRadioDefault2'
																	defaultChecked
																/>
																<label
																	className='form-check-label'
																	htmlFor='flexRadioDefault2'
																>
																	No
																</label>
															</div>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Logists/Sample Pickup: is sample collection done as
													per schedule?
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-inline'>
															<div className='form-check'>
																<input
																	className='form-check-input'
																	type='radio'
																	name='flexRadioDefault'
																	id='flexRadioDefault1'
																/>
																<label
																	className='form-check-label'
																	htmlFor='flexRadioDefault1'
																>
																	Yes
																</label>
															</div>
															<div className='form-check ml-3'>
																<input
																	className='form-check-input'
																	type='radio'
																	name='flexRadioDefault'
																	id='flexRadioDefault2'
																	defaultChecked
																/>
																<label
																	className='form-check-label'
																	htmlFor='flexRadioDefault2'
																>
																	No
																</label>
															</div>
														</div>
													</div>
												</div>
												<div className='col-sm-4'>
													{' '}
													<textarea
														class='form-control'
														id='exampleFormControlTextarea1'
														rows='1'
													></textarea>
												</div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													is all the stock avaiability is in line with branch
													business
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='radio'
																name='flexRadioDefault'
																id='flexRadioDefault1'
															/>
															<label
																className='form-check-label'
																htmlFor='flexRadioDefault1'
															>
																Yes
															</label>
														</div>
														<div className='form-check ml-3'>
															<input
																className='form-check-input'
																type='radio'
																name='flexRadioDefault'
																id='flexRadioDefault2'
																defaultChecked
															/>
															<label
																className='form-check-label'
																htmlFor='flexRadioDefault2'
															>
																No
															</label>
														</div>
													</div>
												</div>
												<div className='col-sm-4'>
													<textarea
														class='form-control'
														id='exampleFormControlTextarea1'
														rows='1'
													></textarea>
												</div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													is All V-Mitr Notifications cleared?
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Google ratings collected in the day
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>
													Corss Billings done (USG/CT/MRI/X-ray/PET CT only)
												</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor Productivity</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor 1 BMID</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor 1 Name</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>USG Done -Doc 1</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>CT Done -Doc 1</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>MRI Done -Doc 1</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>X-Ray Done -Doc 1</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor 2 BMID</div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor 2 Name </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>

											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>USG Done -Doc 2 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>

											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>CT Done -Doc 2 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>MRI Done -Doc 2 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>X-Ray Done -Doc 2 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor 3 BMID </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Doctor 3 Name </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>USG Done -Doc 3 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>CT Done -Doc 3 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>MRI Done -Doc 3 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>X-Ray Done -Doc 3 </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>Cardiologist Name </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>TMT Done </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
											<div
												className='col-sm-12 row mt-2'
												style={{
													background: '#eeeeee',
													padding: '5px',
												}}
											>
												<div className='col-sm-4'>ECG Done </div>
												<div className='col-sm-4'>
													<div className='form-inline'>
														<div className='form-check'>
															<input
																className='form-check-input'
																type='number'
																name='num'
																id=''
															/>
														</div>
													</div>
												</div>
												<div className='col-sm-4'></div>
											</div>
										</div>
										<div className='col-sm-4 mt-2'>
											{' '}
											<button className='btn btn-sm btn-info'>
												Submit
											</button>{' '}
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Nighttask;
