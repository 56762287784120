import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { debounce } from 'lodash';
import * as XLSX from 'xlsx';
import '../customStyle.css';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import FormSkeleton from '../../components/other/formSkeleton';

const Manage_cluster = () => {
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');

	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [currentDepartment, setCurrentDepartment] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState('USER_ID');
	const [sortOrder, setSortOrder] = useState('asc');

	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedClusterInfo, setSelectedClusterInfo] = useState(null);
	const [arealist, setArealist] = useState(''); // Add this line
	////console.log('Clusters from context:', clusters);
	useEffect(() => {
		const fetchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getClusters`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.ok) {
					const result = await response.json();
					if (result.status) {
						setItems(result.data);
						setFilteredItems(result.data);
						setLoading(false);
					} else {
						//toast.error(result.message || 'Error fetching clusters.');
					}
				} else {
					const errorBody = await response.text();
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch clusters');
			} finally {
				setLoading(false);
			}
		};
		fetchList();
	}, [token]);

	useEffect(() => {
		const debouncedSearch = debounce(() => {
			const filtered = items.filter((item) => {
				const cluster = item.cluster ? item.cluster.toLowerCase() : '';
				const zone = item.zone ? item.zone.toLowerCase() : '';

				return cluster.includes(searchQuery.toLowerCase());
			});

			const sorted = filtered.sort((a, b) => {
				if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
				if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
				return 0;
			});

			setFilteredItems(sorted);
			setCurrentPage(1);
		}, 300); // debounce delay of 300ms

		debouncedSearch();

		return () => debouncedSearch.cancel(); // Cancel debounced function on cleanup
	}, [searchQuery, items, sortColumn, sortOrder]); // dependencies as usual

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value); // Update the searchQuery directly
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = (e) => {
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(column);
			setSortOrder('asc');
		}
	};
	const validateEditFormData = () => {
		if (!formData.cluster) {
			//toast.error('Cluster name is required.');
			return false;
		}

		// Only require Department ID if it's greater than 0 (indicating an edit)
		if (formData.cluster_id && formData.cluster_id <= 0) {
			//toast.error('Cluster ID is required for editing.');
			return false;
		}

		return true;
	};
	const handleSubmitEditForm = async (e) => {
		e.preventDefault();

		////console.log('Form Data before submission:', formData); // Log formData

		const isValid = validateEditFormData(); // Ensure validation
		//console.log('Validation result:', isValid);
		if (!isValid) return;

		// Log each individual field to see if they are set correctly
		////console.log('manager_id:', formData.manager_id);
		////console.log('category_id:', formData.category_id);

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		//console.log('Form data to submit:', formDataToSubmit); // Check what is being sent

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/editCluster`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			//console.log('API Response:', response); // Log the raw response

			if (response.ok) {
				const result = await response.json();
				//console.log('Parsed API Response:', result); // Check the parsed response

				if (result.status) {
					  window.location.reload();
					handleCloseModal();
					//	navigate('/manage_department');
				} else {
					//toast.error(result.message || 'An error occurred while updating.');
				}
			} else {
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
				console.error('Error submitting form:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	// Handle the delete form submission
	const handleSubmitDeleteForm = async (e) => {
		e.preventDefault();

		try {
			// Prepare form data with only the 'id'
			const formDataToSubmit = new FormData();
			formDataToSubmit.append('cluster_id', formData.cluster_id);

			const response = await fetch(`${process.env.REACT_APP_API_URL}api/deleteCluster`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					// No 'Content-Type' header since FormData automatically sets the correct boundary
				},
				body: formDataToSubmit, // Send form data with just the 'id'
			});

			if (response.ok) {
				const result = await response.json();
				if (result.status) {
					  window.location.reload();
					handleCloseModal(); // Close modal upon successful deletion
				} else {
					//toast.error(result.message || 'Failed to delete the cluster.');
				}
			} else {
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
				console.error('Error submitting delete request:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to delete the cluster');
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		//console.log('Form submitted'); // Log form submission

		// Create a new object excluding the id if it's not needed
		const { id, ...dataToSubmit } = formData;

		// Validate form data
		if (!validateEditFormData()) {
			//console.log('Validation failed');
			return; // Stop execution if validation fails
		}

		// Create FormData object
		const formDataToSubmit = new FormData();
		Object.keys(dataToSubmit).forEach((key) => {
			if (dataToSubmit[key] !== undefined && dataToSubmit[key] !== '') {
				formDataToSubmit.append(key, dataToSubmit[key]);
			}
		});

		// If id is needed by the API, append it even if it's 0
		formDataToSubmit.append('id', id || 0); // Append id, default to 0 if not present

		//console.log('Form data to submit:', Array.from(formDataToSubmit.entries())); // Log FormData

		try {
			//console.log('Calling API...'); // Indicate API call is about to happen
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNewCluster`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			//console.log('API Response:', response); // Log API response
			if (response.ok) {
				const result = await response.json();
				//console.log('Parsed API Response:', result);
				if (result.status) {
					 window.location.reload();
					closeModal();
					navigate('/manage_cluster');
				} else {
					//toast.error( 	result.message || 'An error occurred while adding the Cluster.',);
				}
			} else {
				const errorBody = await response.text();
				console.error('Error response:', errorBody);
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const openModal = () => {
		//console.log('Opening modal');
		setModalOpen(true);
	};
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		//console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};
	const [formData, setFormData] = useState({
		cluster: '',
		cluster_id: '',
	});

	const closeModal = () => {
		setModalOpen(false);
		setShowModal(false);
		setShowDeleteModal(false);
		setFormData({ dept_name: '', cat_id: '', id: '' }); // Reset form
	};

	// Handle modal close
	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedClusterInfo(null); // Clear the selected bank info after closing modal
		setShowDeleteModal(false);
	};
	// Handle Edit button click
	const handleEditClick = (item) => {
		////console.log('Clicked Item:', item); // Debug clicked item
		setSelectedClusterInfo(item); // Set the selected bank info
		setShowModal(true); // Show the modal
	};
	const handleDeleteClick = (item) => {
		////console.log('Clicked Item:', item); // Debug clicked item
		setSelectedClusterInfo(item); // Set the selected bank info
		setShowDeleteModal(true); // Show the modal
	};
	// Variables for pagination
	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);
	const exportToExcel = () => {
		const filteredClusters = filteredItems.map((item, index) => ({
			Id: index + 1,
			ClusterName: item.cluster,
			Zone: item.zone,
			Branches: item.branchList.map((branch) => branch.branch).join(', '), // Combine branch names into a single string
		}));

		const worksheet = XLSX.utils.json_to_sheet(filteredClusters);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Clusters');
		XLSX.writeFile(workbook, 'Cluster_Details.xlsx');
	};

	// useEffect to populate form data when selectedClusterInfo changes
	useEffect(() => {
		if (selectedClusterInfo) {
			setFormData({
				cluster: selectedClusterInfo.cluster || '',
				cluster_id: selectedClusterInfo.cluster_id || '',
			});
		} else {
			setFormData({
				cluster: '',
				cluster_id: '',
			});
		}
		//console.log('Selected Cluster Info:', selectedClusterInfo);
	}, [selectedClusterInfo]);
	return (
		<>
			<Header /> <Sidebar />
			
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h3 className='m-0'>View Cluster Operations Details</h3>
							</div>
						</div>
					</div>
				</div>
				<section className='content'>
					<div className='container-fluid' style={{minHeight:'600px'}}>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										{loading ? (
											<p> <FormSkeleton /></p>
										) : error ? (
											<p>Error: {error}</p>
										) : (
											<>
												<div className='row'>
													<div className='col-sm-3'>
														<div className='form-group'>
															<select
																style={{ width: '5em' }}
																className='form-control custom-select-sm'
																value={itemsPerPage}
																onChange={handleItemsPerPageChange}
															>
																<option value={10}>10</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
														</div>
													</div>
													<div className='col-sm-6'>
														<div
															id='example1_filter'
															style={{ float: 'right' }}
															className='col-sm-9 dataTables_filter'
														>
															<label>
																<input
																	type='search'
																	value={searchQuery}
																	onChange={handleSearchChange}
																	className='form-control form-control-sm'
																	placeholder='Search'
																	aria-controls='example1'
																/>
															</label>
														</div>
													</div>
													<div className='col-sm-3'>
														<span style={{ float: 'right' }}>
															<button
																type='button'
																className='btn btn-sm btn-success'
																onClick={() => setModalOpen(true)}
															>
																Add New
															</button>
															{modalOpen && (
																<div className='customModal'>
																	<div className='customModalContent'>
																		<span
																			className='close'
																			onClick={handleCloseModal}
																		>
																			&times;
																		</span>
																		<h4>Add New Cluster</h4>

																		<form onSubmit={handleSubmit}>
																			<div className='modal-body'>
																				<div className='form-group'>
																					<label htmlFor='branch'>
																						Cluster Name
																					</label>
																					<input
																						type='text'
																						id='cluster'
																						className='form-control form-control-sm'
																						name='cluster'
																						value={formData.cluster}
																						onChange={handleInputChange}
																						required
																					/>
																				</div>
																			</div>
																			<div className='modal-footer'>
																				<button
																					type='button'
																					className='btn btn-secondary'
																					onClick={closeModal}
																				>
																					Close
																				</button>
																				<button
																					type='submit'
																					className='btn btn-primary'
																				>
																					Add
																				</button>
																			</div>
																		</form>
																	</div>
																</div>
															)}
															<button
																type='button'
																className='btn btn-sm btn-secondary ml-2'
																onClick={exportToExcel}
															>
																Export to Excel
															</button>
														</span>
													</div>
												</div>

												<table className='table table-bordered table-striped'>
													<thead>
														<tr>
															<th>S.No</th>
															<th>Cluster</th>
															<th>Zone</th>
															<th>Branches Mapped</th>
															<th style={{ minWidth: '9em' }}>Action</th>
														</tr>
													</thead>
													<tbody>
														{currentItems.length > 0 ? (
															currentItems.map((item, index) => (
																<tr key={index}>
																	<td>
																		{index +
																			1 +
																			(currentPage - 1) * itemsPerPage}
																	</td>
																	<td>{item.cluster}</td>
																	<td>{item.zone || 'No zone assigned'}</td>
																	<td>
																		{item.branchList &&
																		item.branchList.length > 0 ? (
																			item.branchList.map((branch, bIndex) => (
																				<span key={bIndex}>
																					{branch.branch}
																					{bIndex < item.branchList.length - 1
																						? ', '
																						: ''}
																				</span>
																			))
																		) : (
																			<span style={{ color: '#c9c6c6' }}>
																				No branches mapped
																			</span>
																		)}
																	</td>
																	<td>
																		<button
																			type='button'
																			className='btn btn-success btn-sm'
																			onClick={() => handleEditClick(item)}
																		>
																			Edit
																		</button>
																		<Link to='#'>
																			<button
																				type='button'
																				className='btn btn-danger btn-sm'
																				onClick={() => handleDeleteClick(item)}
																				style={{ marginLeft: '1em' }}
																			>
																				Delete
																			</button>
																		</Link>
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan='5'>No data available</td>
															</tr>
														)}
													</tbody>
												</table>
												{loading && <p>Loading...</p>}
											</>
										)}
									</div>
								</div>
								<nav>
									<ul className='pagination' style={{ float: 'right' }}>
										<li className='page-item'>
											<button
												className='page-link'
												onClick={() => handlePageChange(currentPage - 1)}
												disabled={currentPage === 1}
											>
												Previous
											</button>
										</li>
										{[...Array(totalPages).keys()].map((pageNumber) => (
											<li
												key={pageNumber}
												className={`page-item ${
													pageNumber + 1 === currentPage ? 'active' : ''
												}`}
											>
												<button
													className='page-link'
													onClick={() => handlePageChange(pageNumber + 1)}
												>
													{pageNumber + 1}
												</button>
											</li>
										))}
										<li className='page-item'>
											<button
												className='page-link'
												onClick={() => handlePageChange(currentPage + 1)}
												disabled={currentPage === totalPages}
											>
												Next
											</button>
										</li>
									</ul>
								</nav>
								<div className='mb-3'>
									Showing {startItem} to {endItem} of {filteredItems.length}{' '}
									entries
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{/* Custom Modal */}
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<h4>Edit Department Details</h4>
						{selectedClusterInfo && (
							<>
								{/* {//console.log('Selected Branch Info:', selectedClusterInfo)} */}
								<form onSubmit={handleSubmitEditForm}>
									<div className='modal-body'>
										<div className='form-group'>
											<label htmlFor='name'>Cluster Name</label>
											<input
												type='text'
												id='cluster'
												className='form-control'
												name='cluster'
												value={formData.cluster}
												onChange={handleInputChange}
											/>
										</div>
									</div>
									<div className='modal-footer'>
										<input
											type='hidden'
											name='cluster_id'
											value={formData.cluster_id || ''}
										/>
										<button
											type='button'
											className='btn btn-secondary'
											onClick={closeModal}
										>
											Close
										</button>
										<button type='submit' className='btn btn-primary'>
											{currentDepartment ? 'Update' : 'Add'}
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			{showDeleteModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>

						{selectedClusterInfo && (
							<>
								{/* {//console.log('Selected Branch Info:', selectedClusterInfo)} */}
								<form onSubmit={handleSubmitDeleteForm}>
									<div className='modal-body'>
										<div className='form-group'>
											<label htmlFor='department'>
												Are you sure? Want to delete Cluster -{' '}
												{formData.cluster}
											</label>
										</div>
									</div>
									<div className='modal-footer'>
										<input
											type='hidden'
											name='cluster_id'
											value={formData.cluster_id || ''}
										/>
										<button
											type='button'
											className='btn btn-secondary'
											onClick={handleCloseModal}
										>
											Close
										</button>
										<button type='submit' className='btn btn-primary'>
											Delete
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Manage_cluster;
