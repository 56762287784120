import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { Link, useNavigate } from 'react-router-dom';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import Manage_cluster from './../settings/Manage_cluster';
const CmMorningTask = () => {
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [submitStatus, setSubmitStatus] = useState(null);
	const [selectedCluster, setSelectedCluster] = useState();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();
	const [uploadedMTlist, setUploadedMTlist] = useState([]);
	const [mtDetails, setMtDetails] = useState(null);
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const [formData, setFormData] = useState([]);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};

	const closeModal = () => {
		setModalOpen(false);
		setShowModal(false);
	};
	const handleOpenModal = (item) => {
		setShowModal(true); // Show the modal
	};
	// useEffect(() => {
	// 	const fetchBranchList = async () => {
	// 		try {
	// 			const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
	// 				method: 'POST',
	// 				headers: {
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 			});
	// 			console.log('API Response:', response); // Log API response
	// 			if (response.ok) {
	// 				const result = await response.json();
	// 				console.log('Parsed API Response:', result);
	// 				if (result.STATUS) {
	// 					setBranchlist(result.data);
	// 				} else {
	// 					////toast.error(
	// 						//result.message ||
	// 						'An error occurred while fetching the Branch list.',
	// 					);
	// 				}
	// 			} else {
	// 				const errorBody = await response.text();
	// 				console.error('Error response:', errorBody);
	// 				////toast.error(`Error: ${response.status} - ${response.statusText}`);
	// 			}
	// 		} catch (error) {
	// 			console.error('Fetch error:', error);
	// 			////toast.error('Failed to fetch branch list');
	// 		} finally {
	// 			setLoading(false); // Ensure loading state is set to false after the fetch
	// 		}
	// 	};

	// 	fetchBranchList();
	// }, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');
		const selectCluster = sessionStorage.getItem('selectedClusterId');
		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}

		if (selectCluster) {
			setSelectedCluster(selectCluster);
		} else if (clusterlist.length > 0) {
			setSelectedCluster(clusterlist[0].cluster_id);
			sessionStorage.setItem('selectedClusterId', clusterlist[0].cluster_id);
		}
	}, [branchlist, clusterlist]);

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedCluster: selectedCluster,
				};

				// Make the API request with JSON payload
				const mtListResponse = await fetch(`${process.env.REACT_APP_API_URL}api/uploadedCmMTtask`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});

				if (!mtListResponse.ok) {
					throw new Error(`Error: ${mtListResponse.status}`);
				}

				const mtListData = await mtListResponse.json();

				if (mtListData && Array.isArray(mtListData.data)) {
					console.log('MtData:', mtListData.data);
					setUploadedMTlist(mtListData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setUploadedMTlist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setUploadedMTlist([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
						sessionStorage.setItem(
							'selectedClusterId',
							result.data[0].cluster_id,
						);
					} else {
						////toast.error( result.message || 	'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				////toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

	const handleCloseModal = () => {
		setShowModal(false);
	};
	const selectBranch = (e) => {
		const branchId = e.target.value;
		setSelectedBranch(branchId);
		sessionStorage.setItem('selectedBranchId', branchId); // Store in sessionStorage
	};

	const selectDate = (e) => {
		const sDate = e.target.value;
		setSelectedDate(sDate);
		sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
	};
	const getBranchName = (branchId) => {
		const branch = branchlist.find((b) => b.branch_id === branchId);
		return branch ? branch.branch : 'Branch not found';
	};
	const getClusterName = (clusterId) => {
		const cluster = clusterlist.find((b) => b.cluster_id === clusterId);
		return cluster ? cluster.cluster : 'Cluster not found';
	};
	const validateEditFormData = () => {
		console.log('Validating form data');

		// if (!formData.mt0101 || formData.mt0101 === '') {
		// 	console.log('Missing value for "is All Manpower in proper dress code?"');
		// 	return false;
		// }
		// More validation checks...

		return true; // If everything is valid
	};
	// const handleSubmit = async (e) => {
	// 	e.preventDefault();

	// 	if (!validateEditFormData()) {
	// 		console.log('Validation failed');
	// 		return;
	// 	}

	// 	// Prepare requestData by filtering non-empty values from formData
	// 	const requestData = {
	// 		createdDTM: selectedDate,
	// 		cluster_id: selectedCluster,
	// 		// Append non-empty fields from formData dynamically
	// 		...Object.fromEntries(
	// 			Object.entries(formData)
	// 				.filter(([key, value]) => value !== '') // Filter out empty values
	// 				.map(([key, value]) => [key, value]), // Keep the key-value pair
	// 		),
	// 	};

	// 	console.log('Form data to submit:', requestData);

	// 	try {
	// 		const response = await fetch(`${process.env.REACT_APP_API_URL}api/addCmMorningTask`, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json', // Send JSON data
	// 				Authorization: `Bearer ${token}`,
	// 			},
	// 			body: JSON.stringify(requestData), // Send as JSON
	// 		});

	// 		if (response.ok) {
	// 			const result = await response.json();
	// 			if (result.status === true) {
	// 				// toast.success(result.message, {
	// 					onClose: () => window.location.reload(),
	// 				});
	// 				closeModal();
	// 				navigate('/cmMorningTask');
	// 			} else {
	// 				////toast.error(
	// 					'Message : ' + result.message ||
	// 						'An error occurred while adding the User.',
	// 				);
	// 			}
	// 		} else {
	// 			////toast.error('Error: ' + response.status + ' - ' + response.statusText);
	// 		}
	// 	} catch (error) {
	// 		////toast.error('Failed to submit form');
	// 	}
	// };

	const handleSelectCluster = (e) => {
		const selectedClusterId = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			cluster: selectedClusterId,
			cluster_id: selectedClusterId,
		}));

		sessionStorage.setItem('selectedClusterId', selectedClusterId);
		console.log('Cluster ID stored in session:', selectedClusterId);
	};

	const [submittedPayload, setSubmittedPayload] = useState(null); // State to store the submitted payload

	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			// Prepare the request payload in JSON format with updated mtDetails
			const requestData = {
				...mtDetails[0], // Assuming you're sending only the first item for simplicity.
				...formData, // Include formData in the request payload
			};

			// Show the payload in the state before sending
			setSubmittedPayload(requestData);

			const mtResponse = await fetch(
				`${process.env.REACT_APP_API_URL}api/saveCm_morningtaskDetails`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the updated data to send
				},
			);

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {
				console.log('Data submitted successfully!');
			  window.location.reload() 
			} else {
				console.error('Failed to submit data');
				////toast.error('Failed to submit form');
			}
		} catch (error) {
			console.error('Error submitting data:', error);
			// Show error message
			setSubmitStatus({ message: `Error: ${error.message}`, type: 'error' });
		}
	};
	const initialFormData = {
		cluster: '',
		cluster_id: sessionStorage.getItem('selectedClusterId') || '',
		mt0100: 'Question Number 1 ?',
		mt0101: '',
		mt0102: '',
		mt0200: 'Question Number 2 ?',
		mt0201: '',
		mt0202: '',
		mt0300: 'Question Number 3 ?',
		mt0301: '',
		mt0302: '',
		mt0400: 'Question Number 4 ?',
		mt0401: '',
		mt0402: '',
		mt0500: 'Question Number 5 ?',
		mt0501: '',
		mt0502: '',
		mt0600: 'Question Number 6 ?',
		mt0601: '',
		mt0602: '',
		mt0700: 'Question Number 7 ?',
		mt0701: '',
		mt0702: '',
		mt0800: 'Question Number 8 ?',
		mt0801: '',
		mt0802: '',
		mt0900: 'Question Number 9 ?',
		mt0901: '',
		mt0902: '',
		mt1000: 'Question Number 10 ?',
		mt1001: '',
		mt1002: '',
		branch: '',
		emp_code: userEmp_code,
	};
	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];
	useEffect(() => {
		if (mtDetails && Array.isArray(mtDetails) && mtDetails.length > 0) {
			const mtDetail = mtDetails[0]; // Assuming mtDetails[0] comtains the desired record

			// Dynamically set formData using the mtDetail data
			setFormData((prevState) => ({
				...prevState,
				mid: mtDetail.mid || '',
				cluster_id: mtDetail.cluster_id || '',
				mt0100: mtDetail.mt0100 || 'Question Number 1 ? ',
				mt0101: mtDetail.mt0101 || '',
				mt0200: mtDetail.mt0200 || 'Question Number 2 ? ',
				mt0201: mtDetail.mt0201 || '',
				mt0202: mtDetail.mt0202 || '',
				mt0300: mtDetail.mt0300 || 'Question Number 3 ? ',
				mt0301: mtDetail.mt0301 || '',
				mt0302: mtDetail.mt0302 || '',
				mt0400: mtDetail.mt0400 || 'Question Number 4 ? ',
				mt0401: mtDetail.mt0401 || '',
				mt0402: mtDetail.mt0402 || '',
				mt0500: mtDetail.mt0500 || 'Question Number 5 ? ',
				mt0501: mtDetail.mt0501 || '',
				mt0502: mtDetail.mt0502 || '',
				mt0600: mtDetail.mt0600 || 'Question Number 6 ? ',
				mt0601: mtDetail.mt0601 || '',
				mt0602: mtDetail.mt0602 || '',
				mt0700: mtDetail.mt0700 || 'Question Number 7 ? ',
				mt0701: mtDetail.mt0701 || '',
				mt0702: mtDetail.mt0702 || '',
				mt0800: mtDetail.mt0800 || 'Question Number 8 ? ',
				mt0801: mtDetail.mt0801 || '',
				mt0802: mtDetail.mt0802 || '',
				mt0900: mtDetail.mt0900 || 'Question Number 9 ? ',
				mt0901: mtDetail.mt0901 || '',
				mt0902: mtDetail.mt0902 || '',
				mt1000: mtDetail.mt1000 || 'Question Number 10? ',
				mt1001: mtDetail.mt1001 || '',
				mt1002: mtDetail.mt1002 || '',
			}));
		} else {
			// If no details to edit, set form to initial state for a new task
			setFormData(initialFormData);
		}
	}, [mtDetails]); // Ensure mtDetails is properly updated
	useEffect(() => {
		const fetchMtData = async () => {
			try {
				// Wait for selectedCluster to be available
				const selectedCluster = await new Promise((resolve) => {
					const interval = setInterval(() => {
						const cluster = sessionStorage.getItem('selectedClusterId');
						if (cluster) {
							clearInterval(interval);
							resolve(cluster);
						}
					}, 100);
				});

				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedCluster: selectedCluster,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/getCmMorningTaskDetails`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		};

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

	useEffect(() => {
		if (selectedDate === today) {
			if (selectedCluster && (!mtDetails || mtDetails.length === 0)) {
				const createDefaultTask = async () => {
					const requestData = {
						createdDTM: selectedDate,
						cluster_id: selectedCluster,
						...initialFormData,
					};

					try {
						const response = await fetch(`${process.env.REACT_APP_API_URL}api/addCmMorningTask`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								Authorization: `Bearer ${token}`,
							},
							body: JSON.stringify(requestData),
						});

						if (response.ok) {
							const result = await response.json();
							if (result.status === true) {
								 
								  window.location.reload() 
							}
						}
					} catch (error) {
						////toast.error('Failed to create default task');
					}
				};

				createDefaultTask();
			}
		}
	}, [selectedCluster, selectedDate, mtDetails, token, initialFormData, today]);

	return (
		<>
			<Header /> <Sidebar /> 
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-3 form-group'>
								<select
									className='form-control form-control-sm'
									name='cluster'
									value={formData.cluster_id || selectedCluster}
									onChange={handleSelectCluster}
								>
									<option value=''>Select Cluster</option>
									{clusterlist.map((r) => (
										<option key={r.id} value={r.cluster_id}>
											{r.cluster}
										</option>
									))}
								</select>
							</div>

							<div className='col-sm-3 form-group'>
								<input
									type='date'
									name='selectDate'
									onChange={selectDate}
									className='form-control form-control-sm'
									placeholder='Select Date'
									max={today}
									value={selectedDate} // Set the default value here
								/>
							</div>
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</div>
				{/* /.content-header */}
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'></div>

										<div className='row '>
											{selectedCluster && selectedDate && (
												<div className='col-sm-8'>
													<h6>
														Morning Check list of Cluster:{' '}
														<span className='blue'>
															{getClusterName(selectedCluster)}{' '}
														</span>{' '}
														Dated: <span className='blue'> {selectedDate}</span>
													</h6>
												</div>
											)}

											{/* Display all fields from mtDetails */}
											<div className='col-sm-8'>
												{/* Display all fields from mtDetails with serial number */}
												{mtDetails && mtDetails.length > 0 && (
													<div className='row'>
														<div className='col-sm-12'>
															<div className='table-responsive'>
																<form
																	className='col-sm-12'
																	onSubmit={handleEditSubmit}
																>
																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0100'
																				id='mt0100'
																				value={formData.mt0100 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0101'
																						id='mt0101'
																						value={formData.mt0101 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0200'
																				id='mt0200'
																				value={formData.mt0200 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0201'
																						id='mt0201'
																						value={formData.mt0201 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0300'
																				id='mt0300'
																				value={formData.mt0300 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0301'
																						id='mt0301'
																						value={formData.mt0301 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0400'
																				id='mt0400'
																				value={formData.mt0400 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0401'
																						id='mt0401'
																						value={formData.mt0401 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0500'
																				id='mt0500'
																				value={formData.mt0500 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0501'
																						id='mt0501'
																						value={formData.mt0501 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0600'
																				id='mt0600'
																				value={formData.mt0600 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0601'
																						id='mt0601'
																						value={formData.mt0601 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0700'
																				id='mt0700'
																				value={formData.mt0700 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0701'
																						id='mt0701'
																						value={formData.mt0701 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0800'
																				id='mt0800'
																				value={formData.mt0800 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0801'
																						id='mt0801'
																						value={formData.mt0801 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt0900'
																				id='mt0900'
																				value={formData.mt0900 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt0901'
																						id='mt0901'
																						value={formData.mt0901 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div
																		className='form-group row p-2 mb-2'
																		style={{
																			background: '#eeeeee',
																		}}
																	>
																		<div className='col-sm-6'>
																			<input
																				className='form-control form-control-sm'
																				type='text'
																				name='mt1000'
																				id='mt1000'
																				value={formData.mt1000 || ''}
																				onChange={handleInputChange}
																				readOnly // <-- Add onChange handler
																			/>
																		</div>
																		<div className='col-12 col-sm-4'>
																			<div className='form-inline'>
																				<div className='form-check w-100'>
																					<input
																						className='form-control form-control-sm'
																						type='text'
																						name='mt1001'
																						id='mt1001'
																						value={formData.mt1001 || ''}
																						onChange={handleInputChange} // <-- Add onChange handler
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																	{selectedDate == today && (
																		<button
																			type='submit'
																			className='btn btn-sm btn-primary'
																		>
																			Submit
																		</button>
																	)}
																</form>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>

						{/* <form onSubmit={handleSubmit}> */}
						<div className='modal-body'>
							<div className='form-group'>
								<table>
									<tr>
										<td>
											{/* <label htmlFor='emp_code'>
													Create New Checklist for Date :
												</label>

												<input
													type='text'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={selectedDate}
													onChange={handleInputChange}
												/>
												<input
													type='text'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={selectedBranch}
													onChange={handleInputChange}
												/> */}
											{/* <input
													type='date'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={formData.checkListDate || ''}
													onChange={handleInputChange}
													max={new Date().toISOString().split('T')[0]} // Sets max date to today
													min={
														new Date(
															new Date().setDate(new Date().getDate() - 4),
														)
															.toISOString()
															.split('T')[0]
													} // Sets min date to 4 days back
												/> */}
										</td>
									</tr>
								</table>
							</div>
						</div>

						<div className='modal-footer'>
							{/* <input type='hidden' name='id' value={formData.id || ''} /> */}
							<button
								type='button'
								className='btn btn-sm btn-secondary'
								onClick={handleCloseModal}
							>
								Close
							</button>
							<button type='submit' className='btn btn-sm btn-primary'>
								Add
							</button>
						</div>
						{/* </form> */}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default CmMorningTask;
