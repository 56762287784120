import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import { useParams, useNavigate } from 'react-router-dom';
import FormSkeleton from '../../components/other/formSkeleton';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import Manage_cluster from '../settings/Manage_cluster';
const EditCmMorningTask = () => {
	const { mid } = useParams();  
	const [midSelected, setMidSelected] = useState(mid); 
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [submitStatus, setSubmitStatus] = useState(null);
	const [selectedCluster, setSelectedCluster] = useState();
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');
	const [selectedBranch, setSelectedBranch] = useState();
	const [selectedDate, setSelectedDate] = useState();
	 ;
	const [mtDetails, setMtDetails] = useState(null);
	const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
	const userEmp_code = userProfile.emp_code;
	const [formData, setFormData] = useState([]);
	const [showSnackbar, setShowSnackbar] = useState(false);
	useEffect(() => {
        if (sessionStorage.getItem('showSnackbar') === 'true') {
            setShowSnackbar(true);
            sessionStorage.removeItem('showSnackbar');
            // Hide snackbar after 3 seconds
            setTimeout(() => setShowSnackbar(false), 2000);
        }
    }, []);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};

	 

	useEffect(() => {
        if (mid) {  setMidSelected(mid); // Set the midSelected state
        } else {  console.error('mid is undefined or invalid');  }
    }, [mid]);

 
	// 	const fetchBranchList = async () => {
	// 		try {
	// 			const response = await fetch(`${process.env.REACT_APP_API_URL}api/getUserBranchList`, {
	// 				method: 'POST',
	// 				headers: {
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 			});
	// 			console.log('API Response:', response); // Log API response
	// 			if (response.ok) {
	// 				const result = await response.json();
	// 				console.log('Parsed API Response:', result);
	// 				if (result.STATUS) {
	// 					setBranchlist(result.data);
	// 				} else {
	// 					////toast.error(
	// 						//result.message ||
	// 						'An error occurred while fetching the Branch list.',
	// 					);
	// 				}
	// 			} else {
	// 				const errorBody = await response.text();
	// 				console.error('Error response:', errorBody);
	// 				////toast.error(`Error: ${response.status} - ${response.statusText}`);
	// 			}
	// 		} catch (error) {
	// 			console.error('Fetch error:', error);
	// 			////toast.error('Failed to fetch branch list');
	// 		} finally {
	// 			setLoading(false); // Ensure loading state is set to false after the fetch
	// 		}
	// 	};

	// 	fetchBranchList();
	// }, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');
		const selectCluster = sessionStorage.getItem('selectedClusterId');
		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}

		if (selectCluster) {
			setSelectedCluster(selectCluster);
		} else if (clusterlist.length > 0) {
			setSelectedCluster(clusterlist[0].cluster_id);
			sessionStorage.setItem('selectedClusterId', clusterlist[0].cluster_id);
		}
	}, [branchlist, clusterlist]);

	 
	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
						sessionStorage.setItem(
							'selectedClusterId',
							result.data[0].cluster_id,
						);
					} else {
						////toast.error( result.message || 	'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				////toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

	const handleCloseModal = () => {
		setShowModal(false);
	};
	 
	const validateEditFormData = () => {
		console.log('Validating form data');

		// if (!formData.mt0101 || formData.mt0101 === '') {
		// 	console.log('Missing value for "is All Manpower in proper dress code?"');
		// 	return false;
		// }
		// More validation checks...

		return true; // If everything is valid
	};
 
	 

	 
	const handleEditSubmit = async (e) => {
		e.preventDefault(); // Prevent the default form submit behavior (page reload)

		try {
			// Prepare the request payload in JSON format with updated mtDetails
			const requestData = {
				...mtDetails[0], // Assuming you're sending only the first item for simplicity.
				...formData, // Include formData in the request payload
			}; 

			const mtResponse = await fetch(
				`${process.env.REACT_APP_API_URL}api/saveCm_morningtaskDetails`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the updated data to send
				},
			);

			if (!mtResponse.ok) {
				throw new Error(`Error: ${mtResponse.status}`);
			}

			const responseData = await mtResponse.json();

			if (responseData.status === true) {
				sessionStorage.setItem('showSnackbar', 'true');	
			  window.location.reload() 
			} else {
				console.error('Failed to submit data');
			 
			}
		} catch (error) {
			console.error('Error submitting data:', error);
			// Show error message
			setSubmitStatus({ message: `Error: ${error.message}`, type: 'error' });
		}
	};
	const initialFormData = {
		cluster: '',
		cluster_id: sessionStorage.getItem('selectedClusterId') || '',
		mt0100: 'Question Number 1 ?',
		mt0101: '',
		mt0102: '',
		mt0200: 'Question Number 2 ?',
		mt0201: '',
		mt0202: '',
		mt0300: 'Question Number 3 ?',
		mt0301: '',
		mt0302: '',
		mt0400: 'Question Number 4 ?',
		mt0401: '',
		mt0402: '',
		mt0500: 'Question Number 5 ?',
		mt0501: '',
		mt0502: '',
		mt0600: 'Question Number 6 ?',
		mt0601: '',
		mt0602: '',
		mt0700: 'Question Number 7 ?',
		mt0701: '',
		mt0702: '',
		mt0800: 'Question Number 8 ?',
		mt0801: '',
		mt0802: '',
		mt0900: 'Question Number 9 ?',
		mt0901: '',
		mt0902: '',
		mt1000: 'Question Number 10 ?',
		mt1001: '',
		mt1002: '',
		branch: '',
		emp_code: userEmp_code,
	};
	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];
	useEffect(() => {
		if (mtDetails && Array.isArray(mtDetails) && mtDetails.length > 0) {
			const mtDetail = mtDetails[0]; // Assuming mtDetails[0] comtains the desired record

			// Dynamically set formData using the mtDetail data
			setFormData((prevState) => ({
				...prevState,
				mid: mtDetail.mid || '',
				cluster_id: mtDetail.cluster_id || '',
				mt0100: mtDetail.mt0100 || 'Question Number 1 ? ',
				mt0101: mtDetail.mt0101 || '',
				mt0200: mtDetail.mt0200 || 'Question Number 2 ? ',
				mt0201: mtDetail.mt0201 || '',
				mt0202: mtDetail.mt0202 || '',
				mt0300: mtDetail.mt0300 || 'Question Number 3 ? ',
				mt0301: mtDetail.mt0301 || '',
				mt0302: mtDetail.mt0302 || '',
				mt0400: mtDetail.mt0400 || 'Question Number 4 ? ',
				mt0401: mtDetail.mt0401 || '',
				mt0402: mtDetail.mt0402 || '',
				mt0500: mtDetail.mt0500 || 'Question Number 5 ? ',
				mt0501: mtDetail.mt0501 || '',
				mt0502: mtDetail.mt0502 || '',
				mt0600: mtDetail.mt0600 || 'Question Number 6 ? ',
				mt0601: mtDetail.mt0601 || '',
				mt0602: mtDetail.mt0602 || '',
				mt0700: mtDetail.mt0700 || 'Question Number 7 ? ',
				mt0701: mtDetail.mt0701 || '',
				mt0702: mtDetail.mt0702 || '',
				mt0800: mtDetail.mt0800 || 'Question Number 8 ? ',
				mt0801: mtDetail.mt0801 || '',
				mt0802: mtDetail.mt0802 || '',
				mt0900: mtDetail.mt0900 || 'Question Number 9 ? ',
				mt0901: mtDetail.mt0901 || '',
				mt0902: mtDetail.mt0902 || '',
				mt1000: mtDetail.mt1000 || 'Question Number 10? ',
				mt1001: mtDetail.mt1001 || '',
				mt1002: mtDetail.mt1002 || '',
			}));
		} else {
			// If no details to edit, set form to initial state for a new task
			setFormData(initialFormData);
		}
	}, [mtDetails]); // Ensure mtDetails is properly updated
	
	useEffect(() => {
		const fetchMtData = async () => {
			try {
				// Wait for selectedCluster to be available
				const selectedCluster = await new Promise((resolve) => {
					const interval = setInterval(() => {
						const cluster = sessionStorage.getItem('selectedClusterId');
						if (cluster) {
							clearInterval(interval);
							resolve(cluster);
						}
					}, 100);
				});

				// Prepare the request payload in JSON format
				const requestData = {
					mid: midSelected,
					 
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(
					`${process.env.REACT_APP_API_URL}api/getCmMorningTaskDetailsNew`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json', // Set content-type as JSON
							Authorization: `Bearer ${token}`, // Ensure correct format
						},
						body: JSON.stringify(requestData), // Stringify the JSON payload
					},
				);

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		};

		fetchMtData();
	}, [token, mid]);  
 
	return (
		<>
			<Header /> <Sidebar /> 
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				 
				{/* Main content */}
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'style={{minHeight:'630px'}}>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<div className='row'></div>
										{showSnackbar && <div className="snackbar">Form Submitted Successfully!</div>}
										<form onSubmit={(e) => handleEditSubmit(e)} className='row '>
											 
											{/* Display all fields from mtDetails */}
											<div className='col-sm-8'>
												{mtDetails && mtDetails.length > 0 ? (
													<>
														{mtDetails.map((task, index) => (
															<React.Fragment key={`${task.id}-${index}`}>
															<div className='row'>
															<div className='col-12 col-md-12'>
															<p className='mt-2 pp1'>
																Dated: {new Date(task.createdDTM).toLocaleDateString('en-US', {
																	day: 'numeric',
																	month: 'short',
																	year: 'numeric'   
																})}
															&nbsp; By: {task.fname} {task.lname}
															&nbsp; Cluster: {task.cluster_name}
															</p>


																</div>
																<div className='col-12 col-md-12'>
																<p style={{ color:'blue',borderRadius:'5px', border:'1px solid #cccccc',width:'280px',background:'#eeeeee', padding:'5px'}} className='mt-2  pp1'>																		 
																	<i className="fas fa-users bg-danger  mr-2"></i> Subject Group																	 
																	</p>
																</div>
																<div className='col-12 col-md-6 '>
																	<p><i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0100} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																<div className="input-group">
																	<select
																		className={`form-control form-control-sm ${
																			!formData.mt0101 && !task.mt0101 ? 'empty-field' : ''
																		  }`}
																		name="mt0101"
																		id="mt0101"
																		value={formData.mt0101 || task.mt0101 || ''}
																		onChange={handleInputChange}
																	>
																		<option value="">Select</option> {/* Default empty option */}
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>
																</div>

																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																	 
																		 
																		<textarea
																			name='mt0102'
																			id='mt0102'
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"
																			value={formData.mt0102 ||task.mt0102 || ''} // Set the default value here
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>
																	</div>

																</div>

																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0200} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																			className={`form-control form-control-sm ${
																				!formData.mt0201 && !task.mt0201 ? 'empty-field' : ''
																			  }`}
																			name='mt0201'
																				id='mt0201'
																				value={
																					formData.mt0201 || task.mt0201 || ''
																				}
																			onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																		</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																	 
																	 		<textarea
																			name='mt0202'
																			id='mt0202'
																			value={
																				formData.mt0202 || task.mt0202 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>
															</div>
															<div key={index} className='row'>
																<div className='col-12 col-md-12'>
																<p style={{color:'blue',borderRadius:'5px', border:'1px solid #cccccc',width:'280px',background:'#eeeeee', padding:'5px'}} className='mt-2 pp1'>																			 
																	<i className="fas fa-tools bg-danger  mr-2 "></i>	Subject Group 2 																 
																	</p>
																</div>
																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0300} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt0301 && !task.mt0301 ? 'empty-field' : ''
																		  }`}
																		name='mt0301'
																			id='mt0301'
																			value={
																				formData.mt0301 || task.mt0301 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt0302'
																			id='mt0302'
																			value={
																				formData.mt0302 || task.mt0302 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>

																{/* //Are all Biomedical equiment working */}
																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0500} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt0501 && !task.mt0501 ? 'empty-field' : ''
																		  }`}
																		name='mt0501'
																			id='mt0501'
																			value={
																				formData.mt0501 || task.mt0501 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt0502'
																			id='mt0502'
																			value={
																				formData.mt0502 || task.mt0502 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>

																{/* Are there Maintainance Observation */}
																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0800} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt0801 && !task.mt0801 ? 'empty-field' : ''
																		  }`}
																		name='mt0801'
																			id='mt0801'
																			value={
																				formData.mt0801 || task.mt0801 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt0802'
																			id='mt0802'
																			value={
																				formData.mt0802 || task.mt0802 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>

															</div>
 
															<div key={index} className='row'>
																<div className='col-12 col-md-12'>
																<p style={{color:'blue',borderRadius:'5px', border:'1px solid #cccccc',width:'280px',background:'#eeeeee', padding:'5px'}} className='mt-2 pp1'>																		 
																	<i className="fas fa-tasks  bg-danger mr-2 "></i>	Subject Group 3																 
																	</p>
																</div>

																{/* is Doc Avaialble or Oncall Doc arranged? */}
																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0600} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																			className={`form-control form-control-sm ${
																				!formData.mt0601 && !task.mt0601 ? 'empty-field' : ''
																			  }`}
																			name='mt0601'
																				id='mt0601'
																				value={
																					formData.mt0601 || task.mt0601 || ''
																				}
																			onChange={handleInputChange}
																			>
																			<option value="">Select</option>  
																			<option value="Yes">Yes</option>
																			<option value="No">No</option>
																		</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt0602'
																			id='mt0602'
																			value={
																				formData.mt0602 || task.mt0602 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>

																{/* Previous day pending reports */}

																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0700} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt0701 && !task.mt0701 ? 'empty-field' : ''
																		  }`}
																		name='mt0701'
																			id='mt0701'
																			value={
																				formData.mt0701 || task.mt0701 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt0702'
																			id='mt0702'
																			value={
																				formData.mt0702 || task.mt0702 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>

																{/* is IT Portal updated with upto date information? */}

																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt1000} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt1001 && !task.mt1001 ? 'empty-field' : ''
																		  }`}
																		name='mt1001'
																			id='mt1001'
																			value={
																				formData.mt1001 || task.mt1001 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt1002'
																			id='mt1002'
																			value={
																				formData.mt1002 || task.mt1002 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>

															</div>

															<div key={index} className='row'>
																<div className='col-12 col-md-12'  >
																	<p style={{color:'blue',borderRadius:'5px', border:'1px solid #cccccc',width:'280px',background:'#eeeeee', padding:'5px'}} className='mt-2 pp1'>																		 
																	<i className="fas fa-info-circle bg-danger mr-2 ml-2 "></i>	Subject Group 4														 
																	</p>
																</div>
																<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0400} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt0401 && !task.mt0401 ? 'empty-field' : ''
																		  }`}
																		name='mt0401'
																			id='mt0401'
																			value={
																				formData.mt0401 || task.mt0401 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																 
																		<div className='col-12 col-md-6 '>
																	<p> <i className="fas fa-circle fa-1x mr-2" style={{color:'#ebd729'}} ></i> {task.mt0900} : </p>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																		 
																		 
																		<select
																		className={`form-control form-control-sm ${
																			!formData.mt0901 && !task.mt0901 ? 'empty-field' : ''
																		  }`}
																		name='mt0901'
																			id='mt0901'
																			value={
																				formData.mt0901 || task.mt0901 || ''
																			}
																		onChange={handleInputChange}
																		>
																		<option value="">Select</option>  
																		<option value="Yes">Yes</option>
																		<option value="No">No</option>
																	</select>

																	</div>
																</div>
																<div className='col-12 col-md-3 mb-2'>
																	<div className='input-group'>																 
																		
																		<textarea
																			name='mt0902'
																			id='mt0902'
																			value={
																				formData.mt0902 || task.mt0902 || ''
																			}
																			onChange={handleInputChange}
																			className="form-control form-control-sm"
																			placeholder="Enter Description"																			
																			rows="1" // Adjust the number of rows to fit the field
																			maxLength="200" // Limit input length to 10 (for 'YYYY-MM-DD')
																		/>

																	</div>
																</div>
																<div className='col-12 col-md-12 mb-2'>
																	<div className='input-group'>																 
																	<input
																			name='mid'
																			id='mid'
																			value={
																				formData.mid || task.mid || ''
																			}
																			type='hidden'
																			 
																		/>
																		{(task.createdDTM === today && role === 'CM') && (
																	<button
																			type='submit'
																			className='btn btn-sm btn-primary'
																		>
																			Submit
																		</button>
																		)}
																	</div>
																</div>
																
															</div>
														</React.Fragment>
															
														))}
													</>
												) : (
													<div style={{minHeight:'630px'}}>
													<FormSkeleton />
													</div>
												)}
											</div>

										</form>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>

						{/* <form onSubmit={handleSubmit}> */}
						<div className='modal-body'>
							<div className='form-group'>
								<table>
									<tr>
										<td>
											{/* <label htmlFor='emp_code'>
													Create New Checklist for Date :
												</label>

												<input
													type='text'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={selectedDate}
													onChange={handleInputChange}
												/>
												<input
													type='text'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={selectedBranch}
													onChange={handleInputChange}
												/> */}
											{/* <input
													type='date'
													id='checkListDate'
													className='form-control form-control-sm'
													name='checkListDate'
													value={formData.checkListDate || ''}
													onChange={handleInputChange}
													max={new Date().toISOString().split('T')[0]} // Sets max date to today
													min={
														new Date(
															new Date().setDate(new Date().getDate() - 4),
														)
															.toISOString()
															.split('T')[0]
													} // Sets min date to 4 days back
												/> */}
										</td>
									</tr>
								</table>
							</div>
						</div>

						<div className='modal-footer'>
							{/* <input type='hidden' name='id' value={formData.id || ''} /> */}
							<button
								type='button'
								className='btn btn-sm btn-secondary'
								onClick={handleCloseModal}
							>
								Close
							</button>
							<button type='submit' className='btn btn-sm btn-primary'>
								Add
							</button>
						</div>
						{/* </form> */}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default EditCmMorningTask;
