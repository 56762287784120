import React, { useEffect , useState} from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate, 
} from 'react-router-dom';
import { useAuth } from './context/authprovider';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Welcome from './pages/Welcome';
import Manage_branch from './pages/settings/Manage_branch';
import Manage_cluster from './pages/settings/Manage_cluster';
import Manage_user from './pages/settings/Manage_user';
import Manage_area from './pages/settings/Manage_area';
import Morningtask from './pages/Morningtask';
import Nighttask from './pages/Nighttask';
import CmTask from './pages/cm/CmTask';
import BmTask from './pages/bm/BmTask';
import BmGrid from './pages/bm/BmGrid';
import BmNightGrid from './pages/bm/BmNightGrid';
import EditBmMorningTask from './pages/bm/EditBmMorningTask';
import EditBmNightTask from './pages/bm/EditBmNightTask';
import AddBmMorningTask from './pages/bm/AddBmMorningTask';
import AddBmNightTask from './pages/bm/AddBmNightTask';
import CmMorningTask from './pages/cm/CmMorningTask';
import CmNightTask from './pages/cm/CmNightTask';
import BmNightTask from './pages/bm/BmNightTask';
import Manage_cluster_map from './pages/settings/Manage_cluster_map';
import Manage_zone_map from './pages/settings/Manage_zone_map';
import Manage_zone from './pages/settings/Manage_zone';
import Zonal from './pages/zonel_manager/Zonal';
import Zcm_list from './pages/zonel_manager/Zcm_list';
import AvpBranch from './pages/avp/AvpBranch';
import AvpCm from './pages/avp/AvpCm';
import BM_Dashboard from './pages/bm/BM_Dashboard';
import CM_Dashboard from './pages/cm/CM_Dashboard'; 
import AVP_Dashboard from './pages/avp/AVP_Dashboard';
import ZONAL_Dashboard from './pages/zonel_manager/ZONAL_Dashboard';
import CmGrid from './pages/cm/CmGrid';
import CmNightGrid from './pages/cm/CmNightGrid';
import Bmc_morningGrid from './pages/cm/Bmc_morningGrid';
import Bmc_NightGrid from './pages/cm/Bmc_NightGrid';
import EditCmMorningTask from './pages/cm/EditCmMorningTask';
import EditCmNightTask from './pages/cm/EditCmNightTask';
import EditBmWeeklyTask from './pages/bm/EditBmWeeklyTask';
import BmWeeklyGrid from './pages/bm/BmWeeklyGrid';
import Bm_z_NightGrid from './pages/zonel_manager/Bm_z_NightGrid';
import Bm_z_morningGrid from './pages/zonel_manager/Bm_z_morningGrid';
import Changepassword from './pages/Changepassword';
import Cm_z_Grid from './pages/zonel_manager/Cm_z_morningGrid';
import Cm_z_NightGrid from './pages/zonel_manager/Cm_z_NightGrid';
import Bmc_WeeklyGrid from './pages/cm/Bmc_WeeklyGrid';
import Bmc_CombinedGrid from './pages/cm/Bmc_CombinedGrid';
import BmCombinedGrid from './pages/bm/BmCombinedGrid';
import Bm_z_CombinedGrid from './pages/zonel_manager/Bm_z_CombinedGrid';
import Bm_z_weeklyGrid from './pages/zonel_manager/Bm_z_WeeklyGrid';
import { use } from 'react';

function App() {
	 
	const { role, isAuthenticated, logout } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) return <div>Loading...</div>;

    // Define access based on roles
    const isBM = role === 'BM';
    const isCM = role === 'CM';
    const isAVP = role === 'AVP';
    const isZonalM = role === 'ZONAL_MANAGER';
    const hasAccess = role === 'SUPER_ADMIN';
	return (
		<Router>
			<Routes>
				<Route path='/dashboard' element={<Dashboard />} />
				<Route path='/CM_Dashboard' element={<CM_Dashboard />} />
				<Route path='/ZONAL_Dashboard' element={<ZONAL_Dashboard />} />
				<Route path='/AVP_Dashboard' element={<AVP_Dashboard />} />
				<Route path='/' element={<Welcome />} />
				<Route path='/login' element={<Login />} />
				<Route
					path='/manage_branch'
					element={
						hasAccess ? <Manage_branch /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/manage_cluster'
					element={
						hasAccess ? <Manage_cluster /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/manage_users'
					element={
						hasAccess ? <Manage_user /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/manage_area'
					element={
						hasAccess ? <Manage_area /> : <Navigate to='/dashboard' replace />
					}
				/>

				<Route
					path='/cluster_map'
					element={
						hasAccess ? <Manage_cluster_map /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/zone_map'
					element={
						hasAccess ? <Manage_zone_map /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/manage_zone'
					element={
						hasAccess ? <Manage_zone /> : <Navigate to='/dashboard' replace />
					}
				/>
				
				<Route path='/morningtask' element={<Morningtask />} />
				<Route path='/nighttask' element={<Nighttask />} />
				<Route
					path='/cmMorningTask'
					element={
						isCM ? <CmMorningTask /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/cmNightTask'
					element={
						isCM ? <CmNightTask /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/cmGrid'
					element={
						isCM ? <CmGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/Bmc_morningGrid'
					element={
						isCM ? <Bmc_morningGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/Bmc_NightGrid'
					element={
						isCM ? <Bmc_NightGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/Bmc_WeeklyGrid'
					element={
						isCM ? <Bmc_WeeklyGrid /> : <Navigate to='/dashboard' replace />
					}
				/>

				<Route
					path='/zonallist'
					element={isZonalM ? <Zonal /> : <Navigate to='/dashboard' replace />}
				/>

				<Route
					path='/zcm_list'
					element={
						isZonalM ? <Zcm_list /> : <Navigate to='/dashboard' replace />
					}
				/>

				<Route
					path='/Bm_z_morningGrid'
					element={
						(isZonalM || isAVP)? <Bm_z_morningGrid /> : <Navigate to='/dashboard' replace />
					}
				/>

				<Route
					path='/Bm_z_CombinedGrid'
					element={
						(isZonalM || isAVP)? <Bm_z_CombinedGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
					<Route
					path='/Bm_z_weeklyGrid'
					element={
						(isZonalM || isAVP)? <Bm_z_weeklyGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				
				<Route
					path='/Cm_z_morningGrid'
					element={
						(isZonalM || isAVP) ? <Cm_z_Grid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/Bm_z_NightGrid'
					element={
						(isZonalM || isAVP) ? <Bm_z_NightGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/Cm_z_NightGrid'
					element={
						(isZonalM || isAVP) ? <Cm_z_NightGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route 
					path='/cmtask'
					element={isCM ? <CmTask /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/EditCmMorningTask/:mid'
					element={(isCM || isZonalM) ? <EditCmMorningTask /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/EditCmNightTask/:cm_nid'
					element={(isCM || isZonalM) ? <EditCmNightTask /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/cmnightgrid'
					element={isCM ? <CmNightGrid /> : <Navigate to='/dashboard' replace />}
				/>
					<Route
					path='/bmccombogrid'
					element={isCM ? <Bmc_CombinedGrid /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/BM_Dashboard'
					element={isBM ? <BM_Dashboard /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/EditBmMorningTask/:mid'
					element={(isBM || isCM || isZonalM || isAVP) ? <EditBmMorningTask /> : <Navigate to='/dashboard' replace />}
				/>

				<Route
					path='/EditBmNightTask/:nid'
					element={(isBM || isCM || isZonalM || isAVP) ? <EditBmNightTask /> : <Navigate to='/dashboard' replace />}
				/>

				<Route
					path='/EditBmWeeklyTask/:bmw_id'
					element={(isBM || isCM || isZonalM || isAVP) ? <EditBmWeeklyTask /> : <Navigate to='/dashboard' replace />}
				/>

				<Route
					path='/BmWeeklyGrid'
					element={(isBM) ? <BmWeeklyGrid /> : <Navigate to='/dashboard' replace />}
				/>


				<Route
					path='/AddBmMorningTask'
					element={isBM ? <AddBmMorningTask /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/AddBmNightTask'
					element={isBM ? <AddBmNightTask /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/bmgrid'
					element={isBM ? <BmGrid /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/bmcombogrid'
					element={isBM ? <BmCombinedGrid /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/bmnightgrid'
					element={
						isBM ? <BmNightGrid /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/bmnighttask'
					element={
						isBM ? <BmNightTask /> : <Navigate to='/dashboard' replace />
					}
				/>
				<Route
					path='/cmtask'
					element={isAVP ? <CmTask /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/avpBranch'
					element={isAVP ? <AvpBranch /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/avpCm'
					element={isAVP ? <AvpCm /> : <Navigate to='/dashboard' replace />}
				/>
				<Route
					path='/changePassword'
					element= {<Changepassword />}  />				
			</Routes>
		</Router>
	);
}

export default App;
