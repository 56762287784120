import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';


import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FormSkeleton from '../../components/other/formSkeleton';
const Bm_z_CombinedGrid = () => {
  const { role } = useAuth();

  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const [clusterlist, setClusterlist] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [zoneslist, setZoneslist] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState();
  const [selectedZoneId, setSelectedZoneId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showNModal, setShowNModal] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [m_excelData, setM_ExcelData] = useState([]);
  const [n_excelData, setN_ExcelData] = useState([]);

  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const userEmp_code = userProfile.emp_code;
  // Get today's date in YYYY-MM-DD format
  const today = new Date();
  const thisMonth = today.getMonth() + 1;
  const thisYear = today.getFullYear();
  const thisMonthWithYear = `${thisYear}-${String(thisMonth).padStart(2, '0')}`;

  const todayDate = today.toISOString().split('T')[0];
  const [selectedBranch, setSelectedBranch] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const yesterdayDate = yesterday.toISOString().split('T')[0];
  // const handlePageChange = (pageNumber) => {
  // 	setCurrentPage(pageNumber);
  // };
  const [items, setItems] = useState([]);
  const [mItems, setMItems] = useState([]);
  const [nItems, setNItems] = useState([]);


  const selectBranch = (e) => {
    const branch = e.target.value;
    setSelectedBranch(branch);
    sessionStorage.setItem('selectedBranchId', branch); // Store in sessionStorage
    window.location.reload();
  };
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };
  const selectDate = (e) => {
    const sDate = e.target.value;
    setSelectedDate(sDate);
    sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
  };
  const selectMonth = (e) => {
    const sMonth = e.target.value;
    setSelectedMonth(sMonth);
    sessionStorage.setItem('selectedMonth', sMonth); // Store in sessionStorage
    window.location.reload();
  };

  const handleSelectZone = (e) => {
    const zoneId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      zone_id: selectedZoneId,
    }));
    setSelectedZoneId(zoneId);
    sessionStorage.setItem('selectedZoneId', zoneId); // Store in sessionStorage
  };

  const handleSelectCluster = (e) => {
    const selectedClusterId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      cluster: selectedClusterId,
      cluster_id: selectedClusterId,
    }));

    sessionStorage.setItem('selectedClusterId', selectedClusterId);
    //console.log('Cluster ID stored in session:', selectedClusterId);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setShowNModal(false);
  };


  useEffect(() => {
    const fetchClusterList = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log('API Response:', response); // Log API response
        if (response.ok) {
          const result = await response.json();
          console.log('Parsed API Response:', result);
          if (result.STATUS) {
            // Check the correct key
            setClusterlist(result.data);
          } else {

          }
        } else {
          const errorBody = await response.text();
          console.error('Error response:', errorBody);
          ////toast.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        ////toast.error('Failed to fetch Cluster list');
      } finally {
        setLoading(false); // Ensure loading state is set to false after the fetch
      }
    };

    fetchClusterList();
  }, [token]);

  // Fetch the list of zones
  useEffect(() => {
    async function fetchRoleData() {
      try {
        const rolesResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getUserZones`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`, // Ensure correct format
          },
        });
        if (!rolesResponse.ok) {
          throw new Error(`Error: ${rolesResponse.status}`);
        }
        const roleData = await rolesResponse.json();

        if (roleData && Array.isArray(roleData.data)) {
          console.log(`Zone data: ${roleData.data}`);
          setZoneslist(roleData.data); // Make sure data is an array
        } else {
          console.error('No valid data received');
          setZoneslist([]); // Empty array if no valid data
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
        setZoneslist([]); // Empty array on error
      }
    }

    fetchRoleData();
  }, [token]);

  // Fetch the list of clusters
  useEffect(() => {
    const fetchClusterList = async () => {
      try {
        const selectedZoneId = sessionStorage.getItem('selectedZoneId'); // Retrieve cluster ID from session storage
        // Create FormData and append the selectedClusterId
        const formData = new FormData();
        formData.append('zone_id', selectedZoneId);

        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getZoneClusterList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        console.log('API Response:', response); // Log the response
        if (response.ok) {
          const result = await response.json();
          console.log('Parsed API Response:', result);
          if (result.STATUS) {
            setClusterlist(result.data);
          } else {

          }
        } else {
          const errorBody = await response.text();
          console.error('Error response:', errorBody);
          ////toast.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        ////toast.error('Failed to fetch Cluster list');
      } finally {
        setLoading(false); // Ensure loading state is set to false after the fetch
      }
    };

    if (token && sessionStorage.getItem('selectedZoneId')) {
      fetchClusterList();
    }
  }, [token, sessionStorage.getItem('selectedZoneId')]); // Dependency array

  // Fetch the list of branches
  useEffect(() => {
    const fetchBranchList = async () => {
      try {
        const selectedClusterId = sessionStorage.getItem('selectedClusterId'); // Retrieve cluster ID from session storage

        // Log the selectedClusterId to confirm its value
        console.log('Retrieved selectedClusterId:', selectedClusterId);

        // Ensure selectedClusterId is not null or undefined
        if (!selectedClusterId) {
          console.warn('No cluster ID found in session storage.');
          ////toast.error('Cluster ID is missing. Please select a cluster.');
          setLoading(false);
          return;
        }

        // Create FormData and append the selectedClusterId
        const formData = new FormData();
        formData.append('cluster_id', selectedClusterId);

        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getClusterBranchList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        console.log('API Response:', response); // Log the response
        if (response.ok) {
          const result = await response.json();
          console.log('Parsed API Response:', result);
          if (result.STATUS) {
            setBranchlist(result.data);
          } else {

          }
        } else {
          const errorBody = await response.text();
          console.error('Error response:', errorBody);
          ////toast.error(`Error: ${response.status} - ${response.statusText}`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        ////toast.error('Failed to fetch branch list');
      } finally {
        setLoading(false); // Ensure loading state is set to false after the fetch
      }
    };

    if (token && sessionStorage.getItem('selectedClusterId')) {
      fetchBranchList();
    }
  }, [token, sessionStorage.getItem('selectedClusterId')]); // Dependency array

  useEffect(() => {
    const selectedClusterId = sessionStorage.getItem('selectedClusterId');
    if (selectedClusterId) {
      setFormData((prevData) => ({
        ...prevData,
        cluster: selectedClusterId,
        cluster_id: selectedClusterId,
      }));
      console.log('Retrieved Cluster ID from session:', selectedClusterId);
    }
  }, []);

 

  useEffect(() => {
    const selectDate = sessionStorage.getItem('selectedDate');
    const selectBranch = sessionStorage.getItem('selectedBranchId');
    const selectCluster = sessionStorage.getItem('selectedClusterId');
		const selectedZoneId = sessionStorage.getItem('selectedZoneId');
    const selectMonth = sessionStorage.getItem('selectedMonth') || thisMonthWithYear;
    // Set the current date if selectedDate is empty
    if (selectDate) {
      setSelectedDate(selectDate);
    } else {
      const today = new Date().toISOString().split('T')[0];
      setSelectedDate(today);
    }

    setSelectedMonth(selectMonth);

    // Check if selectedBranch exists, otherwise set default from branchlist once it's available
    if (selectBranch) {
      setSelectedBranch(selectBranch);
    } else if (branchlist.length > 0) {
      setSelectedBranch('0');
      sessionStorage.setItem('selectedBranchId', '0');
    }

    if (selectCluster) {
      setSelectedCluster(selectCluster);
    } else if (clusterlist.length > 0) {
      setSelectedCluster(clusterlist[0].cluster_id);
      sessionStorage.setItem('selectedCluster', clusterlist[0].cluster_id);
    }
  
    if (selectedZoneId) {
      setSelectedZoneId(selectedZoneId);
    } else if (zoneslist.length > 0) {
      setSelectedZoneId(zoneslist[0].zone_id);
      sessionStorage.setItem('selectedZoneId', zoneslist[0].zone_id);
    }
  
  }, [branchlist, thisMonthWithYear]);

 

  const initialFormData = {
    mid: '',
    cluster: '',
    cluster_id: '',
    mt0100: 'is All Manpower Available?',
    mt0101: '',
    mt0102: '',
    mt0200: 'is All Manpower in proper dress code?',
    mt0201: '',
    mt0202: '',
    mt0300: 'Generator Check',
    mt0301: '',
    mt0302: '',
    mt0400: 'is Pooja Done',
    mt0401: '',
    mt0402: '',
    mt0500: 'Are all Biomedical equiment working',
    mt0501: '',
    mt0502: '',
    mt0600: 'is Doc Avaialble or Oncall Doc arranged?',
    mt0601: '',
    mt0602: '',
    mt0700: 'Previous day pending reports',
    mt0701: '',
    mt0702: '',
    mt0800: 'Are there Maintainance Observation',
    mt0801: '',
    mt0802: '',
    mt0900: 'is front door glass cleaned well?',
    mt0901: '',
    mt0902: '',
    mt1000: 'is IT Portal updated with upto date information?',
    mt1001: '',
    mt1002: '',
    branch: '',
    emp_code: userEmp_code,
    createdDTM: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const n_initialFormData = {
    nid: '',
    cluster: '',
    cluster_id: '',
    nt0100: 'Branch Closing time as per last update form branch',
    nt0101: '',
    nt0102: '',
    nt0200: 'Day-End Report Tally - Total Revenue',
    nt0201: '',
    nt0202: '',
    nt0300: 'Day-End Report Tally - Cash',
    nt0301: '',
    nt0302: '',
    nt0400: 'Day-End Report Tally - Paytm',
    nt0401: '',
    nt0402: '',
    nt0500: 'Day-End Report Tally - Card',
    nt0501: '',
    nt0502: '',
    nt0600: 'Total USG Done',
    nt0601: '',
    nt0602: '',
    nt0700: 'Total CT Done',
    nt0701: '',
    nt0702: '',
    nt0800: 'Total MRI Done',
    nt0801: '',
    nt0802: '',
    nt0900: 'Total X-Ray Done',
    nt0901: '',
    nt0902: '',
    nt1000: 'PDR Cases',
    nt1001: '',
    nt1002: '',
    nt1100: 'PDR Amount',
    nt1101: '',
    nt1200: 'Total Refunds (Count)',
    nt1201: '',
    nt1300: 'Total Refunds (Amount)',
    nt1301: '',
    nt1400: 'is Petty cash handling clear?',
    nt1401: '',
    nt1500: 'Logists/Sample Pickup: is sample collection done a per schedule?',
    nt1501: '',
    nt1600: 'is all the stock avaiability is in line with branch business',
    nt1601: '',
    nt1700: 'is All V-Mitr Notifications cleared?',
    nt1701: '',
    nt1800: 'Google ratings collected in the day',
    nt1801: '',
    nt1900: 'Cross Billings done (USG/CT/MRI/X-ray/PET CT only)',
    nt1901: '',
    nt2000: 'Doctor Productivity',
    nt2001: '',
    nt2100: 'Doctor 1 BMID',
    nt2101: '',
    nt2200: 'Doctor 1 Name',
    nt2201: '',
    nt2300: 'USG Done -Doc 1',
    nt2301: '',
    nt2400: 'CT Done -Doc 1',
    nt2401: '',
    nt2500: 'MRI Done -Doc 1',
    nt2501: '',
    nt2600: 'X-Ray Done -Doc 1',
    nt2601: '',
    nt2700: 'Doctor 2 BMID',
    nt2701: '',
    nt2800: 'Doctor 2 Name',
    nt2801: '',
    nt2900: 'USG Done -Doc 2',
    nt2901: '',
    nt3000: 'CT Done -Doc 2',
    nt3001: '',
    nt3100: 'MRI Done -Doc 2 ',
    nt3101: '',
    nt3200: 'X-Ray Done -Doc 2',
    nt3201: '',
    nt3300: 'Doctor 3 BMID',
    nt3301: '',
    nt3400: 'Doctor 3 Name',
    nt3401: '',
    nt3500: 'USG Done -Doc 3',
    nt3501: '',
    nt3600: 'CT Done -Doc 3',
    nt3601: '',
    nt3700: 'MRI Done -Doc 3',
    nt3701: '',
    nt3800: 'X-Ray Done -Doc 3',
    nt3801: '',
    nt3900: 'Cardiologist Name',
    nt3901: '',
    nt4000: 'TMT Done',
    nt4001: '',
    nt4100: 'ECG Done',
    nt4101: '',
    branch: '',
    emp_code: userEmp_code,
    createdDTM: '',
  };

  const [nformData, setNFormData] = useState(n_initialFormData);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value, // Update the formData state with the selected date
  //   }));
  // };

  // const nhandleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line
  //   setNFormData((prevNFormData) => ({
  //     ...prevNFormData,
  //     [name]: value, // Update the formData state with the selected date
  //   }));
  // };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const requestData = {
  //     ...formData, // Get the current form data, including the date and other values
  //     createdDTM: formData.createdDTM,  // Ensure createdDTM is included
  //     branch_id: sessionStorage.getItem('selectedBranchId'),  // Add branch_id from session storage or wherever it is stored
  //   };

  //   console.log('Request Data:', requestData); // Log the request data for debugging

  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/addMorningTask`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     if (response.ok) {
  //       const result = await response.json();
  //       if (result.status === true) {
  //         navigate(`/EditBmMorningTask/${result.data}`);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     // Optionally add error handling like toast notifications
  //   }
  // };

  // const nhandleCloseModal = () => {
  //   setShowModal(false);
  // };
  // const nhandleSubmit = async (e) => {
  //   e.preventDefault();

  //   const requestData = {
  //     ...nformData, // Get the current form data, including the date and other values
  //     createdDTM: nformData.createdDTM,  // Ensure createdDTM is included
  //     branch_id: sessionStorage.getItem('selectedBranchId'),  // Add branch_id from session storage or wherever it is stored
  //   };

  //   console.log('Request Data:', requestData); // Log the request data for debugging

  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_API_URL}api/addNightTask`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     if (response.ok) {
  //       const result = await response.json();
  //       if (result.status === true) {
  //         navigate(`/EditBmNightTask/${result.data}`);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     // Optionally add error handling like toast notifications
  //   }
  // };

  useEffect(() => {
    const fetchMorningTasks = async () => {
      const requestData = {
        selectedMonth: selectedMonth,
        selectedBranch: selectedBranch,
        selectedCluster: selectedCluster,
      };
      try {
        setLoading(true); // Set loading to true while fetching
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCM_BranchMorningTaskList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const result = await response.json();

          // Access result.data.data to get the actual array
          if (Array.isArray(result.data.data)) {
            setM_ExcelData(result.data.data);
            setMItems(result.data.data);
          } else {
            ////toast.error('Fetched data is not an array');
            setM_ExcelData([]);
          }


        } else {
          // navigate(`/login`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        // navigate(`/login`);

      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchMorningTasks();
  }, [token, navigate, selectedMonth, selectedBranch, selectedCluster]); // Trigger the fetch when token, navigate, or selectedDate changes

  useEffect(() => {
    const fetchNightTasks = async () => {
      const requestData2 = {
        selectedMonth: selectedMonth,
        selectBranch: selectedBranch,
        selectedCluster: selectedCluster,
      };
      try {
        setLoading(true); // Set loading to true while fetching
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCM_BranchNightTaskList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData2),
        });

        if (response.ok) {
          const result = await response.json();

          // Access result.data.data to get the actual array
          if (Array.isArray(result.data.data)) {
            setN_ExcelData(result.data.data);
          } else {
            // // toast.error('Fetched data is not an array');
            setN_ExcelData([]);
          }

        } else {
          console.error('Fetch error:', response);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        navigate(`/login`);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchNightTasks();
  }, [token, navigate, selectedMonth, selectedBranch,  selectedCluster]); // Trigger the fetch when token changes


  useEffect(() => {
    const fetchComboTasks = async () => {
      const requestData = {
        selectedMonth: selectedMonth,
        selectedBranch: selectedBranch,
        selectedCluster: selectedCluster,
      };
      try {
        setLoading(true); // Set loading to true while fetching
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMBranchComboTaskList`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const result = await response.json();
          console.log('API Response:', result); // Log the full API response
          console.log('Data in result:', result.data); // Log result.data to verify

          // Access result.data.data to get the actual array
          if (Array.isArray(result.data.data)) {
            setExcelData(result.data.data);
            setFilteredItems(result.data.data); // Set the fetched data
            setItems(result.data.data);
          } else {
            ////toast.error('Fetched data is not an array');
            setExcelData([]);
          }


        } else {
          console.error('Fetch error:', response);
          //navigate(`/login`);
        }
      } catch (error) {
        console.error('Fetch error:', error);
        // navigate(`/login`);

      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchComboTasks();
  }, [token, navigate, selectedMonth, selectedBranch, selectedCluster]); // Trigger the fetch when token, navigate, or selectedDate changes


  useEffect(() => {
    if (items.length > 0) {
      // Filter items based on search query
      const filtered = items.filter((item) => {
        const fullName = `${item.fname || ''} ${item.lname || ''
          }`.toLowerCase();

        const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
        const formattedDate = new Date(item.mtaskDate).toLocaleDateString('en-GB').replace(/\//g, '-');
        const taskDate = item.mtaskDate ? formattedDate.toLowerCase() : '';
        const fname = item.fname ? item.fname.toLowerCase() : '';
        const cluster_name = item.cluster_name ? item.cluster_name.toLowerCase() : '';
        const zone_name = item.zone_name ? item.zone_name.toLowerCase() : '';

        return (
          branch_name.includes(searchQuery.toLowerCase()) ||
          taskDate.includes(searchQuery.toLowerCase()) ||
          fname.includes(searchQuery.toLowerCase()) ||
          cluster_name.includes(searchQuery.toLowerCase()) ||
          zone_name.includes(searchQuery.toLowerCase())
        );
      });


      const sortedFilteredItems = filtered.sort((a, b) => {
        const taskDateA = a.taskDate ? a.taskDate.toLowerCase() : '';
        const taskDateB = b.taskDate ? b.taskDate.toLowerCase() : '';

        return taskDateB.localeCompare(taskDateA); // Descending order
      });

      // Set filtered items only if they differ
      if (
        JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
      ) {
        setFilteredItems(sortedFilteredItems);

      }
    }
  }, [searchQuery, items]);

  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];

  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const exportToExcelMorningTask = () => {
    const formattedExcelData = m_excelData.map((mItem) => ({
      CreatedDTM: `${mItem.createdDTM || '--'} `,
      CreatedBy: `${mItem.fname + ' ' + mItem.lname || '--'} `,
      Branch: mItem.branch_name || '--',

      is_All_Manpower_Available: `${mItem.mt0101 || '--'} `,
      is_All_Manpower_Available_Description: ` ${mItem.mt0102 || '--'}`,

      is_All_Manpower_In_ProperDressCode: `${mItem.mt0201 || '--'} `,
      is_All_Manpower_In_ProperDressCode_Desription: `${mItem.mt0202 || '--'}`,

      Generator_Check: `${mItem.mt0301 || '--'}`,
      Generator_Check_Desription: `${mItem.mt0302 || ''}`,

      is_Pooja_Done: `${mItem.mt0401 || '--'} `,

      Are_All_Biomedical_Equiment_Working: `${mItem.mt0501 || '--'}`,
      Are_All_Biomedical_Equiment_Working_Description: ` ${mItem.mt0502 || '--'}`,

      is_Doc_Avaialble_or_Oncall_Doc_Arranged: `${mItem.mt0601 || '--'}`,
      is_Doc_Avaialble_or_Oncall_Doc_Arranged_Description: `${mItem.mt0602 || ''}`,

      Previous_Day_Pending_Reports: `${mItem.mt0701 || '--'} `,
      Previous_Day_Pending_Reports_Description: ` ${mItem.mt0702 || '--'}`,

      Are_There_Maintainance_Observation: `${mItem.mt0801 || '--'} `,
      Are_There_Maintainance_Observation_Description: ` ${mItem.mt0802 || '-- '
        }`,
      is_Front_Door_Glass_Cleaned_Well: `${mItem.mt0901 || '--'}`,
      is_Front_Door_Glass_Cleaned_Well_Description: ` ${mItem.mt0902 || '-- '
        }`,

      is_IT_Portalup_Dated_With_Upto_Date_Information: `${mItem.mt1001 || '--'}  `,
      is_IT_Portalup_Dated_With_Upto_Date_Information_Description: ` ${mItem.mt1002 || ' '
        }`,
      LastModifiedDTM: `${mItem.modifiedDTM || '--'} `,

    }));
    const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
    XLSX.writeFile(workbook, 'BM_Morning_Tasks.xlsx');
  };

  const exportToExcelNightTask = () => {
    const formattedExcelData = excelData.map((nItem) => {
      // Handling doctor data
      const MriInfo = (nItem.mri || []).map(d => `${d.doctor_name} (${d.bmid}) : ${d.doctor_count}`).join(", ");
      const XrayInfo = (nItem.xray || []).map(d => `${d.doctor_name} (${d.bmid}) : ${d.doctor_count}`).join(", ");
      const CtInfo = (nItem.ct || []).map(d => `${d.doctor_name} (${d.bmid}) : ${d.doctor_count}`).join(", ");
      const UsgInfo = (nItem.usg || []).map(d => `${d.doctor_name} (${d.bmid}) : ${d.doctor_count}`).join(", ");
      const CardioTmtInfo = (nItem.cardiologist_tmt || []).map(d => `${d.doctor_name} (${d.bmid}) : ${d.doctor_count}`).join(", ");
      const CardiologistEcgInfo = (nItem.cardiologist_ecg || []).map(d => `${d.doctor_name} (${d.bmid}) : ${d.doctor_count}`).join(", ");

      return {
        CreatedDTM: `${nItem.createdDTM || '--'} `,
        CreatedBy: `${nItem.fname + ' ' + nItem.lname || '--'} `,
        Branch: nItem.branch_name || '--',
        DayEndReportTallyTotalRevenue: `${nItem.nt0201 || '--'} ${nItem.nt0202 || ''}`,
        DayEndReportTallyCash: `${nItem.nt0301 || '--'} `,
        DayEndReportTallyPaytm: `${nItem.nt0401 || '--'} `,
        DayEndReportTallyCard: `${nItem.nt0501 || '--'} `,
        PDR_Amount: `${nItem.nt1101 || '--'} `,
        TotalRefundsCount: `${nItem.nt1201 || '--'} `,
        TotalRefundsAmount: `${nItem.nt1301 || '--'} `,
        isPettyCashHandlingClear: `${nItem.nt1401 || '--'} `,
        isPettyCashHandlingClear_Description: `${nItem.nt1402 || '--'} `,
        CrossBillingsDone_USG_CT_MRI_XRay_PET_CT_only: `${nItem.nt1901 || '--'} `,
        TotalUSG_Done: `${nItem.nt0601 || '--'} `,
        TotalCT_Done: `${nItem.nt0701 || '--'} `,
        TotalMRI_Done: `${nItem.nt0801 || '--'} `,
        TotalXRay_Done: `${nItem.nt0901 || '--'} `,
        TotalPDRcases: `${nItem.nt1001 || '--'} `,
        GoogleRatingsCollectedInTheDay: `${nItem.nt1801 || '--'} `,
        isAllVMitrNotificationsCleared: `${nItem.nt1701 || '--'} `,
        BranchClosingTimeAsPerLastUpdateFormBranch: `${nItem.nt0101 || '--'} `,
        LastModifiedDTM: `${nItem.modifiedDTM || '--'} `,
        MRI: MriInfo || '--', // Add the doctor info here
        XRay: XrayInfo || '--',
        CT: CtInfo || '--',
        USG: UsgInfo || '--',
        CardiologistTMT: CardioTmtInfo || '--',
        CardiologistECG: CardiologistEcgInfo || '--',
        
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
    XLSX.writeFile(workbook, 'BM_Night_Tasks.xlsx');
  };

  return (
    <>
      <Header />
      <Sidebar />

      <div className='content-wrapper'>
        

        <section style={{ minHeight: '630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"><FormSkeleton /></div>
                  ) : (
                    <div className="card-body">
                      <div className='row '>
                        <div className='col-sm-6'>
                          <table>
                            <tr>
                              <td>
                                <div className='mb-2'>
                                  <select
                                    style={{ width: '5em' }}
                                    className='form-control form-control-sm'
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                  >
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select></div>
                              </td>
                              <td style={{ width: '100%' }}>
                                <div
                                  id='example1_filter'
                                  className='dataTables_filter mb-2'
                                >
                                  <input
                                    type='search'
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className='form-control form-control-sm'
                                    placeholder='Search'
                                    aria-controls='example1'
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='mb-2 '>
                                  <input type='month' name='selectedMonth' id='selectedMonth'
                                    onChange={selectMonth}
                                    value={selectedMonth || thisMonthWithYear}
                                    className='form-control form-control-sm ' />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div> 
                        <div className='col-sm-6' >
                          <table>
                            <tr>
                              <td><select
                                className='form-control form-control-sm'                                 
                                name='zone_id'
                                value={ selectedZoneId || ''}
                                onChange={handleSelectZone}
                              >
                                <option value=''>Select Zone</option>
                                {zoneslist.map((r) => (
                                  <option key={r.id} value={r.zone_id}>
                                    {r.zone}
                                  </option>
                                ))}
                              </select></td>
                              <td><select
                                className='form-control form-control-sm'
                                name='cluster'
                                width='100%'
                                value={selectedCluster || ''}
                                onChange={handleSelectCluster}
                              >
                                <option value=''>Select Cluster</option>
                                {clusterlist.map((r) => (
                                  <option key={r.id} value={r.cluster_id}>
                                    {r.cluster}
                                  </option>
                                ))}
                              </select></td>
                              <td>
                                <select
                                  className="form-control form-control-sm"
                                  name='selectedBranch'
                                  id='selectedBranch'
                                  width='100%'
                                  value={selectedBranch || '0'}
                                  onChange={selectBranch}
                                >
                                  <option value="0">All Branch</option>
                                  {branchlist.length > 0 ? (
                                    branchlist.map((branch) => (
                                      <option key={branch.branch_id} value={branch.branch_id}>
                                        {branch.branch}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="" disabled>
                                      No branches available
                                    </option>
                                  )}
                                </select>


                              </td>

                            </tr>
                          </table>

                        </div>
                        <div className='col-sm-4 mb-2 mt-2'>
                          <table>
                            <tr>
                              <td>
                                <button className='btn btn-sm btn-info '
                                  onClick={() => navigate(`/Bm_z_weeklyGrid`)}>
                                  Weekly Tasks
                                </button>

                              </td>
                              <td>  <button
                                type='button'
                                className='btn btn-sm btn-warning'
                                onClick={exportToExcelMorningTask}
                              >
                                Morning Tasks Excel
                              </button></td>
                              <td> <button
                                type='button'
                                className='btn btn-sm btn-secondary'
                                onClick={exportToExcelNightTask}
                              >
                                Night Tasks Excel
                              </button> </td>
                            </tr>
                          </table>



                        </div>
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Dated</th>
                              <th>Branch</th>
                              <th>Morning Task </th>
                              <th>Night Task</th>
                              <th>Cluster</th>
                              <th>Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.length > 0 ? (
                              currentItems.map((item, index) => {
                                const formattedDate = new Date(item.mtaskDate).toLocaleDateString('en-GB').replace(/\//g, '-');
                                return (
                                  <tr key={index}>
                                    <td>{formattedDate}</td>
                                    <td>{item.branch_name} </td>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.mid) {
                                            console.log('Navigating to EditBmMorningTask with mid:', item.mid); // Log the mid value
                                            navigate(`/EditBmMorningTask/${item.mid}`);
                                          } else {
                                            console.error('mid is undefined or empty');
                                            //toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }}
                                        className="btn btn-sm btn-warning fas fa-edit"
                                      ></i> {item.fname} {item.lname}
                                    </td>
                                    <td>
                                      {item.nid ? (<>
                                        <i onClick={() => {
                                          if (item.nid) {
                                            navigate(`/EditBmNightTask/${item.nid}`);
                                          } else {
                                            console.error('nid is undefined or empty');
                                            //toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }} className="btn btn-sm btn-secondary fas fa-edit">
                                        </i> {item.nt_fname} {item.nt_lname}</>
                                      ) : 'No Night Task'}

                                    </td>
                                    <td>{item.cluster_name}</td>
                                    <td>{item.zone_name}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);

                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );

                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }

                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${pageNumber === currentPage ? 'active' : ''
                                    }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>

                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>

      </div>




      <Footer />
    </>
  );
};

export default Bm_z_CombinedGrid;
