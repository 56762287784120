import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
import * as XLSX from 'xlsx';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
const CmTask = () => {
	const { role } = useAuth();
	const [clusterlist, setClusterlist] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [zoneslist, setZoneslist] = useState([]);
	const [loading, setLoading] = useState([]);
	const [selectedCluster, setSelectedCluster] = useState();
	const [selectedBranch, setSelectedBranch] = useState();
	const [mtDetails, setMtDetails] = useState();
	const [nightTaskDetails, setNightTaskDetails] = useState();
	const [selectedDate, setSelectedDate] = useState();
	const [selectedZone, setSelectedZone] = useState();
	const token = sessionStorage.getItem('authToken');
	const [excelData, setExcelData] = useState([]);

	const selectBranch = (e) => {
		const branchId = e.target.value;
		setSelectedBranch(branchId);
		sessionStorage.setItem('selectedBranchId', branchId); // Store in sessionStorage
	};
	const selectDate = (e) => {
		const sDate = e.target.value;
		setSelectedDate(sDate);
		sessionStorage.setItem('selectedDate', sDate); // Store in sessionStorage
	};
	// const handleSelectBranch = (e) => {
	// 	const branchId = e.target.value;
	// 	setSelectedBranch(branchId);
	// 	sessionStorage.setItem('selectedBranchId', branchId); // Store in sessionStorage
	// };
	// const handleSelectZone = (e) => {
	// 	const zoneId = e.target.value;
	// 	setSelectedZone(zoneId);
	// 	sessionStorage.setItem('selectedZoneId', zoneId); // Store in sessionStorage
	// };

	const handleSelectCluster = (e) => {
		const selectedClusterId = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			cluster: selectedClusterId,
			cluster_id: selectedClusterId,
		}));

		sessionStorage.setItem('selectedClusterId', selectedClusterId);
		console.log('Cluster ID stored in session:', selectedClusterId);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log(`Input changed - Name: ${name}, Value: ${value}`); // Debugging line

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value, // Update the specific field
		}));
	};
	const [formData, setFormData] = useState({
		cluster: '',
		cluster_id: selectedCluster || '',
	});

	useEffect(() => {
		const selectDate = sessionStorage.getItem('selectedDate');
		const selectBranch = sessionStorage.getItem('selectedBranchId');
		const selectCluster = sessionStorage.getItem('selectedClusterId');
		// Set the current date if selectedDate is empty
		if (selectDate) {
			setSelectedDate(selectDate);
		} else {
			const today = new Date().toISOString().split('T')[0];
			setSelectedDate(today);
		}

		// Check if selectedBranch exists, otherwise set default from branchlist once it's available
		if (selectBranch) {
			setSelectedBranch(selectBranch);
		} else if (branchlist.length > 0) {
			setSelectedBranch(branchlist[0].branch_id);
			sessionStorage.setItem('selectedBranchId', branchlist[0].branch_id);
		}

		if (selectCluster) {
			setSelectedCluster(selectCluster);
		} else if (clusterlist.length > 0) {
			setSelectedCluster(clusterlist[0].cluster_id);
			sessionStorage.setItem('selectedClusterId', clusterlist[0].cluster_id);
		}
	}, [branchlist, clusterlist]);

	useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
					} else {
						//toast.error( 	result.message || 	'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);
	useEffect(() => {
		async function fetchRoleData() {
			try {
				const rolesResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getUserZones`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
				});
				if (!rolesResponse.ok) {
					throw new Error(`Error: ${rolesResponse.status}`);
				}
				const roleData = await rolesResponse.json();

				if (roleData && Array.isArray(roleData.data)) {
					console.log(`Zone data: ${roleData.data}`);
					setZoneslist(roleData.data); // Make sure data is an array
				} else {
					console.error('No valid data received');
					setZoneslist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching roles:', error);
				setZoneslist([]); // Empty array on error
			}
		}

		fetchRoleData();
	}, [token]);
	useEffect(() => {
		const fetchBranchList = async () => {
			try {
				const selectedClusterId = sessionStorage.getItem('selectedClusterId'); // Retrieve cluster ID from session storage

				// Log the selectedClusterId to confirm its value
				console.log('Retrieved selectedClusterId:', selectedClusterId);

				// Ensure selectedClusterId is not null or undefined
				if (!selectedClusterId) {
					console.warn('No cluster ID found in session storage.');
					//toast.error('Cluster ID is missing. Please select a cluster.');
					setLoading(false);
					return;
				}

				// Create FormData and append the selectedClusterId
				const formData = new FormData();
				formData.append('cluster_id', selectedClusterId);

				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getClusterBranchList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});

				console.log('API Response:', response); // Log the response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						setBranchlist(result.data);
					} else {
						//toast.error( 	result.message || 'An error occurred while fetching the Branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		if (token && sessionStorage.getItem('selectedClusterId')) {
			fetchBranchList();
		}
	}, [token, sessionStorage.getItem('selectedClusterId')]); // Dependency array

	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedBranch: selectedBranch,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getMorningTaskDetails`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setMtDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setMtDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setMtDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]);

	useEffect(() => {
		async function fetchDownload() {
			try {
				// Prepare the request payload in URL-encoded format
				const formData = new URLSearchParams();
				formData.append('selectedDate', selectedDate);

				// Make the API request with URL-encoded payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/branchQuetions`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded', // Set content-type as URL-encoded
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: formData.toString(), // Convert formData to URL-encoded string
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const excelData = await mtResponse.json();

				if (excelData && Array.isArray(excelData.data)) {
					console.log('excelData:', excelData.data);
					setExcelData(excelData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setExcelData([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setExcelData([]); // Empty array on error
			}
		}

		fetchDownload();
	}, [token, selectedDate]);
	const exportToExcel = () => {
		const formattedExcelData = excelData.map((item) => ({
			Branch: item.branchName || '',
			is_All_Manpower_Available: `${item.mt0101 || ''} ${item.mt0102 || ''}`,
			is_All_Manpower_In_ProperDressCode: `${item.mt0201 || ''} ${
				item.mt0202 || ''
			}`,
			Generator_Check: `${item.mt0301 || ''} ${item.mt0302 || ''}`,
			is_Pooja_Done: `${item.mt0401 || ''} ${item.mt0402 || ''}`,
			Are_All_Biomedical_Equiment_Working: `${item.mt0501 || ''} ${
				item.mt0502 || ''
			}`,
			is_Doc_Avaialble_or_Oncall_Doc_Arranged: `${item.mt0601 || ''} ${
				item.mt0602 || ''
			}`,
			Previous_Day_Pending_Reports: `${item.mt0701 || ''} ${item.mt0702 || ''}`,
			Are_There_Maintainance_Observation: `${item.mt0801 || ''} ${
				item.mt0802 || ''
			}`,
			is_Front_Door_Glass_Cleaned_Well: `${item.mt0901 || ''} ${
				item.mt0902 || ''
			}`,
			is_IT_Portalup_Dated_With_Upto_Date_Information: `${item.mt1001 || ''} ${
				item.mt1002 || ''
			}`,
			Dated: `${item.createdDTM || ''} `,
			CreatedBy: `${item.fname + ' ' + item.lname || ''} `,
		}));
		const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
		XLSX.writeFile(workbook, 'Branches_Details.xlsx');
	};

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);
	useEffect(() => {
		async function fetchMtData() {
			try {
				// Prepare the request payload in JSON format
				const requestData = {
					selectedDate: selectedDate,
					selectedBranch: selectedBranch,
				};

				// Make the API request with JSON payload
				const mtResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getNightTaskDetails`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json', // Set content-type as JSON
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
					body: JSON.stringify(requestData), // Stringify the JSON payload
				});

				if (!mtResponse.ok) {
					throw new Error(`Error: ${mtResponse.status}`);
				}

				const mtData = await mtResponse.json();

				if (mtData && Array.isArray(mtData.data)) {
					console.log('MtData:', mtData.data);
					setNightTaskDetails(mtData.data); // Set the received data to state
				} else {
					console.error('No valid data received');
					setNightTaskDetails([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching morning task data:', error);
				setNightTaskDetails([]); // Empty array on error
			}
		}

		fetchMtData();
	}, [token, selectedDate, selectedBranch]); // Dependencies to trigger the effect

	// Helper function to check if the user has the required role
	const hasAccess = role === 'SUPER_ADMIN' || role === 'AUDITOR';
	const today = new Date().toISOString().split('T')[0];
	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				{/* Content Header (Page header) */}
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-3 form-group'>
								<select
									className='form-control form-control-sm'
									name='cluster'
									value={formData.cluster || selectedCluster || ''}
									onChange={handleSelectCluster}
								>
									<option value=''>Select Cluster</option>
									{clusterlist.map((r) => (
										<option key={r.id} value={r.cluster_id}>
											{r.cluster}
										</option>
									))}
								</select>
							</div>
							<div className='col-sm-3 form-group'>
								<select
									name='selectBranch'
									id='selectBranch'
									onChange={selectBranch}
									className='form-control form-control-sm'
									value={selectedBranch} // Set the default selected value
								>
									<option value=''>Select Branch</option>
									{branchlist.map((b) => (
										<option key={b.id} value={b.branch_id}>
											{b.branch}
										</option>
									))}
								</select>
							</div>
							<div className='col-sm-3 form-group'>
								<input
									type='date'
									name='selectDate'
									onChange={selectDate}
									className='form-control form-control-sm'
									placeholder='Select Date'
									max={today}
									value={selectedDate} // Set the default value here
								/>
							</div>
							<div className='col-sm-3 form-group' style={{ float: 'left' }}>
								<button onClick={exportToExcel} className='btn btn-sm btn-info'>
									Download
								</button>
							</div>
						</div>
						{/* /.row */}
						<section className='col-lg-12'>
							<div className='card'>
								<div className='card-body row'>
									<div className='col-sm-6 mt-2'>
										{mtDetails && mtDetails.length > 0 ? (
											<>
												<h5>Morning Check Lists</h5>
												<div
													className='task-container'
													style={{
														border: '1px solid #cccccc',
														paddingLeft: '10px',
														paddingTop: '10px',
													}}
												>
													{mtDetails.map((task, index) => (
														<div key={index} className='task-card'>
															<p>
																<strong>
																	{task.fname} {task.lname}{' '}
																	<small>({task.emp_code} )</small>
																</strong>
															</p>
															<p className='pp1'>
																<strong> {task.mt0100} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0101} {task.mt0102}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt0200} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0201} {task.mt0202}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.mt0300} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0301} {task.mt0302}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt0400} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0401} {task.mt0402}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt0500} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0501} {task.mt0502}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt0600} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0601} {task.mt0602}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt0700} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0701} {task.mt0702}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt0800} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0801} {task.mt0802}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.mt0900} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt0901} {task.mt0902}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.mt1000} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.mt1001} {task.mt1002}
																</span>
															</p>
														</div>
													))}
												</div>
											</>
										) : (
											<></>
										)}
									</div>

									<div style={{ minHeight:'500px'}} className='col-sm-6 mt-2'>
										{nightTaskDetails && nightTaskDetails.length > 0 ? (
											<>
												<h5>Night Closing Check List</h5>
												<div
													className='task-container'
													style={{
														border: '1px solid #cccccc',
														paddingLeft: '10px',
														paddingTop: '10px',
													}}
												>
													{nightTaskDetails.map((task, index) => (
														<div key={index} className='task-card'>
															<p>
																<strong>
																	{task.fname} {task.lname}{' '}
																	<small>({task.emp_code} )</small>
																</strong>
															</p>
															<p className='pp1'>
																<strong> {task.nt0100} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0101} {task.nt0102}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0200} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0201} {task.nt0202}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0200} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0201} {task.nt0202}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt0300} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0301} {task.nt0302}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0400} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0401} {task.nt0402}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0500} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0501} {task.nt0502}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0600} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0601} {task.nt0602}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0700} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0701} {task.nt0702}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt0800} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0801} {task.nt0802}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt0900} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt0901} {task.nt0902}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1000} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1001} {task.nt1002}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt1100} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1101} {task.nt1102}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1200} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1201} {task.nt1202}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt1300} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1301} {task.nt1302}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1400} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1401} {task.nt1402}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1500} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1501} {task.nt1502}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1600} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1601} {task.nt1602}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1700} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1701} {task.nt1702}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt1800} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1801} {task.nt1802}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt1900} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt1901} {task.nt1902}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2000} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2001} {task.nt2002}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt2100} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2101} {task.nt2102}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2200} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2201} {task.nt2202}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt2300} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2301} {task.nt2302}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2400} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2401} {task.nt2402}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2500} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2501} {task.nt2502}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2600} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2601} {task.nt2602}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2700} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2701} {task.nt2702}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt2800} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2801} {task.nt2802}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt2900} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt2901} {task.nt2902}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3000} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3001} {task.nt3002}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt3100} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3101} {task.nt3102}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3200} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3201} {task.nt3202}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt3300} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3301} {task.nt3302}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3400} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3401} {task.nt3402}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3500} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3501} {task.nt3502}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3600} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3601} {task.nt3602}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3700} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3701} {task.nt3702}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt3800} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3801} {task.nt3802}
																</span>
															</p>

															<p className='pp1'>
																<strong> {task.nt3900} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt3901} {task.nt3902}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt4000} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt4001} {task.nt4002}
																</span>
															</p>
															<p className='pp1'>
																<strong> {task.nt4100} : </strong>{' '}
																<span className='red'>
																	{' '}
																	{task.nt4101} {task.nt4102}
																</span>
															</p>
														</div>
													))}
												</div>
											</>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</section>
					</div>
					{/* /.container-fluid */}
				</div>
				{/* /.content-header */}
			</div>
			<Footer />
		</>
	);
};

export default CmTask;
