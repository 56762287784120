import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';

import * as XLSX from 'xlsx';
import '../customStyle.css';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Manage_cluster_map = () => {
	const navigate = useNavigate();

	const [items, setItems] = useState([]);
	const [branchlist, setBranchlist] = useState([]);
	const [clusterslist, setClusterslist] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [currentBranch, setCurrentBranch] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [filteredClusters, setFilteredClusters] = useState([]); // Clusters filtered by area
	const [modalOpen, setModalOpen] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	// Modal state
	const [showModal, setShowModal] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [selectedBranchInfo, setSelectedBranchInfo] = useState(null);
	const [formData, setFormData] = useState({
		cluster_id: '',
		branches: [], // To hold selected branch IDs
		area_id: [], // Ensure branches is initialized as an array
		mobile: '',
		branch: '',
		branch_code: '',
		id: '',
	});

	const [arealist, setArealist] = useState([]);
	const token = sessionStorage.getItem('authToken');
	//console.log('token is ', token);
	const validateEditFormData = () => {
		if (!formData.area_id || formData.area_id.length === 0) {
			//toast.error('At least one area must be selected.');
			//console.log("Validation failed: 'area_id' is empty or not defined");
			return false;
		}
		if (!formData.cluster_id) {
			//toast.error('Cluster is required.');
			//console.log("Validation failed: 'cluster_id' is missing");
			return false;
		}
		if (!formData.branches || formData.branches.length === 0) {
			//toast.error('At least one branch must be selected.');
			//console.log("Validation failed: 'branches' is empty or not defined");
			return false;
		}
		//console.log('Validation passed');
		return true;
	};

	useEffect(() => {
		async function fetchClusterData() {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getClusters`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!response.ok) {
					throw new Error(`Error: ${response.status}`);
				}
				const data = await response.json();
				if (data && Array.isArray(data.data)) {
					setClusterslist(data.data); // Set the complete cluster list
				} else {
					console.error('No valid cluster data received');
				}
			} catch (error) {
				console.error('Error fetching clusters:', error);
			}
		}

		fetchClusterData();
	}, [token]);

	useEffect(() => {
		async function fetchClusterData() {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchDetails`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!response.ok) {
					throw new Error(`Error: ${response.status}`);
				}
				const data = await response.json();
				if (data && Array.isArray(data.data)) {
					setBranchlist(data.data); // Set the complete cluster list
				} else {
					console.error('No valid branch data received');
				}
			} catch (error) {
				console.error('Error fetching branchs:', error);
			}
		}

		fetchClusterData();
	}, [token]);

	useEffect(() => {
		async function fetchAreaData() {
			try {
				const areaResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getArea`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!areaResponse.ok) {
					throw new Error(`Error: ${areaResponse.status}`);
				}
				const areaData = await areaResponse.json();

				if (areaData && Array.isArray(areaData.data)) {
					setArealist(areaData.data);
				} else {
					console.error('No valid area data received');
					setArealist([]);
				}
			} catch (error) {
				console.error('Error fetching areas:', error);
				setArealist([]);
			}
		}
		fetchAreaData();
	}, [token]);

	// Filter clusters whenever area_id changes
	useEffect(() => {
		if (formData.area_id) {
			const filtered = clusterslist.filter(
				(cluster) => cluster.area_id === formData.area_id,
			);
			setFilteredClusters(filtered);
		} else {
			setFilteredClusters(clusterslist); // Reset to all clusters if no area is selected
		}
	}, [formData.area_id, clusterslist]);

	useEffect(() => {
		if (selectedBranchInfo) {
			setFormData({
				branch: selectedBranchInfo.branch || '',
				branch_code: selectedBranchInfo.branch_code || '', // Ensure branch_code is included
				cluster_id: selectedBranchInfo.cluster_id || '', // Ensure cluster_id is included
				mobile: selectedBranchInfo.mobile || '',
				id: selectedBranchInfo.id || '',
				area_id: selectedBranchInfo.area_id || '',
			});
		} else {
			setFormData({
				branch: '',
				branch_code: '', // Reset branch_code
				cluster_id: '', // Reset cluster_id
				mobile: '',
				id: '',
				area_id: '',
			}); // Reset form data when closing modal
		}
	}, [selectedBranchInfo]);

	useEffect(() => {
		const fetchList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchDetails`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
						// No need to set 'Content-Type' for FormData
					},
				});

				//console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					//console.log('Parsed API Response:', result);
					if (result.status) {
						setItems(result.data);
						setFilteredItems(result.data);
						setLoading(false);
					} else {
						//toast.error( result.message || 'An error occurred while fetching the branch list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch branch list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchList();
	}, []); // Empty dependency array, so this runs only once on component mount

	useEffect(() => {
		//console.log('Selected Branch Info:', selectedBranchInfo); // Log selected info
		if (selectedBranchInfo) {
			setFormData({
				branch: selectedBranchInfo.branch || '',
				branch_code: selectedBranchInfo.branch_code || '', // Ensure this is set correctly
				cluster_id: selectedBranchInfo.cl_id || '', // Correctly reference the cluster ID
				mobile: selectedBranchInfo.mobile || '',
				id: selectedBranchInfo.id || '',
			});
		} else {
			setFormData({
				branch: '',
				branch_code: '', // Reset correctly when closing modal
				cluster_id: '',
				mobile: '',
				id: '',
			});
		}
	}, [selectedBranchInfo]);

	useEffect(() => {
		const filtered = items.filter(
			(item) =>
				item.branch?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				item.manager_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				item.cluster_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
				item.id.toString().toLowerCase().includes(searchQuery.toLowerCase()),
		);

		const sorted = filtered.sort((a, b) => {
			if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
			if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
			return 0;
		});

		setFilteredItems(sorted);
		setCurrentPage(1);
		//console.log('Items:', items);
		//console.log('Search Query:', searchQuery);
		//console.log('Filtered Items:', filteredItems);
	}, [searchQuery, items, sortColumn, sortOrder]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(column);
			setSortOrder('asc');
		}
	};

	const exportToExcel = () => {
		const filteredClusters = filteredItems.map((item) => ({
			Id: item.id,
			Branch: item.branch,
			Manager: item.manager_name,
			Cluster: item.cluster_name,
			Area: item.area,
			Mobile: item.mobile,
		}));
		const worksheet = XLSX.utils.json_to_sheet(filteredClusters);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Branches');
		XLSX.writeFile(workbook, 'Branches_Details.xlsx');
	};

	// Open modal for adding or editing a branch
	const openModal = () => {
		//console.log('Opening modal');
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setCurrentBranch(null);
		setShowModal(false);
		setShowDeleteModal(false);
	};
	// Handle modal close
	const handleCloseModal = () => {
		setShowModal(false);
		setShowDeleteModal(false);
		setSelectedBranchInfo(null); // Clear the selected bank info after closing modal
	};
	// Handle Edit button click
	const handleEditClick = (item) => {
		//console.log('Clicked Item:', item); // Debug clicked item
		setSelectedBranchInfo(item); // Set the selected bank info
		setShowModal(true); // Show the modal
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		//console.log('Form submitted');

		// Validate form data
		if (!validateEditFormData()) {
			//console.log('Validation failed');
			return; // Stop execution if validation fails
		}

		// Create FormData object with only required fields
		const formDataToSubmit = new FormData();

		// Include only the necessary fields
		formDataToSubmit.append('cluster_id', formData.cluster_id); // Add cluster_id
		formData.branches.forEach((branchId) => {
			formDataToSubmit.append('branches[]', branchId); // Append branches array
		});
		formData.area_id.forEach((areaId) => {
			formDataToSubmit.append('areas[]', areaId); // Append areas array
		});

	 

		try {
			//console.log('Calling API...');
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/clusterMapping`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			if (response.ok) {
				const result = await response.json();
				if (result.status) {
					 
			 window.location.reload();
					 
					closeModal();
					navigate('/cluster_map');
				} else {
					//toast.error(result.message || 'An error occurred while mapping.');
				}
			} else {
				const errorBody = await response.text();
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};

	const handleSubmitEditForm = async (e) => {
		e.preventDefault();

		//console.log('Form Data before submission:', formData); // Log formData

		const isValid = validateEditFormData(); // Ensure validation
		//console.log('Validation result:', isValid);
		if (!isValid) return;

		// Log each individual field to see if they are set correctly
		//console.log('branch_code:', formData.branch_code);
		//console.log('cluster_id:', formData.cluster_id);

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		//console.log('Form data to submit:', formDataToSubmit); // Check what is being sent

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/editBranchDetails`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			//console.log('API Response:', response); // Log the raw response

			if (response.ok) {
				const result = await response.json();
				//console.log('Parsed API Response:', result); // Check the parsed response

				if (result.status) {
					  window.location.reload() ;
					handleCloseModal();
					navigate('/Manage_cluster_map');
				} else {
					//toast.error(result.message || 'An error occurred while updating.');
				}
			} else {
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
				console.error('Error submitting form:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};
	const handleSubmitDeleteForm = async (e) => {
		e.preventDefault();

		////console.log('Form Data before submission:', formData); // Log formData

		const isValid = validateEditFormData(); // Ensure validation
		//console.log('Validation result:', isValid);
		if (!isValid) return;

		const formDataToSubmit = new FormData();
		Object.keys(formData).forEach((key) => {
			if (formData[key] !== '') {
				formDataToSubmit.append(key, formData[key]);
			}
		});

		// Log FormData contents
		for (const [key, value] of formDataToSubmit.entries()) {
			//console.log(`${key}: ${value}`);
		}

		//console.log('Form data to submit:', formDataToSubmit); // Check what is being sent

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/deleteBranch`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formDataToSubmit,
			});

			//console.log('API Response:', response); // Log the raw response

			if (response.ok) {
				const result = await response.json();
				//console.log('Parsed API Response:', result); // Check the parsed response

				if (result.status) {
					  window.location.reload() 
					handleCloseModal();
					//navigate('/manage_department');
				} else {
					//toast.error(result.message || 'An error occurred while updating.');
				}
			} else {
				//toast.error('Error: ' + response.status + ' - ' + response.statusText);
				console.error('Error submitting form:', response);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to submit form');
		}
	};
	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === 'checkbox') {
			if (name === 'branches') {
				// Handle checkbox logic for branches
				setFormData((prevData) => {
					const updatedBranches = prevData.branches || [];
					if (checked) {
						return {
							...prevData,
							branches: [...updatedBranches, value],
						};
					} else {
						return {
							...prevData,
							branches: updatedBranches.filter(
								(branchId) => branchId !== value,
							),
						};
					}
				});
			} else if (name === 'areas') {
				// Make sure this matches your checkbox names
				setFormData((prevData) => {
					const updatedAreas = prevData.area_id || [];
					if (checked) {
						return {
							...prevData,
							area_id: [...updatedAreas, value], // Append to area_id
						};
					} else {
						return {
							...prevData,
							area_id: updatedAreas.filter((areaId) => areaId !== value), // Remove from area_id
						};
					}
				});
			}
		} else {
			// Handle other input types
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
	};

	const handleDeleteClick = (item) => {
		////console.log('Clicked Item:', item); // Debug clicked item
		setSelectedBranchInfo(item); // Set the selected bank info
		setShowDeleteModal(true); // Show the modal
	};

	return (
		<>
			<Header /> <Sidebar />
			
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h5 className='m-0'>Cluster Mapping Form</h5>
							</div>
						</div>
					</div>
				</div>
				<section className='content'>
					<div className='container-fluid' style={{minHeight:'600px'}}>
						<div className='row'>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-body'>
										<form className='form row' onSubmit={handleSubmit}>
											<div className='col-sm-3'>
												<div className='form-group'>
													<label htmlFor='clusters'>Select Cluster</label>
													<select
														id='clusters'
														name='cluster_id'
														value={formData.cluster_id} // Ensure this binds correctly
														onChange={handleInputChange}
														className='form-control'
														style={{ maxWidth: '80%' }}
														required
													>
														<option value=''>Select Cluster</option>
														{clusterslist.map((cluster) => (
															<option key={cluster.id} value={cluster.id}>
																{cluster.name}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className='col-sm-3'>
												<div className='form-group'>
													<label htmlFor='BRANCH'>Select Branches</label>
													<div
														className='scrollable-checkboxes'
														style={{
															maxHeight: '500px', // Set the max height as needed
															overflowY: 'auto', // Enable vertical scrolling
															border: '1px solid #ced4da', // Optional: for a border like the form control
															padding: '8px',
															borderRadius: '4px',
															maxWidth: '80%',
														}}
													>
														{branchlist.map((b) => (
															<div className='form-check' key={b.id}>
																<input
																	className='form-check-input'
																	type='checkbox'
																	value={b.id}
																	id={`branch_${b.id}`}
																	name='branches' // Ensure this is the name that corresponds to formData.branches
																	onChange={handleInputChange}
																/>

																<label
																	className='form-check-label'
																	htmlFor={`branch_${b.id}`}
																>
																	{b.branch}
																</label>
															</div>
														))}
													</div>
												</div>
											</div>

											<div className='col-sm-3'>
												<div className='form-group'>
													<label htmlFor='area'>Select Area</label>
													<div
														className='scrollable-checkboxes'
														style={{
															maxHeight: '500px',
															overflowY: 'auto',
															border: '1px solid #ced4da',
															padding: '8px',
															borderRadius: '4px',
															maxWidth: '80%',
														}}
													>
														{arealist.map((area) => (
															<div className='form-check' key={area.id}>
																<input
																	className='form-check-input'
																	type='checkbox'
																	value={area.id}
																	id={`area_${area.id}`}
																	name='areas' // Make sure this is 'areas' to match the handling function
																	onChange={handleInputChange}
																/>
																<label
																	className='form-check-label'
																	htmlFor={`area_${area.id}`}
																>
																	{area.area}
																</label>
															</div>
														))}
													</div>
												</div>
											</div>

											<div className='col-sm-3'>
												<button
													type='submit'
													className='btn btn-sm btn-primary mt-5'
												>
													Save
												</button>
											</div>
										</form>
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{/* Custom Modal */}
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<h4>Edit Branch Details</h4>
						{selectedBranchInfo && (
							<>
								 
								<form onSubmit={handleSubmitEditForm}>
									<div className='form-group'>
										<label htmlFor='branch'>Branch</label>
										<input
											type='text'
											id='branch'
											name='branch'
											value={formData.branch}
											onChange={handleInputChange}
											className='form-control'
											required
										/>
									</div>

									<div className='form-group'>
										<label htmlFor='clusters'>Cluster</label>
										<select
											id='clusters'
											name='cluster_id'
											value={formData.cluster_id} // Ensure this binds correctly
											onChange={handleInputChange}
											className='form-control'
											required
										>
											<option value=''>Select Cluster</option>
											{clusterslist.map((cluster) => (
												<option key={cluster.id} value={cluster.id}>
													{cluster.name}
												</option>
											))}
										</select>
									</div>

									<input type='hidden' name='id' value={formData.id} />
									<button type='submit' className='btn btn-sm btn-primary'>
										Submit
									</button>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			{showDeleteModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>

						{selectedBranchInfo && (
							<>
								{/* {//console.log('Selected Branch Info:', selectedBranchInfo)} */}
								<form onSubmit={handleSubmitDeleteForm}>
									<div className='modal-body'>
										<div className='form-group'>
											<label htmlFor='department'>
												Are you sure? Want to delete branch -{formData.branch}
											</label>
										</div>
									</div>
									<div className='modal-footer'>
										<input type='text' name='id' value={formData.id || ''} />
										<button
											type='button'
											className='btn btn-secondary'
											onClick={closeModal}
										>
											Close
										</button>
										<button type='submit' className='btn btn-primary'>
											Update
										</button>
									</div>
								</form>
							</>
						)}
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Manage_cluster_map;
