import React, { useState, useEffect } from 'react';

import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';


import '../customStyle.css';

const CM_Dashboard = () => {

	const [selectedMonth, setSelectedMonth] = useState();
	const [cmDashboardCount, setBmDashboardCount] = useState([]);
	const token = sessionStorage.getItem('authToken');
	const [loading, setLoading] = useState([]);
	const selectMonth = (e) => {
		const sMonth = e.target.value;
		setSelectedMonth(sMonth);
		sessionStorage.setItem('selectedMonth', sMonth); // Store in sessionStorage
		window.location.reload();
	  };
	  const today = new Date();
	  const thisMonth = today.getMonth() + 1;
	  const thisYear = today.getFullYear();
	  const thisMonthWithYear = `${thisYear}-${String(thisMonth).padStart(2, '0')}`;

	  useEffect(() => {		 
		const selectMonth = sessionStorage.getItem('selectedMonth') || thisMonthWithYear;
	 		setSelectedMonth(selectMonth);		 
	  }, [ thisMonthWithYear]);
	
	 
	useEffect(() => {
		const fetchClusterList = async () => {
			const requestData = {
				selectedMonth: selectedMonth,
				 
			  };
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/CM_DashboardCount`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestData),
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setBmDashboardCount(result.data);
					} else {
						//toast.error( 	result.message || 'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					//toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				//toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token, selectedMonth, ]);

	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-10'>
								<h3 className='m-0'> Welcome to Vijaya Diagnostic Centre </h3>
							</div>
							<div className='col-sm-2'>
							 <div className='mb-2 '>
                                  <input type='month' name='selectedMonth' id='selectedMonth'
                                    onChange={selectMonth}
                                    value={selectedMonth || thisMonthWithYear}
                                    className='form-control form-control-sm ' />
                                 
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className='content'>
					<div className='container-fluid'>
						<div className='row' style={{ minHeight: '630px' }}>
							<div className='col-lg-3'>
								<div className='small-box bg-info'>
									<div className='inner'>
										<h3>{cmDashboardCount.bm_morningTasksCount}</h3>
										<p>BM - Total Morning Tasks</p>
									</div>
									<div className='icon'>
										<i class='fas fa-sun'></i>
										</div>
									{/* <a href='#' className='small-box-footer'>
										More info <i className='fas fa-arrow-circle-right' />
									</a> */}
								</div>
							</div>
							<div className='col-lg-3'>
								<div className='small-box bg-success'>
									<div className='inner'>
										<h3>
											{cmDashboardCount.bm_nightTasksCount}
										</h3>
										<p>BM - Total Night Closing Tasks</p>
									</div>
									<div className='icon'><i className="fas fa-solid fa-moon"></i>
										</div>	
								</div>
							</div>
							<div className='col-lg-3'>
								<div className='small-box bg-primary'>
									<div className='inner'>
										<h3>{cmDashboardCount.cm_morningTasksCount}</h3>
										<p>CM - Total Morning Tasks</p>
									</div>
									<div className='icon'>
										<i class='fas fa-sun'></i>
										</div>
								</div>
							</div>
							<div className='col-lg-3'>
								<div className='small-box bg-warning'>
									<div className='inner'>
										<h3>
											{cmDashboardCount.cm_nightTasksCount}
										</h3>
										<p>CM - Total Night Closing Tasks</p>
									</div>
									<div className='icon'><i className="fas fa-solid fa-moon"></i>
										</div>	
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default CM_Dashboard;
