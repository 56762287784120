import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FormSkeleton from '../../components/other/formSkeleton';

const Manage_zone_map = () => {
	const navigate = useNavigate();
	const token = sessionStorage.getItem('authToken');

	const [zonelist, setZonelist] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false); // Modal state
	const [showModal, setShowModal] = useState(false);
	const [brancheslist, setBrancheslist] = useState([]);
	const [clusterslist, setClusterslist] = useState([]);
 
	const [selectedClusterForBranch, setSelectedClusterForBranch] =
		useState(null);
	const handleClusterClick = (clusterId) => {
		setSelectedClusterForBranch(clusterId);
		setModalOpen(true);
	};
	const [zoneTree, setZoneTree] = useState(null);

 

	const closeModal = () => {
		setModalOpen(false);
		setShowModal(false);
	};

	// Handle modal close
	const handleCloseModal = () => {
		setShowModal(false);
		setModalOpen(false);
	};

	// Fetch clusters data
	useEffect(() => {
		async function fetchClusterData() {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getClusters`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!response.ok) {
					throw new Error(`Error: ${response.status}`);
				}
				const data = await response.json();
				if (data && Array.isArray(data.data)) {
					setClusterslist(data.data); // Set the complete cluster list
				} else {
					console.error('No valid cluster data received');
				}
			} catch (error) {
				console.error('Error fetching clusters:', error);
			}
		}

		fetchClusterData();
	}, [token]);

	// Fetch branches data
	useEffect(() => {
		async function fetchBranchData() {
			try {
				const branchResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getBranchDetails`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`, // Ensure correct format
					},
				});
				if (!branchResponse.ok) {
					throw new Error(`Error: ${branchResponse.status}`);
				}
				const branchData = await branchResponse.json();

				if (branchData && Array.isArray(branchData.data)) {
					setBrancheslist(branchData.data); // Make sure data is an array
				} else {
					console.error('No valid data received');
					setBrancheslist([]); // Empty array if no valid data
				}
			} catch (error) {
				console.error('Error fetching branches:', error);
				setBrancheslist([]); // Empty array on error
			}
		}
		fetchBranchData();
	}, [token]);

	//
	useEffect(() => {
		async function fetchZoneData() {
			try {
				const zoneResponse = await fetch(`${process.env.REACT_APP_API_URL}api/getZones`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (!zoneResponse.ok) {
					throw new Error(`Error: ${zoneResponse.status}`);
				}
				const zoneData = await zoneResponse.json();
				if (zoneData && Array.isArray(zoneData.data)) {
					setZonelist(zoneData.data);
				} else {
					setZonelist([]);
				}
			} catch (error) {
				console.error('Error fetching zones:', error);
				setZonelist([]);
			} finally {
				setLoading(false);
			}
		}
		fetchZoneData();
	}, [token]);

	const [formData, setFormData] = useState({
		branch_id: [],
		cluster_id: '',
		zone_id: sessionStorage.getItem('selectedZoneId') || '',
	});
	const [selectedZoneId, setSelectedZoneId] = useState(formData.zone_id);

	 

	// Handle zone change
	const handleZoneChange = async (input) => {
		// Check if input is an event (from dropdown change) or a direct zone_id string
		const zone_id = input.target ? input.target.value : input;
		setSelectedZoneId(zone_id);
		sessionStorage.setItem('selectedZoneId', zone_id);

		// Update formData with the selected zone_id
		setFormData((prevData) => ({
			...prevData,
			zone_id: zone_id,
		}));

		if (zone_id) {
			const formDataPayload = new FormData();
			formDataPayload.append('zone_id', zone_id);

			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}api/getZoneClusterBranchesTree`,
					{
						method: 'POST',
						headers: {
							Authorization: `Bearer ${token}`,
						},
						body: formDataPayload,
					},
				);

				if (response.ok) {
					const result = await response.json();
					if (result.STATUS) {
						setZoneTree(result.data);
					} else {
						////toast.error(result.message || 'Error fetching zone tree.');
						setZoneTree(null);
					}
				} else {
					 
					////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				////toast.error('Failed to fetch zone tree');
			} finally {
				setLoading(false);
			}
		}
	};

	 

	// Handle input change
	const handleInputChange = (e) => {
		e.preventDefault();
		const { name, value, type, options } = e.target;

		// Handling checkboxes/select with multiple options
		if (type === 'select-multiple') {
			const selectedValues = Array.from(options)
				.filter((option) => option.selected)
				.map((option) => option.value);

			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: selectedValues,
			}));
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value,
			}));
		}
	};

	// Handle form submission
	const handleSubmitCluster = async () => {
		const formDataToSend = new FormData();
		formDataToSend.append('cluster_id', formData.cluster_id);
		formDataToSend.append('zone_id', selectedZoneId);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addClusterToZone`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					// No need to set 'Content-Type' for FormData; it is set automatically
				},
				body: formDataToSend,
			});

			if (response.ok) {
				const result = await response.json();
				if (result.STATUS) {
					 window.location.reload();
					closeModal();
					navigate('/zone_map');
				} else {
					 
				}
			} else {
				////toast.error('Error: Please Check.');
			}
		} catch (error) {
			////toast.error('Failed to submit form');
		}
	};

	const handleSubmitBranch = async () => {
		const formDataToSend = new FormData();
		formDataToSend.append('branch_id', formData.branch_id);
		formDataToSend.append('cluster_id', selectedClusterForBranch);

		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/addBranchToCluster`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
					// No need to set 'Content-Type' for FormData; it is set automatically
				},
				body: formDataToSend,
			});

			if (response.ok) {
				const result = await response.json();
				if (result.STATUS) {
					  window.location.reload();
					closeModal();
					navigate('/zone_map');
				} else {
					 
				}
			} else {
				////toast.error('Error: Please Check.');
			}
		} catch (error) {
			////toast.error('Failed to submit form');
		}
	};
	return (
		<>
			<Header /> <Sidebar />
			 
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h5 className='m-0'>Zone Cluster Branches Mapping</h5>
							</div>
						</div>
					</div>
				</div>
				<section className='content'>
					<div className='container-fluid'>
						<div className='row'style={{minHeight:'630px'}}>
							<section className='col-lg-12 connectedSortable'>
								<div className='card'>
									<div className='card-header row'>
										<div className='col-sm-3'>Zone :</div>
										<div className='col-sm-3'>
											{loading ? (
												<p> <FormSkeleton /> </p>
											) : (
												<select
													className='form-control form-control-sm'
													name='zone_id'
													value={formData.zone_id} // Default to sessionStorage value
													onChange={handleZoneChange}
												>
													<option value=''>Select Zone</option>
													{zonelist.map((a) => (
														<option key={a.id} value={a.z_id}>
															{a.zone}
														</option>
													))}
												</select>
											)}
										</div>
									</div>
									<div className='card-body row'>
										{zoneTree ? (
											<>
												<div>
													<h5>
														Zone : <span className='red'> {zoneTree.zone}</span>
													</h5>
												</div>
												<div className='col-sm-12 row'>
													{zoneTree.clusters.map((cluster) => (
														<div
															className='col-sm-3'
															style={{
																border: '1px solid #cccccc',
																margin: '5px',
																padding: '15px',
																background: '#eeeeee',
															}}
															key={cluster.cluster_id}
														>
															<h5>
																Cluster:{' '}
																<span className='red'> {cluster.cluster}</span>
															</h5>
															<ul>
																{cluster.branches.map((branch) => (
																	<li key={branch.branch_id}>
																		{branch.branch}
																	</li>
																))}
															</ul>

															<i
																style={{ cursor: 'pointer' }}
																onClick={() =>
																	handleClusterClick(cluster.cluster_id)
																}
																className='fas fa-plus'
																title='Add branch to this cluster'
															></i>
														</div>
													))}
													<div className='col-sm-3'>
														<button
															type='button'
															className='btn btn-sm btn-success'
															onClick={() => setShowModal(true)}
														>
															<i className='fas fa-plus'></i>
														</button>
													</div>
												</div>

												{modalOpen && (
													<div className='customModal'>
														<div className='customModalContent'>
															<span
																className='close'
																onClick={handleCloseModal}
															>
																&times;
															</span>
															<h6>Add Branch to Cluster</h6>

															<form
																onSubmit={(e) => {
																	e.preventDefault();
																	handleSubmitBranch();
																}}
															>
																<div className='modal-body'>
																	<select
																		name='branch_id'
																		className='form-control form-control-sm'
																		onChange={handleInputChange}
																		value={formData.branch_id}
																	>
																		<option value=''>Select branch</option>
																		{brancheslist.map((a) => (
																			<option
																				key={a.branch_id}
																				value={a.branch_id}
																			>
																				{a.branch}
																			</option>
																		))}
																	</select>

																	<input
																		type='hidden'
																		name='cluster_id'
																		className='form-control form-control-sm'
																		value={selectedClusterForBranch}
																	/>
																</div>

																<div className='modal-footer'>
																	<button
																		type='button'
																		className='btn btn-sm btn-secondary'
																		onClick={handleCloseModal}
																	>
																		Close
																	</button>
																	<button
																		type='submit'
																		className='btn btn-sm btn-primary'
																	>
																		Update
																	</button>
																</div>
															</form>
														</div>
													</div>
												)}
											</>
										) : (
											<p>Please Select a zone to see details</p>
										)}
									</div>
								</div>
							</section>
						</div>
					</div>
				</section>
			</div>
			{showModal && (
				<div className='customModal'>
					<div className='customModalContent'>
						<span className='close' onClick={handleCloseModal}>
							&times;
						</span>
						<h6>Add Cluster to Zone</h6>

						<form
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmitCluster();
							}}
						>
							<div className='modal-body'>
								<select
									name='cluster_id'
									className='form-control form-control-sm'
									onChange={handleInputChange}
									value={formData.cluster_id}
								>
									<option value=''>Select Cluster</option>
									{clusterslist.map((a) => (
										<option key={a.cluster_id} value={a.cluster_id}>
											{a.cluster}
										</option>
									))}
								</select>

								<input
									type='hidden'
									className='form-control form-control-sm'
									value={selectedZoneId}
								/>
							</div>

							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-sm btn-secondary'
									onClick={handleCloseModal}
								>
									Close
								</button>
								<button type='submit' className='btn btn-sm btn-primary'>
									Update
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default Manage_zone_map;
