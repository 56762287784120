import React, { useState, useEffect } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Default styles

import '../customStyle.css';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FormSkeleton from '../../components/other/formSkeleton';
const Cm_z_NightGrid = () => {
  const { role } = useAuth();
  const [filteredItems, setFilteredItems] = useState([]); // To hold fetched data
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [token] = useState(sessionStorage.getItem('authToken'));
  const navigate = useNavigate();
	const [searchQuery, setSearchQuery] = useState('');
  const [clusterlist, setClusterlist] = useState([]);
  const [formData, setFormData] = useState([]);
  const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};
  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const userEmp_code = userProfile.emp_code;
  const today = new Date().toISOString().split('T')[0];
	const [excelData, setExcelData] = useState([]);
	// const handlePageChange = (pageNumber) => {
	// 	setCurrentPage(pageNumber);
	// };
  const [items, setItems] = useState([]);
	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};
  const newFormData = 
  {
		cluster: '',
		cluster_id: sessionStorage.getItem('selectedClusterId') || '',
		cm_nt0100: 'Question Number 1 ?',
		cm_nt0101: '',
		cm_nt0102: '',
		cm_nt0200: 'Question Number 2 ?',
		cm_nt0201: '',
		cm_nt0202: '',
		cm_nt0300: 'Question Number 3 ?',
		cm_nt0301: '',
		cm_nt0302: '',
		cm_nt0400: 'Question Number 4 ?',
		cm_nt0401: '',
		cm_nt0402: '',
		cm_nt0500: 'Question Number 5 ?',
		cm_nt0501: '',
		cm_nt0502: '',
		cm_nt0600: 'Question Number 6 ?',
		cm_nt0601: '',
		cm_nt0602: '',
		cm_nt0700: 'Question Number 7 ?',
		cm_nt0701: '',
		cm_nt0702: '',
		cm_nt0800: 'Question Number 8 ?',
		cm_nt0801: '',
		cm_nt0802: '',
		cm_nt0900: 'Question Number 9 ?',
		cm_nt0901: '',
		cm_nt0902: '',
		cm_nt1000: 'Question Number 10 ?',
		cm_nt1001: '',
		cm_nt1002: '',
		branch: '',
		emp_code: userEmp_code,
	}; 
  useEffect(() => {
		const fetchClusterList = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCMclusterList`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				console.log('API Response:', response); // Log API response
				if (response.ok) {
					const result = await response.json();
					console.log('Parsed API Response:', result);
					if (result.STATUS) {
						// Check the correct key
						setClusterlist(result.data);
						sessionStorage.setItem(
							'selectedClusterId',
							result.data[0].cluster_id,
						);
					} else {
						////toast.error( result.message || 	'An error occurred while fetching the Cluster list.',);
					}
				} else {
					const errorBody = await response.text();
					console.error('Error response:', errorBody);
					////toast.error(`Error: ${response.status} - ${response.statusText}`);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				////toast.error('Failed to fetch Cluster list');
			} finally {
				setLoading(false); // Ensure loading state is set to false after the fetch
			}
		};

		fetchClusterList();
	}, [token]);

	useEffect(() => {
		const selectedClusterId = sessionStorage.getItem('selectedClusterId');
		if (selectedClusterId) {
			setFormData((prevData) => ({
				...prevData,
				cluster: selectedClusterId,
				cluster_id: selectedClusterId,
			}));
			console.log('Retrieved Cluster ID from session:', selectedClusterId);
		}
	}, []);

  const createNewCMNightTask = async () => {       
    const requestData = {
      createdDTM:today,				 
      ...newFormData,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/addCmNightTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === true) {					 
         navigate(`/EditCmNightTask/${result.data}`);
        }					 
      }
    } catch (error) {
      //toast.error('Failed to create default task');
    }
  };

 
  useEffect(() => {
	const fetchNightTasks = async () => {
	  try {
		setLoading(true); // Set loading to true while fetching
		const response = await fetch(`${process.env.REACT_APP_API_URL}api/getCm_Z_NightTaskList`, {
		  method: 'POST',
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		});
  
		if (response.ok) {
		  const result = await response.json();
		  console.log('API Response:', result); // Log the full API response
		  console.log('Data in result:', result.data); // Log result.data to verify
  
		  // Access result.data.data to get the actual array
		  if (Array.isArray(result.data.data)) {
        setExcelData(result.data.data);
			  setFilteredItems(result.data.data); // Set the fetched data
        setItems(result.data.data);
		  } else {
			  ////toast.error('Fetched data is not an array');
        setExcelData([]);
		  }
 

		} else {
		  //toast.error('Failed to fetch morning tasks');
		}
	  } catch (error) {
		console.error('Fetch error:', error);
		//toast.error('Failed to fetch morning tasks');
	  } finally {
		setLoading(false); // Set loading to false after fetching
	  }
	};
  
	fetchNightTasks();
  }, [token]); // Trigger the fetch when token changes
  

  useEffect(() => {
		if (items.length > 0) {
			// Filter items based on search query
			const filtered = items.filter((item) => {
				const fullName = `${item.fname || ''} ${
					item.lname || ''
				}`.toLowerCase();
 
				const branch_name = item.branch_name ? item.branch_name.toLowerCase() : '';
				const formattedDate = new Date(item.createdDTM).toLocaleDateString();
        const createdDTM = item.createdDTM ?  formattedDate.toLowerCase() : '';
				const fname = item.fname ? item.fname.toLowerCase() : '';
				const cluster_name = item.cluster_name ? item.cluster_name.toLowerCase() : '';
				const zone_name = item.zone_name ? item.zone_name.toLowerCase() : '';
				 
				return (				 
					branch_name.includes(searchQuery.toLowerCase()) ||
					createdDTM.includes(searchQuery.toLowerCase()) ||
					fname.includes(searchQuery.toLowerCase()) ||
					cluster_name.includes(searchQuery.toLowerCase()) ||
					zone_name.includes(searchQuery.toLowerCase())
				);
			});

			// Sort filtered items by role in descending order
			const sortedFilteredItems = filtered.sort((a, b) => {
				const createdDTMA = a.createdDTM ? a.createdDTM.toLowerCase() : '';
				const createdDTMB = b.createdDTM ? b.createdDTM.toLowerCase() : '';

				return createdDTMB.localeCompare(createdDTMA); // Descending order
			});

			// Set filtered items only if they differ
			if (
				JSON.stringify(sortedFilteredItems) !== JSON.stringify(filteredItems)
			) {
				setFilteredItems(sortedFilteredItems);
			 
			}
		}
	}, [searchQuery, items]);

  // Pagination logic
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredItems) ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : [];

  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, filteredItems.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


   

  const exportToExcel = () => {
		const formattedExcelData = excelData.map((item) => ({
			Cluster: item.cluster_name || '--',		
			Dated: `${item.createdDTM || '--'} `,
			CreatedBy: `${item.fname + ' ' + item.lname || '--'} `,
      QuestionNumber_1: `${item.cm_nt0101 || '--'} ${item.cm_nt0102 || ''}`,
			QuestionNumber_2: `${item.cm_nt0201 || '--'} ${
				item.cm_nt0202 || '--'
			}`,
      QuestionNumber_3: `${item.cm_nt0301 || '--'} ${
				item.cm_nt0302 || '--'
			}`,
      QuestionNumber_4: `${item.cm_nt0401 || '--'} ${
				item.cm_nt0402 || '--'
			}`,
      QuestionNumber_5: `${item.cm_nt0501 || '--'} ${
				item.cm_nt0502 || '--'
			}`,
      QuestionNumber_6: `${item.cm_nt0601 || '--'} ${
        item.cm_nt0602 || '--'
      }`,
      QuestionNumber_7: `${item.cm_nt0701 || '--'} ${
        item.cm_nt0702 || '--'
      }`,
      QuestionNumber_8: `${item.cm_nt0801 || '--'} ${
        item.cm_nt0802 || '--'
      }`,
      QuestionNumber_9: `${item.cm_nt0901 || '--'} ${
        item.cm_nt0902 || '--'
      }`,
      QuestionNumber_10: `${item.cm_nt1001 || '--'} ${
        item.cm_nt1002 || '--'
      }`,
		}));
		const worksheet = XLSX.utils.json_to_sheet(formattedExcelData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'CM_Night_task');
		XLSX.writeFile(workbook, 'CM_Night_task_Details.xlsx');
	};

  return (
    <>
      <Header />
      <Sidebar />
      
      <div className='content-wrapper'>
        <div className='content-header'>
          <div className='container-fluid'></div>
        </div>

        <section style={{ minHeight:'630px' }} className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  {loading ? (
                    <div className="card-body"> <FormSkeleton/> </div>
                  ) : (
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-sm-3'>
                          <div className='form-group'>
                            <select
                              style={{ width: '5em' }}
                              className='form-control form-control-sm'
                              value={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div
                            id='example1_filter'
                            className='dataTables_filter'
                          >
                            <label>
                              <input
                                type='search'
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className='form-control form-control-sm'
                                placeholder='Search'
                                aria-controls='example1'
                              />
                            </label>
                          </div>
                        </div>
                        <div className='col-sm-5'>
                         
                          <button
                            type='button'
                            className='btn btn-sm btn-secondary ml-2'
                            onClick={exportToExcel}
                          >
                            Export to Excel
                          </button>
                        </div>
                      </div>

                      {/* Table for displaying the data */}
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th> </th>
                              <th>Cluster</th>
                              <th>Dated</th>
                              <th>Created by</th>                             
                              <th>Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredItems.length > 0 ? (
                              filteredItems.map((item, index) => {
                                const formattedDate = new Date(item.createdDTM).toLocaleDateString();
                                return (
                                  <tr key={index}>
                                    <td>
                                      <i
                                        onClick={() => {
                                          if (item.cm_nid) {                                            
                                            navigate(`/EditCmNightTask/${item.cm_nid}`);
                                          } else {
                                            console.error('mid is undefined or empty');
                                            //toast.error('Invalid task ID. Cannot navigate to edit page.');
                                          }
                                        }}
                                        className="btn btn-sm btn-primary fas fa-edit"
                                      ></i>
                                    </td>
                                    <td>{item.cluster_name}</td>
                                    <td>{formattedDate}</td>
                                    <td>{item.fname} {item.lname}</td>                                    
                                    <td>{item.zone_name}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav>
                        <ul className='pagination' style={{ float: 'right' }}>
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {(() => {
                            const pages = [];
                            const totalPagesToShow = 10; // Number of pages to show
                            const halfToShow = Math.floor(totalPagesToShow / 2);

                            let startPage = Math.max(1, currentPage - halfToShow);
                            let endPage = Math.min(
                              totalPages,
                              startPage + totalPagesToShow - 1,
                            );

                            if (endPage - startPage < totalPagesToShow - 1) {
                              startPage = Math.max(1, endPage - totalPagesToShow + 1);
                            }

                            for (
                              let pageNumber = startPage;
                              pageNumber <= endPage;
                              pageNumber++
                            ) {
                              pages.push(
                                <li
                                  key={pageNumber}
                                  className={`page-item ${
                                    pageNumber === currentPage ? 'active' : ''
                                  }`}
                                >
                                  <button
                                    className='page-link'
                                    onClick={() => handlePageChange(pageNumber)}
                                  >
                                    {pageNumber}
                                  </button>
                                </li>,
                              );
                            }
                            return pages;
                          })()}
                          <li className='page-item'>
                            <button
                              className='page-link'
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>

                      <div className='mb-3'>
                        Showing {startItem} to {endItem} of {filteredItems.length}{' '}
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Cm_z_NightGrid;
