import React, { useState, useEffect } from 'react';
 
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Sidebar from '../../components/common/Sidebar';
import { useAuth } from '../../context/authprovider';
 
 
import '../customStyle.css';

const AVP_Dashboard = () => {
	 
	
const[bmDashboardCount, setBmDashboardCount]= useState([]);
const token = sessionStorage.getItem('authToken');
const [loading, setLoading] = useState([]);

useEffect(() => {
	const fetchClusterList = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}api/BM_DashboardCount`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			console.log('API Response:', response); // Log API response
			if (response.ok) {
				const result = await response.json();
				console.log('Parsed API Response:', result);
				if (result.STATUS) {
					// Check the correct key
					setBmDashboardCount(result.data);
				} else {
					//toast.error( 	result.message || 'An error occurred while fetching the Cluster list.',);
				}
			} else {
				const errorBody = await response.text();
				console.error('Error response:', errorBody);
				//toast.error(`Error: ${response.status} - ${response.statusText}`);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			//toast.error('Failed to fetch Cluster list');
		} finally {
			setLoading(false); // Ensure loading state is set to false after the fetch
		}
	};

	fetchClusterList();
}, [token]);

	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
					<div className='row mb-2' style={{minHeight:'630px'}}>
							<div className='col-sm-6'>
								 
								<h3 className='m-0'> Welcome to Vijaya Diagnostic Centre </h3>		
								 
							</div>
						</div>
					</div>
				</div>

				 
			</div>
			<Footer />
		</>
	);
};

export default AVP_Dashboard;
