import React,{useEffect} from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Sidebar from '../components/common/Sidebar';
import { useAuth } from '../context/authprovider';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
	 const { role } = useAuth();
	const { isAdmin } = useAuth();
	// Helper function to check if the user has the required role
	const hasAccess = isAdmin === 'Y';
	const token = sessionStorage.getItem('authToken');	
	const navigate = useNavigate();

	

	useEffect(() => {
		if (token) { 
			 console.log('Role:', role);
			if (role === 'BM') {
				navigate('/BM_Dashboard');
			}else if (role === 'CM') {
				navigate('/CM_Dashboard');
			}else if (role === 'AVP') {
				navigate('/AVP_Dashboard');
			}else if (role === 'ZONAL_MANAGER') {
				navigate('/ZONAL_Dashboard');
				}else if (role === 'SUPER_ADMIN') {
					navigate('/Dashboard');
				}else{
					navigate('/login');
				}	
		}else{
			navigate('/login');
		}
	}, [role, navigate]);



	return (
		<>
			<Header /> <Sidebar />
			{/* Content Wrapper. Contains page content */}
			<div className='content-wrapper'>
				<div className='content-header'>
					<div className='container-fluid'>
						<div className='row mb-2'>
							<div className='col-sm-6'>
								<h1 className='m-0'>
									{/* {isAdmin === 'Y' && <> ADMIN Dashboard </>} */}
								</h1>
							</div>
						</div>
					</div>
				</div>

				<section className='content' style={{ height: 'auto' }}>
					<div className='container-fluid'>
						<div className='row' style={{ minHeight: '580px' }}>
							<div className='col-lg-12'>
								<h3> Welcome to Vijaya Diagnostic Centre </h3>
								{isAdmin === 'Y' && <p> ADMIN Dashboard </p>}
							</div>

							{/* <div className='col-lg-3 col-6'>
								<div className='small-box bg-info'>
									<div className='inner'>
										<h3>150</h3>
										<p>New Orders</p>
									</div>
									<div className='icon'>
										<i className='ion ion-bag' />
									</div>
									<a href='#' className='small-box-footer'>
										More info <i className='fas fa-arrow-circle-right' />
									</a>
								</div>
							</div>
							<div className='col-lg-3 col-6'>
								<div className='small-box bg-success'>
									<div className='inner'>
										<h3>
											53<sup style={{ fontSize: 20 }}>%</sup>
										</h3>
										<p>Bounce Rate</p>
									</div>
									<div className='icon'>
										<i className='ion ion-stats-bars' />
									</div>
									<a href='#' className='small-box-footer'>
										More info <i className='fas fa-arrow-circle-right' />
									</a>
								</div>
							</div>
							<div className='col-lg-3 col-6'>
								<div className='small-box bg-warning'>
									<div className='inner'>
										<h3>44</h3>
										<p>User Registrations</p>
									</div>
									<div className='icon'>
										<i className='ion ion-person-add' />
									</div>
									<a href='#' className='small-box-footer'>
										More info <i className='fas fa-arrow-circle-right' />
									</a>
								</div>
							</div>
							<div className='col-lg-3 col-6'>
								<div className='small-box bg-danger'>
									<div className='inner'>
										<h3>65</h3>
										<p>Unique Visitors</p>
									</div>
									<div className='icon'>
										<i className='ion ion-pie-graph' />
									</div>
									<a href='#' className='small-box-footer'>
										More info <i className='fas fa-arrow-circle-right' />
									</a>
								</div>
							</div> */}
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default Dashboard;
